/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUser,
  faTrash,
  faEdit,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import starLife from "../../StarLife_Chatbot-06.png";
import "react-phone-number-input/style.css";
import BenButton from "../ui/Button";
import FormBeneficiaryModal from "./FormBeneficiaryModal";
import EditBeneficiaryModal from "./EditBeneficiaryModal";
import FormTrusteeModal from "./FormTrusteeModal";
import EditTrusteeModal from "./EditTrusteeModal";
import FormMemberModal from "./FormMemberModal";
import EditMemberModal from "./EditMemberModal";
import Menu from "./menu";
// import { Modal } from "react-bootstrap";

export class FormBeneficiaryDetails extends PureComponent {
  state = {
    percentageError: "",
    trusteeError: "",
    // familyError: "",
    // showBenModal: false,
    // benList: [],
  };

  // showBenModalHandler = (e) => {
  //   this.setState({ showBenModal: true });
  // };

  // hideBenModalHandler = (e) => {
  //   this.setState({ showBenModal: false });
  // };

  continue = (e) => {
    e.preventDefault();
    let percentageError = "";
    let trusteeError = "";
    // let familyError = "";

    if (
      this.props.values.benList.reduce((a, v) => (a = a + v.percent), 0) !== 100
    ) {
      // Checks if percetage is equal to 100%
      percentageError = "Beneficiary total percentage must add up to 100%";
    }

    if (this.props.values.trusteeRequired) {
      if (Object.keys(this.props.values.trustee).length === 0) {
        trusteeError =
          "Please provide one trustee since your beneficiary(ies) is below 18 years of age";
      }
    }

    // if (
    //   (this.props.values.familyList.length < this.props.values.totalMember) &
    //   (this.props.values.totalMember > 1)
    // ) {
    //   familyError = `Kindly add up to ${this.props.values.totalMember} family members for this package`;
    // } else if (
    //   this.props.values.familyList.length > this.props.values.totalMember
    // ) {
    //   familyError = `Only ${this.props.values.totalMember} family members are required for this package`;
    // }

    // if (!e.target.form[1].value) {
    //   // Checks if field is empty
    //   employerError = "Please enter name of employer";
    // }

    if (percentageError || trusteeError) {
      this.setState({
        percentageError,
        trusteeError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values,
      addBen,
      updateBen,
      showBenModalHandler,
      hideBenModalHandler,
      showEditBenModalHandler,
      hideEditBenModalHandler,
      removeBen,
      showTrusteeModalHandler,
      hideTrusteeModalHandler,
      showEditTrusteeModalHandler,
      hideEditTrusteeModalHandler,
      trusteeRequredHandler,
      addTrust,
      removeTrust,
      valueChange,
      updateTrust,
      addMember,
      updateMember,
      showMemberModalHandler,
      hideMemberModalHandler,
      hideEditMemberModalHandler,
      showEditMemberModalHandler,
      removeMember,
    } = this.props;

    return (
      <div>
        <Menu />
        <hr />
        <br></br>
        <h3>Please add your beneficiary details.</h3>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 900,
            padding: 10,
          }}
        >
          {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
          {/* <br></br> */}
          <div style={{ float: "right" }}>
            <BenButton type="button" onClick={showBenModalHandler}>
              Add Beneficiary
            </BenButton>
          </div>
          <br></br>
          <br></br>
          <br></br>
          {/* <FormBeneficiaryModal
            showModal={this.state.showBenModal}
            hideBenModalHandler={this.hideBenModalHandler}
          ></FormBeneficiaryModal> */}
          {/* {this.state.showBenModal && <FormBeneficiaryModal />} */}
          {values.showBenModal && (
            <FormBeneficiaryModal
              title="Beneficiary Details"
              message="Message"
              onConfirm=""
              backdrop={hideBenModalHandler}
              addBen={addBen}
              trusteeRequred={values.trusteeRequred}
              trusteeRequredHandler={trusteeRequredHandler}
              valueChange={valueChange}
            />
          )}
          {values.showEditBenModal && (
            <EditBeneficiaryModal
              title="Edit Beneficiary Details"
              message="Message"
              onConfirm=""
              backdrop={hideEditBenModalHandler}
              // addBen={addBen}
              updateBen={updateBen}
              othernames={this.state.newBen.othernames}
              surname={this.state.newBen.surname}
              dob={this.state.newBen.dob}
              gender={this.state.newBen.gender}
              relationship={this.state.newBen.relationship}
              percent={this.state.newBen.percent}
              phone={this.state.newBen.phone}
              id={this.state.newBen.id}
              trusteeRequred={values.trusteeRequred}
              valueChange={valueChange}
            />
          )}
          <h4>
            <FontAwesomeIcon icon={faUsers} style={{ color: "#763984" }} />{" "}
            &nbsp; <u>Beneficiary(ies) Details of the Account Holder</u>
          </h4>
          {/* <Card> */}
          <table
            border="1"
            width="100%"
            className="table table-bordered table-hover table-sm w-auto"
          >
            <thead border="1">
              <tr>
                <th>Surname</th>
                <th>Othernames</th>
                <th>Date of Birth</th>
                <th>Gender</th>
                <th>Relationship</th>
                <th>Contact Number</th>
                <th>Percentage</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead>
            <tbody>
              {values.benList.map((ben) => (
                <tr key={ben.id}>
                  <td>{ben.surname}</td>
                  <td>{ben.othernames}</td>
                  <td>{ben.dob}</td>
                  <td>{ben.gender}</td>
                  <td>{ben.relationship}</td>
                  <td>{ben.phone}</td>
                  <td>{ben.percent}</td>
                  <td>
                    <a
                      href="#"
                      onClick={() => {
                        this.setState({ newBen: ben });
                        showEditBenModalHandler();
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </a>
                  </td>
                  <td>
                    {/* <span
                      style={{ color: "red" }}
                      onClick={() => removeBen(ben.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span> */}
                    <a
                      href="#"
                      style={{ color: "red" }}
                      onClick={() => removeBen(ben.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <b>
                    Total{" "}
                    {values.benList.reduce((a, v) => (a = a + v.percent), 0)}
                  </b>
                </td>
              </tr>
            </tfoot>
            {/* <thead>
              <tr>
                <th>Surname</th>
                <th>Othernames</th>
                <th>Date of Birth</th>
                <th>Relationship</th>
                <th>Contact Number</th>
                <th>Percentage</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead> */}
          </table>
          <div
            style={{
              textAlign: "left",
              color: "red",
            }}
          >
            {this.state.percentageError}
          </div>
          <br></br>
          <br></br>
          {values.trusteeRequired && (
            <div>
              <div style={{ float: "right" }}>
                <BenButton type="button" onClick={showTrusteeModalHandler}>
                  Add Trustee
                </BenButton>
              </div>
              <br></br>
              <br></br>
              {values.showTrusteeModal && (
                <FormTrusteeModal
                  title="Trustee Details"
                  message="Message"
                  onConfirm=""
                  backdrop={hideTrusteeModalHandler}
                  addTrust={addTrust}
                  trusteeRequred={values.trusteeRequred}
                  trusteeRequredHandler={trusteeRequredHandler}
                  valueChange={valueChange}
                  trustee={values.trustee}
                />
              )}
              {values.showEditTrusteeModal && (
                <EditTrusteeModal
                  title="Edit Trustee Details"
                  message="Message"
                  onConfirm=""
                  backdrop={hideEditTrusteeModalHandler}
                  // addBen={addBen}
                  updateBen={updateBen}
                  othernames={this.state.newTrust.othernames}
                  surname={this.state.newTrust.surname}
                  dob={this.state.newTrust.dob}
                  gender={this.state.newTrust.gender}
                  relationship={this.state.newTrust.relationship}
                  phone={this.state.newTrust.phone}
                  id={this.state.newTrust.id}
                  trusteeRequred={values.trusteeRequred}
                  valueChange={valueChange}
                  updateTrust={updateTrust}
                />
              )}
              <h4>
                <FontAwesomeIcon icon={faUser} style={{ color: "#763984" }} />{" "}
                &nbsp; <u>Trustee Details</u>
              </h4>
              {/* <Card> */}
              <table
                border="1"
                width="100%"
                className="table table-bordered table-hover table-sm w-auto"
              >
                <thead border="1">
                  <tr>
                    <th>Surname</th>
                    <th>Othernames</th>
                    <th>Date of Birth</th>
                    <th>Gender</th>
                    <th>Relationship</th>
                    <th>Contact Number</th>
                    <th colSpan="2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {values.trustee.map((trustee) => (
                    <tr key={trustee.id}>
                      <td>{trustee.surname}</td>
                      <td>{trustee.othernames}</td>
                      <td>{trustee.dob}</td>
                      <td>{trustee.gender}</td>
                      <td>{trustee.relationship}</td>
                      <td>{trustee.phone}</td>
                      <td>
                        <a
                          href="#"
                          onClick={() => {
                            this.setState({ newTrust: trustee });
                            showEditTrusteeModalHandler();
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </a>
                      </td>
                      <td>
                        {/* <span
                      style={{ color: "red" }}
                      onClick={() => removeBen(ben.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span> */}
                        <a
                          href="#"
                          style={{ color: "red" }}
                          onClick={() => removeTrust(trustee.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
                {/* <thead>
              <tr>
                <th>Surname</th>
                <th>Othernames</th>
                <th>Date of Birth</th>
                <th>Relationship</th>
                <th>Contact Number</th>
                <th>Percentage</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead> */}
              </table>
              <div
                style={{
                  textAlign: "left",
                  color: "red",
                }}
              >
                {this.state.trusteeError}
              </div>
              <br></br>
            </div>
          )}
          <Button
            variant="secondary"
            // type="submit"
            // style={{ backgroundColor: "#763984" }}
            onClick={this.back}
          >
            Back
          </Button>
          &nbsp;
          <Button
            variant="primary"
            // type="submit"
            style={{
              backgroundColor: "#763984",
              outlineColor: "#763984",
              borderColor: "#763984",
            }}
            onClick={this.continue}
          >
            Continue
          </Button>
          {/* </Card> */}
        </div>
      </div>
    );
  }
}

export default FormBeneficiaryDetails;
