import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import mypadie from "../StarLife-Chatbot-paddie.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export class FormPersonalDetails extends Component {
  state = {
    occError: "",
    employerError: "",
  };

  continue = (e) => {
    e.preventDefault();
    console.log(e.target.form[0].value);
    let occError = "";
    let employerError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      occError = "Please select your occupation";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      employerError = "Please enter name of employer";
    }

    if (occError || employerError) {
      this.setState({
        occError,
        employerError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    const occs = [
      { id: "1", label: "Accountant", value: "Accountant" },
      { id: "2", label: "Teacher", value: "Teacher" },
    ];

    // Using state to keep track of what the selected occupation is selected
    // this.setState(occs);

    // Using this function to update the state of fruit
    // whenever a new option is selected from the dropdown

    return (
      <div>
        <h3>
          <img src={mypadie} alt="logo" style={{ width: "70px" }} />
          Nice to meet you,{" "}
          {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
            values["firstName"].toLowerCase().slice(1)}
          ! Please add your employment details.
        </h3>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 500,
            padding: 10,
          }}
        >
          {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
          {/* <br></br> */}
          <h4>
            <FontAwesomeIcon icon={faBriefcase} /> &nbsp;{" "}
            <u>Employer Details</u>
          </h4>
          <Form
            style={{
              color: "purple",
            }}
          >
            <Form.Group>
              <FloatingLabel
                controlId="occupation"
                label="Occupation"
                className="mb-2"
              >
                <Form.Control
                  as="select"
                  onChange={handleChange("occupation")}
                  defaultValue={values.occupation}
                  style={{
                    border: this.state.occError ? "1px solid red" : "",
                  }}
                >
                  <option></option>
                  {occs.map((occ) => (
                    <option key={occ.id} value={occ.value}>
                      {occ.label}
                    </option>
                  ))}
                </Form.Control>
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.occError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="employer"
                label="Name of Employer"
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Name of Employer"
                  autoComplete="off"
                  onChange={handleChange("employer")}
                  defaultValue={values.employer}
                  style={{
                    border: this.state.employerError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.employerError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button>
            &nbsp;
            <Button
              variant="primary"
              // type="submit"
              style={{
                backgroundColor: "#763984",
                borderColor: "#763984",
                outlineColor: "#763984",
              }}
              onClick={this.continue}
            >
              Continue
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default FormPersonalDetails;
