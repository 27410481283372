import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import validator from "validator";
import mypadie from "../StarLife-Chatbot-paddie.png";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import IntlTelInput from "react-bootstrap-intl-tel-input";
import axios from "axios";

export class FormUserDetails extends Component {
  state = {
    firstNameError: "",
    lastNameError: "",
    dobError: "",
    genderError: "",
    phoneError: "",
    emailError: "",
    idTypeError: "",
    idNumberError: "",
  };

  continue = (e) => {
    e.preventDefault();

    const API_USER = process.env.REACT_APP_API_USER;
    const API_KEY = process.env.REACT_APP_API_KEY;
    const API_URL = process.env.REACT_APP_BASE_URL;

    let firstNameError = "";
    let lastNameError = "";
    let dobError = "";
    let genderError = "";
    let phoneError = "";
    let emailError = "";
    let idTypeError = "";
    let idNumberError = "";

    // console.log(e.target.form[8].value);

    if (!e.target.form[1].value) {
      // Checks if field is empty
      firstNameError = "Please enter your first name";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      lastNameError = "Please enter your last name";
    }

    if (!e.target.form[3].value) {
      // Checks if field is empty
      dobError = "Please enter your correct date of birth";
    }

    if (!e.target.form[4].value) {
      // Checks if field is empty
      genderError = "Please select your gender";
    }

    if (!e.target.form[5].value) {
      // Checks if field is empty
      phoneError = "Please enter your phone number";
    }

    if (!e.target.form[6].value) {
      // Checks if field is empty
      idTypeError = "Please select your ID type";
    }

    if (!e.target.form[7].value) {
      // Checks if field is empty
      idNumberError = "Please enter your ID number";
    }

    if (e.target.form[8].value) {
      if (validator.isEmail(e.target.form[8].value)) {
      } else {
        emailError = "Please enter a valid email address";
      }
    }

    console.log(e.target.form[5].value.length);

    console.log(e.target.form[5].value.match(/^\d{10}$/));

    const pattern = new RegExp(/^[0-9\b]+$/);

    if (
      !pattern.test(e.target.form[5].value) ||
      e.target.form[5].value.length !== 10
    ) {
      // Checks if field is empty
      phoneError = "Please enter a valid phone number";
    }

    if (
      firstNameError ||
      lastNameError ||
      dobError ||
      genderError ||
      phoneError ||
      emailError ||
      idTypeError ||
      idNumberError
    ) {
      this.setState({
        firstNameError,
        lastNameError,
        dobError,
        genderError,
        phoneError,
        emailError,
        idTypeError,
        idNumberError,
      });
      return false;
    }

    this.props.nextStep();
    const getOTP = {
      Phone_Number: e.target.form[5].value,
      Api_User: API_USER,
      Api_Key: API_KEY,
    };

    //Get OTP
    axios
      .post(API_URL + "/SL_GetOTP", getOTP)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    return true;
  };

  render() {
    const { values, handleChange } = this.props;

    return (
      <div>
        <h3>
          <img
            src={mypadie}
            alt="logo"
            style={{
              width: "70px",
            }}
          />
          Great! Buying a life insurance policy takes <br></br>only 5 minutes.
          Ready to go?
        </h3>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 500,
            padding: 10,
          }}
        >
          <h4>
            <FontAwesomeIcon icon={faUser} /> &nbsp; <u>Personal Details</u>
          </h4>
          <Form
            style={{
              color: "purple",
            }}
          >
            <Form.Group>
              <FloatingLabel
                controlId="title"
                label="Title"
                className="mb-2"
                style={{ maxHeight: "20" }}
                // size="25%"
              >
                <Form.Control as="select" className="w-50">
                  <option></option>
                  <option value="MR.">MR.</option>
                  <option value="MRS.">MRS.</option>
                  <option value="MS.">MS.</option>
                  <option value="SNTR.">SNTR.</option>
                  <option value="REV.">REV.</option>
                  <option value="HON.">HON.</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
            <Form.Group
              style={{
                color: "purple",
              }}
            >
              <FloatingLabel
                controlId="firstname"
                label="First Name"
                className="mb-2"
              >
                <Form.Control
                  required
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Your First Name"
                  onChange={handleChange("firstName")}
                  defaultValue={values.firstName}
                  style={{
                    border: this.state.firstNameError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.firstNameError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="lastname"
                label="Last Name"
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder="Enter Your First Name"
                  onChange={handleChange("lastName")}
                  defaultValue={values.lastName}
                  style={{
                    border: this.state.lastNameError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.lastNameError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="dob"
                label="Date of Birth"
                className="mb-2"
              >
                <Form.Control
                  type="date"
                  placeholder="Enter Your Date of Birth"
                  onChange={handleChange("dob")}
                  defaultValue={values.dob}
                  style={{
                    border: this.state.dobError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.dobError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel controlId="gender" label="Gender" className="mb-3">
                <Form.Control
                  as="select"
                  onChange={handleChange("gender")}
                  defaultValue={values.gender}
                  style={{
                    border: this.state.genderError ? "1px solid red" : "",
                  }}
                >
                  <option></option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Form.Control>
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.genderError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="phonenumber"
                label="Mobile Number/WhatsApp Number"
                className="mb-2"
              >
                <Form.Control
                  type="number"
                  autoComplete="off"
                  placeholder="Enter Your Phone Number"
                  onChange={handleChange("phone")}
                  defaultValue={values.phone}
                  style={{
                    border: this.state.phoneError ? "1px solid red" : "",
                  }}
                />
                {/* <PhoneInput
                  placeholder="Enter phone number"
                  onChange={handleChange("phone")}
                  defaultValue={values.phone}
                  value={this.state.phone}
                /> */}

                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.phoneError}
                </div>
              </FloatingLabel>
              {/* <IntlTelInput
                preferredCountries={["GH", "US", "GB"]}
                defaultCountry={"GH"}
                defaultValue={"+233 xxxxxxxxx"}
                onChange={(data) => handleChange(data)}
              /> */}
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="idType"
                label="ID Type"
                className="mb-2"
              >
                <Form.Control
                  as="select"
                  onChange={handleChange("idType")}
                  defaultValue={values.idType}
                  style={{
                    border: this.state.idTypeError ? "1px solid red" : "",
                  }}
                >
                  <option></option>
                  <option value="National ID">National ID</option>
                  <option value="Passport">Passport</option>
                  <option value="Voters">Voters</option>
                  <option value="Drivers">Drivers</option>
                </Form.Control>
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.idTypeError}
                </div>
              </FloatingLabel>
            </Form.Group>

            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="ID Number"
                className="mb-2"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter Your ID Number"
                  autoComplete="off"
                  onChange={handleChange("idNumber")}
                  defaultValue={values.idNumber}
                  style={{
                    border: this.state.idNumberError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.idNumberError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-2"
              >
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoComplete="off"
                  onChange={handleChange("email")}
                  defaultValue={values.email}
                  style={{
                    border: this.state.emailError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.emailError}
                </div>
              </FloatingLabel>
            </Form.Group>
            <Button
              variant="primary"
              style={{
                backgroundColor: "#763984",
                borderColor: "#763984",
                outlineColor: "#763984",
              }}
              onClick={this.continue}
            >
              Let's Do This
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default FormUserDetails;
