import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

export class ProductDetailsWPP extends Component {
  render() {
    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="products"
            label="Select WPP Product"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={this.handleProductChange}
              //   defaultValue={values.product}
              //   style={{
              //     border: this.state.productError ? "1px solid red" : "",
              //   }}
            >
              <option></option>
              {/* {products.map((product) => (
                <option key={product.id} value={product.value}>
                  {product.label}
                </option>
              ))} */}
            </Form.Control>
            {/* <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {this.state.productError}
            </div> */}
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsWPP;
