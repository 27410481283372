import React from "react";
import Card from "./Card";
import Button from "react-bootstrap/Button";
//import Button from "./Button";
import classes from "./ErrorModal.module.css";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { titleCase } from "../func/utils";

// const Quotation = (props) => {
function Quotation(props) {
  const [coverDetails, setCoverDetails] = React.useState([]);

  // console.log("CoverDetails", props.coverDetails);

  React.useEffect(function effectFunction() {
    setCoverDetails(
      // JSON.parse(localStorage.getItem("quotation"))[
      //   "coverTypeAllocations"
      // ].filter((cover) => cover.cvtPremium !== 0)
      JSON.parse(props.getDecryptedData("quotation"))[
        "coverTypeAllocations"
      ].filter((cover) => cover.cvtPremium !== 0)
    );
  }, []);
  // console.log(
  //   JSON.parse(localStorage.getItem("quotation"))["coverTypeAllocations"]
  // );
  // let coverDetails = JSON.parse(localStorage.getItem("quotation"))[
  //   "coverTypeAllocations"
  // ];

  // const coverDetails = JSON.parse(localStorage.getItem("quotation"))[
  //   "coverTypeAllocations"
  // ].filter((cover) => cover.cvtPremium !== 0);

  console.log("quot", JSON.parse(props.getDecryptedData("quotation")));

  console.log("cover details", coverDetails);

  // coverDetails = coverDetails.filter(cover => cover.cvtPremium !== 0)
  return (
    <React.Fragment>
      <div className={classes.backdrop} />
      <Card className={classes.modal} style={{ width: "50%", height: "50px" }}>
        <header className={classes.header}>
          <h3>{props.title}</h3>
        </header>
        <div className={classes.content}>
          <div className="table-responsive-sm">
            <table border="0" align="center" width="70%">
              {/* {coverDetails.filter(cover).map((cover, index) => (
                <tr>
                  <td align="left">
                    <h5>{cover.cvtDesc}</h5>
                  </td>
                </tr>
              ))} */}
              {coverDetails &&
                coverDetails.map((cover, index) => (
                  <tr>
                    <td align="left">
                      <h5>{titleCase(cover.cvtDesc)}</h5>
                    </td>
                    <td align="left">
                      <h5>{cover.cvtPremium.toFixed(2)}</h5>
                    </td>
                  </tr>
                ))}
              <hr />
              <tr>
                <td align="left">
                  <h5>
                    <b>Total Premium (Ghc)</b>
                  </h5>
                </td>
                <td align="left">
                  <h5>
                    <b>
                      {Number(props.values.premium).toFixed(2)}
                      {/* {props.values.premium
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                    </b>
                  </h5>
                </td>
              </tr>
              <hr />
              {props.values.dreadDisease === "Yes" && (
                <tr>
                  <td align="left">
                    <h5>Critical Illness Sum Assured (Ghc)</h5>
                  </td>
                  <td align="left">
                    <h5>
                      {(props.values.sa * 0.5)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h5>
                  </td>
                </tr>
              )}
              <tr>
                <td align="left">
                  <h5>Initial Sum Assured (Ghc)</h5>
                </td>
                <td align="left">
                  <h5>
                    {props.values.sa
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h5>
                </td>
              </tr>
            </table>
          </div>
          {/* <p>{props.message}</p> */}
        </div>
        <footer className={classes.actions}>
          <Button
            variant="secondary"
            // type="submit"
            // style={{ backgroundColor: "#763984" }}
            onClick={props.backdrop}
          >
            Recalculate
          </Button>
          &nbsp;
          <Button
            variant="primary"
            // type="submit"
            style={{
              backgroundColor: "#296F24",
              outlineColor: "#191c4a",
              borderColor: "#191c4a",
              color: "#fff",
            }}
            onClick={props.continue}
          >
            Continue
          </Button>
        </footer>
      </Card>
    </React.Fragment>
  );
}

export default Quotation;
