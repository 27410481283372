import React from "react";
import Card from "./Card";
import Button from "react-bootstrap/Button";
//import Button from "./Button";
import classes from "./ErrorModal.module.css";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { titleCase } from "../func/utils";

// const Quotation = (props) => {
function Quotation(props) {
  const [coverDetails, setCoverDetails] = React.useState([]);

  React.useEffect(function effectFunction() {
    setCoverDetails(
      JSON.parse(localStorage.getItem("quotation"))[
        "coverTypeAllocations"
      ].filter((cover) => cover.cvtPremium !== 0)
    );
  }, []);

  return (
    <React.Fragment>
      <div className={classes.backdrop} />
      <Card className={classes.modal} style={{ width: "50%", height: "50px" }}>
        <header className={classes.header}>
          <h3>{props.title}</h3>
        </header>
        <div className={classes.content}>
          <div className="table-responsive-sm">
            <table border="0" align="center" width="100%">
              {/* {coverDetails.filter(cover).map((cover, index) => (
                <tr>
                  <td align="left">
                    <h5>{cover.cvtDesc}</h5>
                  </td>
                </tr>
              ))} */}
              {coverDetails &&
                coverDetails.map((cover, index) => (
                  <tr>
                    <td align="left">{titleCase(cover.cvtDesc)}</td>
                    <td align="left">{cover.cvtPremium.toFixed(2)}</td>
                  </tr>
                ))}
              <tr>
                <td align="left">
                  Spectra Health Scan Plan (
                  {props.values.rider.split("-")[1] +
                    " - " +
                    props.values.planType.split("-")[0]}
                  )
                </td>
                <td align="left">
                  {(props.values.assuredPrem["monthly"] !== undefined ||
                    props.values.assuredPrem[0].monthly !== undefined) &&
                  props.values.paymentFrequency === "M" &&
                  Array.isArray(props.values.assuredPrem)
                    ? parseFloat(props.values.assuredPrem[0].monthly).toFixed(2)
                    : (props.values.assuredPrem["monthly"] !== undefined ||
                        props.values.assuredPrem[0].monthly !== undefined) &&
                      props.values.paymentFrequency === "M" &&
                      !Array.isArray(props.values.assuredPrem)
                    ? parseFloat(props.values.assuredPrem.monthly).toFixed(2)
                    : (props.values.assuredPrem["premium"] !== undefined ||
                        props.values.assuredPrem[0].premium !== undefined) &&
                      props.values.paymentFrequency === "A" &&
                      Array.isArray(props.values.assuredPrem)
                    ? parseFloat(props.values.assuredPrem[0].premium).toFixed(2)
                    : (props.values.assuredPrem["premium"] !== undefined ||
                        props.values.assuredPrem[0].premium !== undefined) &&
                      props.values.paymentFrequency === "A" &&
                      !Array.isArray(props.values.assuredPrem)
                    ? parseFloat(props.values.assuredPrem.premium).toFixed(2)
                    : 0}
                </td>
              </tr>
              <tr>
                <td align="left">Total Premium (Ghc)</td>
                <td align="left">
                  {(props.values.assuredPrem["monthly"] !== undefined ||
                    props.values.assuredPrem[0].monthly !== undefined) &&
                  props.values.paymentFrequency === "M" &&
                  Array.isArray(props.values.assuredPrem)
                    ? (
                        parseFloat(props.values.premium) +
                        parseFloat(props.values.assuredPrem[0].monthly)
                      ).toFixed(2)
                    : (props.values.assuredPrem["monthly"] !== undefined ||
                        props.values.assuredPrem[0].monthly !== undefined) &&
                      props.values.paymentFrequency === "M" &&
                      !Array.isArray(props.values.assuredPrem)
                    ? (
                        parseFloat(props.values.premium) +
                        parseFloat(props.values.assuredPrem.monthly)
                      ).toFixed(2)
                    : (props.values.assuredPrem["premium"] !== undefined ||
                        props.values.assuredPrem[0].premium !== undefined) &&
                      props.values.paymentFrequency === "A" &&
                      Array.isArray(props.values.assuredPrem)
                    ? (
                        parseFloat(props.values.premium) +
                        parseFloat(props.values.assuredPrem[0].premium)
                      ).toFixed(2)
                    : (props.values.assuredPrem["premium"] !== undefined ||
                        props.values.assuredPrem[0].premium !== undefined) &&
                      props.values.paymentFrequency === "A" &&
                      Array.isArray(props.values.assuredPrem)
                    ? (
                        parseFloat(props.values.premium) +
                        parseFloat(props.values.assuredPrem.premium)
                      ).toFixed(2)
                    : parseFloat(props.values.premium).toFixed(2)}
                </td>
              </tr>
              <hr />
              {/* <tr>
                <td align="left">
                  <h5>Total Premium (Ghc)</h5>
                </td>
                <td align="left">
                  <h5>
                    {(props.values.assuredPrem["monthly"] !== undefined ||
                      props.values.assuredPrem[0].monthly !== undefined) &&
                    props.values.paymentFrequency === "M" &&
                    Array.isArray(props.values.assuredPrem)
                      ? (
                          parseFloat(props.values.premium) +
                          parseFloat(props.values.assuredPrem[0].monthly)
                        ).toFixed(2)
                      : (props.values.assuredPrem["monthly"] !== undefined ||
                          props.values.assuredPrem[0].monthly !== undefined) &&
                        props.values.paymentFrequency === "M" &&
                        !Array.isArray(props.values.assuredPrem)
                      ? (
                          parseFloat(props.values.premium) +
                          parseFloat(props.values.assuredPrem.monthly)
                        ).toFixed(2)
                      : (props.values.assuredPrem["premium"] !== undefined ||
                          props.values.assuredPrem[0].premium !== undefined) &&
                        props.values.paymentFrequency === "A" &&
                        Array.isArray(props.values.assuredPrem)
                      ? (
                          parseFloat(props.values.premium) +
                          parseFloat(props.values.assuredPrem[0].premium)
                        ).toFixed(2)
                      : (props.values.assuredPrem["premium"] !== undefined ||
                          props.values.assuredPrem[0].premium !== undefined) &&
                        props.values.paymentFrequency === "A" &&
                        Array.isArray(props.values.assuredPrem)
                      ? (
                          parseFloat(props.values.premium) +
                          parseFloat(props.values.assuredPrem.premium)
                        ).toFixed(2)
                      : parseFloat(props.values.premium).toFixed(2)}
                  </h5>
                </td>
              </tr> */}
              {props.values.dreadDisease === "Yes" && (
                <tr>
                  <td align="left">Critical Illness Sum Assured (Ghc)</td>
                  <td align="left">
                    {(props.values.sa * 0.5)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
              )}
              <tr>
                <td align="left">Initial Sum Assured (Ghc)</td>
                <td align="left">
                  {props.values.sa
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
              <hr />
              <tr align="left">
                <td>
                  <u>Spectra Health Scan Benefits</u>
                </td>
                <td>
                  <u>Coverage (%)</u>
                </td>
              </tr>
              <tr align="left">
                <td>All CT scans</td>
                <td>100</td>
              </tr>
              <tr align="left">
                <td>X-ray</td>
                <td>100</td>
              </tr>
              <tr align="left">
                <td>Mammogram</td>
                <td>100</td>
              </tr>
              <tr align="left">
                <td>Ultrasound</td>
                <td>100</td>
              </tr>
              <tr align="left">
                <td>Virtual Colonoscopy</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 32.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 35}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 37.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 40}</td>
                  ) : (
                    <td>30</td>
                  )
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 42.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 45}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 47.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 50}</td>
                  ) : (
                    <td>40</td>
                  )
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 52.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 55}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 57.2}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 60}</td>
                  ) : (
                    <td>50</td>
                  )
                ) : (
                  ""
                )}
                {/* <td>50</td> */}
              </tr>
              <tr align="left">
                <td>Liver Elastography</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 32.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 35}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 37.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 40}</td>
                  ) : (
                    <td>30</td>
                  )
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 42.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 45}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 47.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 50}</td>
                  ) : (
                    <td>40</td>
                  )
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 52.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 55}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 57.2}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 50 + " -> 2nd Year: " + 60}</td>
                  ) : (
                    <td>50</td>
                  )
                ) : (
                  ""
                )}
              </tr>
              <tr align="left">
                <td>MRI Scans</td>
                <td>100</td>
              </tr>
              <tr align="left">
                <td>Echocardiography</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  <td>N/A</td>
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  <td>100</td>
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  <td>100</td>
                ) : (
                  ""
                )}
              </tr>
              <tr align="left">
                <td>4D baby-face procedures</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  <td>N/A</td>
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  <td>100</td>
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  <td>100</td>
                ) : (
                  ""
                )}
              </tr>
              <tr align="left">
                <td>Whole-body Diffusion Procedure MRI</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 22.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 25}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 27.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 30}</td>
                  ) : (
                    <td>20</td>
                  )
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 32.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 35}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 37.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 40}</td>
                  ) : (
                    <td>30</td>
                  )
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 42.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 45}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 47.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 50}</td>
                  ) : (
                    <td>40</td>
                  )
                ) : (
                  ""
                )}
              </tr>
              <tr align="left">
                <td>Cardiac MRI</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  <td>30</td>
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  <td>40</td>
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  <td>50</td>
                ) : (
                  ""
                )}
              </tr>
              <tr align="left">
                <td>Routine CT & Ultrasound Guided Interventions</td>
                {props.values.rider.split("-")[1] === "Bronze" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 22.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 25}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 27.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 20 + " -> 2nd Year: " + 30}</td>
                  ) : (
                    <td>20</td>
                  )
                ) : props.values.rider.split("-")[1] === "Silver" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 32.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 35}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 37.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 30 + " -> 2nd Year: " + 40}</td>
                  ) : (
                    <td>30</td>
                  )
                ) : props.values.rider.split("-")[1] === "Gold" ? (
                  props.values.escalationRateSpectra === "5" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 42.5}</td>
                  ) : props.values.escalationRateSpectra === "10" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 45}</td>
                  ) : props.values.escalationRateSpectra === "15" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 47.5}</td>
                  ) : props.values.escalationRateSpectra === "20" ? (
                    <td>{"1st Year: " + 40 + " -> 2nd Year: " + 50}</td>
                  ) : (
                    <td>40</td>
                  )
                ) : (
                  ""
                )}
              </tr>
            </table>
          </div>
          {/* <p>{props.message}</p> */}
        </div>
        <div style={{ fontSize: "0.9rem" }}>
          <small>
            <em>
              <b>
                {props.values.rider.split("-")[1] === "Bronze"
                  ? "NB: All request for Ultrasound, Xray, Mammogram, CT scans and MRI scans will be limited to 3 modalities per year"
                  : props.values.rider.split("-")[1] === "Silver"
                  ? "NB: All request for Ultrasound, Xray, Mammogram, Echocardiography, 4D Baby face procedures, CT scans and MRI scans will be limited to 4 modalities per year"
                  : props.values.rider.split("-")[1] === "Gold"
                  ? "NB: All request for Ultrasound, Xray, Mammogram, Echocardiography, 4D Baby face procedures, CT scans and MRI scans will be limited to 5 modalities per year"
                  : ""}
              </b>
            </em>
          </small>
        </div>
        <footer className={classes.actions}>
          <Button
            variant="secondary"
            // type="submit"
            // style={{ backgroundColor: "#763984" }}
            onClick={props.backdrop}
          >
            Recalculate
          </Button>
          &nbsp;
          <Button
            variant="primary"
            // type="submit"
            style={{
              backgroundColor: "#763984",
              outlineColor: "#763984",
              borderColor: "#763984",
            }}
            onClick={props.continue}
          >
            Continue
          </Button>
        </footer>
      </Card>
    </React.Fragment>
  );
}

export default Quotation;
