import React, { PureComponent } from "react";
import gcb from "../../gcb-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";

export class FormMedicalSurgery extends PureComponent {
  render() {
    const { values, handleChange, surError } = this.props;

    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="surgery"
            label="Type of surgery or procedure"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              onChange={handleChange("surgery")}
              defaultValue={values.surgery}
              style={{
                border: surError ? "1px solid red" : "",
              }}
            ></Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {surError}
            </div>
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default FormMedicalSurgery;
