import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

export class ProductDetailsPA extends Component {
  render() {
    const { values, handleChange } = this.props;
    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="products"
            label="Select PA Change"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={this.handleProductChange}
              //   defaultValue={values.product}
              //   style={{
              //     border: this.state.productError ? "1px solid red" : "",
              //   }}
            >
              <option></option>
              {/* {products.map((product) => (
                <option key={product.id} value={product.value}>
                  {product.label}
                </option>
              ))} */}
            </Form.Control>
            {/* <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {this.state.productError}
            </div> */}
          </FloatingLabel>
          <FloatingLabel
            controlId="pasurname"
            label="Child Surname"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              onChange={handleChange("paSurname")}
              //   defaultValue={values.paSurname}
              style={
                {
                  // border: this.state.accountNumberError ? "1px solid red" : "",
                }
              }
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {/* {this.state.accountNumberError} */}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="paothername"
            label="Child Othernames"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              onChange={handleChange("paSurname")}
              //   defaultValue={values.paSurname}
              style={
                {
                  // border: this.state.accountNumberError ? "1px solid red" : "",
                }
              }
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {/* {this.state.accountNumberError} */}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="padob"
            label="Date of Birth"
            className="mb-2"
          >
            <Form.Control
              type="date"
              placeholder="Enter Your Date of Birth"
              onChange={handleChange("padob")}
              //   defaultValue={values.padob}
              style={
                {
                  // border: this.state.dobError ? "1px solid red" : "",
                }
              }
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {/* {this.state.dobError} */}
            </div>
          </FloatingLabel>
          <FloatingLabel controlId="pagender" label="Gender" className="mb-3">
            <Form.Control
              as="select"
              onChange={handleChange("pagender")}
              //   defaultValue={values.pagender}
              style={
                {
                  // border: this.state.genderError ? "1px solid red" : "",
                }
              }
            >
              <option></option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {/* {this.state.genderError} */}
            </div>
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsPA;
