/* eslint-disable default-case */
import React, { Component, useContext } from "react";
import AgentWelcome from "./AgentWelcome";
import AgentFormOTP from "./AgentFormOTP";

export class UserFormAgency extends Component {
  state = {
    step: 1,
  };

  // Proceed to the next step
  nextStep = (stepno) => {
    const { step } = this.state;

    // console.log(step);
    // console.log("Stepno:" + stepno);

    if (stepno) {
      this.setState({
        step: stepno,
      });
    } else
      this.setState({
        step: step + 1,
      });
  };

  render() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return (
          <AgentWelcome
            nextStep={this.nextStep}
            // handleChange={this.handleChange}
            // values={values}
          />
        );
      case 2:
        return (
          <AgentFormOTP
            nextStep={this.nextStep}
            // handleChange={this.handleChange}
            // values={values}
          />
        );
    }
  }
}

export default UserFormAgency;
