import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import starLife from "../../StarLife_Chatbot-06.png";
import axios from "axios";
import "../Spinner.css";
import OtpInput from "react-otp-input";
import Menu from "./menu";

export class FormOTP extends PureComponent {
  state = {
    passcodeError: "",
    loading: false,
    otp: "",
    minutes: 3,
    seconds: 0,
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  handleOTPChange = (otp) => this.setState({ otp });

  resendOTP(phone) {
    const getOTP = {
      Phone_Number: phone,
    };

    this.setState({ loading: true });

    //Get OTP
    axios
      .post("/api/customer/resend/otp", getOTP)
      .then((response) => {
        this.setState({ loading: false, otp: "", minutes: 3, seconds: 0 });
        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;

          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.myInterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
      })
      .catch((error) => {
        this.setState({ loading: false, otp: "" });
      });
  }

  continue = (e) => {
    e.preventDefault();
    let passcodeError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (!this.state.otp) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (passcodeError) {
      this.setState({
        passcodeError,
      });
      return false;
    }

    const OTP = {
      OTP: this.state.otp,
      Phone_Number: this.props.values.phoneNumber,
    };

    this.setState({ loading: true });

    //Verify OTP
    axios
      .post("/api/customer/verify/otp", OTP)
      .then((response) => {
        if (response.data.Verification === true) {
          this.props.nextStep();
          return true;
        } else {
          passcodeError = "Verification failed!";
          if (passcodeError) {
            this.setState({
              passcodeError,
              loading: false,
              otp: "",
            });
            return false;
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false, otp: "" });
      });
  };

  render() {
    const { values } = this.props;
    const { minutes, seconds } = this.state;

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-retail"}></div>
            <Menu />
            <hr />
            <br></br>
            <h2>
              We've just sent your one time passcode to ********
              {values["phoneNumber"].slice(8)}.
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
              <img src={mypadie} alt="logo" style={{ width: "70px" }} />
              Nice to meet you, {values["firstName"]}! Please add your employment
              details.
            </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon
                  icon={faUserLock}
                  style={{ color: "#763984" }}
                />{" "}
                &nbsp; <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: this.state.passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={this.state.otp}
                    onChange={this.handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.passcodeError}
                  </div>
                </Form.Group>
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                  }}
                  onClick={this.continue}
                >
                  Verify
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.resendOTP(values["phoneNumber"])}
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <Menu />
            <hr />
            <br></br>
            <h2>
              We've just sent your one time passcode to ********
              {values["phoneNumber"].slice(8)}.
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon
                  icon={faUserLock}
                  style={{ color: "#763984" }}
                />{" "}
                &nbsp; <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: this.state.passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={this.state.otp}
                    onChange={this.handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.passcodeError}
                  </div>
                </Form.Group>
                &nbsp;
                <div>
                  {minutes === 0 && seconds === 0 ? (
                    <h5>OTP has Expired!</h5>
                  ) : (
                    <h5>
                      Resend OTP in: {minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h5>
                  )}
                </div>
                {/* <br></br> */}
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                  }}
                  onClick={this.continue}
                >
                  Verify
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.resendOTP(values["phoneNumber"])}
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormOTP;
