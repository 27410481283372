import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { getAge } from "../func/utils";

import qmark from "../../question-mark.svg";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";

export class ProductDetailsWPP extends PureComponent {
  state = {
    paChange: false,
    incrementOption: false,
  };

  handlePAChange = (e) => {
    const pa = e.target.value;
    if (pa === "Yes") {
      this.setState({ paChange: true });
      this.props.valueChange(["pa", pa]);
    } else {
      this.setState({ paChange: false });
      this.props.valueChange(["pa", pa]);
      // this.props.valueChange(["escalationRate", ""]);
    }
  };

  handleIncrementOption = (e) => {
    const escalation = e.target.value;
    if (escalation === "Yes") {
      this.setState({ incrementOption: true });
      this.props.valueChange(["escalation", escalation]);
    } else {
      this.setState({ incrementOption: false });
      this.props.valueChange(["escalation", escalation]);
    }
  };

  render() {
    const {
      values,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      handleChange,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
      paymentFrequencyError,
    } = this.props;

    let age;
    const maxAge = 65;
    let policyTerm = [];

    if (values.clntCode) {
      const dob = values.dob;
      const [dob_day, dob_month, dob_year] = dob.split("-");
      const newDob = [dob_year, dob_month, dob_day].join("-");
      age = getAge(newDob);
    } else {
      const dob = values.dob;
      age = getAge(dob);
    }

    if (maxAge - age < 10 && maxAge - age >= 4) {
      for (let step = 4; step <= maxAge - age; step++) {
        policyTerm.push({ value: step, label: step });
      }
    } else if (maxAge - age >= 10) {
      for (let step = 4; step <= 10; step++) {
        policyTerm.push({ value: step, label: step });
      }
    } else {
    }

    return (
      <div>
        {/* <div style={{ width: "100%" }}>
          <dv style={{ width: "50%", height: "100px", float: "left" }}>A</dv>
          <div style={{ marginLeft: "50%", height: "100px" }}>?</div>
        </div> */}

        <Form.Group>
          <div>
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="term"
                  label="Select policy term (min of 4 years: max of 10 years)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    autoComplete="off"
                    onChange={handleChange("term")}
                    defaultValue={values.term}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: termError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {policyTerm.map((term) => (
                      <option key={term.value} value={term.value}>
                        {term.label + " years"}
                      </option>
                    ))}
                    {/* <option value={"4"}>4 years</option>
                    <option value={"5"}>5 years</option>
                    <option value={"6"}>6 years</option>
                    <option value={"7"}>7 years</option>
                    <option value={"8"}>8 years</option>
                    <option value={"9"}>9 years</option>
                    <option value={"10"}>10 years</option> */}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {termError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#2B5A9B",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>

            {/* premium */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="premium"
                  label="Enter your premium (minimum 50 ghc)"
                  className="mb-2"
                >
                  <Form.Control
                    type="number"
                    autoComplete="off"
                    onChange={handleChange("premium")}
                    defaultValue={values.premium}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: premiumError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {premiumError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="premiumTip"
                  style={{
                    color: "#2B5A9B",
                  }}
                />
                <ReactTooltip
                  id="premiumTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Premium is an amount paid periodically for an insurance
                  policy.
                </ReactTooltip>
              </div>
            </div>

            {/* Payment Frequeny */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <Form.Group>
                  <div className="mb-3" style={{ textAlign: "left" }}>
                    Payment Frequency
                  </div>
                  <InputGroup
                    className="mb-3"
                    onChange={handleChange("paymentFrequency")}
                    defaultValue={values.paymentFrequency}
                  >
                    <InputGroup.Radio
                      style={{
                        border: paymentFrequencyError ? "1px solid red" : "",
                      }}
                      value="M"
                      name="paymentFrequency"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: paymentFrequencyError ? "1px solid red" : "",
                      }}
                      value="A"
                      name="paymentFrequency"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Annually
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {paymentFrequencyError}
                    </div>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="payFreqTip"
                  style={{
                    color: "#2B5A9B",
                  }}
                />
                <ReactTooltip
                  id="payFreqTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  How often do you want to pay your premium?
                </ReactTooltip>
              </div>
            </div>

            {/* Escalation */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="escalation"
                  label="Do you wish to have incremental option?"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleIncrementOption}
                    defaultValue={values.escalation}
                    style={{
                      border: escalationError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {escalationError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="escalationTip"
                  style={{
                    color: "#2B5A9B",
                  }}
                />
                <ReactTooltip
                  id="escalationTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  The option to increase the <br />
                  contractual premium on <br />
                  an annual basis in order <br /> for the benefits to be
                  <br />
                  inflation-linked.
                </ReactTooltip>
              </div>
            </div>
            {this.state.incrementOption && (
              <ProductDetailsEscalation
                escalationRateError={escalationRateError}
                handleChange={handleChange}
                values={values}
              />
            )}
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsWPP;
