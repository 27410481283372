import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
// import ListGroup from 'react-bootstrap/ListGroup'
import { ListGroup, ListGroupItem } from "react-bootstrap";

export class Confirm extends Component {
  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    console.log(this.props.values);
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: { firstName, lastName, email, occupation, city, bio },
    } = this.props;
    return (
      <div>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 500,
            padding: 10,
          }}
        >
          <h4>
            <FontAwesomeIcon icon={faCheck} /> &nbsp; <u>Confirm Detail</u>
          </h4>
          <ListGroup as="ol" numbered>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">First Name</div>
                {firstName}
              </div>
            </ListGroup.Item>
            <ListGroupItem header="First Name">{firstName}</ListGroupItem>
            <ListGroupItem primaryText="First Name" secondaryText={firstName} />
            <ListGroupItem primaryText="Last Name" secondaryText={lastName} />
            <ListGroupItem primaryText="Email" secondaryText={email} />
            <ListGroupItem
              primaryText="Occupation"
              secondaryText={occupation}
            />
            <ListGroupItem primaryText="City" secondaryText={city} />
            <ListGroupItem primaryText="Bio" secondaryText={bio} />
          </ListGroup>
          <br />
          <Button
            variant="secondary"
            // type="submit"
            // style={{ backgroundColor: "#763984" }}
            onClick={this.back}
          >
            Back
          </Button>
          &nbsp;
          <Button
            variant="primary"
            style={{ backgroundColor: "#763984" }}
            onClick={this.continue}
          >
            Confirm & Continue
          </Button>
        </div>
      </div>
    );
  }
}

export default Confirm;
