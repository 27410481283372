import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import amanano from "../../amanano-logo.jpg";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "../Spinner.css";
import OtpInput from "react-otp-input";

export class FormOTP extends Component {
  state = {
    passcodeError: "",
    loading: false,
    otp: "",
  };

  //   resendOTP = (e) => {
  //     alert(this.value);
  //     console.log(e);
  //   };

  handleOTPChange = (otp) => this.setState({ otp });

  resendOTP(phone) {
    const getOTP = {
      Phone_Number: phone,
    };

    this.setState({ loading: true });

    //Get OTP
    axios
      .post("/api/customer/resend/otp", getOTP)
      .then((response) => {
        this.setState({ loading: false, otp: "" });
        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ loading: false, otp: "" });
        console.log(error);
      });
  }

  continue = (e) => {
    e.preventDefault();
    // console.log(this.state.otp);
    // console.log(e.target.form[0].value);
    let passcodeError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (!this.state.otp) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (passcodeError) {
      this.setState({
        passcodeError,
      });
      return false;
    }

    const OTP = {
      OTP: this.state.otp,
    };

    this.setState({ loading: true });

    //Verify OTP
    axios
      .post("/api/customer/verify/otp", OTP)
      .then((response) => {
        // console.log(response.data);

        if (response.data.Verification === true) {
          //   console.log(response.data.Verification);
          this.props.nextStep();
          return true;
        } else {
          //   console.log("False " + response.data.Verification);
          passcodeError = "Verification failed!";
          if (passcodeError) {
            // alert(existingPhoneError);
            this.setState({
              passcodeError,
              loading: false,
              otp: "",
            });
            return false;
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false, otp: "" });
        console.log(error);
      });
  };

  //   back = (e) => {
  //     e.preventDefault();
  //     this.props.prevStep();
  //   };

  render() {
    const { values, handleChange } = this.props;

    // Using state to keep track of what the selected occupation is selected
    // this.setState(occs);

    // Using this function to update the state of fruit
    // whenever a new option is selected from the dropdown

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-ga"}></div>
            <br></br>
            <img src={amanano} alt="logo" style={{ height: "150px" }} />
            <br></br>
            <br></br>
            <h2>
              We've just sent your one time passcode to {values["phoneNumber"]}.
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
              <img src={mypadie} alt="logo" style={{ width: "70px" }} />
              Nice to meet you, {values["firstName"]}! Please add your employment
              details.
            </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
                <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: this.state.passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={this.state.otp}
                    onChange={this.handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.passcodeError}
                  </div>
                </Form.Group>
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "yellow",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Verify
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.resendOTP(values["phoneNumber"])}
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <br></br>
            <img src={amanano} alt="logo" style={{ height: "150px" }} />
            <br></br>
            <br></br>
            <h2>
              We've just sent your one time passcode to {values["phoneNumber"]}.
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
                <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: this.state.passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={this.state.otp}
                    onChange={this.handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.passcodeError}
                  </div>
                </Form.Group>
                {/* <br></br> */}
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "yellow",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Verify
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.resendOTP(values["phoneNumber"])}
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormOTP;
