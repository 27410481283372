const BankBranchesData = [
  {
    bbrCode: 10441,
    bbrBnkCode: 1033,
    bbrBranchName: "WIAMOASE",
    bbrShtDesc: "087515",
  },
  {
    bbrCode: 10442,
    bbrBnkCode: 1033,
    bbrBranchName: "TETREM",
    bbrShtDesc: "087511",
  },
  {
    bbrCode: 10443,
    bbrBnkCode: 1033,
    bbrBranchName: "BOAMANG",
    bbrShtDesc: "087512",
  },
  {
    bbrCode: 10444,
    bbrBnkCode: 1033,
    bbrBranchName: "ASHTOWN",
    bbrShtDesc: "087513",
  },
  {
    bbrCode: 10445,
    bbrBnkCode: 1033,
    bbrBranchName: "PANKRONO",
    bbrShtDesc: "087514",
  },
  {
    bbrCode: 10446,
    bbrBnkCode: 1033,
    bbrBranchName: "AGONA",
    bbrShtDesc: "087516",
  },
  {
    bbrCode: 10447,
    bbrBnkCode: 1033,
    bbrBranchName: "KRONUM",
    bbrShtDesc: "087517",
  },
  {
    bbrCode: 10448,
    bbrBnkCode: 1033,
    bbrBranchName: "SUAME",
    bbrShtDesc: "087518",
  },
  {
    bbrCode: 10449,
    bbrBnkCode: 1033,
    bbrBranchName: "TAFO",
    bbrShtDesc: "087519",
  },
];

export default BankBranchesData;
