import React, { Component } from "react";
import ahantaman from "../../ahantaman-logo3-1.png";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faSmile,
  faPrint,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { Receipt } from "./Receipt";
import Menu from "./menu";

export class Success extends Component {
  render() {
    const { values } = this.props;
    // const proposal_details = {
    //   ProposalNumber: "PCLP1006001180227148",
    //   ProductName: "CHILD LIFELINE PLUS",
    //   PolicyHolder: "KWABENA KORANG AGADZI",
    //   Premium: 342.168,
    //   InitialSumAssured: "53,341.62",
    //   PaymentFrequency: "M",
    //   MaturityDate: "15-05-2028",
    //   message_code: 100,
    //   message: "1 Policy(s) found",
    // };
    return (
      <div>
        <Menu />
        <hr />
        <br></br>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 600,
            padding: 10,
          }}
        >
          {values.md2 === "Yes" ? (
            <h4>
              Proposal submitted successfully and is pending review because of
              your answer(s) to a medical question(s). You will soon be
              contacted.{" "}
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "#0f3660" }}
              />
            </h4>
          ) : (
            <h4>
              Proposal submitted successfully.{" "}
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "#0f3660" }}
              />
            </h4>
          )}

          <div>
            <Receipt
              values={values.proposal_details}
              ref={(el) => (this.componentRef = el)}
            />
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <div>
                    <div>
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#0f366", color: "#fff" }}
                      >
                        Print <FontAwesomeIcon icon={faPrint} />
                      </Button>
                    </div>
                    &nbsp;
                  </div>
                );
              }}
              content={() => this.componentRef}
            />
          </div>
          <h5>
            Thank you!{" "}
            {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
              values["firstName"].toLowerCase().slice(1)}{" "}
            <FontAwesomeIcon icon={faSmile} style={{ color: "#0f3660" }} />
          </h5>
          <a style={{ color: "#0f3660" }} href="/retail/gpcc/onboarding">
            Return to Home{" "}
            <FontAwesomeIcon icon={faHome} style={{ color: "#0f3660" }} />
          </a>
        </div>
      </div>
    );
  }
}

export default Success;
