import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import validator from "validator";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import gcb from "../../gcb-logo.png";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { getAge } from "../func/utils";
import NewHeader from "./header/NewHeaderAccess";
import { parsePhoneNumber, getNumberType } from "libphonenumber-js";
import BottomButton from "./SaveandExit";
import MaskedInput from "react-maskedinput";
import moment from "moment";

export class FormUserDetails extends PureComponent {
  state = {
    firstNameError: "",
    lastNameError: "",
    dobError: "",
    genderError: "",
    phoneError: "",
    emailError: "",
    idTypeError: "",
    idNumberError: "",
    maritalStatusError: "",
    occError: "",
    pepError: "",
    loading: false,
    phoneNumber: "",
    card: "",
  };

  handlePhoneChange = (value) => {
    console.log(value);
    this.setState({
      phoneNumber: value,
    });

    console.log(this.state.phoneNumber);
  };

  _onIdChange = (e) => {
    console.log("Card value", e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  continue = (e) => {
    e.preventDefault();
    console.log(e);

    let firstNameError = "";
    let lastNameError = "";
    let dobError = "";
    let genderError = "";
    let phoneError = "";
    let emailError = "";
    let idTypeError = "";
    let idNumberError = "";
    let occError = "";
    let pepError = "";
    let maritalStatusError = "";
    let submitError = "";
    let pNumber = "";

    this.setState({
      firstNameError,
      lastNameError,
      dobError,
      genderError,
      phoneError,
      emailError,
      idTypeError,
      idNumberError,
      occError,
      maritalStatusError,
      pepError,
      submitError,
    });

    // console.log(e);

    if (!e.target.form[0].value) {
      // Checks if field is empty
      firstNameError = "Please enter first name";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      lastNameError = "Please enter last name";
    }

    if (!e.target.form[3].value) {
      // Checks if field is empty
      dobError = "Please enter correct date of birth";
    } else {
      if (getAge(e.target.form[3].value) < 18) {
        dobError =
          "Policy holder should be 18 years or above but not over 64 years";
      }

      if (getAge(e.target.form[3].value) > 64) {
        dobError = "Policy holder should not be over 64 years";
      }
    }

    if (e.target.form[3].value) {
      console.log("dob", e.target.form[3].value);
    }

    if (!e.target.form[4].value) {
      // Checks if field is empty
      genderError = "Please select gender";
    }

    if (!e.target.form[5].value) {
      // Checks if field is empty
      maritalStatusError = "Please select marital status";
    }

    if (!e.target.form[7].value) {
      // Checks if field is empty
      phoneError = "Please enter phone number";
    }

    if (this.state.phoneNumber) {
      pNumber = parsePhoneNumber(this.state.phoneNumber);

      console.log("pNumber", pNumber);
      console.log(typeof pNumber.nationalNumber);

      console.log("Validate", isValidPhoneNumber(pNumber.number));

      if (!isValidPhoneNumber(pNumber.number)) {
        phoneError = "Please enter a valid phone number";
      }

      // if (existingPhoneError) {
      //   this.setState({
      //     existingPhoneError,
      //   });
      //   return false;
      // }

      // const isValid = pNumber.number
      //   ? isValidPhoneNumber(pNumber.number)
      //   : false;

      // console.log("Validate", isValidPhoneNumber(pNumber.number));

      // this.setState({
      //   isValid: isValid,
      // });
    }

    if (!e.target.form[8].value) {
      // Checks if field is empty
      occError = "Please select occupation";
    }

    if (!e.target.form[9].value) {
      // Checks if field is empty
      pepError = "Please indicate PEP status";
    }

    // if (!e.target.form[10].value) {
    //   // Checks if field is empty
    //   idTypeError = "Please select your ID type";
    // }

    if (!e.target.form[10].value) {
      // Checks if field is empty
      idNumberError = "Please enter Ghana Card number";
    }

    if (this.state.card) {
      // Check if input matches the pattern
      if (/^\d{9}-\d$/.test(this.state.card)) {
        this.props.handleChangeValue("idNumber", "GHA-" + this.state.card);
      } else {
        idNumberError = "Please enter a valid Ghana Card number";
      }
    }

    if (e.target.form[11].value) {
      if (validator.isEmail(e.target.form[11].value)) {
      } else {
        emailError = "Please enter a valid email address";
      }
    }

    // console.log(e.target.form[5].value.length);

    // console.log(e.target.form[5].value.match(/^\d{10}$/));

    // const pattern = new RegExp(/^[0-9\b]+$/);

    // if (
    //   !pattern.test(e.target.form[6].value) ||
    //   e.target.form[6].value.length !== 10
    // ) {
    //   // Checks if field is empty
    //   phoneError = "Please enter a valid phone number";
    // }

    if (
      firstNameError ||
      lastNameError ||
      dobError ||
      genderError ||
      phoneError ||
      emailError ||
      idTypeError ||
      idNumberError ||
      occError ||
      pepError ||
      maritalStatusError ||
      submitError
    ) {
      this.setState({
        firstNameError,
        lastNameError,
        dobError,
        genderError,
        phoneError,
        emailError,
        idTypeError,
        idNumberError,
        occError,
        pepError,
        maritalStatusError,
        submitError,
      });
      return false;
    }

    const pnumber = pNumber.nationalNumber;

    const existingClient = {
      Customer_Mobile: "0" + pnumber,
    };

    this.props.valueChange([
      "smsNumber",
      pNumber.countryCallingCode + pNumber.nationalNumber,
    ]);

    this.props.valueChange(["phoneNumber", "0" + pnumber]);

    this.setState({ loading: true });

    //Verify client phone
    axios
      .post("/api/customer/verify", existingClient)
      .then((response) => {
        if (response.data.message_code === 100) {
          phoneError =
            "Phone number already exist. Kindly use the existing client option.";
          if (phoneError) {
            this.setState({
              loading: false,
              phoneError,
            });
            return false;
          }
        } else if (response.data.message_code === 101) {
          const getOTP = {
            Phone_Number: pNumber.countryCallingCode + pNumber.nationalNumber,
            Bank: "ACCESS BANC",
          };

          //Get OTP
          axios
            .post("/api/customer/otp", getOTP)
            .then((response) => {
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
            });
          this.props.nextStep();
          return true;
        } else {
          submitError =
            "Error Occurred while submitting form, Please Try Again Later";
          if (submitError) {
            this.setState({
              loading: false,
              submitError,
            });
            return false;
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });

    // this.props.nextStep();
    // const getOTP = {
    //   Phone_Number: e.target.form[5].value,
    //   Api_User: API_USER,
    //   Api_Key: API_KEY,
    // };

    // //Get OTP
    // axios
    //   .post(API_URL + "/SL_GetOTP", getOTP)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    const dob = values.dob;
    const [dob_day, dob_month, dob_year] = dob.split("-");
    const newDob = [dob_year, dob_month, dob_day].join("/");

    // const issueDate = values.issueDate;
    // const [issue_day, issue_month, issue_year] = issueDate.split("-");
    // const newIssueDate =[issue_year, issue_month, issue_day].join("-");

    const occs = [
      {
        occupationCode: 2018561,
        occupationDesc: "ACCOUNTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017447,
        occupationDesc: "ACCOUNTS",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005321,
        occupationDesc: "ACCOUNTS ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018554,
        occupationDesc: "ACCOUNTS CLERK",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018595,
        occupationDesc: "ACTUARY",
        lifeClass: 20026,
      },
      {
        occupationCode: 200368,
        occupationDesc: "ADMINISTRATION MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 200369,
        occupationDesc: "ADMINISTRATION OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 200367,
        occupationDesc: "ADMINISTRATIVE ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018547,
        occupationDesc: "ADMINISTRATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 200370,
        occupationDesc: "ADVERTISING EXECUTIVE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003156,
        occupationDesc: "AGRICULTURAL OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017421,
        occupationDesc: "AGRICULTURE",
        lifeClass: 20027,
      },
      {
        occupationCode: 2018625,
        occupationDesc: "AGRICULTURIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003160,
        occupationDesc: "AGRO FORESTER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018638,
        occupationDesc: "AIRCRAFT LOADER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003184,
        occupationDesc: "AIRCRAFT TECHNICIAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018637,
        occupationDesc: "AIRMAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 200374,
        occupationDesc: "ANAESTHETIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018582,
        occupationDesc: "ARCHITECT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006374,
        occupationDesc: "ARTISAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 200381,
        occupationDesc: "ARTIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005345,
        occupationDesc: "ASSISTANT DIRECTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018648,
        occupationDesc: "ASSISTANT MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 200380,
        occupationDesc: "AUDIT ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004265,
        occupationDesc: "AUDIT EXAMINER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018553,
        occupationDesc: "AUDITOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 200340,
        occupationDesc: "BANKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018539,
        occupationDesc: "BANKING",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003172,
        occupationDesc: "BIOCHEMIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005271,
        occupationDesc: "BOOK KEEPER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018596,
        occupationDesc: "BROADCASTER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017491,
        occupationDesc: "BROADCASTING                  ",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018612,
        occupationDesc: "BUILDING CONTRACTOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003111,
        occupationDesc: "BUILDING INSPECTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004241,
        occupationDesc: "BURSAR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006399,
        occupationDesc: "BUS CONDUCTOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018568,
        occupationDesc: "BUSINESS EXECUTIVE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006381,
        occupationDesc: "BUSINESSMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018540,
        occupationDesc: "BUSINESSMAN/WOMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006382,
        occupationDesc: "BUSINESSWOMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006397,
        occupationDesc: "BUTCHER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018601,
        occupationDesc: "CAR  RENTAL  COMPANY",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005360,
        occupationDesc: "CARETAKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018620,
        occupationDesc: "CARPENTER",
        lifeClass: 20028,
      },
      {
        occupationCode: 200387,
        occupationDesc: "CARTOGRAPHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018594,
        occupationDesc: "CASHIER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018634,
        occupationDesc: "CATERER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018533,
        occupationDesc: "CHARTERED ACCOUNTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005353,
        occupationDesc: "CHEF",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005269,
        occupationDesc: "CHEMICAL SPRAYER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2005335,
        occupationDesc: "CHEMIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018610,
        occupationDesc: "CIVIL ENGINEERING",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018543,
        occupationDesc: "CIVIL SERVANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017488,
        occupationDesc: "CIVILIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004226,
        occupationDesc: "CLEANER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005309,
        occupationDesc: "CLEANING SERVICES",
        lifeClass: 20028,
      },
      {
        occupationCode: 2004247,
        occupationDesc: "CLEARING",
        lifeClass: 20026,
      },
      {
        occupationCode: 200389,
        occupationDesc: "CLERGY",
        lifeClass: 20026,
      },
      {
        occupationCode: 200338,
        occupationDesc: "CLERICAL OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018575,
        occupationDesc: "CLERK",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005334,
        occupationDesc: "CO-ORDINATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2007403,
        occupationDesc: "COMPUTER ANALYST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005288,
        occupationDesc: "COMPUTER ENGINEER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003168,
        occupationDesc: "COMPUTER TECHNICIAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018599,
        occupationDesc: "CONSTRUCTION COMPANY",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018590,
        occupationDesc: "CONSULTANCY",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018647,
        occupationDesc: "CONTRACTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006384,
        occupationDesc: "CONTROLLER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018619,
        occupationDesc: "COOK",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003214,
        occupationDesc: "COOPERATIVE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018589,
        occupationDesc: "COUNSELOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005276,
        occupationDesc: "COURT CLERK",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005330,
        occupationDesc: "CRANE OPERATOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2004252,
        occupationDesc: "CREDIT OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2007404,
        occupationDesc: "CURRICULUM DEVELOPER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003101,
        occupationDesc: "CUSTOMER SERVICE MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018592,
        occupationDesc: "CUSTOMER SERVICE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018636,
        occupationDesc: "CUSTOMS OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005337,
        occupationDesc: "DATA ANALYST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004235,
        occupationDesc: "DATABASE OPERATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2019654,
        occupationDesc: "DIETICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018566,
        occupationDesc: "DIRECTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 200352,
        occupationDesc: "DIVER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2005304,
        occupationDesc: "DOCKER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018542,
        occupationDesc: "DOCTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018583,
        occupationDesc: "DRAFTMANSHIP",
        lifeClass: 20026,
      },
      {
        occupationCode: 200383,
        occupationDesc: "DRAUGHTSMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018649,
        occupationDesc: "DRILLER",
        lifeClass: 20028,
      },
      {
        occupationCode: 200229,
        occupationDesc: "DRIVER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018609,
        occupationDesc: "DRIVING",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018611,
        occupationDesc: "DRIVING INSTRUCTOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2007402,
        occupationDesc: "EDUCATIONIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018616,
        occupationDesc: "ELECTRICIAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018617,
        occupationDesc: "ENGINEER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003121,
        occupationDesc: "ENVIRONMENTALIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005325,
        occupationDesc: "EXECUTIVE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005277,
        occupationDesc: "EXTENSION OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018650,
        occupationDesc: "FABRICATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018605,
        occupationDesc: "FACTORY WORKER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005272,
        occupationDesc: "FARM HAND",
        lifeClass: 20029,
      },
      {
        occupationCode: 2003197,
        occupationDesc: "FARM SUPERVISOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 200225,
        occupationDesc: "FARMER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018558,
        occupationDesc: "FASHION DESIGNER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018559,
        occupationDesc: "FINANCE EXECUTIVE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018531,
        occupationDesc: "FINANCE MANAGER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2006375,
        occupationDesc: "FINANCE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018563,
        occupationDesc: "FINANCIAL ANALYST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017428,
        occupationDesc: "FINANCIAL SERVICES - INSURANCE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018622,
        occupationDesc: "FIRE OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2004248,
        occupationDesc: "FISHERIES OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2019656,
        occupationDesc: "FOOTBALLER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018578,
        occupationDesc: "FOREMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 200357,
        occupationDesc: "FOREST GUARD",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018591,
        occupationDesc: "FORESTRY",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018586,
        occupationDesc: "FREIGHT FORWARDER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018603,
        occupationDesc: "GARAGE / MOTOR WORKSHOP",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005318,
        occupationDesc: "GENERAL MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003152,
        occupationDesc: "GEOLOGIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 200395,
        occupationDesc: "GRAPHIC DESIGNER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003169,
        occupationDesc: "HAIR DRESSER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018551,
        occupationDesc: "HAIRDRESSER/BEAUTICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018541,
        occupationDesc: "HEALTH WORKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006392,
        occupationDesc: "HERBALIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006400,
        occupationDesc: "HORTICULTURIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018537,
        occupationDesc: "HOTELIER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003102,
        occupationDesc: "HOUSEKEEPER",
        lifeClass: 20026,
      },
      {
        occupationCode: 200343,
        occupationDesc: "HOUSEWIFE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018577,
        occupationDesc: "HR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003104,
        occupationDesc: "HUMAN RESOURCE MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003105,
        occupationDesc: "HUMAN RESOURCE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018630,
        occupationDesc: "IMMIGRATION OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018613,
        occupationDesc: "INDUSTRIALIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005346,
        occupationDesc: "INFORMATION TECHNOLOGIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018571,
        occupationDesc: "INSURANCE AGENT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006389,
        occupationDesc: "INSURANCE INVESTIGATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003122,
        occupationDesc: "INSURANCE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018579,
        occupationDesc: "INSURER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018567,
        occupationDesc: "INTERIOR DECORATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018576,
        occupationDesc: "IT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018628,
        occupationDesc: "JOURNALIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018570,
        occupationDesc: "JUDICIAL SERVICE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003193,
        occupationDesc: "KITCHEN STAFF",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018629,
        occupationDesc: "LAB TECHNICIAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003175,
        occupationDesc: "LABORATORY TECHNOLOGIST",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018624,
        occupationDesc: "LABOURER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005327,
        occupationDesc: "LAND SCAPPING",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003192,
        occupationDesc: "LAUNDRY OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018572,
        occupationDesc: "LAWYER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018555,
        occupationDesc: "LECTURER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018652,
        occupationDesc: "LEGAL PRACTITIONER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018580,
        occupationDesc: "LIBRARIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003215,
        occupationDesc: "LINESMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005275,
        occupationDesc: "LOAN OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005301,
        occupationDesc: "LUSHER-CARGO",
        lifeClass: 20029,
      },
      {
        occupationCode: 2003181,
        occupationDesc: "MACHINE OPERATOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005305,
        occupationDesc: "MAINTENANCE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 200223,
        occupationDesc: "MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018565,
        occupationDesc: "MANAGING DIRECTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018627,
        occupationDesc: "MARINE SERVICE",
        lifeClass: 20028,
      },
      {
        occupationCode: 2004242,
        occupationDesc: "MARKETING OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018548,
        occupationDesc: "MARKETING PERSONEL",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018633,
        occupationDesc: "MASON",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003157,
        occupationDesc: "MASONRY",
        lifeClass: 20028,
      },
      {
        occupationCode: 2004231,
        occupationDesc: "MATRON",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004225,
        occupationDesc: "MEAT INSPECTOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018632,
        occupationDesc: "MECHANIC",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018587,
        occupationDesc: "MEDIA & COMMUNICATION",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003120,
        occupationDesc: "MEDICAL ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017435,
        occupationDesc: "MEDICAL DOCTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005358,
        occupationDesc: "MEDICAL RESEARCH",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005357,
        occupationDesc: "MEDICAL TUTOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003176,
        occupationDesc: "MESSENGER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003117,
        occupationDesc: "METEOROLOGIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004257,
        occupationDesc: "METER READER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018535,
        occupationDesc: "MIDWIFE",
        lifeClass: 20026,
      },
      {
        occupationCode: 200354,
        occupationDesc: "MILITARY OFFICER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018653,
        occupationDesc: "MINING",
        lifeClass: 20026,
      },
      {
        occupationCode: 200378,
        occupationDesc: "MUSICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018626,
        occupationDesc: "NAVY OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005355,
        occupationDesc: "NETWORK ENGINEER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018550,
        occupationDesc: "NON-GOVERNMENTAL ORG.",
        lifeClass: 20026,
      },
      {
        occupationCode: 200339,
        occupationDesc: "NURSE",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018545,
        occupationDesc: "NURSING",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005356,
        occupationDesc: "NURSING OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003191,
        occupationDesc: "OFFICE ADMINISTRATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004236,
        occupationDesc: "OFFICE ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003199,
        occupationDesc: "OPERATIONS OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003126,
        occupationDesc: "OPTICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017465,
        occupationDesc: "OTHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018645,
        occupationDesc: "OTHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018640,
        occupationDesc: "OTHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018646,
        occupationDesc: "OTHERS",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003205,
        occupationDesc: "PACKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003206,
        occupationDesc: "PAINTER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018651,
        occupationDesc: "PANTRYMAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018557,
        occupationDesc: "PASTORING",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006378,
        occupationDesc: "PERSONAL ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005267,
        occupationDesc: "PERSONNEL OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003116,
        occupationDesc: "PHARMACIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005349,
        occupationDesc: "PHARMACY ATTENDANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004239,
        occupationDesc: "PHOTOGRAPHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005350,
        occupationDesc: "PHYSICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018593,
        occupationDesc: "PHYSIOTHERAPY",
        lifeClass: 20026,
      },
      {
        occupationCode: 200346,
        occupationDesc: "PILOT",
        lifeClass: 20029,
      },
      {
        occupationCode: 2004261,
        occupationDesc: "PLUMBER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018618,
        occupationDesc: "POLICE",
        lifeClass: 20028,
      },
      {
        occupationCode: 200358,
        occupationDesc: "POLICE OFFICER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2003208,
        occupationDesc: "PORTER",
        lifeClass: 20028,
      },
      {
        occupationCode: 200362,
        occupationDesc: "POSTAL OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2021661,
        occupationDesc: "PPPPP",
        lifeClass: 20027,
      },
      {
        occupationCode: 2005361,
        occupationDesc: "PRINCIPAL",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003179,
        occupationDesc: "PRINTER",
        lifeClass: 20028,
      },
      {
        occupationCode: 200356,
        occupationDesc: "PRISON OFFICER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018623,
        occupationDesc: "PRISONS OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018598,
        occupationDesc: "PROCUREMENT OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003210,
        occupationDesc: "PRODUCER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005279,
        occupationDesc: "PRODUCTION MANAGER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003209,
        occupationDesc: "PRODUCTION OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005294,
        occupationDesc: "PROGRAMMER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003141,
        occupationDesc: "PROJECT COORDINATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018635,
        occupationDesc: "PROJECT MANAGER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005290,
        occupationDesc: "PUBLIC HEALTH OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005289,
        occupationDesc: "PUBLIC HEALTH TECHNICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003194,
        occupationDesc: "PUBLIC RELATIONS OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018573,
        occupationDesc: "PUBLIC SERVANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005354,
        occupationDesc: "PURCHASING OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004256,
        occupationDesc: "QUALITY CONTROL",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003140,
        occupationDesc: "QUALITY SURVEYOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004240,
        occupationDesc: "RADIOGRAPHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018600,
        occupationDesc: "REAL ESTATE  COMPANY",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018597,
        occupationDesc: "RECEPTIONIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018585,
        occupationDesc: "RECORDS OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018588,
        occupationDesc: "REGISTRAR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018560,
        occupationDesc: "RESEARCHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018602,
        occupationDesc: "RETAIL  COMPANY",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018569,
        occupationDesc: "REVENUE OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 200353,
        occupationDesc: "SAILOR",
        lifeClass: 20029,
      },
      {
        occupationCode: 2005317,
        occupationDesc: "SALES MANAGER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018556,
        occupationDesc: "SALES REPRESENTATIVE",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004254,
        occupationDesc: "SEAMAN",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018549,
        occupationDesc: "SEAMSTRESS/TAILORING",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018544,
        occupationDesc: "SECRETARY",
        lifeClass: 20026,
      },
      {
        occupationCode: 2017437,
        occupationDesc: "SECRETARY",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018608,
        occupationDesc: "SECURITY FORCE",
        lifeClass: 20028,
      },
      {
        occupationCode: 200359,
        occupationDesc: "SECURITY OFFICER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2018641,
        occupationDesc: "SELF EMPLOYED",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018639,
        occupationDesc: "SHIP REPAIR OFFICER",
        lifeClass: 20029,
      },
      {
        occupationCode: 2004243,
        occupationDesc: "SHIPPING CLERK",
        lifeClass: 20026,
      },
      {
        occupationCode: 2019655,
        occupationDesc: "SHOE MAKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004244,
        occupationDesc: "SHOP ASSISTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018538,
        occupationDesc: "SHOPPING MALL ATTENDANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018581,
        occupationDesc: "SOCIAL WORKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018614,
        occupationDesc: "SOLDIER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018564,
        occupationDesc: "STAR ASSURANCE STAFF",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005352,
        occupationDesc: "STATION OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003137,
        occupationDesc: "STATISTICIAN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018621,
        occupationDesc: "STEEL BENDER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2004234,
        occupationDesc: "STOREKEEPER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018584,
        occupationDesc: "STOREKEEPING",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018552,
        occupationDesc: "STUDENT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003153,
        occupationDesc: "SUPERVISOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2004255,
        occupationDesc: "SUPPLIES OFFICER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018631,
        occupationDesc: "SURVEYOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005320,
        occupationDesc: "SYSTEM ADMINISTRATOR",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018562,
        occupationDesc: "TEACHER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2006394,
        occupationDesc: "TECHNICAL ASSISTANT",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005369,
        occupationDesc: "TECHNICAL INSTRUCTOR",
        lifeClass: 20028,
      },
      {
        occupationCode: 2017511,
        occupationDesc: "TECHNICIAN",
        lifeClass: 20028,
      },
      {
        occupationCode: 2005340,
        occupationDesc: "TOUR CONSULTANT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018546,
        occupationDesc: "TRADER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018606,
        occupationDesc: "TRADING COMPANY",
        lifeClass: 20028,
      },
      {
        occupationCode: 2018607,
        occupationDesc: "TRAVEL AGENCY/AIRLINES",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003136,
        occupationDesc: "TRAVEL AGENT",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018574,
        occupationDesc: "TYPIST",
        lifeClass: 20026,
      },
      {
        occupationCode: 200332,
        occupationDesc: "UNDERWRITER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005331,
        occupationDesc: "UNION OFFICIAL",
        lifeClass: 20026,
      },
      {
        occupationCode: 2021660,
        occupationDesc: "UNKNOWN",
        lifeClass: 20026,
      },
      {
        occupationCode: 2003161,
        occupationDesc: "VETERINARY OFFICER",
        lifeClass: 20028,
      },
      {
        occupationCode: 200385,
        occupationDesc: "WAITER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2005342,
        occupationDesc: "WATCHMAN",
        lifeClass: 20029,
      },
      {
        occupationCode: 2003171,
        occupationDesc: "WATER ENGINEER",
        lifeClass: 20028,
      },
      {
        occupationCode: 2003132,
        occupationDesc: "WELDER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2021657,
        occupationDesc: "WORKER",
        lifeClass: 20026,
      },
      {
        occupationCode: 2018604,
        occupationDesc: "WORKSHOP - INDUSTRIAL",
        lifeClass: 20028,
      },
    ];

    let occupation;
    const occup = parseInt(values.occupation);

    if (occup) {
      occupation = occs.find((x) => x.occupationCode === occup).occupationDesc;
    } else {
    }

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-access"}></div>
            <NewHeader />
            <br></br>

            <h3>
              Great! Buying a life insurance policy takes <br></br>only 5
              minutes. Ready to go?
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp;{" "}
                <u>Personal Details of the Account Holder</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                {/* <Form.Group>
              <FloatingLabel
                controlId="title"
                label="Title"
                className="mb-2"
                // style={{ maxHeight: "20" }}
                // size="25%"
              >
                <Form.Control as="select">
                  <option></option>
                  <option value="MR.">MR.</option>
                  <option value="MRS.">MRS.</option>
                  <option value="MS.">MS.</option>
                  <option value="SNTR.">SNTR.</option>
                  <option value="REV.">REV.</option>
                  <option value="HON.">HON.</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group> */}
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="firstname"
                    label="First Name"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your First Name"
                      onChange={handleChange("firstName")}
                      defaultValue={values.firstName}
                      style={{
                        border: this.state.firstNameError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.firstNameError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="othernames"
                    label="Other Names"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your Other Name"
                      onChange={handleChange("otherNames")}
                      defaultValue={values.otherNames}
                      style={{
                        border: this.state.otherNameError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.otherNameError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="lastname"
                    label="Last Name"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your First Name"
                      onChange={handleChange("lastName")}
                      defaultValue={values.lastName}
                      style={{
                        border: this.state.lastNameError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.lastNameError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="dob"
                    label="Date of Birth"
                    className="mb-2"
                  >
                    <Form.Control
                      type="date"
                      placeholder="Enter Your Date of Birth"
                      onChange={handleChange("dob")}
                      defaultValue={newDob}
                      style={{
                        border: this.state.dobError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.dobError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="gender"
                    label="Gender"
                    className="mb-3"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("gender")}
                      defaultValue={values.gender}
                      style={{
                        border: this.state.genderError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.genderError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="maritalstatus"
                    label="Marital Status"
                    className="mb-3"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("maritalStatus")}
                      defaultValue={values.maritalStatus}
                      style={{
                        border: this.state.maritalStatusError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value="S">Single</option>
                      <option value="M">Married</option>
                      <option value="D">Divorced</option>
                      <option value="W">Widowed</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.maritalStatusError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="phonenumber"
                    label="Mobile Number/WhatsApp Number"
                    className="mb-2"
                  >
                    <Form.Control
                      type="number"
                      autoComplete="off"
                      placeholder="Enter Your Phone Number"
                      onChange={handleChange("phoneNumber")}
                      defaultValue={values.phoneNumber}
                      style={{
                        border: this.state.phoneError ? "1px solid red" : "",
                      }}
                    />
                    {/* <PhoneInput
                  placeholder="Enter phone number"
                  onChange={handleChange("phone")}
                  defaultValue={values.phone}
                  value={this.state.phone}
                /> */}

                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.phoneError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="occupation"
                    label="Occupation"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("occupation")}
                      style={{
                        border: this.state.occError ? "1px solid red" : "",
                      }}
                    >
                      <option>{occupation}</option>
                      {occs.map((occ) => (
                        <option
                          key={occ.occupationCode}
                          value={occ.occupationCode}
                        >
                          {occ.occupationDesc}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.occError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="pepStatus"
                    label="Are you a political exposed person?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("pepStatus")}
                      defaultValue={values.pepStatus}
                      style={{
                        border: this.state.pepError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.pepError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="idType"
                    label="ID Type"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("idType")}
                      defaultValue={values.idType}
                      style={{
                        border: this.state.idTypeError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value="Ghana Card">Ghana Card</option>
                      {/* <option value="Passport">Passport</option>
                  <option value="Voters">Voters</option>
                  <option value="Drivers">Drivers</option> */}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.idTypeError}
                    </div>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter Your Ghana Card No (GHA-XXXXXXXXX-X)"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Ghana Card No (GHA-000000000-0)"
                      autoComplete="off"
                      onChange={handleChange("idNumber")}
                      defaultValue={values.idNumber}
                      style={{
                        border: this.state.idNumberError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.idNumberError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                {/* <Form.Group>
              <FloatingLabel
                controlId="issueDate"
                label="Date of Issue"
                className="mb-2"
              >
                <Form.Control
                  type="date"
                  placeholder="Enter Date of Issue"
                  onChange={handleChange("issueDate")}
                  defaultValue={newIssueDate}
                  style={{
                    border: this.state.issueDateError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.issueDateError}
                </div>
              </FloatingLabel>
            </Form.Group> */}
                <Form.Group>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-2"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoComplete="off"
                      onChange={handleChange("email")}
                      defaultValue={values.email}
                      style={{
                        border: this.state.emailError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.emailError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#ee7e01",
                    outlineColor: "#ffc52a",
                    borderColor: "#ffc52a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Let's Do This
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            {/* <br></br> */}
            {/* <img
              src={gcb}
              alt="logo"
              // style={{ width: "150px", height: "150px", padding: 10 }}
            /> */}
            <NewHeader />

            <br></br>
            <h3>
              Great! Buying a life insurance policy takes <br></br>only 5
              minutes. Ready to go?
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp;{" "}
                <u>Personal Details of the Account Holder</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                {/* <Form.Group>
              <FloatingLabel
                controlId="title"
                label="Title"
                className="mb-2"
                // style={{ maxHeight: "20" }}
                // size="25%"
              >
                <Form.Control as="select">
                  <option></option>
                  <option value="MR.">MR.</option>
                  <option value="MRS.">MRS.</option>
                  <option value="MS.">MS.</option>
                  <option value="SNTR.">SNTR.</option>
                  <option value="REV.">REV.</option>
                  <option value="HON.">HON.</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group> */}
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="firstname"
                    label="First Name"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your First Name"
                      onChange={handleChange("firstName")}
                      defaultValue={values.firstName}
                      style={{
                        border: this.state.firstNameError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.firstNameError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="othernames"
                    label="Other Names"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your Other Name"
                      onChange={handleChange("otherNames")}
                      defaultValue={values.otherNames}
                      style={{
                        border: this.state.otherNameError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.otherNameError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="lastname"
                    label="Last Name"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your First Name"
                      onChange={handleChange("lastName")}
                      defaultValue={values.lastName}
                      style={{
                        border: this.state.lastNameError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.lastNameError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="dob"
                    label="Date of Birth"
                    className="mb-2"
                  >
                    <Form.Control
                      type="date"
                      placeholder="Enter Your Date of Birth"
                      onChange={handleChange("dob")}
                      defaultValue={values.dob}
                      style={{
                        border: this.state.dobError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.dobError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="gender"
                    label="Gender"
                    className="mb-3"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("gender")}
                      defaultValue={values.gender}
                      style={{
                        border: this.state.genderError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.genderError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="maritalstatus"
                    label="Marital Status"
                    className="mb-3"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("maritalStatus")}
                      defaultValue={values.maritalStatus}
                      style={{
                        border: this.state.maritalStatusError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value="S">Single</option>
                      <option value="M">Married</option>
                      <option value="D">Divorced</option>
                      <option value="W">Widowed</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.maritalStatusError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <PhoneInput
                    defaultCountry="GH"
                    placeholder="Mobile Number/WhatsApp Number"
                    value={this.state.phoneNumber}
                    onChange={this.handlePhoneChange}
                    // onBlur={this.handlePhoneChange}
                    style={{
                      border: this.state.phoneError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.phoneError}
                  </div>
                </Form.Group>
                <div>&nbsp;</div>
                <Form.Group>
                  <FloatingLabel
                    controlId="occupation"
                    label="Occupation"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("occupation")}
                      style={{
                        border: this.state.occError ? "1px solid red" : "",
                      }}
                    >
                      <option>{occupation}</option>
                      {occs.map((occ) => (
                        <option
                          key={occ.occupationCode}
                          value={occ.occupationCode}
                        >
                          {occ.occupationDesc}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.occError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="pepStatus"
                    label="Are you a political exposed person?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("pepStatus")}
                      defaultValue={values.pepStatus}
                      style={{
                        border: this.state.pepError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.pepError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                &nbsp;
                {/* <Form.Group>
                  <FloatingLabel
                    controlId="idType"
                    label="ID Type"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("idType")}
                      defaultValue={values.idType}
                      style={{
                        border: this.state.idTypeError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value="Ghana Card">Ghana Card</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.idTypeError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <FloatingLabel
                    controlId="idnumber"
                    label="Enter Your Ghana Card No (GHA-XXXXXXXXX-X)"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Ghana Card No (GHA-000000000-0)"
                      autoComplete="off"
                      onChange={handleChange("idNumber")}
                      defaultValue={values.idNumber}
                      style={{
                        border: this.state.idNumberError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.idNumberError}
                    </div>
                  </FloatingLabel>
                </Form.Group> */}
                {/* <MaskedInput
                  mask="GHA-000000000-0"
                  // unmask={false}
                  // lazy={false}
                  placeholder="GHA-XXXXXXXXX-X"
                  name="card"
                  size="20"
                  onChange={this._onChange}
                  className="border-gray-300 focus:border-green-400 focus:ring focus:ring-green-200 dark:focus:ring-green-200 rounded-md shadow-sm"
                /> */}
                {/* <label>
                  Card Number:{" "}
                  <MaskedInput
                    mask="1111-1111-1111-1111"
                    name="card"
                    size="20"
                    onChange={this._onChange}
                  />
                </label>
                <label>
                  Expiry Date:{" "}
                  <MaskedInput
                    mask="11/1111"
                    name="expiry"
                    placeholder="mm/yyyy"
                    onChange={this._onChange}
                  />
                </label> */}
                <Form.Group>
                  GHA-
                  <MaskedInput
                    mask="111111111-1"
                    name="card"
                    placeholder="Enter Ghana Card No only (000000000-0)"
                    onChange={this._onIdChange}
                    size={54}
                    style={{
                      border: this.state.idNumberError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.idNumberError}
                  </div>
                </Form.Group>
                &nbsp;
                {/* <Form.Group>
              <FloatingLabel
                controlId="issueDate"
                label="Date of Issue"
                className="mb-2"
              >
                <Form.Control
                  type="date"
                  placeholder="Enter Date of Issue"
                  onChange={handleChange("issueDate")}
                  defaultValue={newIssueDate}
                  style={{
                    border: this.state.issueDateError ? "1px solid red" : "",
                  }}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.issueDateError}
                </div>
              </FloatingLabel>
            </Form.Group> */}
                <Form.Group>
                  <FloatingLabel
                    controlId="email"
                    label="Email address"
                    className="mb-2"
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoComplete="off"
                      onChange={handleChange("email")}
                      defaultValue={values.email}
                      style={{
                        border: this.state.emailError ? "1px solid red" : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.emailError}
                      {this.state.submitError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#ffc52a" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#ee7e01",
                    outlineColor: "#ffc52a",
                    borderColor: "#ffc52a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Let's Do This
                </Button>
              </Form>
              <br></br>
              <BottomButton
                step={this.props.step}
                values={this.props.values}
                getDecryptedData={this.props.getDecryptedData}
                saveEncryptedData={this.props.saveEncryptedData}
                resetStep={this.props.resetStep}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormUserDetails;
