import React, { PureComponent } from "react";
import gcb from "../../gcb-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";

export class FormMedicalMedication extends PureComponent {
  //   state = {
  //     goodHealthError: "",
  //     anyMedicalError: "",
  //     medicationError: "",
  //     surgicalError: "",
  //     loading: false,
  //     condition: false,
  //     medication: false,
  //     surgery: false,
  //   };

  //   handleConditionChange = (e) => {
  //     console.log(e.target.value);
  //     const md2 = e.target.value;
  //     if (md2 === "Yes") {
  //       this.setState({ condition: true });
  //       this.props.valueChange(["md2", md2]);
  //     } else {
  //       this.setState({ condition: false });
  //       this.props.valueChange(["md2", md2]);
  //     }
  //   };

  //   handleIncrementOption = (e) => {
  //     const escalationRate = e.target.value;
  //     if (escalationRate === "Yes") {
  //       this.setState({ incrementOption: true });
  //       this.props.valueChange(["escalationRate", escalationRate]);
  //     } else {
  //       this.setState({ incrementOption: false });
  //     }
  //   };

  //   continue = (e) => {
  //     e.preventDefault();

  //     let goodHealthError = "";
  //     let anyMedicalError = "";
  //     let medicationError = "";
  //     let surgicalError = "";

  //     if (!e.target.form[0].value) {
  //       // Checks if field is empty
  //       goodHealthError = "Please select an option";
  //     }

  //     if (!e.target.form[1].value) {
  //       // Checks if field is empty
  //       anyMedicalError = "Please select an option";
  //     }

  //     if (!e.target.form[2].value) {
  //       // Checks if field is empty
  //       medicationError = "Please select an option";
  //     }

  //     if (!e.target.form[3].value) {
  //       // Checks if field is empty
  //       surgicalError = "Please select an option";
  //     }

  //     if (
  //       goodHealthError ||
  //       anyMedicalError ||
  //       medicationError ||
  //       surgicalError
  //     ) {
  //       this.setState({
  //         goodHealthError,
  //         anyMedicalError,
  //         medicationError,
  //         surgicalError,
  //       });
  //       return false;
  //     }

  //     this.props.nextStep();
  //     return true;
  //   };

  //   back = (e) => {
  //     e.preventDefault();
  //     this.props.prevStep();
  //   };

  render() {
    const { values, handleChange, medError } = this.props;

    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="medication"
            label="Type of medication and dosage"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              onChange={handleChange("medication")}
              defaultValue={values.medication}
              style={{
                border: medError ? "1px solid red" : "",
              }}
            ></Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {medError}
            </div>
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default FormMedicalMedication;
