import React from "react";

export const Receipt = React.forwardRef((props, ref) => {
  const { values } = props;
  //   console.log(values);
  return (
    <div ref={ref}>
      {/* <br></br> */}
      <br></br>
      <div
        className="container center_div"
        style={{
          display: "block",
          width: 650,
          padding: 10,
        }}
      >
        <table
          style={{
            borderWidth: "2px",
            borderColor: "#aaaaaa",
            borderStyle: "solid",
            width: "100%",
          }}
        >
          <tr>
            <td
              colSpan={"2"}
              style={{
                backgroundColor: "#763984",
                color: "white",
              }}
            >
              <h5>PROPOSAL DETAILS</h5>
            </td>
          </tr>

          <tr>
            <td align="left" width={""}>
              Proposal Number:
            </td>
            <td align="left">
              <b>{values.spectra_policy.ProposalNumber}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Product Name:</td>
            <td align="left">
              <b>StarLife ULTIMATE PROTECTION PLUS</b>
            </td>
          </tr>
          <tr>
            <td align="left">Spectra Health Scan Plan Package:</td>
            <td align="left">
              <b>{values.spectra_policy.PackageName}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Payment Frequency:</td>
            <td align="left">
              <b>{values.spectra_policy.PaymentFrequency}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Product Premium (Ghȼ):</td>
            <td align="left">
              <b>{values.spectra_policy.InitPremium}</b>
            </td>
          </tr>

          <tr>
            <td align="left">Scan Plan Premium (Ghȼ):</td>
            <td align="left">
              <b>{values.spectra_policy.PackagePremium.toFixed(2)}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Total Premium (Ghȼ):</td>
            <td align="left">
              <b>
                {Math.round(
                  (values.spectra_policy.InitPremium +
                    values.spectra_policy.PackagePremium) *
                    100
                ) / 100}
              </b>
            </td>
          </tr>
          {values.dreadDisease === "Yes" && (
            <tr>
              <td align="left">Critical Illness Sum Assured (Ghȼ):</td>
              <td align="left">
                <b>
                  {(values.sa * 0.5)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </td>
            </tr>
          )}
          <tr>
            <td align="left">Initial Sum Assured (Ghȼ):</td>
            <td align="left">
              <b>
                {values.spectra_policy.InitSum.toString().replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}
              </b>
            </td>
          </tr>
        </table>{" "}
      </div>
    </div>
  );
});
