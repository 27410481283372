const AccessBankBranchesData = [
  {
    bbrCode: 10068,
    bbrBnkCode: 1019,
    bbrBranchName: "EAST CANTONMENTS",
    bbrShtDesc: "281001",
  },
  {
    bbrCode: 10069,
    bbrBnkCode: 1019,
    bbrBranchName: "TEMA MAIN",
    bbrShtDesc: "281002",
  },
  {
    bbrCode: 10070,
    bbrBnkCode: 1019,
    bbrBranchName: "OSU OXFORD",
    bbrShtDesc: "281003",
  },
  {
    bbrCode: 10071,
    bbrBnkCode: 1019,
    bbrBranchName: "LASHIBI",
    bbrShtDesc: "281004",
  },
  {
    bbrCode: 10072,
    bbrBnkCode: 1019,
    bbrBranchName: "WATSON HOUSE, OSU",
    bbrShtDesc: "281006",
  },
  {
    bbrCode: 10073,
    bbrBnkCode: 1019,
    bbrBranchName: "KANTAMANTO",
    bbrShtDesc: "281007",
  },
  {
    bbrCode: 10074,
    bbrBnkCode: 1019,
    bbrBranchName: "KANESHIE",
    bbrShtDesc: "281008",
  },
  {
    bbrCode: 10075,
    bbrBnkCode: 1019,
    bbrBranchName: "CASTLE ROAD",
    bbrShtDesc: "281009",
  },
  {
    bbrCode: 10076,
    bbrBnkCode: 1019,
    bbrBranchName: "MADINA",
    bbrShtDesc: "281010",
  },
  {
    bbrCode: 10077,
    bbrBnkCode: 1019,
    bbrBranchName: "FADAMA",
    bbrShtDesc: "281011",
  },
  {
    bbrCode: 10078,
    bbrBnkCode: 1019,
    bbrBranchName: "TAMALE",
    bbrShtDesc: "281012",
  },
  {
    bbrCode: 10079,
    bbrBnkCode: 1019,
    bbrBranchName: "ADUM",
    bbrShtDesc: "281013",
  },
  {
    bbrCode: 10080,
    bbrBnkCode: 1019,
    bbrBranchName: "SUAME",
    bbrShtDesc: "281014",
  },
  {
    bbrCode: 10081,
    bbrBnkCode: 1019,
    bbrBranchName: "TARKWA",
    bbrShtDesc: "281015",
  },
  {
    bbrCode: 10082,
    bbrBnkCode: 1019,
    bbrBranchName: "TAKORADI",
    bbrShtDesc: "281016",
  },
  {
    bbrCode: 10083,
    bbrBnkCode: 1019,
    bbrBranchName: "NORTH INDUSTRIAL AREA",
    bbrShtDesc: "281017",
  },
  {
    bbrCode: 10084,
    bbrBnkCode: 1019,
    bbrBranchName: "RING ROAD CENTRAL",
    bbrShtDesc: "281018",
  },
  {
    bbrCode: 10085,
    bbrBnkCode: 1019,
    bbrBranchName: "KANESHIE POST OFFICE",
    bbrShtDesc: "281019",
  },
  {
    bbrCode: 10086,
    bbrBnkCode: 1019,
    bbrBranchName: "ABEKA LAPAZ",
    bbrShtDesc: "281020",
  },
  {
    bbrCode: 10087,
    bbrBnkCode: 1019,
    bbrBranchName: "OKAISHIE",
    bbrShtDesc: "281021",
  },
  {
    bbrCode: 10088,
    bbrBnkCode: 1019,
    bbrBranchName: "ASHAIMAN",
    bbrShtDesc: "281022",
  },
  {
    bbrCode: 10089,
    bbrBnkCode: 1019,
    bbrBranchName: "TECHIMAN",
    bbrShtDesc: "281023",
  },
  {
    bbrCode: 10090,
    bbrBnkCode: 1019,
    bbrBranchName: "UPSA",
    bbrShtDesc: "281024",
  },
  {
    bbrCode: 10091,
    bbrBnkCode: 1019,
    bbrBranchName: "ACHIMOTA",
    bbrShtDesc: "281025",
  },
  {
    bbrCode: 10092,
    bbrBnkCode: 1019,
    bbrBranchName: "TEMA COMMUNITY 1",
    bbrShtDesc: "281026",
  },
  {
    bbrCode: 10093,
    bbrBnkCode: 1019,
    bbrBranchName: "NIMA",
    bbrShtDesc: "281027",
  },
  {
    bbrCode: 10094,
    bbrBnkCode: 1019,
    bbrBranchName: "AMAKOM",
    bbrShtDesc: "281028",
  },
  {
    bbrCode: 10095,
    bbrBnkCode: 1019,
    bbrBranchName: "TEMA INDUSTRIAL AREA",
    bbrShtDesc: "281029",
  },
  {
    bbrCode: 10096,
    bbrBnkCode: 1019,
    bbrBranchName: "SEFWI WIAWSO",
    bbrShtDesc: "281030",
  },
  {
    bbrCode: 10097,
    bbrBnkCode: 1019,
    bbrBranchName: "ENCHI",
    bbrShtDesc: "281031",
  },
  {
    bbrCode: 10098,
    bbrBnkCode: 1019,
    bbrBranchName: "IRIS",
    bbrShtDesc: "281032",
  },
  {
    bbrCode: 10099,
    bbrBnkCode: 1019,
    bbrBranchName: "KASOA",
    bbrShtDesc: "281033",
  },
  {
    bbrCode: 10100,
    bbrBnkCode: 1019,
    bbrBranchName: "SPINTEX",
    bbrShtDesc: "281034",
  },
  {
    bbrCode: 10101,
    bbrBnkCode: 1019,
    bbrBranchName: "HO",
    bbrShtDesc: "281035",
  },
  {
    bbrCode: 10102,
    bbrBnkCode: 1019,
    bbrBranchName: "NEWTOWN",
    bbrShtDesc: "281036",
  },
  {
    bbrCode: 10103,
    bbrBnkCode: 1019,
    bbrBranchName: "ALABAR",
    bbrShtDesc: "281037",
  },
  {
    bbrCode: 10104,
    bbrBnkCode: 1019,
    bbrBranchName: "HAATSO",
    bbrShtDesc: "281038",
  },
  {
    bbrCode: 10105,
    bbrBnkCode: 1019,
    bbrBranchName: "BOLGATANGA",
    bbrShtDesc: "281039",
  },
  {
    bbrCode: 10106,
    bbrBnkCode: 1019,
    bbrBranchName: "GACC",
    bbrShtDesc: "281040",
  },
  {
    bbrCode: 10107,
    bbrBnkCode: 1019,
    bbrBranchName: "WA",
    bbrShtDesc: "281041",
  },
  {
    bbrCode: 10108,
    bbrBnkCode: 1019,
    bbrBranchName: "KNUST",
    bbrShtDesc: "281042",
  },
  {
    bbrCode: 10109,
    bbrBnkCode: 1019,
    bbrBranchName: "LEGON",
    bbrShtDesc: "281043",
  },
  {
    bbrCode: 10110,
    bbrBnkCode: 1019,
    bbrBranchName: "ADJIRIGANOR",
    bbrShtDesc: "281044",
  },
  {
    bbrCode: 10111,
    bbrBnkCode: 1019,
    bbrBranchName: "KEJETIA",
    bbrShtDesc: "281045",
  },
  {
    bbrCode: 10112,
    bbrBnkCode: 1019,
    bbrBranchName: "OCTAGON",
    bbrShtDesc: "281046",
  },
  {
    bbrCode: 10113,
    bbrBnkCode: 1019,
    bbrBranchName: "TAKORADI MARKET CIRCLE",
    bbrShtDesc: "281047",
  },
  {
    bbrCode: 10114,
    bbrBnkCode: 1019,
    bbrBranchName: "KOFORIDUA",
    bbrShtDesc: "281048",
  },
  {
    bbrCode: 10177,
    bbrBnkCode: 1019,
    bbrBranchName: "SOUTH INDUSTRIAL AREA",
    bbrShtDesc: "281001",
  },
  {
    bbrCode: 10178,
    bbrBnkCode: 1019,
    bbrBranchName: "AIRPORT",
    bbrShtDesc: "281001",
  },
  {
    bbrCode: 10193,
    bbrBnkCode: 1019,
    bbrBranchName: "KUMASI ASAFO",
    bbrShtDesc: "281001",
  },
  {
    bbrCode: 10311,
    bbrBnkCode: 1019,
    bbrBranchName: "TUDU",
    bbrShtDesc: "281049",
  },
  {
    bbrCode: 10329,
    bbrBnkCode: 1019,
    bbrBranchName: "ODORKOR",
    bbrShtDesc: "281050",
  },
  {
    bbrCode: 10336,
    bbrBnkCode: 1019,
    bbrBranchName: "DARKUMAN",
    bbrShtDesc: "281051",
  },
  {
    bbrCode: 10352,
    bbrBnkCode: 1019,
    bbrBranchName: "AHODWO",
    bbrShtDesc: "281052",
  },
  {
    bbrCode: 10383,
    bbrBnkCode: 1019,
    bbrBranchName: "SEFWI DWENASE",
    bbrShtDesc: "281053",
  },
  {
    bbrCode: 10412,
    bbrBnkCode: 1019,
    bbrBranchName: "AGBOGBLOSHIE",
    bbrShtDesc: "281054",
  },
];

export default AccessBankBranchesData;
