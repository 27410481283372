import React, { PureComponent } from "react";
import mypadie from "../StarLife-Chatbot-paddie.png";
import logo from "../StarLife_Chatbot-06.png";
import spectra from "../spectra-logo-removebg-preview.png";
import gcb from "../gcb-logo.png";
import access from "../access-logo-main.png";
import omni from "../omnibsic_bank_logo.png";
import fbn from "../fbn-logo.png";
import amenfiman from "../Amenfi-Man-logo.png";
import odotobri from "../odotobri-logo.png";
import atwimakwanwoma from "../akrb_logo22.png";
import fiaseman from "../fiasemanlogo-1.png";
import amansie from "../amansie_logo.png";
import ahantaman from "../ahantaman-logo3-1.png";
import garural from "../Ga-Rural-Bank-logo.jpg";
import abokobi from "../abokobi.png";
import atwima from "../atwima-rural-logo-removebg-preview.png";
import yaaasantewaa from "../yaa-logo.jpg";
import amanano from "../amanano-logo.jpg";
import awutu from "../AWUTU-LOGO.png";
import capital from "../capital-logo.png";
import enyan from "../enyan-logo.png";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import "./Welcome.styles.css";

export class Welcome extends PureComponent {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    return (
      <React.Fragment>
        <img
          src={logo}
          alt="logo"
          style={{
            width: "100%",
          }}
        />
        <br /> <br />
        <h1>
          <b>Digital Onboarding Platforms</b>
        </h1>
        <hr />
        {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
        <div id="wrapper">
          <div id="content-wrapper">
            <div id="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/retail/spectra"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Spectra Health
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={spectra}
                                alt="logo"
                                style={{
                                  // width: "60px",
                                  height: "50px",
                                  // marginLeft: "auto",
                                  // marginRight: "auto",
                                  // display: "block",
                                  // border: "3px solid #73AD21",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/gcb"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                GCB Bancassurance
                              </div>
                              {/* <div className="h5 mb-0 font-weight-bold text-gray-800">
                        100
                      </div> */}
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={gcb}
                                alt="logo"
                                style={{
                                  height: "50px",
                                  // marginLeft: "auto",
                                  // marginRight: "auto",
                                  // display: "block",
                                  // border: "3px solid #73AD21",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/access"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Access Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={access}
                                alt="logo"
                                // style={{ width: "50px", height: "35px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/omnibsic"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                OmniBSIC Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={omni}
                                alt="logo"
                                style={{ height: "50px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  {/* <div className="col-xl-2 col-md-5 mb-3">
            <a href="#" target="" class=" text-decoration-none">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                        FBN Bancassurance
                      </div>
                    </div>
                    <div className="col-auto">
                      <img
                        src={fbn}
                        alt="logo"
                        style={{ width: "50px", height: "40px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div> */}

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/amenfiman"
                      target="_blanc"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Amenfiman Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={amenfiman}
                                alt="logo"
                                style={{
                                  width: "160px",
                                  height: "50px",
                                  backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/odotobri"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Odotobri Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={odotobri}
                                alt="logo"
                                style={{
                                  // width: "50px",
                                  height: "50px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/atwimakwanwoma"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Atwima Kwanwoma Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={atwimakwanwoma}
                                alt="logo"
                                style={{
                                  // width: "50px",
                                  height: "50px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/fiaseman"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                Fiaseman Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={fiaseman}
                                alt="logo"
                                style={{
                                  // width: "50px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/amansie"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Amansie West Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={amansie}
                                alt="logo"
                                style={{
                                  width: "150px",
                                  height: "50px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/ahantaman"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Ahantaman Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={ahantaman}
                                alt="logo"
                                style={{
                                  width: "150px",
                                  height: "50px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/garural"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Ga Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={garural}
                                alt="logo"
                                style={{
                                  // width: "150px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/abokobi"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Abokobi Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={abokobi}
                                alt="logo"
                                style={{
                                  width: "150px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/atwima"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Atwima Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={atwima}
                                alt="logo"
                                style={{
                                  width: "150px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/yaaasantewaa"
                      target="_blanc"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Yaa Asantewaa Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={yaaasantewaa}
                                alt="logo"
                                style={{
                                  width: "160px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/amanano"
                      target="_blanc"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Amanano Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={amanano}
                                alt="logo"
                                style={{
                                  // width: "160px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/awutu"
                      target="_blanc"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Awutu Emasa Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={awutu}
                                alt="logo"
                                style={{
                                  // width: "160px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/capital"
                      target="_blank"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Capital Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={capital}
                                alt="logo"
                                style={{
                                  width: "150px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="col-xl-2 col-md-5 mb-3">
                    <a
                      href="/bancassurance/enyan"
                      target="_blanc"
                      class=" text-decoration-none"
                    >
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Enyan Denkyira Rural Bancassurance
                              </div>
                            </div>
                            <div
                              className="col-auto"
                              style={{ margin: "auto" }}
                            >
                              <img
                                src={enyan}
                                alt="logo"
                                style={{
                                  // width: "160px",
                                  height: "60px",
                                  // backgroundColor: "#296F24",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p>
        <br />

        <Button
          variant="primary"
          style={{
            backgroundColor: "#763984",
            outlineColor: "#763984",
            borderColor: "#763984",
          }}
          onClick={this.continue}
        >
          Start Application
        </Button> */}
      </React.Fragment>
    );
  }
}

export default Welcome;
