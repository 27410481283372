import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export class ProductDetailsSpectraEscalation extends Component {
  render() {
    const { values, handleChange, escalationSpectraRateError } = this.props;
    return (
      <div>
        <Form.Group>
          <div>
            <div style={{ border: "3px solid #fff" }}>
              <div
                style={{
                  width: "90%",
                  float: "left",
                  // padding: "20px",
                  // border: "2px solid red",
                }}
              >
                <div>
                  <FloatingLabel
                    controlId="escalationRateSpectra"
                    label="Scan Plan Increment Rate and Percentage Discount"
                    className="mb-3"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("escalationRateSpectra")}
                      defaultValue={values.escalationRateSpectra}
                      style={{
                        border: escalationSpectraRateError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value="5"> 5%</option>
                      <option value="10">10%</option>
                      <option value="15">15%</option>
                      <option value="20">20%</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {escalationSpectraRateError}
                    </div>
                  </FloatingLabel>
                </div>
              </div>
              <div
                style={{
                  width: "10%",
                  float: "left",
                  padding: "20px",
                  // border: "2px solid red",
                }}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    data-tip
                    data-for="escalationRateTip"
                  />
                  <ReactTooltip
                    id="escalationRateTip"
                    place="right"
                    effect="solid"
                    data-multiline="true"
                  >
                    The choice of Increase <br />
                    Option 1 to 3 must <br />
                    be made by the policy <br />
                    holder at the inception <br />
                    of the policy.
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsSpectraEscalation;
