import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ProductDetailsEscalation from "./ProductDetailsEscalation";
import ProductDetailsBronze from "./ProductDetailsBronze";
import ProductDetailsSilver from "./ProductDetailsSilver";
import ProductDetailsGold from "./ProductDetailsGold";
import { getAge } from "../func/utils";
import { InputGroup } from "react-bootstrap";
import ProductDetailsSpectraEscalation from "./ProductDetailsSpectraEscalation";

import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export class ProductDetailsUPP extends PureComponent {
  state = {
    paChange: false,
    incrementOption: false,
    bronzeOption: false,
    silverOption: false,
    goldOption: false,
    packageDesc: "",
    incrementOptionSpectra: false,
    assuredPicture: "",
  };

  componentDidMount() {
    if (this.props.values.rider.split("-")[1] === "Bronze") {
      this.setState({
        bronzeOption: true,
        silverOption: false,
        goldOption: false,
        packageDesc: "Package Description for Bronze",
      });
    } else if (this.props.values.rider.split("-")[1] === "Silver") {
      this.setState({
        bronzeOption: false,
        silverOption: true,
        goldOption: false,
        packageDesc: "Package Description for Silver",
      });
    } else if (this.props.values.rider.split("-")[1] === "Gold") {
      this.setState({
        bronzeOption: false,
        silverOption: false,
        goldOption: true,
        packageDesc: "Package Description for Gold",
      });
    }

    if (this.props.values.escalation === "Yes") {
      this.setState({ incrementOption: true });
    } else {
      this.setState({ incrementOption: false });
    }

    if (this.props.values.escalationSpectra === "Yes") {
      this.setState({ incrementOptionSpectra: true });
    } else {
      this.setState({ incrementOptionSpectra: false });
    }
  }

  handlePAChange = (e) => {
    const pa = e.target.value;
    if (pa === "Yes") {
      this.setState({ paChange: true });
      this.props.valueChange(["pa", pa]);
    } else {
      this.setState({ paChange: false });
      this.props.valueChange(["pa", pa]);
      // this.props.valueChange(["escalationRate", ""]);
    }
  };

  handleIncrementOption = (e) => {
    const escalation = e.target.value;
    if (escalation === "Yes") {
      this.setState({ incrementOption: true });
      this.props.valueChange(["escalation", escalation]);
    } else {
      this.setState({ incrementOption: false });
      this.props.valueChange(["escalation", escalation]);
      this.props.valueChange(["escalationRate", ""]);
    }
  };

  handleIncrementOptionSpectra = (e) => {
    const escalationSpectra = e.target.value;
    if (escalationSpectra === "Yes") {
      this.setState({ incrementOptionSpectra: true });
      this.props.valueChange(["escalationSpectra", escalationSpectra]);
    } else {
      this.setState({ incrementOptionSpectra: false });
      this.props.valueChange(["escalationSpectra", escalationSpectra]);
      this.props.valueChange(["escalationRateSpectra", ""]);
    }
  };

  handlePlanType = (e) => {
    const planType = e.target.value;
    if (planType === "Bronze") {
      this.setState({
        bronzeOption: true,
        silverOption: false,
        goldOption: false,
        packageDesc: "Package Description for Bronze",
      });
      this.props.valueChange(["rider", "1-Bronze"]);
      //   this.props.valueChange(["escalation", escalation]);
    } else if (planType === "Silver") {
      this.setState({ policy_id: "2" });
      this.setState({
        bronzeOption: false,
        silverOption: true,
        goldOption: false,
        packageDesc: "Package Description for Silver",
      });
      this.props.valueChange(["rider", "2-Silver"]);
      //   this.props.valueChange(["escalation", escalation]);
    } else if (planType === "Gold") {
      this.setState({ policy_id: "3" });
      this.setState({
        bronzeOption: false,
        silverOption: false,
        goldOption: true,
        packageDesc: "Package Description for Gold",
      });
      this.props.valueChange(["rider", "3-Gold"]);
    } else {
      this.setState({
        bronzeOption: false,
        silverOption: false,
        goldOption: false,
        packageDesc: "",
      });
      this.props.valueChange(["rider", ""]);
    }
  };

  handlePackageType = (e) => {
    const packageType = e.target.value;
    if (parseInt(packageType.split("-")[1]) > 1) {
      this.props.valueChange(["memberRequired", true]);
      this.props.valueChange(["planType", packageType]);
      this.props.valueChange([
        "totalMember",
        parseInt(packageType.split("-")[1] - 1),
      ]);
    } else {
      this.props.valueChange(["memberRequired", false]);
      this.props.valueChange(["planType", packageType]);
      this.props.valueChange([
        "totalMember",
        parseInt(packageType.split("-")[1] - 1),
      ]);
    }
  };

  // On file select (from the pop up)
  onPictureSelect = (event) => {
    var reader = new FileReader();
    // reader.readAsText(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.props.valueChange(["assuredPicture", reader.result.split(",")[1]]);
    };
    this.setState({
      assuredPicture: URL.createObjectURL(event.target.files[0]),
    });
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };

  render() {
    const {
      values,
      termError,
      riderError,
      dreadDiseaseError,
      handleChange,
      premiumError,
      escalationError,
      escalationRateError,
      planError,
      paymentFrequencyError,
      mChecked,
      aChecked,
      escalationSpectraError,
      escalationSpectraRateError,
      assuredPictureError,
    } = this.props;

    let age;
    const maxAge = 65;
    let policyTerm = [];

    if (values.clntCode) {
      const dob = values.dob;
      const [dob_day, dob_month, dob_year] = dob.split("-");
      const newDob = [dob_year, dob_month, dob_day].join("-");
      age = getAge(newDob);
    } else {
      const dob = values.dob;
      age = getAge(dob);
    }

    if (maxAge - (age + 1) < 20 && maxAge - (age + 1) >= 8) {
      for (let step = 8; step <= maxAge - (age + 1); step++) {
        policyTerm.push({ value: step, label: step });
      }
    } else if (maxAge - (age + 1) >= 20) {
      for (let step = 8; step <= 20; step++) {
        policyTerm.push({ value: step, label: step });
      }
    } else {
    }

    return (
      <div>
        {/* <div style={{ border: "3px solid #fff" }}>
          <div
            style={{
              width: "90%",
              float: "left",
              padding: "20px",
              border: "2px solid red",
            }}
          >
            <div>1</div>
          </div>
          <div
            style={{
              width: "10%",
              float: "left",
              padding: "20px",
              border: "2px solid red",
            }}
          >
            <div>2</div>
          </div>
        </div> */}
        {/* <div style={{ width: "100%" }}>
          <div style={{ width: "96.5%", height: "100px", float: "left" }}>
            A
          </div>
          <div style={{ marginLeft: "50%", height: "100px" }}>?</div>
        </div> */}
        <Form.Group>
          <div>
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="term"
                  label="Select policy term (min of 8 years: max of 20 years)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    autoComplete="off"
                    onChange={handleChange("term")}
                    defaultValue={values.term}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: termError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {policyTerm.map((term) => (
                      <option key={term.value} value={term.value}>
                        {term.label + " years"}
                      </option>
                    ))}
                    {/* <option value={"8"}>8 years</option>
                    <option value={"9"}>9 years</option>
                    <option value={"10"}>10 years</option>
                    <option value={"11"}>11 years</option>
                    <option value={"12"}>12 years</option>
                    <option value={"13"}>13 years</option>
                    <option value={"14"}>14 years</option>
                    <option value={"15"}>15 years</option>
                    <option value={"16"}>16 years</option>
                    <option value={"17"}>17 years</option>
                    <option value={"18"}>18 years</option>
                    <option value={"19"}>19 years</option>
                    <option value={"20"}>20 years</option> */}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {termError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>

            {/* Riders */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="rider"
                  label="Spectra Health Scan Plan Type"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handlePlanType}
                    defaultValue={values.rider.split("-")[1]}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.paymentMode}
                    style={{
                      border: riderError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Bronze"}>Bronze</option>
                    <option value={"Silver"}>Silver</option>
                    <option value={"Gold"}>Gold</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {riderError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="riderTip"
                />
                <ReactTooltip
                  id="riderTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  {this.state.packageDesc
                    ? this.state.packageDesc
                    : "Mandatory option that adds scan benefit to the basic cover provided"}
                </ReactTooltip>
              </div>
            </div>
            {this.state.bronzeOption && (
              <ProductDetailsBronze
                planError={planError}
                handleChange={handleChange}
                values={values}
                handlePackageType={this.handlePackageType}
              />
            )}
            {this.state.silverOption && (
              <ProductDetailsSilver
                planError={planError}
                handleChange={handleChange}
                values={values}
                handlePackageType={this.handlePackageType}
              />
            )}
            {this.state.goldOption && (
              <ProductDetailsGold
                planError={planError}
                handleChange={handleChange}
                values={values}
                handlePackageType={this.handlePackageType}
              />
            )}

            {/* tpd */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="dreadDisease"
                  label="Dread Disease/ Critical Illness"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("dreadDisease")}
                    defaultValue={values.dreadDisease}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.paymentMode}
                    style={{
                      border: dreadDiseaseError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {dreadDiseaseError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="dreadTip"
                />
                <ReactTooltip
                  id="dreadTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Provides 50% of selected Sum <br />
                  Assured when diseases that have <br />
                  severe impact on policyholder <br />
                  lifestyle occurs e.g. stroke,
                  <br /> cancer, Kidney failure, AIDS.
                </ReactTooltip>
              </div>
            </div>

            {/* premium */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="premium"
                  label="Enter your basic sum assured (minimum 100,000 ghc)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("sa")}
                    defaultValue={values.sa}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: premiumError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"100000"}>100,000</option>
                    <option value={"200000"}>200,000</option>
                    <option value={"300000"}>300,000</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {premiumError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="premiumTip"
                />
                <ReactTooltip
                  id="premiumTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  A sum assured is a fixed amount <br />
                  that is paid to the beneficiary <br />
                  of the policyholder in the unfortunate <br />
                  event of the policyholder's demise.
                </ReactTooltip>
              </div>
            </div>

            {/* Payment Frequeny */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <Form.Group>
                  <div className="mb-3" style={{ textAlign: "left" }}>
                    Payment Frequency
                  </div>
                  <InputGroup
                    className="mb-3"
                    onChange={handleChange("paymentFrequency")}
                    defaultValue={values.paymentFrequency}
                  >
                    <InputGroup.Radio
                      style={{
                        border: paymentFrequencyError ? "1px solid red" : "",
                      }}
                      value="M"
                      name="paymentFrequency"
                      aria-label="Radio 1"
                      defaultChecked={mChecked}
                    />{" "}
                    &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: paymentFrequencyError ? "1px solid red" : "",
                      }}
                      value="A"
                      name="paymentFrequency"
                      aria-label="Radio 1"
                      defaultChecked={aChecked}
                    />{" "}
                    &nbsp; Annually
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {paymentFrequencyError}
                    </div>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="payFreqTip"
                />
                <ReactTooltip
                  id="payFreqTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  How often do you want to pay your premium?
                </ReactTooltip>
              </div>
            </div>

            {/* Escalation */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="escalation"
                  label="Do you wish to have incremental option?"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleIncrementOption}
                    defaultValue={values.escalation}
                    style={{
                      border: escalationError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {escalationError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="escalationTip"
                />
                <ReactTooltip
                  id="escalationTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  The option to increase the <br />
                  contractual premium on <br />
                  an annual basis in order <br /> for the benefits to be
                  <br />
                  inflation-linked.
                </ReactTooltip>
              </div>
            </div>

            {this.state.incrementOption && (
              <ProductDetailsEscalation
                escalationRateError={escalationRateError}
                handleChange={handleChange}
                values={values}
              />
            )}

            {/* Escalation for Spectra */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="escalationSpectra"
                  label="Do you wish to have incremental option on your Scan Plan?"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleIncrementOptionSpectra}
                    defaultValue={values.escalationSpectra}
                    style={{
                      border: escalationSpectraError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {escalationSpectraError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="escalationSpectraTip"
                />
                <ReactTooltip
                  id="escalationSpectraTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  The option to increase the <br />
                  percentage discount
                  <br />
                  for your scan plan
                </ReactTooltip>
              </div>
            </div>
            {this.state.incrementOptionSpectra && (
              <ProductDetailsSpectraEscalation
                escalationSpectraRateError={escalationSpectraRateError}
                handleChange={handleChange}
                values={values}
              />
            )}
          </div>
          {/* Picture of Assured */}
          <div
            style={{
              width: "90%",
              float: "left",
              // padding: "20px",
              // border: "2px solid red",
            }}
          >
            <div>
              <Form.Group>
                <div className="mb-" style={{ textAlign: "left" }}>
                  Picture of Assured
                </div>
                <div style={{ textAlign: "left" }}>
                  {this.state.assuredPicture && (
                    <img
                      src={this.state.assuredPicture}
                      width="200"
                      height="100"
                    />
                  )}
                </div>

                <Form.Control
                  accept="image/*"
                  type="file"
                  controlId="assuredPicture"
                  label="Picture of assured"
                  className="mb-2"
                  onChange={this.onPictureSelect}
                  style={{
                    border: assuredPictureError ? "1px solid red" : "",
                  }}
                ></Form.Control>

                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {assuredPictureError}
                </div>
              </Form.Group>
            </div>
          </div>
          <div
            style={{
              width: "10%",
              float: "left",
              padding: "20px",
              // border: "2px solid red",
            }}
          >
            <div>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                data-tip
                data-for="pictureTip"
              />
              <ReactTooltip
                id="pictureTip"
                place="right"
                effect="solid"
                data-multiline="true"
              >
                Picture of Assured.
              </ReactTooltip>
            </div>
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsUPP;
