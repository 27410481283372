const BankBranchesData = [
  {
    bbrCode: 10299,
    bbrBnkCode: 1027,
    bbrBranchName: "ACHIMOTA",
    bbrShtDesc: "081130",
  },
  {
    bbrCode: 10300,
    bbrBnkCode: 1027,
    bbrBranchName: "ABLEKUMA",
    bbrShtDesc: "081930",
  },
  {
    bbrCode: 10301,
    bbrBnkCode: 1027,
    bbrBranchName: "KOKROBITE",
    bbrShtDesc: "081530",
  },
  {
    bbrCode: 10302,
    bbrBnkCode: 1027,
    bbrBranchName: "KWABENYA",
    bbrShtDesc: "081730",
  },
  {
    bbrCode: 10303,
    bbrBnkCode: 1027,
    bbrBranchName: "TAIFA",
    bbrShtDesc: "081230",
  },
  {
    bbrCode: 10304,
    bbrBnkCode: 1027,
    bbrBranchName: "MALLAM",
    bbrShtDesc: "081330",
  },
  {
    bbrCode: 10305,
    bbrBnkCode: 1027,
    bbrBranchName: "POKUASE",
    bbrShtDesc: "081830",
  },
  {
    bbrCode: 10306,
    bbrBnkCode: 1027,
    bbrBranchName: "MEDIE",
    bbrShtDesc: "081630",
  },
  {
    bbrCode: 10307,
    bbrBnkCode: 1027,
    bbrBranchName: "AMASAMAN",
    bbrShtDesc: "081030",
  },
];

export default BankBranchesData;
