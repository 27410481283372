import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faHome,
  faSearch,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import logo from "../../starlife-logo.png";
import AuthContext from "../../store/auth-context";

function Menu() {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.dsoLogout();
  };
  return (
    <div>
      {/* <!-- Image and text --> */}
      {authCtx.isDSOLoggedIn ? (
        <ul className="nav justify-content-end">
          <img
            src={logo}
            alt="logo"
            // style={{ width: "150px", height: "150px", padding: 10 }}
            // style={{
            //   align: "left",
            //   left: 0,
            // }}
          />
          {/* <div style={{ flex: 0.15 }} /> */}
          <div style={{ flex: 0.42 }} />
          <li className="nav-item">
            <a
              className="nav-link active"
              href="/retail/starlife"
              style={{ color: "#763984" }}
            >
              {/* Home  */}
              <FontAwesomeIcon icon={faHome} title="Home" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" style={{ color: "#763984" }}>
              {/* Customer Enquiry  */}
              <FontAwesomeIcon icon={faSearch} title="Customer Enquiry" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" style={{ color: "#763984" }}>
              {/* Endorsements  */}
              <FontAwesomeIcon icon={faEdit} title="Endorsements" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" style={{ color: "#763984" }}>
              {/* Logout  */}
              <span onClick={logoutHandler}>
                <FontAwesomeIcon icon={faSignOutAlt} title="Logout" />
              </span>
            </a>
          </li>
        </ul>
      ) : (
        <img
          src={logo}
          alt="logo"
          style={{
            align: "right",
          }}
        />
      )}
    </div>
  );
}

export default Menu;
