import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import logo from "../../static/img/starlife-logo.png";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LoginPortal() {
  const navigate = useNavigate();
  const [userNameError, setUserNameError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);

  const authCtx = useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setUserNameError(null);
    setPasswordError(null);

    let unameError = "";
    let pError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      unameError = "Please enter your username";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      pError = "Please enter your password";
    }

    if (unameError || pError) {
      setUserNameError(unameError);
      setPasswordError(pError);
      return false;
    }

    const userLogin = {
      User_Name: e.target.form[0].value,
      Password: e.target.form[1].value,
    };

    setLoading(true);

    //Verify client phone
    axios
      .post("/api/merchant/login", userLogin)
      .then((response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          const expirationTime = new Date(new Date().getTime() + 3600 * 2000);
          authCtx.login(
            response.data.token,
            response.data.user_name,
            response.data.bbr_code,
            expirationTime.toISOString(),
            response.data.bank
          );
          navigate("/bancassurance/portal/home", { replace: true });
        } else {
          setLoading(false);
          toast.error("Invalid username or password", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occured. Kindly try again later", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <br></br>
          <img
            src={logo}
            alt="logo"
            //   style={{ width: "150px", height: "150px", padding: 10 }}
          />
          <br></br>
          <br></br>

          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp;{" "}
                <u>Personal Details of the Account Holder</u>
              </h4> */}
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="username"
                  label="User Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Your Username Name"
                    style={{
                      border: userNameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {userNameError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="password"
                  label="Password"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="password"
                    autoComplete="off"
                    placeholder="Enter Your Password"
                    style={{
                      border: passwordError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {passwordError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Login <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
            </Form>
          </div>
        </>
      ) : (
        <>
          <br></br>
          <img
            src={logo}
            alt="logo"
            //   style={{ width: "150px", height: "150px", padding: 10 }}
          />
          <br></br>
          <br></br>

          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp; <u>Login</u>
              </h4> */}
            <Form
              onSubmit={handleSubmit}
              style={{
                color: "purple",
              }}
            >
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="username"
                  label="User Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Your Username Name"
                    style={{
                      border: userNameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {userNameError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="password"
                  label="Password"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="password"
                    autoComplete="off"
                    placeholder="Enter Your Password"
                    style={{
                      border: passwordError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {passwordError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <div style={{ textAlign: "right", border: "1px" }}>
                <a href="/bancassurance/portal/forgot-password">
                  Forgot Password?
                </a>
              </div>
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Login <FontAwesomeIcon icon={faSignInAlt} />
              </Button>{" "}
            </Form>
          </div>
          <ToastContainer autoClose={20000} />
        </>
      )}
    </div>
  );
}

export default LoginPortal;
