import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
// import "./sb-admin-2.min.css";
import "../../static/vendor/fontawesome-free/css/all.min.css";
// import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
import logo from "../../static/img/starlife-logo.png";
import DataTable from "react-data-table-component";
import Export from "react-data-table-component";
import { Modal, Button, InputGroup } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Viewer from "react-viewer";
import pdfmodal from "./pdf_modal";
import FlashMessage from "react-flash-message";
import AuthContext from "../../store/auth-context";
import proposalData from "./proposalsdata";
import atwima from "../../akrb_logo22.png";
import amenfiman from "../../Amenfi-Man-logo2.png";
import gcb from "../../gcb-logo.png";
import access from "../../access-logo-main.png";
import okomfo from "../../Logo-okomfo-new.png";
import ahantaman from "../../ahantaman-logo3-1.png";
import { env } from "process";
import { titleCase } from "../func/utils";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useNavigate } from "react-router-dom";

function AdminPasswordChange() {
  const navigate = useNavigate();
  // componentDidMount() {
  //   const script = document.createElement("script");
  //   script.src = "static/vendor/datatables/jquery.dataTables.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }

  const authCtx = useContext(AuthContext);

  const [pending, setPending] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [proposals, setProposals] = React.useState([]);
  const [url, setUrl] = React.useState([]);
  const [proposalurl, setProposalurl] = React.useState([]);
  const [showModal, setShow] = React.useState(false);
  const [showPasswordChangeMessage, setPasswordChangeMessage] =
    React.useState(false);
  const [showRejectMessage, setShowRejectMessage] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [policyDetails, setPolicyDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [policyNo, setPolicyNo] = React.useState(null);
  const [pDetails, setPDetails] = React.useState([]);
  const [proposerId, setProposerId] = React.useState(null);
  const [yesChecked, setYesChecked] = React.useState(null);
  const [noChecked, setNoChecked] = React.useState(null);
  const [currentPassError, setCurrentPassError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  const bank = authCtx.bank;
  const branch = authCtx.bbrCode;

  let inputStyle = {
    backgroundColor: "#763984",
    color: "white",
  };

  let proposal_logo = "";

  if (bank === "ATWIMA KWANWOMA RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#009900",
      color: "#fff",
    };
    proposal_logo = atwima;
  } else if (bank === "AMENFIMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = amenfiman;
  } else if (bank === "GCB BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ffc52a",
      color: "#4a4a4a",
    };
    proposal_logo = gcb;
  } else if (bank === "ACCESS BANK BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ee7e01",
      color: "#fff",
    };
    proposal_logo = access;
  } else if (bank === "OKOMFO ANOKYE RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = okomfo;
  } else if (bank === "AHANTAMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#A80404",
      color: "#fff",
    };
    proposal_logo = ahantaman;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setCurrentPassError(null);
    setPasswordError(null);
    setConfirmPasswordError(null);

    let curPassError = "";
    let pError = "";
    let conPassError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      curPassError = "Please enter your current password";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      pError = "Please enter your new password";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      conPassError = "Please confirm password";
    }

    if (curPassError || pError || conPassError) {
      setCurrentPassError(curPassError);
      setPasswordError(pError);
      setConfirmPasswordError(conPassError);
      return false;
    }

    const userPassword = {
      User_Name: authCtx.userName,
      Current_Password: e.target.form[0].value,
      Password: e.target.form[1].value,
      Confirm_Password: e.target.form[2].value,
    };

    setLoading(true);

    //Verify client phone
    axios
      .post("/api/merchant/change-password", userPassword)
      .then((response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          setPasswordChangeMessage(true);
          setLoading(false);
          setTimeout(() => {
            setPasswordChangeMessage(false);
          }, 5000);
        } else if (response.data.message_code === 102) {
          setLoading(false);
          alert(
            "New Password does not meet password requirements. Password should be 8 characters long, must contain at least one lowercase, one uppercase and one digit"
          );
        } else if (response.data.message_code === 101) {
          setLoading(false);
          alert("Current Password provided is invalid");
          logoutHandler();
        } else if (response.data.message_code === 103) {
          setLoading(false);
          alert("Password and confirm password does not match");
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("Invalid Username/Password");
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          {/* // <!-- Page Wrapper --> */}
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
              id="accordionSidebar"
            >
              {/* <!-- Sidebar - Brand --> */}
              <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/bancassurance/portal/home"
              >
                <div
                  className="sidebar-brand-icon"
                  style={{ backgroundColor: "white", marginLeft: "50px" }}
                >
                  <img src={logo} height="70" />
                </div>
              </a>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider my-0"></hr>
              {/* <!-- Nav Item - Dashboard --> */}
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/home">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span>Merchant Dashboard</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/report/successful"
                >
                  <i className="fa fa-file"></i>
                  <span>Reports</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/change-password"
                >
                  <i className="fa fa-file"></i>
                  <span>Change Password</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fas fa-sign-out-alt"></i>
                  <span onClick={logoutHandler}>Logout</span>
                </a>
              </li>
            </ul>
            {/* <!-- End of Sidebar --> */}

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              {/* <!-- Main Content --> */}
              <div id="content">
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* <!-- Sidebar Toggle (Topbar) --> */}
                  <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {% if current_user.is_authenticated %} */}
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                          {authCtx.userName}
                          {/* <font color="#763984">DELIJAH</font> */}
                        </span>
                        {/* {% endif %} */}
                        <i className="fas fa-user"></i>
                      </a>
                      {/* <!-- Dropdown - User Information --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logoutModal"
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
                  {/* <!-- Page Heading --> */}
                  {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
                  {/* <!-- DataTales Example --> */}
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      {showPasswordChangeMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-success">
                              <strong>Password successfully update.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      {showRejectMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-danger">
                              <strong>Proposal rejected successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      <div className="table-responsive">
                        {/* <button
                      id="export"
                      name="export"
                      value="export"
                      data-toggle="modal"
                      data-target="#ExportModal"
                      className="btn btn-primary"
                      type="submit"
                      style={{ float: "right", height: "31px" }}
                    >
                      <i className="fas fa-download"></i> Export
                    </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of Main Content --> */}

              {/* <!-- Footer --> */}
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()} StarLife
                      Assurance. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
                </div>
              </footer>
              {/* <!-- End of Footer --> */}
            </div>
            {/* <!-- End of Content Wrapper --> */}
          </div>
          {/* //   <!-- End of Page Wrapper --> */}

          {/* <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      ) : (
        <>
          {/* // <!-- Page Wrapper --> */}
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
              id="accordionSidebar"
            >
              {/* <!-- Sidebar - Brand --> */}
              <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/bancassurance/portal/home"
              >
                <div
                  className="sidebar-brand-icon"
                  style={{ backgroundColor: "white", marginLeft: "50px" }}
                >
                  <img src={logo} height="70" />
                </div>
              </a>
              <br></br>
              {/* <!-- Divider --> */}
              {/* <hr className="sidebar-divider my-0"></hr> */}
              {/* <!-- Nav Item - Dashboard --> */}
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/home">
                  <i class="fa fa-home" aria-hidden="true"></i>
                  <span>Home</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/report/successful"
                >
                  <i className="fa fa-file"></i>
                  <span>Reports</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/change-password"
                >
                  <i className="fa fa-key"></i>
                  <span>Change Password</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fas fa-sign-out-alt"></i>
                  <span onClick={logoutHandler}>Logout</span>
                </a>
              </li>
            </ul>
            {/* <!-- End of Sidebar --> */}

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              {/* <!-- Main Content --> */}
              <div id="content">
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* <!-- Sidebar Toggle (Topbar) --> */}
                  <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {% if current_user.is_authenticated %} */}
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                          {authCtx.userName}
                          {/* <font color="#763984">DELIJAH</font> */}
                        </span>
                        {/* {% endif %} */}
                        <i className="fas fa-user"></i>
                      </a>
                      {/* <!-- Dropdown - User Information --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logoutModal"
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
                  {/* <!-- Page Heading --> */}
                  {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
                  {/* <!-- DataTales Example --> */}
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      {showPasswordChangeMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-success">
                              <strong>Password successfully update.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      {showRejectMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-danger">
                              <strong>Proposal rejected successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      <div
                        className="container center_div"
                        style={{
                          display: "block",
                          width: 400,
                          padding: 10,
                        }}
                      >
                        {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp; <u>Login</u>
              </h4> */}
                        <Form
                          //   onSubmit={}
                          style={{
                            color: "purple",
                          }}
                        >
                          <Form.Group
                            style={{
                              color: "purple",
                            }}
                          >
                            <FloatingLabel
                              controlId="currentpassword"
                              label="Current Password"
                              className="mb-2"
                            >
                              <Form.Control
                                required
                                type="password"
                                autoComplete="off"
                                placeholder="Current Password"
                                style={{
                                  border: currentPassError
                                    ? "1px solid red"
                                    : "",
                                }}
                              />
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                }}
                              >
                                {currentPassError}
                              </div>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group
                            style={{
                              color: "purple",
                            }}
                          >
                            <FloatingLabel
                              controlId="newpassword"
                              label="New Password"
                              className="mb-2"
                            >
                              <Form.Control
                                required
                                type="password"
                                autoComplete="off"
                                placeholder="New Password"
                                style={{
                                  border: passwordError ? "1px solid red" : "",
                                }}
                              />
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                }}
                              >
                                {passwordError}
                              </div>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group
                            style={{
                              color: "purple",
                            }}
                          >
                            <FloatingLabel
                              controlId="confirmpassword"
                              label="Confirm Password"
                              className="mb-2"
                            >
                              <Form.Control
                                required
                                type="password"
                                autoComplete="off"
                                placeholder="Confirm Password"
                                style={{
                                  border: confirmPasswordError
                                    ? "1px solid red"
                                    : "",
                                }}
                              />
                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                }}
                              >
                                {confirmPasswordError}
                              </div>
                            </FloatingLabel>
                          </Form.Group>
                          <Button
                            variant="primary"
                            style={{
                              backgroundColor: "#763984",
                              outlineColor: "#763984",
                              borderColor: "#763984",
                              color: "white",
                            }}
                            onClick={handleSubmit}
                          >
                            Change Password
                          </Button>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of Main Content --> */}

              {/* <!-- Footer --> */}
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()} StarLife
                      Assurance. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
                </div>
              </footer>
              {/* <!-- End of Footer --> */}
            </div>
            {/* <!-- End of Content Wrapper --> */}
          </div>
          {/* //   <!-- End of Page Wrapper --> */}

          {/* <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      )}
    </>
  );
}

export default AdminPasswordChange;
