const BankBranchesData = [
  {
    bbrCode: 10343,
    bbrBnkCode: 1029,
    bbrBranchName: "ALABAR",
    bbrShtDesc: "082017",
  },
  {
    bbrCode: 10344,
    bbrBnkCode: 1029,
    bbrBranchName: "ATONSU",
    bbrShtDesc: "082016",
  },
  {
    bbrCode: 10345,
    bbrBnkCode: 1029,
    bbrBranchName: "AYIGYA",
    bbrShtDesc: "082015",
  },
  {
    bbrCode: 10346,
    bbrBnkCode: 1029,
    bbrBranchName: "KRONUM",
    bbrShtDesc: "082011",
  },
  {
    bbrCode: 10347,
    bbrBnkCode: 1029,
    bbrBranchName: "NEW TAFO",
    bbrShtDesc: "082014",
  },
  {
    bbrCode: 10348,
    bbrBnkCode: 1029,
    bbrBranchName: "OLD TAFO",
    bbrShtDesc: "082013",
  },
  {
    bbrCode: 10349,
    bbrBnkCode: 1029,
    bbrBranchName: "PAKYI NO. 2",
    bbrShtDesc: "082010",
  },
  {
    bbrCode: 10350,
    bbrBnkCode: 1029,
    bbrBranchName: "SANTASI",
    bbrShtDesc: "082012",
  },
  {
    bbrCode: 10351,
    bbrBnkCode: 1029,
    bbrBranchName: "SOKOBAN",
    bbrShtDesc: "082018",
  },
  {
    bbrCode: 10365,
    bbrBnkCode: 1029,
    bbrBranchName: "BANTAMA",
    bbrShtDesc: "082019",
  },
  {
    bbrCode: 10574,
    bbrBnkCode: 1029,
    bbrBranchName: "ADUM",
    bbrShtDesc: "082020",
  },
];

export default BankBranchesData;
