import React, { PureComponent } from "react";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import gcb from "../../south_akim_logonew.png";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshake,
  faSearch,
  faEye,
  faPrint,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Tab, Nav } from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import Tabs from "./Tabs";
import Menu from "./menu";
import NewHeader from "./header/NewHeaderAtwima";
import moment from "moment";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";

export class WelcomeEnquiry extends PureComponent {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      searchParameter: "",
      phoneError: "",
      accountError: "",
      policyError: "",
      loading: false,
      policyDetails: {},
      showModal: false,
      queryResults: [],
    };
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleShow = () => {
    this.setState({ showModal: true });
  };

  searchHandler = (e) => {
    e.preventDefault();
    console.log(e);
    let searchParameter = "";
    let phoneError = "";
    let accountError = "";
    let policyError = "";

    this.setState({
      searchParameter,
      phoneError,
      accountError,
      policyError,
    });

    if (
      !e.target.phone.value &
      !e.target.account.value &
      !e.target.policy.value
    ) {
      // Checks if field is empty
      searchParameter = "Please enter a search parameter";
    }

    if (searchParameter) {
      this.setState({
        searchParameter,
      });
      return false;
    }

    this.setState({ loading: true });

    const searchData = {
      phoneNumber: e.target.phone.value,
      acccountNumber: e.target.account.value,
      policyNumber: e.target.policy.value,
      bbrCode: 724,
    };

    axios
      .post("/api/dso/enquiry", searchData)
      .then((response) => {
        console.log(response);
        if (response.data.message_code === 100) {
          this.setState({ queryResults: response.data.data });
        } else {
          alert("Nothing found.");
          this.setState({ queryResults: [] });
        }
        this.setState({ loading: false });
        console.log("query", this.state.queryResults);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false, queryResults: [] });
      });
  };

  handleViewAction = (e, pol_no) => {
    e.preventDefault();
    console.log(pol_no);
    const pol_details = {
      Policy_Number: pol_no,
    };

    this.setState({ loading: true });

    axios
      .post("/api/dso/customer", pol_details)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ policyDetails: response.data });
          console.log("policy details", this.state.policyDetails);
          this.setState({ loading: false });
          this.handleShow();
        } else {
          this.setState({ loading: false });
          alert("Error occured displaying details. Please try again later.");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        alert("Error occured displaying details. Please try again later.");
      });
  };

  // downloadPDF = () => {
  //   // const { policyNumber, issueDate, assuredName, expiryDate, product } = this.state.policyDetails;
  //   const premiums = this.state.policyDetails.premium;
  //   console.log("input", premiums);

  //   // Create a new jsPDF instance
  //   const pdf = new jsPDF();

  //   // Set initial y position for content
  //   let y = 10;

  //   // Add title for premium table
  //   pdf.setFontSize(16);
  //   pdf.text("Premium Payment Ledger", 105, y, { align: "center" });
  //   y += 10;

  //   // Add header row for premium table
  //   pdf.setFontSize(12);
  //   pdf.text("Date", 60, y);
  //   pdf.text("Amount", 140, y);
  //   y += 10;

  //   // Loop through premium data and add rows to the table
  //   premiums.forEach((premium) => {
  //     const { PAYMENT_DATE, AMOUNT } = premium;
  //     const formattedDate = PAYMENT_DATE; // You need to implement formatDate function according to your date format
  //     const formattedAmount = AMOUNT.toFixed(2); // Format amount as needed

  //     // Add row to the table
  //     pdf.text(formattedDate, 60, y);
  //     pdf.text(formattedAmount, 140, y);
  //     y += 10;
  //   });

  //   // Save PDF
  //   pdf.save("policy-details.pdf");
  // };

  downloadPDF = () => {
    const premiums = this.state.policyDetails.premium;
    console.log("input", premiums);

    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Set initial y position for content
    let y = 10;

    // Add title for premium table
    pdf.setFontSize(16);
    pdf.text("Premium Payment Ledger", 105, y, { align: "center" });
    y += 10;

    // Add header row for premium table
    pdf.setFontSize(12);
    pdf.text("Date", 60, y);
    pdf.text("Amount", 140, y);
    y += 10;

    // Calculate the total height of the premium table content
    let totalHeight = 0;
    premiums.forEach((premium) => {
      totalHeight += 10; // Height of each row
    });

    // Set maximum height for the page based on the total height of the content
    const maxHeight = totalHeight + 40; // Add extra padding and title height

    // Loop through premium data and add rows to the table
    premiums.forEach((premium) => {
      // Check if adding a new row exceeds the maximum height
      if (y + 10 > maxHeight) {
        // If exceeds, add a new page
        pdf.addPage();
        y = 10; // Reset y position

        // Add header row for premium table on the new page
        pdf.setFontSize(12);
        pdf.text("Date", 60, y);
        pdf.text("Amount", 140, y);
        y += 10;
      }

      const { PAYMENT_DATE, AMOUNT } = premium;
      const formattedDate = PAYMENT_DATE; // You need to implement formatDate function according to your date format
      const formattedAmount = AMOUNT.toFixed(2); // Format amount as needed

      // Add row to the table
      pdf.text(formattedDate, 60, y);
      pdf.text(formattedAmount, 140, y);
      y += 10;
    });

    // Save PDF
    pdf.save("policy-details.pdf");
  };

  render() {
    console.log("authCtx", this.props.agn_code);
    console.log("queryResults", this.state.queryResults[0]);
    const columns = [
      {
        name: "Policy Number",
        selector: (row) => row.pol_no,
        sortable: true,
      },
      {
        name: "Premium",
        selector: (row) => row.premium,
        sortable: true,
      },
      {
        name: "Sum Assured",
        selector: (row) => row.sum_assured,
        sortable: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            <span
              className="btn btn-warning"
              title="View Details"
              onClick={(e) => this.handleViewAction(e, row.pol_no)}
            >
              <FontAwesomeIcon
                icon={faEye}
                style={{
                  color: "#4a4a4a",
                }}
              />
            </span>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const newColumns = [
      {
        name: "Issue Date",
        selector: (row) => row.ISSUE_DATE,
        sortable: true,
        width: "120px",
      },
      {
        name: "Name",
        selector: (row) =>
          row.FIRST_NAME +
          (row.MIDDLE_NAME ? " " + row.MIDDLE_NAME : "") +
          " " +
          row.SURNAME,
        width: "220px",
      },
      {
        name: "Product Name",
        selector: (row) => row.PROD_NAME,
        width: "170px",
      },
      {
        name: "Proposal Number",
        selector: (row) => row.PROPOSAL_NUMBER,
        width: "179px",
      },
      {
        name: "Policy Number",
        selector: (row) => row.POLICY_NUMBER,
        width: "179px",
      },
      {
        name: "Premium",
        selector: (row) => row.INIT_PREMIUM,
        sortable: true,
        width: "100px",
      },
      {
        name: "Sum Assured",
        selector: (row) => row.INIT_SUM,
        sortable: true,
        width: "120px",
      },
      {
        name: "Maturity Date",
        selector: (row) => row.MATURITY_DATE,
        sortable: true,
        width: "125px",
      },
      {
        name: "Status",
        selector: (row) => row.STATUS,
        sortable: true,
        width: "80px",
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            <span
              className="btn btn-success"
              title="View Details"
              onClick={(e) => this.handleViewAction(e, row.PROPOSAL_NUMBER)}
            >
              <FontAwesomeIcon
                icon={faEye}
                style={{
                  color: "#fff",
                }}
              />
            </span>
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const data = [
      {
        id: 1,
        pol_no: "WPP1007702220966737",
        premium: "40.89",
        sum_assured: "5,000.00",
      },
      {
        id: 2,
        pol_no: "EHP1007707200801168",
        premium: "55",
        sum_assured: "2,775.23",
      },
    ];
    return (
      <React.Fragment>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-amenfiman"}></div>
            <NewHeader />

            <br></br>
            <h3>Customer Enquiry </h3>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  {/* Search Parameters{" "} */}
                </h6>
                <div>
                  <form method="POST" onSubmit={this.searchHandler}>
                    <input
                      className="col-75"
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Phone No"
                      style={{
                        border: this.state.searchParameter
                          ? "1px solid red"
                          : "",
                      }}
                    />{" "}
                    &nbsp;
                    <input
                      className="col-75"
                      type="text"
                      id="account"
                      name="account"
                      placeholder="Account No"
                      style={{
                        border: this.state.searchParameter
                          ? "1px solid red"
                          : "",
                      }}
                    />{" "}
                    &nbsp;
                    <input
                      className="col-75"
                      type="text"
                      id="policy"
                      name="policy"
                      placeholder="Proposal or Policy No"
                      style={{
                        border: this.state.searchParameter
                          ? "1px solid red"
                          : "",
                      }}
                    />{" "}
                    <div
                      style={{
                        //   textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.searchParameter}
                    </div>
                    <br />
                    {/* <br /> */}
                    &nbsp;
                    <button className="btn btn-info" type="submit">
                      <FontAwesomeIcon icon={faSearch} /> &nbsp; Search{" "}
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <DataTable
              columns={newColumns}
              data={this.state.queryResults}
              selectableRows
            />

            {/* Policy Details */}
            <Modal
              size="lg"
              show={this.state.showModal}
              onHide={this.handleClose}
            >
              <Modal.Header
                closeButton
                style={{ backgroundColor: "#009900", color: "white" }}
              >
                <Modal.Title>Policy Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Tabs /> */}
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      href="#contact"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    Home
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    Profile
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    Contact
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <>
            <NewHeader />

            <br></br>
            <h3>Customer Enquiry </h3>
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  {/* Search Parameters{" "} */}
                </h6>
                <div>
                  <form method="POST" onSubmit={this.searchHandler}>
                    <input
                      className="col-75"
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Phone No"
                      style={{
                        border: this.state.searchParameter
                          ? "1px solid red"
                          : "",
                      }}
                    />{" "}
                    &nbsp;
                    <input
                      className="col-75"
                      type="text"
                      id="account"
                      name="account"
                      placeholder="Account No"
                      style={{
                        border: this.state.searchParameter
                          ? "1px solid red"
                          : "",
                      }}
                    />{" "}
                    &nbsp;
                    <input
                      className="col-75"
                      type="text"
                      id="policy"
                      name="policy"
                      placeholder="Proposal or Policy No"
                      style={{
                        border: this.state.searchParameter
                          ? "1px solid red"
                          : "",
                      }}
                    />{" "}
                    <div
                      style={{
                        //   textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.searchParameter}
                    </div>
                    <br />
                    {/* <br /> */}
                    &nbsp;
                    <button className="btn btn-info" type="submit">
                      <FontAwesomeIcon icon={faSearch} /> &nbsp; Search{" "}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {this.state.queryResults.length > 0 ? (
              <DataTable
                columns={newColumns}
                data={this.state.queryResults}
                defaultSortFieldId={1}
                pagination
                BuiltinStory
                theme="default"
                fixedHeader
              />
            ) : (
              <DataTable
                columns={newColumns}
                data={[]}
                defaultSortFieldId={1}
                pagination
                BuiltinStory
                theme="default"
                fixedHeader
              />
            )}

            {/* Policy Details */}
            <Modal
              size="xl"
              show={this.state.showModal}
              onHide={this.handleClose}
            >
              <Modal.Header
                closeButton
                style={{ backgroundColor: "#009900", color: "#fff" }}
              >
                <Modal.Title>Policy Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <Tabs /> */}
                <Tab.Container id="myTab" defaultActiveKey="premium">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="premium">PREMIUMS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="transaction">TRANSACTIONS</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="assured">ASSURED</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="beneficiary">BENEFICIARIES</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="premium">
                      <div role="tabpanel" aria-labelledby="premium-tab">
                        &nbsp;
                        <div class="row">
                          <div class="col">
                            Total Installments Paid:{" "}
                            <b>{this.state.policyDetails.total_count} </b>
                          </div>
                          <div class="col">
                            Total Premium Paid (Gh¢):{" "}
                            <b>
                              {this.state.policyDetails.total_sum &&
                                this.state.policyDetails.total_sum.toFixed(2)}
                            </b>
                          </div>
                          <div class="col">
                            Oustanding Balance (Gh¢):{" "}
                            <b>
                              {this.state.policyDetails.os_balance &&
                                this.state.policyDetails.os_balance.toFixed(2)}
                            </b>
                          </div>
                        </div>
                        <hr></hr>
                        {/* <div>
                          Total Installments Paid: {47} Total Premium Paid:{" "}
                          {"2,633.56"}
                        </div> */}
                        <div ref={this.componentRef}>
                          <DataTable
                            id="data-table"
                            columns={[
                              {
                                name: "RECEIPT DATE",
                                selector: (row) => row.RECEIPT_DATE,
                                // sortable: true,
                              },
                              {
                                name: "DEDUCTION MONTH",
                                selector: (row) =>
                                  moment(
                                    row.DEDUCTION_DATE,
                                    "DD-MM-YYYY"
                                  ).format("MMMM YYYY"),
                                // sortable: true,
                              },
                              {
                                name: "AMOUNT (Gh¢)",
                                selector: (row) => row.AMOUNT.toFixed(2),
                              },
                            ]}
                            data={this.state.policyDetails.premium}
                            // defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            ref={(el) => (this.componentRef = el)}
                          />
                        </div>
                        {/* <button onClick={this.downloadPDF}>Download PDF</button> */}
                        {this.state.policyDetails.total_sum && (
                          <PDFDownloadLink
                            document={
                              <PremiumDocument
                                policyDetails={this.state.policyDetails}
                                // issueDate="12-02-2015"
                                // policyDetails={this.state.policyDetails.}
                                // total={this.state.policyDetails.total_sum}
                              />
                            }
                            fileName="payment ledger.pdf"
                          >
                            {({ blob, url, loading, error }) => (
                              <button
                                disabled={loading}
                                className="btn btn-primary"
                                style={{ backgroundColor: "#009900" }}
                                title="Download Ledger"
                              >
                                {loading ? "Loading document..." : "Download"}{" "}
                                <FontAwesomeIcon icon={faDownload} />
                              </button>
                            )}
                          </PDFDownloadLink>
                        )}
                        {/* <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                              <div>
                                <div>
                                  <Button
                                    variant="primary"
                                    style={{
                                      backgroundColor: "#ffc52a",
                                      color: "#4a4a4a",
                                    }}
                                  >
                                    Print <FontAwesomeIcon icon={faPrint} />
                                  </Button>
                                </div>
                                &nbsp;
                              </div>
                            );
                          }}
                          content={() => this.componentRef.current}
                        /> */}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="transaction">
                      <div role="tabpanel" aria-labelledby="transaction-tab">
                        <DataTable
                          columns={[
                            {
                              name: "TRANSACTION DATE",
                              selector: (row) => row.TRANSACTION_DATE,
                              sortable: true,
                            },
                            {
                              name: "TRANSACTION AMOUNT",
                              selector: (row) => row.AMOUNT,
                            },
                            {
                              name: "TRANSACTION TYPE",
                              selector: (row) => row.TRANSACTION_TYPE,
                            },
                          ]}
                          data={this.state.policyDetails.transactions}
                          defaultSortFieldId={1}
                          pagination
                          BuiltinStory
                          theme="default"
                          fixedHeader
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="assured">
                      <div role="tabpanel" aria-labelledby="assured-tab">
                        <DataTable
                          columns={[
                            {
                              name: "ASSURED NAME",
                              selector: (row) => row.NAME,
                            },
                            {
                              name: "RELATIONSHIP",
                              selector: (row) => row.RELATION,
                            },
                          ]}
                          data={this.state.policyDetails.assured}
                          defaultSortFieldId={1}
                          pagination
                          BuiltinStory
                          theme="default"
                          fixedHeader
                        />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="beneficiary">
                      <div role="tabpanel" aria-labelledby="beneficiary-tab">
                        <DataTable
                          columns={[
                            {
                              name: "BENEFICIARY NAME",
                              selector: (row) => row.NAME,
                            },
                            {
                              name: "RELATIONSHIP",
                              selector: (row) => row.RELATION,
                            },
                          ]}
                          data={this.state.policyDetails.beneficiary}
                          defaultSortFieldId={1}
                          pagination
                          BuiltinStory
                          theme="default"
                          fixedHeader
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </React.Fragment>
    );
  }
}

// Create a custom footer component
const Footer = () => (
  <View style={styles.footer}>
    {/* <Text>{new Date().toLocaleString()}</Text> */}
    <Text>Printed on {moment().format("LLLL")}</Text>
  </View>
);

const Total = ({ total }) => (
  <View
    style={[
      styles.row,
      { borderBottomWidth: 2, position: "relative", fontSize: 15 },
    ]}
  >
    <View
      style={{
        borderBottomWidth: 2,
        position: "absolute",
        top: 0,
        width: "120%",
      }}
    />
    {/* <Text>{new Date().toLocaleString()}</Text> */}
    <Text style={styles.cell}>Total Premium</Text>
    <Text style={styles.cell}></Text>
    <Text style={styles.cell}>{total && total.toFixed(2)}</Text>
  </View>
);

const PremiumDocument = ({ policyDetails }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image src={gcb} style={styles.logo} />
        <View style={styles.header}>
          <Text style={styles.headerText}>Premium Payment Ledger</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            fontSize: 15,
            marginBottom: 10,
            // width: "100%",
            borderBottomWidth: 1,
          }}
        >
          <View style={styles.textContainer}>
            <Text>
              Assured Name:{" "}
              {policyDetails.pol[0] +
                (policyDetails.pol[1] ? " " + policyDetails.pol[1] : "") +
                " " +
                policyDetails.pol[2]}
            </Text>
          </View>
          <View style={styles.textContainer}>
            <Text>Policy Number: {policyDetails.pol[3]}</Text>
          </View>
          {/* <Text style={{ textDecorationLine: "underline" }}>
            Assured Name: BENJAMIN ADJEISAH BATSA
          </Text>
          <Text style={{ textDecorationLine: "underline" }}>
            &nbsp;Policy Number: WPP1007702210611648
          </Text> */}
        </View>
        {/* <View
          style={{
            // flexDirection: "row",
            // fontSize: 15,
            marginBottom: 10,
            // borderBottomColor: "#000",
            // borderBottomWidth: 1,
          }}
        >
          <Text style={{ textAlign: "left", fontWeight: "bold" }}>
            Assured Name:{" "}
            {policyDetails.FIRST_NAME +
              (policyDetails.MIDDLE_NAME
                ? " " + policyDetails.MIDDLE_NAME
                : "") +
              " " +
              policyDetails.SURNAME}
            &nbsp;
          </Text>

          <Text style={{ textAlign: "right", fontWeight: "bold" }}>
            Policy Number: {policyDetails.POLICY_NUMBER}
          </Text>
        </View>
        <Text></Text> */}
        <View style={styles.table}>
          {/* <View style={styles.row}>
            <Text style={styles.cellHeader}>Policy Number:</Text>
            <Text style={styles.cellHeader}>Issue Date: {issueDate}</Text>
          </View> */}
          <View style={styles.row}>
            <Text style={styles.cellHeader}>Receipt Date</Text>
            <Text style={styles.cellHeader}>Deduction Month</Text>
            <Text style={styles.cellHeader}>Amount (Gh¢)</Text>
          </View>
          {policyDetails.premium.map((premium, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.cell}>{premium.RECEIPT_DATE}</Text>
              <Text style={styles.cell}>
                {moment(premium.DEDUCTION_DATE, "DD-MM-YYYY").format(
                  "MMMM YYYY"
                )}
              </Text>
              <Text style={styles.cell}>{premium.AMOUNT.toFixed(2)}</Text>
            </View>
          ))}
        </View>
        <Total total={policyDetails.total_sum} />
        <Footer />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    // width: 50, // Adjust width as needed
    width: "100px",
    height: "40px",
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    marginBottom: 20,
    borderBottomWidth: 1,
  },
  headerText: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  table: {
    flexDirection: "column",
    fontSize: 15,
  },
  row: {
    flexDirection: "row",
    // borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 5,
  },
  cellHeader: {
    width: "40%",
    textAlign: "center",
    fontWeight: "bold",
    borderBottomWidth: 1,
  },
  cell: {
    width: "40%",
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
  textContainer: {
    // borderWidth: 1,
    // borderColor: "black",
    padding: 3,
    // margin: 2,
    // width: "60%",
  },
});

export default WelcomeEnquiry;
