import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import ahantaman from "../../ahantaman-logo3-1.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { getAge } from "../func/utils";
import axios from "axios";
import Menu from "./menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faTrash,
  faEdit,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

import ProductDetailsCLP from "./ProductDetailsCLP";
import ProductDetailsWPP from "./ProductDetailsWPP";
import ProductDetailsUPP from "./ProductDetailsUPP";
import ProductDetailsEHP from "./ProductDetailsEHP";
import Quotation from "./Quotation";

import FormDependentModal from "./FormDependentModal";
import EditDependentModal from "./EditDependentModal";
import BenButton from "../ui/Button";

export class ProductDetails extends PureComponent {
  state = {
    productError: "",
    termError: "",
    tpdError: "",
    retrechmentError: "",
    dreadDiseaseError: "",
    paError: "",
    productChangeCLP: false,
    productChangeWPP: false,
    productChangeUPP: false,
    productChangeEHP: false,
    premiumError: "",
    escalationError: "",
    paSurnameError: "",
    paOthernameError: "",
    paDOB: "",
    paGender: "",
    escalationRateError: "",
    loading: false,
    prodDesc: "",
    showQuotation: false,
    paymentFrequencyError: "",
    quoteError: "",
    mChecked: "",
    aChecked: "",
  };

  componentDidMount() {
    if (this.props.values.product === "GPC") {
      this.setState({ productChangeCLP: true });
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: false });
      this.setState({
        prodDesc:
          "A savings plan designed to meet your ward(s) education financing needs now and in the future. It offers a maturity benefit that is equivalent to the policy sum assured or the death benefit and policy loans on request.",
      });
      this.props.valueChange(["dependentRequired", false]);
    } else if (this.props.values.product === "GPW") {
      this.setState({ productChangeWPP: true });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: false });
      this.props.valueChange(["dependentList", []]);
      this.props.valueChange(["dependentRequired", false]);
      this.setState({
        prodDesc:
          "Financial planning plays an integral part in alleviating life contingencies that hits us and our loved ones. As part of ensuring satisfaction in all aspects of our lives. The successful execution of this plan requires a well-structured financial package.",
      });
    } else if (this.props.values.product === "GPU") {
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: true });
      this.setState({ productChangeEHP: false });
      this.props.valueChange(["dependentRequired", false]);
      this.props.valueChange(["dependentList", []]);
      this.setState({
        prodDesc:
          "The dreams and aspiration of every family ceases upon the demise of the breadwinner. It is during this trying time that the essence of the role of the breadwinner is truly appreciated.",
      });
    } else if (this.props.values.product === "GPH") {
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: true });
      this.props.valueChange(["dependentRequired", true]);
      this.setState({
        prodDesc:
          "Do not let the death of your loved ones affect you emotionally and financially at the same time. The emotional stress may not be avoided, but the financial stress can be avoided when you adequately plan for it by singing on the HomeCall Plus.",
      });
    }
  }

  handleProductChange = (e) => {
    const product = e.target.value;
    const productCode = e.target.value.split("-")[0];

    this.props.valueChange(["product", product]);
    this.props.valueChange(["productCode", productCode]);

    if (product.split("-")[1] === "GPC") {
      this.setState({ productChangeCLP: true });
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: false });
      this.setState({
        prodDesc:
          "A savings plan designed to meet your ward(s) education financing needs now and in the future. It offers a maturity benefit that is equivalent to the policy sum assured or the death benefit and policy loans on request.",
      });
      // this.setState({
      //   termError: null,
      // });
      this.props.valueChange(["dependentRequired", false]);
    } else if (product.split("-")[1] === "GPW") {
      this.setState({ productChangeWPP: true });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: false });
      this.props.valueChange(["dependentList", []]);
      this.props.valueChange(["dependentRequired", false]);
      this.setState({
        prodDesc:
          "Financial planning plays an integral part in alleviating life contingencies that hits us and our loved ones. As part of ensuring satisfaction in all aspects of our lives. The successful execution of this plan requires a well-structured financial package.",
      });
    } else if (product.split("-")[1] === "GPU") {
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: true });
      this.setState({ productChangeEHP: false });
      this.props.valueChange(["dependentRequired", false]);
      this.props.valueChange(["dependentList", []]);
      this.setState({
        prodDesc:
          "The dreams and aspiration of every family ceases upon the demise of the breadwinner. It is during this trying time that the essence of the role of the breadwinner is truly appreciated.",
      });
    } else if (product.split("-")[1] === "GPH") {
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: true });
      this.props.valueChange(["dependentRequired", true]);
      this.setState({
        prodDesc:
          "Do not let the death of your loved ones affect you emotionally and financially at the same time. The emotional stress may not be avoided, but the financial stress can be avoided when you adequately plan for it by singing on the HomeCall Plus.",
      });
    }
  };

  quotation = (e) => {
    e.preventDefault();
    let productError = "";
    let termError = "";
    let tpdError = "";
    let retrechmentError = "";
    let dreadDiseaseError = "";
    let paError = "";
    let premiumError = "";
    let escalationError = "";
    let paSurnameError = "";
    let paOthernameError = "";
    let paDOB = "";
    let paGender = "";
    let escalationRateError = "";
    let paymentFrequencyError = "";

    this.setState({
      productError,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
      paymentFrequencyError,
    });

    if (!e.target.form[0].value) {
      // Checks if field is empty
      productError = "Please select product";
    }

    if (e.target.form[0].value.split("-")[1] === "GPC") {
      if (e.target.form[1].value < 10 || e.target.form[1].value > 25) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        tpdError = "Please select option";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        retrechmentError = "Please select option";
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        dreadDiseaseError = "Please select option";
      }

      if (!e.target.form[5].value) {
        // Checks if field is empty
        paError = "Please select option";
      }

      if (e.target.form[5].value === "Yes") {
        if (!e.target.form[6].value) {
          // Checks if field is empty
          paSurnameError = "Please enter surname for child";
        }

        if (!e.target.form[7].value) {
          // Checks if field is empty
          paOthernameError = "Please enter othernames for child";
        }

        if (!e.target.form[8].value) {
          // Checks if field is empty
          paDOB = "Please enter date of birth for child";
        }

        if (getAge(e.target.form[8].value) >= 18) {
          paDOB = "Child should be below 18 years";
        }

        if (!e.target.form[9].value) {
          // Checks if field is empty
          paGender = "Please select gender of child";
        }

        if (!e.target.form[10].value || e.target.form[10].value < 50) {
          // Checks if field is empty
          premiumError = "Please enter a valid basic premium";
        }

        if (!e.target.form[11].checked & !e.target.form[12].checked) {
          // Checks if field is empty
          paymentFrequencyError = "Please indicated your payment frequency";
        }

        if (!e.target.form[13].value) {
          // Checks if field is empty
          escalationError = "Please select option";
        }

        if (e.target.form[13].value === "Yes") {
          if (!e.target.form[12].value) {
            // Checks if field is empty
            escalationRateError = "Please select escalation rate";
          }
        }
      } else {
        if (!e.target.form[6].value || e.target.form[6].value < 50) {
          // Checks if field is empty
          premiumError = "Please enter a valid basic premium";
        }

        if (!e.target.form[7].checked & !e.target.form[8].checked) {
          // Checks if field is empty
          paymentFrequencyError = "Please indicated your payment frequency";
        }

        if (!e.target.form[9].value) {
          // Checks if field is empty
          escalationError = "Please select option";
        }

        if (e.target.form[9].value === "Yes") {
          if (!e.target.form[8].value) {
            // Checks if field is empty
            escalationRateError = "Please select escalation rate";
          }
        }
      }
    } else if (e.target.form[0].value.split("-")[1] === "GPW") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value || e.target.form[2].value < 50) {
        // Checks if field is empty
        premiumError = "Please enter a valid basic premium";
      }

      if (!e.target.form[3].checked & !e.target.form[4].checked) {
        // Checks if field is empty
        paymentFrequencyError = "Please indicated your payment frequency";
      }

      if (!e.target.form[5].value) {
        // Checks if field is empty
        escalationError = "Please select option";
      }

      if (e.target.form[5].value === "Yes") {
        if (!e.target.form[6].value) {
          // Checks if field is empty
          escalationRateError = "Please select escalation rate";
        }
      }
    } else if (e.target.form[0].value.split("-")[1] === "GPU") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        dreadDiseaseError = "Please select option";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        premiumError = "Please select basic sum assured";
      }

      if (!e.target.form[4].checked & !e.target.form[5].checked) {
        // Checks if field is empty
        paymentFrequencyError = "Please indicated your payment frequency";
      }

      if (!e.target.form[6].value) {
        // Checks if field is empty
        escalationError = "Please select option";
      }

      if (e.target.form[6].value === "Yes") {
        if (!e.target.form[5].value) {
          // Checks if field is empty
          escalationRateError = "Please select escalation rate";
        }
      }
    } else if (e.target.form[0].value.split("-")[1] === "GPH") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        premiumError = "Please select basic sum assured";
      }

      // if (!e.target.form[2].checked & !e.target.form[3].checked) {
      //   // Checks if field is empty
      //   paymentFrequencyError = "Please indicated your payment frequency";
      // }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        escalationError = "Please select option";
      }

      if (e.target.form[2].value === "Yes") {
        if (!e.target.form[3].value) {
          // Checks if field is empty
          escalationRateError = "Please select escalation rate";
        }
      }
    }

    // if (!e.target.form[1].value) {
    //   // Checks if field is empty
    //   termError = "Please enter policy term";
    // }

    // if (e.target.form[1].value) {
    //   if (e.target.form[1].value < 8) {
    //     // Checks if field is empty
    //     termError = "Please enter a valid policy term";
    //   }

    //   if (!e.target.form[2].value) {
    //     // Checks if field is empty
    //     tpdError = "Please select option";
    //   }
    // }

    // if (!e.target.form[2].value) {
    //   // Checks if field is empty
    //   tpdError = "Please select option";
    // }

    if (
      productError ||
      termError ||
      tpdError ||
      retrechmentError ||
      dreadDiseaseError ||
      paError ||
      premiumError ||
      escalationError ||
      paSurnameError ||
      paOthernameError ||
      paDOB ||
      paGender ||
      escalationRateError ||
      paymentFrequencyError
    ) {
      this.setState({
        productError,
        termError,
        tpdError,
        retrechmentError,
        dreadDiseaseError,
        paError,
        premiumError,
        escalationError,
        paSurnameError,
        paOthernameError,
        paDOB,
        paGender,
        escalationRateError,
        paymentFrequencyError,
      });
      return false;
    }

    const existingProduct = {
      Customer_Mobile: this.props.values.phoneNumber,
      Product_Code: e.target.form[0].value.split("-")[1],
      Market: this.props.values.bank.split("-")[1],
    };

    this.setState({
      loading: true,
    });

    if ("product" in localStorage) {
      if (
        JSON.parse(localStorage.getItem("product")) !== null &&
        JSON.parse(localStorage.getItem("product")) !== undefined
      ) {
        if (JSON.parse(localStorage.getItem("product")) === false) {
          axios
            .post("/api/customer/quotation", this.props.values)
            .then((response) => {
              if (response.status === 200) {
                if (Object.keys(response.data).length === 2) {
                  const clntCode = response.data.client.clntCode;
                  const newFirstName = response.data.client.webClntFirstName;
                  const otherNames = response.data.client.webClntMiddleName;
                  const newLastName = response.data.client.webClntLastName;
                  const dob = response.data.client.webClntDob;
                  const gender = response.data.client.webClntGender;
                  const maritalStatus =
                    response.data.client.webClntMaritalStatus;
                  const idType = response.data.client.webClntIdRegDoc;
                  const idNumber = response.data.client.webClntIdRegNo;
                  const occupation = response.data.client.clntOccupation;
                  const postalAddress =
                    response.data.client.webClntPostalAddress;
                  const residentialAddress =
                    response.data.client.webClntPhysicalAddress;
                  const nationality = response.data.client.webClntNationality;
                  const religion = response.data.client.webClntReligion;
                  const pnumber = response.data.client.webClntMobileNo;

                  // this.props.valueChange({ newFirstName, newLastName });
                  this.props.valueChange(["clntCode", clntCode]);
                  this.props.valueChange(["firstName", newFirstName]);
                  this.props.valueChange(["otherNames", otherNames]);
                  this.props.valueChange(["lastName", newLastName]);
                  this.props.valueChange(["dob", dob]);
                  this.props.valueChange(["gender", gender]);
                  this.props.valueChange(["maritalStatus", maritalStatus]);
                  this.props.valueChange(["idType", idType]);
                  this.props.valueChange(["idNumber", idNumber]);
                  this.props.valueChange(["phoneNumber", pnumber]);
                  this.props.valueChange(["occupation", +occupation]);
                  this.props.valueChange(["postalAddress", postalAddress]);
                  this.props.valueChange([
                    "residentialAddress",
                    residentialAddress,
                  ]);
                  this.props.valueChange(["nationality", nationality]);
                  this.props.valueChange(["religion", religion]);

                  this.props.valueChange([
                    "premium",
                    response.data.quote.premium,
                  ]);
                  this.props.valueChange([
                    "sa",
                    response.data.quote.sumInsured,
                  ]);
                  this.props.valueChange([
                    "quoteNo",
                    response.data.quote.quoCode,
                  ]);
                  // this.props.valueChange([
                  //   "coverDetails",
                  //   response.data.coverTypeAllocations,
                  // ]);
                  localStorage.setItem(
                    "quotation",
                    JSON.stringify(response.data.quote)
                  );
                  this.setState({
                    loading: false,
                    // coverDetails: response.data.coverTypeAllocations,
                    showQuotation: true,
                  });
                } else {
                  this.props.valueChange(["premium", response.data.premium]);
                  this.props.valueChange(["sa", response.data.sumInsured]);
                  this.props.valueChange(["quoteNo", response.data.quoCode]);
                  // this.props.valueChange([
                  //   "coverDetails",
                  //   response.data.coverTypeAllocations,
                  // ]);

                  localStorage.setItem(
                    "quotation",
                    JSON.stringify(response.data)
                  );
                  this.setState({
                    loading: false,
                    // coverDetails: response.data.coverTypeAllocations,
                    showQuotation: true,
                  });
                }
              } else {
                this.setState({ loading: false, showQuotation: false });
                alert("An Error Occured");
              }
            })
            .catch((error) => {
              this.setState({ loading: false, showQuotation: false });
              alert("An error occured.");
            });
        }
      }
    } else {
      //Verify if client already has product
      axios
        .post("/api/customer/product/verify", existingProduct)
        .then((response) => {
          if (response.data.message_code === 100) {
            this.setState({ loading: false });
            localStorage.setItem("product", true);
            productError = `${response.data.message} ${
              e.target.form[0].value.split("-")[2]
            }`;
            if (productError) {
              this.setState({
                productError,
              });
              return false;
            }
          } else if (response.data.message_code === 200) {
            this.setState({ loading: false });
            localStorage.setItem("product", true);
            productError = `${response.data.message} ${
              e.target.form[0].value.split("-")[2]
            } this year`;
            if (productError) {
              this.setState({
                productError,
              });
              return false;
            }
          } else {
            localStorage.setItem("product", false);
            axios
              .post("/api/customer/quotation", this.props.values)
              .then((response) => {
                if (response.status === 200) {
                  if (Object.keys(response.data).length === 2) {
                    const clntCode = response.data.client.clntCode;
                    const newFirstName = response.data.client.webClntFirstName;
                    const otherNames = response.data.client.webClntMiddleName;
                    const newLastName = response.data.client.webClntLastName;
                    const dob = response.data.client.webClntDob;
                    const gender = response.data.client.webClntGender;
                    const maritalStatus =
                      response.data.client.webClntMaritalStatus;
                    const idType = response.data.client.webClntIdRegDoc;
                    const idNumber = response.data.client.webClntIdRegNo;
                    const occupation = response.data.client.clntOccupation;
                    const postalAddress =
                      response.data.client.webClntPostalAddress;
                    const residentialAddress =
                      response.data.client.webClntPhysicalAddress;
                    const nationality = response.data.client.webClntNationality;
                    const religion = response.data.client.webClntReligion;
                    const pnumber = response.data.client.webClntMobileNo;

                    // this.props.valueChange({ newFirstName, newLastName });
                    this.props.valueChange(["clntCode", clntCode]);
                    this.props.valueChange(["firstName", newFirstName]);
                    this.props.valueChange(["otherNames", otherNames]);
                    this.props.valueChange(["lastName", newLastName]);
                    this.props.valueChange(["dob", dob]);
                    this.props.valueChange(["gender", gender]);
                    this.props.valueChange(["maritalStatus", maritalStatus]);
                    this.props.valueChange(["idType", idType]);
                    this.props.valueChange(["idNumber", idNumber]);
                    this.props.valueChange(["phoneNumber", pnumber]);
                    this.props.valueChange(["occupation", +occupation]);
                    this.props.valueChange(["postalAddress", postalAddress]);
                    this.props.valueChange([
                      "residentialAddress",
                      residentialAddress,
                    ]);
                    this.props.valueChange(["nationality", nationality]);
                    this.props.valueChange(["religion", religion]);

                    this.props.valueChange([
                      "premium",
                      response.data.quote.premium,
                    ]);
                    this.props.valueChange([
                      "sa",
                      response.data.quote.sumInsured,
                    ]);
                    this.props.valueChange([
                      "quoteNo",
                      response.data.quote.quoCode,
                    ]);
                    localStorage.setItem(
                      "quotation",
                      JSON.stringify(response.data.quote)
                    );
                    // this.props.valueChange([
                    //   "coverDetails",
                    //   response.data.coverTypeAllocations,
                    // ]);
                    this.setState({
                      loading: false,
                      // coverDetails: response.data.coverTypeAllocations,
                      showQuotation: true,
                    });
                  } else {
                    this.props.valueChange(["premium", response.data.premium]);
                    this.props.valueChange(["sa", response.data.sumInsured]);
                    this.props.valueChange(["quoteNo", response.data.quoCode]);
                    // this.props.valueChange([
                    //   "coverDetails",
                    //   response.data.coverTypeAllocations,
                    // ]);

                    localStorage.setItem(
                      "quotation",
                      JSON.stringify(response.data)
                    );
                    this.setState({
                      loading: false,
                      // coverDetails: response.data.coverTypeAllocations,
                      showQuotation: true,
                    });
                  }
                } else {
                  this.setState({ loading: false, showQuotation: false });
                  alert("An Error Occured");
                }
              })
              .catch((error) => {
                this.setState({ loading: false, showQuotation: false });
                alert("An Error Occured");
              });
            // this.props.nextStep();
            // return true;
          }
        })
        .catch((error) => {
          this.setState({ loading: false, showQuotation: false });
          alert("An Error Occured");
        });
      // this.props.nextStep();
      // return true;
    }
  };

  hideModal = () => {
    this.setState({ showQuotation: false });
  };

  continue = (e) => {
    e.preventDefault();

    let quoteError = "";

    this.setState({
      quoteError,
    });

    if (
      this.props.values.premium !== null &&
      this.props.values.sa !== undefined &&
      this.props.values.premium > 0 &&
      this.props.values.sa > 0
    ) {
      this.setState({ showQuotation: false });
      this.props.nextStep();
      return true;
    } else {
      quoteError = "Quotation Error. Kindly recalculate";
    }

    if (quoteError) {
      this.setState({
        quoteError,
      });
      return false;
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep(3);
  };

  render() {
    const {
      values,
      handleChange,
      valueChange,
      showDepModalHandler,
      showEditDepModalHandler,
      hideDepModalHandler,
      hideEditDepModalHandler,
      addDependent,
      updateDependent,
      removeDependent,
    } = this.props;

    const products = [
      {
        id: 118,
        label: "GPCC CHILD EDUCATION",
        value: "118-GPC-GPCC CHILD EDUCATION",
      },
      {
        id: 119,
        label: "GPCC WEALTH MASTER",
        value: "119-GPW-GPCC WEALTH MASTER",
      },
      {
        id: 120,
        label: "GPCC ULTIMATE PROTECTION",
        value: "120-GPU-GPCC ULTIMATE PROTECTION",
      },
      {
        id: 2022542,
        label: "GPCC HOMECALL",
        value: "2022542-GPH-GPCC HOMECALL",
      },
    ];

    let qNo = null;

    if ("quotation" in localStorage) {
      if (
        JSON.parse(localStorage.getItem("quotation")).quoCode !== null &&
        JSON.parse(localStorage.getItem("quotation")).quoCode !== undefined &&
        JSON.parse(localStorage.getItem("quotation")).clntCode ===
          values.clntCode
      ) {
        qNo = JSON.parse(localStorage.getItem("quotation")).quoCode;
        this.props.valueChange(["quoteNo", qNo]);
      } else {
        this.props.valueChange(["quoteNo", qNo]);
      }
    }

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-ahantaman"}></div>
            <Menu />
            <hr />
            <br></br>
            <h3>
              Which of our products are you interested in,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              ?
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <Form.Group>
                    <div style={{ border: "3px solid #fff" }}>
                      <div
                        style={{
                          width: "90%",
                          float: "left",
                          // padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FloatingLabel
                            controlId="products"
                            label="Select Product"
                            className="mb-2"
                          >
                            <Form.Control
                              as="select"
                              onChange={this.handleProductChange}
                              defaultValue={values.product}
                              style={{
                                border: this.state.productError
                                  ? "1px solid red"
                                  : "",
                              }}
                            >
                              <option></option>
                              {products.map((product) => (
                                <option key={product.id} value={product.value}>
                                  {product.label}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                              }}
                            >
                              {this.state.productError}
                            </div>
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>

                    {this.state.productChangeCLP && (
                      <ProductDetailsCLP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                      />
                    )}
                    {this.state.productChangeWPP && (
                      <ProductDetailsWPP
                        termError={this.state.termError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                    {this.state.productChangeUPP && (
                      <ProductDetailsUPP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                  </Form.Group>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#0f366",
                    outlineColor: "#A80404",
                    borderColor: "#A80404",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Get Quote
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <Menu />
            <hr />
            <br></br>
            <h3>
              Which of our products are you interested in,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              ?
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <Form.Group>
                    <div style={{ border: "3px solid #fff" }}>
                      <div
                        style={{
                          width: "90%",
                          float: "left",
                          // padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FloatingLabel
                            controlId="products"
                            label="Select Product"
                            className="mb-2"
                          >
                            <Form.Control
                              as="select"
                              onChange={this.handleProductChange}
                              defaultValue={values.product}
                              style={{
                                border: this.state.productError
                                  ? "1px solid red"
                                  : "",
                              }}
                            >
                              <option></option>
                              {products.map((product) => (
                                <option key={product.id} value={product.value}>
                                  {product.label}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                              }}
                            >
                              {this.state.productError}
                            </div>
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>

                    {this.state.productChangeCLP && (
                      <ProductDetailsCLP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                        mChecked={this.state.mChecked}
                        aChecked={this.state.aChecked}
                      />
                    )}
                    {this.state.productChangeWPP && (
                      <ProductDetailsWPP
                        termError={this.state.termError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        escalationRateError={this.state.escalationRateError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                        mChecked={this.state.mChecked}
                        aChecked={this.state.aChecked}
                      />
                    )}
                    {this.state.productChangeUPP && (
                      <ProductDetailsUPP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                        mChecked={this.state.mChecked}
                        aChecked={this.state.aChecked}
                      />
                    )}
                    {this.state.productChangeEHP && (
                      <ProductDetailsEHP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                        mChecked={this.state.mChecked}
                        aChecked={this.state.aChecked}
                      />
                    )}
                  </Form.Group>
                  &nbsp;
                  {/* Dependent List */}
                  {values.dependentRequired && (
                    <div>
                      <div style={{ float: "right" }}>
                        <BenButton type="button" onClick={showDepModalHandler}>
                          Add Dependent
                        </BenButton>
                      </div>
                      <br></br>
                      <br></br>
                      {values.showDepModal && (
                        <FormDependentModal
                          title="Dependent Details"
                          message="Message"
                          onConfirm=""
                          backdrop={hideDepModalHandler}
                          addDependent={addDependent}
                          valueChange={valueChange}
                          updateQuote={this.state.updateQuote}
                        />
                      )}
                      {values.showEditDepModal && (
                        <EditDependentModal
                          title="Edit Dependent Details"
                          message="Message"
                          onConfirm=""
                          backdrop={hideEditDepModalHandler}
                          // addBen={addBen}
                          updateDependent={updateDependent}
                          othernames={this.state.newMember.othernames}
                          surname={this.state.newMember.surname}
                          dob={this.state.newMember.dob}
                          relationship={this.state.newMember.relationship}
                          gender={this.state.newMember.gender}
                          id={this.state.newMember.id}
                          valueChange={valueChange}
                          updateQuote={this.state.updateQuote}
                        />
                      )}
                      <h4>
                        <FontAwesomeIcon
                          icon={faUserFriends}
                          style={{ color: "" }}
                        />{" "}
                        &nbsp; <u>Dependent Details</u>
                      </h4>
                      {/* <Card> */}
                      <table border="1" width="100%">
                        <thead border="1">
                          <tr>
                            <th>Name</th>
                            {/* <th>Othernames</th> */}
                            <th>DOB</th>
                            <th>Gender</th>
                            <th>Dependent</th>
                            {/* <th>Premium</th> */}
                            <th colSpan="2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.dependentList.map((member) => (
                            <tr key={member.id}>
                              <td>
                                {member.surname} {member.othernames}
                              </td>
                              {/* <td>{member.othernames}</td> */}
                              <td>{member.dob}</td>
                              <td>{member.gender}</td>
                              <td>{member.relationship.split("-")[1]}</td>
                              {/* <td>Premium</td> */}
                              {/* <td>
                                <a
                                  href="#"
                                  onClick={() => {
                                    this.setState({ newMember: member });
                                    showEditDepModalHandler();
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </a>
                              </td> */}
                              <td>
                                {/* <span
                      style={{ color: "red" }}
                      onClick={() => removeBen(ben.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span> */}
                                <a
                                  href="#"
                                  style={{ color: "red" }}
                                  onClick={() => removeDependent(member.id)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* <input type="button" value="Update Quote" align="right" /> */}

                        <tfoot>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tfoot>
                        {/* <thead>
              <tr>
                <th>Surname</th>
                <th>Othernames</th>
                <th>Date of Birth</th>
                <th>Relationship</th>
                <th>Contact Number</th>
                <th>Percentage</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead> */}
                      </table>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.familyError}
                      </div>
                      <br></br>
                    </div>
                  )}
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#0f366",
                    outlineColor: "#A80404",
                    borderColor: "#A80404",
                    color: "#fff",
                  }}
                  onClick={this.quotation}
                >
                  Get Quote
                </Button>
              </Form>
              {this.state.showQuotation && (
                <Quotation
                  title="Quotation Details"
                  message="Message"
                  backdrop={this.hideModal}
                  continue={this.continue}
                  // addBen={addBen}
                  // trusteeRequred={values.trusteeRequred}
                  // trusteeRequredHandler={trusteeRequredHandler}
                  // valueChange={valueChange}
                  values={values}
                  // coverDetails={
                  //   this.state.coverDetails &&
                  //   this.state.coverDetails.filter(
                  //     (cover) => cover.cvtPremium !== 0
                  //   )
                  // }
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProductDetails;
