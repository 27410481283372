const BankBranchesData = [
  {
    bbrCode: 10124,
    bbrBnkCode: 1020,
    bbrBranchName: "BOGOSO",
    bbrShtDesc: "073101",
  },
  {
    bbrCode: 10125,
    bbrBnkCode: 1020,
    bbrBranchName: "PRESTEA",
    bbrShtDesc: "073111",
  },
  {
    bbrCode: 10126,
    bbrBnkCode: 1020,
    bbrBranchName: "ATIEKU",
    bbrShtDesc: "073121",
  },
  {
    bbrCode: 10127,
    bbrBnkCode: 1020,
    bbrBranchName: "ABOSO",
    bbrShtDesc: "073131",
  },
  {
    bbrCode: 10128,
    bbrBnkCode: 1020,
    bbrBranchName: "TARKWA",
    bbrShtDesc: "073141",
  },
  {
    bbrCode: 10129,
    bbrBnkCode: 1020,
    bbrBranchName: "HUNI VALLEY",
    bbrShtDesc: "073151",
  },
  {
    bbrCode: 10130,
    bbrBnkCode: 1020,
    bbrBranchName: "ASANKRAGWA",
    bbrShtDesc: "073171",
  },
  {
    bbrCode: 10131,
    bbrBnkCode: 1020,
    bbrBranchName: "TAMSO",
    bbrShtDesc: "073181",
  },
  {
    bbrCode: 10132,
    bbrBnkCode: 1020,
    bbrBranchName: "SIKAFIE",
    bbrShtDesc: "073191",
  },
  {
    bbrCode: 10133,
    bbrBnkCode: 1020,
    bbrBranchName: "BAWDIE",
    bbrShtDesc: "073201",
  },
  {
    bbrCode: 10134,
    bbrBnkCode: 1020,
    bbrBranchName: "KOKOMPE",
    bbrShtDesc: "073221",
  },
  {
    bbrCode: 10135,
    bbrBnkCode: 1020,
    bbrBranchName: "CAPE COAST",
    bbrShtDesc: "073231",
  },
  {
    bbrCode: 10137,
    bbrBnkCode: 1020,
    bbrBranchName: "DAAMANG",
    bbrShtDesc: "073211",
  },
];

export default BankBranchesData;
