import React from "react";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import logo from "../../static/img/starlife-logo.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPasswordChange(props) {
  const [passwordError, setPasswordError] = React.useState(null);
  const [confirmPasswordError, setconfirmPasswordError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setPasswordError(null);
    setconfirmPasswordError(null);

    let pError = "";
    let cError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      pError = "Please enter your new password";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      cError = "Please confirm your new password";
    }

    if (pError || cError) {
      setPasswordError(pError);
      setconfirmPasswordError(cError);
      return false;
    }

    const passwordChange = {
      Password: e.target.form[0].value,
      Confirm_Password: e.target.form[1].value,
    };

    const token = location.pathname.split("/")[4];

    setLoading(true);

    //Verify client phone
    axios
      .post("/api/merchant/reset-password/" + token, passwordChange)
      .then((response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          toast.success(
            "Password successfully changed. Kindly return to the Login page and Login",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          setLoading(false);
          toast.error(response.data, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.data, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <br></br>
          <img
            src={logo}
            alt="logo"
            //   style={{ width: "150px", height: "150px", padding: 10 }}
          />
          <br></br>
          <br></br>

          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp;{" "}
                <u>Personal Details of the Account Holder</u>
              </h4> */}
            <Form
              onSubmit={handleSubmit}
              style={{
                color: "purple",
              }}
            >
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="newpassword"
                  label="New Password"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="password"
                    autoComplete="off"
                    placeholder="Enter Your New Password"
                    style={{
                      border: passwordError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {passwordError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="confirmpassword"
                  label="Confirm Password"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="password"
                    autoComplete="off"
                    placeholder="Confirm New Password"
                    style={{
                      border: confirmPasswordError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {confirmPasswordError}
                  </div>
                </FloatingLabel>
              </Form.Group>

              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Reset Password
              </Button>
              <br></br>
              <br></br>
              <a href="/bancassurance/portal/login">Back to Login Page</a>
            </Form>
          </div>
        </>
      ) : (
        <>
          <br></br>
          <img
            src={logo}
            alt="logo"
            //   style={{ width: "150px", height: "150px", padding: 10 }}
          />
          <br></br>
          <br></br>

          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp; <u>Login</u>
              </h4> */}
            <Form
              onSubmit={handleSubmit}
              style={{
                color: "purple",
              }}
            >
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="newpassword"
                  label="New Password"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="password"
                    autoComplete="off"
                    placeholder="Enter Your New Password"
                    style={{
                      border: passwordError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {passwordError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="confirmpassword"
                  label="Confirm Password"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="password"
                    autoComplete="off"
                    placeholder="Confirm New Password"
                    style={{
                      border: confirmPasswordError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {confirmPasswordError}
                  </div>
                </FloatingLabel>
              </Form.Group>

              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Reset Password
              </Button>
              <br></br>
              <br></br>
              <a href="/bancassurance/portal/login">Back to Login Page</a>
            </Form>
          </div>
          <ToastContainer autoClose={20000} />
        </>
      )}
    </div>
  );
}

export default ForgotPasswordChange;
