import React, { Component } from "react";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import ahantaman from "../../ahantaman-logo3-1.png";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Menu from "./menu";

export class Welcome extends Component {
  continue = (e) => {
    e.preventDefault();
    if (localStorage.getItem("dso") !== null) {
      this.props.valueChange([
        "agentCode",
        JSON.parse(localStorage.getItem("dso"))["agnShtDesc"],
      ]);
      this.props.valueChange([
        "agnCode",
        JSON.parse(localStorage.getItem("dso"))["agnCode"],
      ]);
      this.props.valueChange([
        "agentName",
        JSON.parse(localStorage.getItem("dso"))["agnName"],
      ]);
    }
    this.props.nextStep();
  };
  render() {
    return (
      <React.Fragment>
        <Menu />
        <hr />
        <br></br>
        <h3>
          Welcome to StarLife GPCC Policy
          {/* <FontAwesomeIcon icon={faHandshake} /> Welcome to GPCC Policy
          Onboarding <FontAwesomeIcon icon={faHandshake} /> */}
        </h3>
        <br />
        <Button
          variant="primary"
          style={{
            backgroundColor: "#0f366",
            outlineColor: "#A80404",
            borderColor: "#A80404",
            color: "#fff",
          }}
          onClick={this.continue}
        >
          Start Application
        </Button>
      </React.Fragment>
    );
  }
}

export default Welcome;
