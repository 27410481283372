import React, { useState, useEffect, useContext } from "react";
import logo from "../StarLife_Chatbot-06.png";
import "bootstrap/dist/css/bootstrap.css";
import "./Welcome.styles.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faKey, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BankPartners from "./data/partners";
import { useNavigate } from "react-router-dom";
// import digitallogo from "../apple-icon-72x72.png";
import digitallogo from "../starlife-logo.png";
import CryptoJS from "crypto-js";
import AuthContext from "../store/auth-context";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function AgentWelcomeLogin(props) {
  const bankPartners = BankPartners;
  const [agentError, setAgentError] = useState("");
  const [agentPwdError, setAgentPwdError] = useState("");
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [agentReferralError, setAgentReferralError] = useState("");
  const [bankPartnerError, setBankPartnerError] = useState("");
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  const getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  //break the encrypted data into smaller chunks and store them in localStorage
  const saveEncryptedData = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
    const totalChunks = Math.ceil(encryptedData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, encryptedData.length);
      const chunk = encryptedData.substring(start, end);

      // Store each chunk in localStorage with a unique key
      localStorage.setItem(`${key}_${i}`, chunk);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  function submitHandler(e) {
    e.preventDefault();
    let agentErrorMsg = "";
    let agentPwdMsg = "";
    let bankPartnerErrorMsg = "";

    if (!e.target.form[0].value) {
      agentErrorMsg = "AGT Code is required";
    }

    if (!e.target.form[1].value) {
      agentPwdMsg = "Password is required";
    }

    if (e.target.form[0].value) {
      if (e.target.form[0].value.length < 7) {
        agentErrorMsg = "Kindly provide a valid AGT Code.";
      }
    }

    // if (!e.target.form[1].value) {
    //   bankPartnerErrorMsg = "Select your banc assurance partner";
    // }

    if (agentErrorMsg || agentPwdMsg) {
      setAgentError(agentErrorMsg);
      setAgentPwdError(agentPwdMsg);
      // setBankPartnerError(bankPartnerErrorMsg);
      return false;
    }

    //Verify agent
    const agent = {
      UserName: e.target.form[0].value,
      Password: e.target.form[1].value,
    };

    setLoading(true);

    axios
      .post("/api/dso/new/login", agent)
      .then(async (response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          const encryptedDSO = await saveEncryptedData(
            "currDSO",
            response.data
          );

          const expirationTime = new Date(new Date().getTime() + 3600 * 24000);
          authCtx.dsoLogin(
            await getDecryptedData("currDSO")["token"],
            await getDecryptedData("currDSO")["agnCode"],
            expirationTime.toISOString()
          );
          if (
            (await getDecryptedData("currDSO")["partner"]) ===
            "GCB BANCASSURANCE"
          ) {
            // this.history.replace("/bancassurance/gcb");
            navigate("/bancassurance/gcb", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] === "ACCESS BANCASSURANCE"
          ) {
            navigate("/bancassurance/access", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "AMENFIMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/amenfiman", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "AHANTAMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/ahantaman", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ATWIMA KWANWOMA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/atwimakwanwoma", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "OKOMFO ANOKYE RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/okomfoanokye", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] === "GA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/garural", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ABOKOBI RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/abokobi", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "YAA ASANTEWAA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/yaaasantewaa", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "AMANSIE WEST RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/amansie", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ATWIMA MPONUA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/atwimamponua", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "FIASEMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/fiaseman", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ODOTOBRI RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/odotobri", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "SOUTH AKIM RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/southakim", {
              replace: true,
            });
          } else if (
            (await getDecryptedData("currDSO")["partner"]) ===
            "GCB BANCASSURANCE TELESALES"
          ) {
            navigate("/bancassurance/gcb/telesales", {
              replace: true,
            });
          } else if (
            (await getDecryptedData("currDSO")["partner"]) ===
            "BSIC BANCASSURANCE"
          ) {
            navigate("/bancassurance/omnibsic", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] === "ACCESS BANCASSURANCE STAFF"
          ) {
            navigate("/bancassurance/access", {
              replace: true,
            });
          } else {
            navigate("/retail/starlife", {
              replace: true,
            });
          }
          return true;
        } else if (response.data.message_code === 101) {
          setLoading(false);
          alert("Invalid username or password");
          return false;
        } else {
          setLoading(false);
          alert("SE or DSO not identified");
          return false;
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
        return false;
      });
  }

  return (
    <div>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
              <u>Enter your AGT to Login</u>
            </h4>{" "}
            <br />
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="agent"
                  label="AGT Code (AGTXXXXXX)"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    // onBlur={this.handleAgent}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.agentCode}
                    style={{
                      border: agentError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentError}
                  </div>
                </FloatingLabel>
                {/* <FloatingLabel
                  controlId="bankPartner"
                  label="Select your Banc Assurance Partner"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("bankBranch")}
                    // defaultValue={values.bankBranch}
                    style={{
                      border: bankPartnerError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {bankPartners.map((partner) => (
                      <option key={partner.id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {bankPartnerError}
                  </div>
                </FloatingLabel> */}
              </Form.Group>
              {/* <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button> */}
              &nbsp;
              <Button
                variant="primary"
                // type="submit"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                }}
              >
                Login <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
            </Form>
          </div>
        </>
      ) : (
        <>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          {/* <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
              <u>Enter your AGT to Login</u>
            </h4>{" "}
            <br />
            <Form
              onSubmit={submitHandler}
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="agent"
                  label="AGT Code (AGTXXXXXX)"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    style={{
                      border: agentError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="agentpassword"
                  label="Password"
                  className="mb-2"
                >
                  <Form.Control
                    type={passwordVisible ? "text" : "password"} // Show password if visible, otherwise hide
                    autoComplete="off"
                    style={{
                      border: agentPwdError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={togglePasswordVisibility}
                    tabIndex={0} // Make it focusable
                    role="button" // Add ARIA role for accessibility
                  >
                    {passwordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentPwdError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <div style={{ textAlign: "right", border: "1px" }}>
                <a href="/forgot-password">Forgot Password?</a>
              </div>
              &nbsp;
              <Button
                variant="primary"
                // type="submit"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                }}
                onClick={submitHandler}
              >
                Login <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
            </Form>
            &nbsp;
            <div
              style={{ textAlign: "center", border: "1px", color: "purple" }}
            >
              Don't Have an account? <a href="/register">Register Here</a>
            </div>
          </div>
        </>
      )}
      {/* <button
        className="link-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        Install
      </button> */}
    </div>
  );
}

export default AgentWelcomeLogin;
