import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../store/auth-context";

const BottomButton = (props) => {
  const authCtx = useContext(AuthContext);
  const saveExitHandler = async () => {
    const { step, values } = props;
    const dataToSave = { step, values };

    console.log("pending to save", dataToSave);

    const existingData = props.getDecryptedData("pendcurr");

    console.log("existingData", existingData);

    const updatedData = existingData
      ? [...existingData, dataToSave]
      : [dataToSave];

    console.log("updated data to save", updatedData);

    props.saveEncryptedData("pendcurr", updatedData);

    localStorage.removeItem("curr");
    localStorage.removeItem("quotation");
    localStorage.removeItem("product");
    props.resetStep(1);

    // const existingData = await retrieveData();
    // const updatedData = existingData
    //   ? [...existingData, dataToSave]
    //   : [dataToSave];
    // saveData(updatedData);
    // removeValue("@Current:data");
    // navigation.navigate("Home");
  };

  return (
    <>
      {authCtx.isDSOLoggedIn ? (
        <Button
          variant="primary"
          // type="submit"
          style={{
            backgroundColor: "#FF4F00",
            outlineColor: "#ffc52a",
            borderColor: "#ffc52a",
            color: "white",
            width: "50%",
          }}
          onClick={saveExitHandler}
        >
          Save and Exit <FontAwesomeIcon icon={faSignOutAlt} title="Exit" />
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};

export default BottomButton;
