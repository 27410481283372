const BankBranchesData = [
  {
    bbrCode: 8478,
    bbrBnkCode: 71,
    bbrBranchName: "GOMOA-DAW",
    bbrShtDesc: "084115",
  },
  {
    bbrCode: 9912,
    bbrBnkCode: 71,
    bbrBranchName: "HEAD OFFICE",
    bbrShtDesc: "084001",
  },
  {
    bbrCode: 9913,
    bbrBnkCode: 71,
    bbrBranchName: "JACOBU",
    bbrShtDesc: "084002",
  },
  {
    bbrCode: 9914,
    bbrBnkCode: 71,
    bbrBranchName: "OBUASI",
    bbrShtDesc: "084003",
  },
  {
    bbrCode: 9915,
    bbrBnkCode: 71,
    bbrBranchName: "ASAWASI",
    bbrShtDesc: "084004",
  },
  {
    bbrCode: 9916,
    bbrBnkCode: 71,
    bbrBranchName: "MAAKRO",
    bbrShtDesc: "084005",
  },
  {
    bbrCode: 9917,
    bbrBnkCode: 71,
    bbrBranchName: "BEKWAI",
    bbrShtDesc: "084006",
  },
  {
    bbrCode: 9918,
    bbrBnkCode: 71,
    bbrBranchName: "KROFROM",
    bbrShtDesc: "084014",
  },
  {
    bbrCode: 9919,
    bbrBnkCode: 71,
    bbrBranchName: "ROMAN HILL",
    bbrShtDesc: "084008",
  },
  {
    bbrCode: 9920,
    bbrBnkCode: 71,
    bbrBranchName: "AGRIC NZEMA",
    bbrShtDesc: "084009",
  },
  {
    bbrCode: 9921,
    bbrBnkCode: 71,
    bbrBranchName: "TAFO",
    bbrShtDesc: "084010",
  },
  {
    bbrCode: 9922,
    bbrBnkCode: 71,
    bbrBranchName: "AYIGYA",
    bbrShtDesc: "084011",
  },
  {
    bbrCode: 9923,
    bbrBnkCode: 71,
    bbrBranchName: "BANTAMA",
    bbrShtDesc: "084012",
  },
  {
    bbrCode: 9966,
    bbrBnkCode: 71,
    bbrBranchName: "SANTASI",
    bbrShtDesc: "084013",
  },
  {
    bbrCode: 10453,
    bbrBnkCode: 71,
    bbrBranchName: "ABUAKWA",
    bbrShtDesc: "084016",
  },
];

export default BankBranchesData;
