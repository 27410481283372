import React from "react";
import gcb from "../../gcb-logo.png";
import moment from "moment";

export const Receipt = React.forwardRef((props, ref) => {
  const { values } = props;
  // console.log(values.productName);
  // console.log(values["productName"]);
  // console.log(values.productName !== "GCB HOMECALL PLUS");
  console.log(values);
  const fname = values.firstname;
  const onames = values.othernames;
  const lname = values.lastname;
  let fullName = "";

  if (onames) {
    fullName = fname + " " + onames + " " + lname;
  } else {
    fullName = fname + " " + lname;
  }
  // console.log(props.values.ProductName);
  return (
    <div ref={ref}>
      {/* <br></br> */}
      <br></br>
      <div
        className="container center_div"
        style={{
          display: "block",
          width: 500,
          padding: 10,
        }}
      >
        <table
          style={{
            borderWidth: "2px",
            borderColor: "#aaaaaa",
            borderStyle: "solid",
            width: "100%",
          }}
        >
          <tr>
            <td
              colSpan={"2"}
              style={{
                backgroundColor: "#191c4a",
                color: "#ffffff",
              }}
            >
              <h5>PROPOSAL DETAILS</h5>
            </td>
          </tr>
          {/* <tr>
            <td align="left" width={""}>
              Proposal Number:
            </td>
            <td align="left">
              <b>{values.ProposalNumber}</b>
            </td>
          </tr> */}
          <tr>
            <td align="left" width={""}>
              Policy Holder:
            </td>
            <td align="left">
              <b>{fullName}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Product Name:</td>
            <td align="left">
              <b>{values.product.split("-")[2]}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Premium (Ghȼ):</td>
            <td align="left">
              <b>{values.premium.toFixed(2)}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Initial Sum Assured (Ghȼ):</td>
            <td align="left">
              <b>
                {values.sa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </b>
            </td>
          </tr>
          {/* <tr>
            <td align="left">Payment Frequency:</td>
            <td align="left">
              <b>{values.PaymentFrequency}</b>
            </td>
          </tr> */}
          {values.Paymentfrequency === "M" && (
            <tr>
              <td align="left">Payment Frequency:</td>
              <td align="left">
                <b>MONTHLY</b>
              </td>
            </tr>
          )}
          {values.Paymentfrequency === "A" && (
            <tr>
              <td align="left">Payment Frequency:</td>
              <td align="left">
                <b>ANNUALLY</b>
              </td>
            </tr>
          )}
          <tr>
            <td align="left">Policy Term (Years):</td>
            <td align="left">
              <b>{values.term}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Date:</td>
            <td align="left">
              <b>{moment().format("DD-MM-YYYY")}</b>
            </td>
          </tr>
          {/* {values.ProductName !== "GCB HOMECALL PLUS" && (
            <tr>
              <td align="left">Maturity Date:</td>
              <td align="left">
                <b>{values.MaturityDate}</b>
              </td>
            </tr>
          )} */}
          {/* <tr>
            <td align="left">Total Premium (Ghȼ):</td>
            <td align="left">
              <b>
                {Math.round(
                  (values.spectra_policy.InitPremium +
                    values.spectra_policy.PackagePremium) *
                    100
                ) / 100}
              </b>
            </td>
          </tr> */}
        </table>{" "}
      </div>
    </div>
  );
});
