import React, { PureComponent } from "react";
import gcb from "../../gcb-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";
import FormMedicalCondition from "./FormMedicalCondition";
import FormMedicalMedication from "./FormMedicalMedication";
import FormMedicalSurgery from "./FormMedicalSurgery";
import NewHeader from "./header/NewHeaderAmenfiman";
import BottomButton from "./SaveandExit";

export class FormMedicalDetails extends PureComponent {
  state = {
    goodHealthError: "",
    anyMedicalError: "",
    medicationError: "",
    surgicalError: "",
    loading: false,
    condition: false,
    medication: false,
    surgery: false,
    conditionError: "",
    medError: "",
    surError: "",
  };

  handleConditionChange = (e) => {
    // console.log(e.target.value);
    const md2 = e.target.value;
    if (md2 === "Yes") {
      this.setState({ condition: true });
      this.props.valueChange(["md2", md2]);
    } else {
      this.setState({ condition: false });
      this.props.valueChange(["md2", md2]);
    }
  };

  handleMedicationChange = (e) => {
    // console.log(e.target.value);
    const md3 = e.target.value;
    if (md3 === "Yes") {
      this.setState({ medication: true });
      this.props.valueChange(["md3", md3]);
    } else {
      this.setState({ medication: false });
      this.props.valueChange(["md3", md3]);
    }
  };

  handleSurgeryChange = (e) => {
    // console.log(e.target.value);
    const md4 = e.target.value;
    if (md4 === "Yes") {
      this.setState({ surgery: true });
      this.props.valueChange(["md4", md4]);
    } else {
      this.setState({ surgery: false });
      this.props.valueChange(["md4", md4]);
    }
  };

  continue = (e) => {
    e.preventDefault();
    // console.log(e);
    let goodHealthError = "";
    let anyMedicalError = "";
    let medicationError = "";
    let surgicalError = "";
    let conditionError = "";
    let medError = "";
    let surError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      goodHealthError = "Please select an option";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      anyMedicalError = "Please select an option";
    }

    if (e.target.form[1].value === "Yes") {
      if (!e.target.form[2].value) {
        // Checks if field is empty
        conditionError = "Please state the condition or illness";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        medicationError = "Please select an option";
      }

      if (e.target.form[3].value === "Yes") {
        if (!e.target.form[4].value) {
          // Checks if field is empty
          medError = "Please state the type of medication and dosage";
        }

        if (!e.target.form[5].value) {
          // Checks if field is empty
          surgicalError = "Please select an option";
        }

        if (e.target.form[5].value === "Yes") {
          if (!e.target.form[6].value) {
            // Checks if field is empty
            surError =
              "Please state the type of surgical operation or procedure";
          }
        }
      } else {
        if (!e.target.form[4].value) {
          // Checks if field is empty
          surgicalError = "Please select an option";
        }

        if (!e.target.form[5].value) {
          // Checks if field is empty
          surError = "Please state the type of surgical operation or procedure";
        }

        if (!e.target.form[6].value) {
          // Checks if field is empty
          surError = "Please state the type of surgical operation or procedure";
        }
      }
    } else {
      if (!e.target.form[2].value) {
        // Checks if field is empty
        medicationError = "Please select an option";
      }

      if (e.target.form[2].value === "Yes") {
        if (!e.target.form[3].value) {
          // Checks if field is empty
          medError = "Please state the type of medication and dosage";
        }

        if (!e.target.form[4].value) {
          // Checks if field is empty
          surgicalError = "Please select an option";
        }

        if (!e.target.form[5].value) {
          // Checks if field is empty
          surError = "Please state the type of surgical operation or procedure";
        }
      } else {
        if (!e.target.form[3].value) {
          // Checks if field is empty
          surgicalError = "Please select an option";
        }

        if (e.target.form[3].value === "Yes") {
          if (!e.target.form[4].value) {
            // Checks if field is empty
            surError =
              "Please state the type of surgical operation or procedure";
          }
        }
      }
    }

    // if (
    //   e.target.form[0].value &&
    //   e.target.form[1].value &&
    //   e.target.form[2].value &&
    //   e.target.form[3].value
    // ) {
    //   this.setState({ loading: true });
    //   //Verify client phone
    //   axios
    //     .post("/api/customer/submit", this.props.values)
    //     .then((response) => {
    //       // console.log(response.data);
    //       if (response.data.message_code === 100) {
    //         this.setState({ loading: false });
    //         console.log(response.data);
    //         this.props.nextStep();
    //         return true;
    //       } else {
    //         this.setState({ loading: false });
    //         console.log(response.data);
    //         return false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.setState({ loading: false });
    //       console.log(error);
    //       return false;
    //     });
    // }

    if (
      goodHealthError ||
      anyMedicalError ||
      medicationError ||
      surgicalError ||
      conditionError ||
      medError ||
      surError
    ) {
      this.setState({
        goodHealthError,
        anyMedicalError,
        medicationError,
        surgicalError,
        conditionError,
        medError,
        surError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      handleChange,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
      valueChange,
    } = this.props;
    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-gcb"}></div>
            <NewHeader />

            <br></br>
            <h3>
              Do you have, or have you ever had any of the following.
              <br /> If yes, kindly provide details,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              .
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faClinicMedical} /> &nbsp;{" "}
                <u>Medical History</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="md1"
                    label="Are you presently in good health?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("md1")}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.goodHealthError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.goodHealthError}
                    </div>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="md2"
                    label="Have you been diagnosed with any medical condition or illness?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handleConditionChange}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.anyMedicalError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.anyMedicalError}
                    </div>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="md3"
                    label="Are you on any medication?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("dreadDisease")}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.medicationError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.medicationError}
                    </div>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="md4"
                    label="Have you undergone any surgical operation or procedure?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handlePAChange}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.surgicalError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.surgicalError}
                    </div>
                  </FloatingLabel>
                  {this.state.paChange && (
                    <ProductDetailsPA
                      paSurnameError={paSurnameError}
                      paOthernameError={paOthernameError}
                      paDOB={paDOB}
                      paGender={paGender}
                      handleChange={handleChange}
                      values={values}
                    />
                  )}
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#ffc52a",
                    outlineColor: "#ffc52a",
                    borderColor: "#ffc52a",
                    color: "#4a4a4a",
                  }}
                  onClick={this.continue}
                >
                  Next
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            {/* <br></br> */}
            <NewHeader />

            <br></br>
            <h3>
              Do you have, or have you ever had any of the following.
              <br /> If yes, kindly provide details,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              .
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faClinicMedical} /> &nbsp;{" "}
                <u>Medical History</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="md1"
                    label="Are you presently in good health?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={handleChange("md1")}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.goodHealthError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.goodHealthError}
                    </div>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="md2"
                    label="Have you been diagnosed with any medical condition or illness?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handleConditionChange}
                      // onChange={handleChange("md2")}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.anyMedicalError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.anyMedicalError}
                    </div>
                  </FloatingLabel>
                  {this.state.condition && (
                    <FormMedicalCondition
                      handleChange={handleChange}
                      valueChange={valueChange}
                      values={values}
                      conditionError={this.state.conditionError}
                    />
                  )}
                  <FloatingLabel
                    controlId="md3"
                    label="Are you on any medication?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handleMedicationChange}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.medicationError
                          ? "1px solid red"
                          : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.medicationError}
                    </div>
                  </FloatingLabel>
                  {this.state.medication && (
                    <FormMedicalMedication
                      handleChange={handleChange}
                      valueChange={valueChange}
                      values={values}
                      medError={this.state.medError}
                    />
                  )}
                  <FloatingLabel
                    controlId="md4"
                    label="Have you undergone any surgical operation or procedure?"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handleSurgeryChange}
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.surgicalError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.surgicalError}
                    </div>
                  </FloatingLabel>
                  {this.state.surgery && (
                    <FormMedicalSurgery
                      handleChange={handleChange}
                      valueChange={valueChange}
                      values={values}
                      surError={this.state.surError}
                    />
                  )}
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#296F24",
                    outlineColor: "#191c4a",
                    borderColor: "#191c4a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
              <br></br>
              <BottomButton
                step={this.props.step}
                values={this.props.values}
                getDecryptedData={this.props.getDecryptedData}
                saveEncryptedData={this.props.saveEncryptedData}
                resetStep={this.props.resetStep}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormMedicalDetails;
