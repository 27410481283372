import React, { PureComponent } from "react";
import gcb from "../../gcb-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import NewHeader from "./header/NewHeaderAtwima";

export class FormAgent extends PureComponent {
  state = {
    agentError: "",
    loading: false,
    agentName: "",
    agentReferralError: "",
    showAgentTextBox: false,
    checked: false,
  };

  handleRadioChange = (e) => {
    if (e.target.form[0].checked) {
      this.setState({ showAgentTextBox: true });
    } else {
      this.props.valueChange(["agentCode", null]);
      this.props.valueChange(["agnCode", null]);
      this.props.valueChange(["agentName", null]);
      this.setState({ showAgentTextBox: false });
    }
  };

  handleAgent = (e) => {
    e.preventDefault();
    let agentError = "";

    // console.log(e.target.value);
    const agent = {
      Agn_Code: e.target.value,
      Agn_Type: "ATWIMA MPONUA RURAL BANCASSURANCE",
    };
    // console.log(Object.values(agent.Agn_Code).length);
    if (Object.values(agent.Agn_Code).length > 7) {
      this.setState({ checked: true });
      this.setState({ loading: true });
      this.props.valueChange(["agentCode", e.target.value]);
      //   alert(agent);
      //Verify agent
      axios
        .post("/api/customer/agent", agent)
        .then((response) => {
          // console.log(response.data);
          if (response.data.message_code === 100) {
            this.setState({ loading: false });
            // console.log(response.data);
            this.props.valueChange(["agnCode", response.data.agnCode]);
            this.props.valueChange(["agentName", response.data.agnName]);
            this.setState({
              agentName: "Agent Name: " + response.data.agnName,
            });
            agentError = "";
            this.setState({ agentError });
            alert("Kindly verify agent name before you submit");
            // this.props.nextStep();
            return true;
          } else {
            this.setState({ loading: false });
            // console.log(response.data);
            agentError = "Agent not identified";
            this.setState({
              agentName: null,
            });
            this.props.valueChange(["agentCode", null]);
            this.props.valueChange(["agnCode", null]);
            this.props.valueChange(["agentName", null]);
            // console.log(agentError);

            if (agentError) {
              this.setState({
                agentError,
              });
              return false;
            }
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          agentError = "An Error occured.";
          // console.log(error);

          if (agentError) {
            this.setState({
              agentError,
            });
            return false;
          }
        });
    } else {
      this.props.valueChange(["agentCode", null]);
      this.props.valueChange(["agnCode", null]);
      this.props.valueChange(["agentName", null]);
    }

    // if (pa === "Yes") {
    //   this.setState({ paChange: true });
    //   this.props.valueChange(["pa", pa]);
    // } else {
    //   this.setState({ paChange: false });
    // }
  };

  handleIncrementOption = (e) => {
    const escalationRate = e.target.value;
    if (escalationRate === "Yes") {
      this.setState({ incrementOption: true });
      this.props.valueChange(["escalationRate", escalationRate]);
    } else {
      this.setState({ incrementOption: false });
    }
  };

  continue = (e) => {
    console.log(e);
    let agentReferralError = "";
    let agentError = "";
    e.preventDefault();

    // if (!e.target.form[0].checked & !e.target.form[1].checked) {
    //   // Checks if field is empty
    //   agentReferralError =
    //     "Please indicated whether you were referred by a DSO or not";
    // }

    // if (e.target.form[0].checked) {
    //   if (!e.target.form[2].value) {
    //     agentError = "AGT Code is required";
    //   }

    //   if (e.target.form[2].value) {
    //     if (e.target.form[2].value.length < 7) {
    //       agentError = "Kindly provide a valid AGT Code.";
    //     }
    //   }
    // }

    if (!e.target.form[0].value) {
      agentError = "AGT Code is required";
    }

    if (e.target.form[0].value) {
      if (e.target.form[0].value.length < 7) {
        agentError = "Kindly provide a valid AGT Code.";
      }
    }

    if (agentReferralError || agentError) {
      this.setState({
        agentReferralError,
        agentError,
      });
      return false;
    }

    this.props.nextStep();
    return true;

    // this.setState({ loading: true });
    // //Verify client phone
    // axios
    //   .post("/api/customer/submit", this.props.values)
    //   .then((response) => {
    //     console.log("response " + response);
    //     console.log("response data " + response.data);
    //     console.log("response status " + response.data.status);
    //     console.log("message code " + response.data.message_code);
    //     // console.log("");
    //     if (response.data.message_code === 100) {
    //       this.setState({ loading: false });
    //       console.log(response.data);
    //       this.props.valueChange(["pNumber", response.data.proposalNumber]);
    //       this.props.nextStep();
    //       return true;
    //     } else {
    //       this.setState({ loading: false });
    //       console.log(response.data);
    //       alert(response.data);
    //       return false;
    //     }
    //   })
    //   .catch((error) => {
    //     this.setState({ loading: false });
    //     console.log(error);
    //     // alert(error);
    //     return false;
    //   });

    // if (
    //     agentError
    // ) {
    //   this.setState({
    //     agentError
    //   });
    //   return false;
    // }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values } = this.props;
    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-atwima"}></div>
            <NewHeader />
            <br></br>
            <h2>
              Almost there{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              , kindly provide Direct Sales Officer (DSO) referral code
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faImage} style={{ color: "" }} /> &nbsp;{" "}
                <u>DSO Referral Code</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="agent"
                    label="AGT Code (AGT111111)"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      onChange={this.handleAgent}
                      // onChange={handleChange("paymentMode")}
                      defaultValue={values.agentCode}
                      style={{
                        border: this.state.goodHealthError
                          ? "1px solid red"
                          : "",
                      }}
                    ></Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.goodHealthError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#191c4a",
                    borderColor: "#191c4a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <NewHeader />
            <br></br>
            <h2>
              Almost there{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              , kindly provide Direct Sales Officer (DSO) referral code
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faImage} style={{ color: "" }} /> &nbsp;{" "}
                <u>DSO Referral Code</u>
              </h4>{" "}
              <br />
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="agent"
                    label="AGT Code (AGT111111)"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      onBlur={this.handleAgent}
                      // onChange={handleChange("paymentMode")}
                      defaultValue={values.agentCode}
                      style={{
                        border: this.state.agentError ? "1px solid red" : "",
                      }}
                    ></Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {this.state.agentName}
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.agentError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#191c4a",
                    borderColor: "#191c4a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormAgent;
