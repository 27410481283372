import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
// import "./sb-admin-2.min.css";
import "../../static/vendor/fontawesome-free/css/all.min.css";
// import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
import logo from "../../static/img/starlife-logo.png";
import DataTable from "react-data-table-component";
import Export from "react-data-table-component";
import { Modal, Button, InputGroup } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Viewer from "react-viewer";
import pdfmodal from "./pdf_modal";
import FlashMessage from "react-flash-message";
import AuthContext from "../../store/auth-context";
import proposalData from "./proposalsdata";
import atwima from "../../akrb_logo22.png";
import amenfiman from "../../Amenfi-Man-logo2.png";
import gcb from "../../gcb-logo.png";
import access from "../../access-logo-main.png";
import okomfo from "../../Logo-okomfo-new.png";
import ahantaman from "../../ahantaman-logo3-1.png";
// import { env } from "process";
import { titleCase } from "../func/utils";
import Form from "react-bootstrap/Form";

function AdminReport() {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.logout();
  };

  const bank = authCtx.bank;
  const branch = authCtx.bbrCode;

  let inputStyle = {
    backgroundColor: "#763984",
    color: "white",
  };

  let proposal_logo = "";

  if (bank === "ATWIMA KWANWOMA RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#009900",
      color: "#fff",
    };
    proposal_logo = atwima;
  } else if (bank === "AMENFIMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = amenfiman;
  } else if (bank === "GCB BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ffc52a",
      color: "#4a4a4a",
    };
    proposal_logo = gcb;
  } else if (bank === "ACCESS BANK BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ee7e01",
      color: "#fff",
    };
    proposal_logo = access;
  } else if (bank === "OKOMFO ANOKYE RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = okomfo;
  } else if (bank === "AHANTAMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#A80404",
      color: "#fff",
    };
    proposal_logo = ahantaman;
  }

  return (
    <>
      {/* // <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/* <!-- Sidebar --> */}
        <ul
          className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          {/* <!-- Sidebar - Brand --> */}
          <a
            className="sidebar-brand d-flex align-items-center justify-content-center"
            href="/bancassurance/portal/home"
          >
            <div
              className="sidebar-brand-icon"
              style={{ backgroundColor: "white", marginLeft: "50px" }}
            >
              <img src={logo} height="70" />
            </div>
          </a>
          <br></br>
          {/* <!-- Divider --> */}
          {/* <hr className="sidebar-divider my-0"></hr> */}
          {/* <!-- Nav Item - Dashboard --> */}
          <li className="nav-item">
            <a className="nav-link" href="/bancassurance/portal/home">
              <i class="fa fa-home" aria-hidden="true"></i>
              <span>Home</span>
            </a>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider"></hr>
          <li className="nav-item">
            <a className="nav-link" href="/bancassurance/portal/report">
              <i className="fa fa-file"></i>
              <span>Reports</span>
            </a>
          </li>
          <hr className="sidebar-divider"></hr>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/bancassurance/portal/change-password"
            >
              <i className="fa fa-file"></i>
              <span>Change Password</span>
            </a>
          </li>
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider"></hr>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <i className="fas fa-sign-out-alt"></i>
              <span onClick={logoutHandler}>Logout</span>
            </a>
          </li>
        </ul>
        {/* <!-- End of Sidebar --> */}

        {/* <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* <!-- Main Content --> */}
          <div id="content">
            {/* <!-- Topbar --> */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              {/* <!-- Sidebar Toggle (Topbar) --> */}
              <button
                id="sidebarToggleTop"
                className="btn btn-link d-md-none rounded-circle mr-3"
              >
                <i className="fa fa-bars"></i>
              </button>
              <ul className="navbar-nav ml-auto">
                <div className="topbar-divider d-none d-sm-block"></div>
                {/* <!-- Nav Item - User Information --> */}
                <li className="nav-item dropdown no-arrow">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {/* {% if current_user.is_authenticated %} */}
                    <span className="mr-2 d-none d-lg-inline text-gray-600">
                      {authCtx.userName}
                      {/* <font color="#763984">DELIJAH</font> */}
                    </span>
                    {/* {% endif %} */}
                    <i className="fas fa-user"></i>
                  </a>
                  {/* <!-- Dropdown - User Information --> */}
                  <div
                    className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      Settings
                    </a>
                    <a className="dropdown-item" href="#">
                      <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      Activity Log
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="#"
                      data-toggle="modal"
                      data-target="#logoutModal"
                    >
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>

            {/* <!-- Begin Page Content --> */}
            <div className="container-fluid">
              {/* <!-- Page Heading --> */}
              {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
              {/* <!-- DataTales Example --> */}
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h3 className="m-0 font-weight-bold text-primary">REPORTS</h3>
                  <div></div>
                  <br />
                </div>
                <div className="card-body">
                  {/* <div className="table-responsive"> */}
                  <div class="row">
                    <div class="col">
                      <a
                        className="nav-link"
                        href="/bancassurance/portal/report/summary"
                      >
                        Summary Report
                      </a>
                    </div>
                    <div class="col">
                      <a
                        className="nav-link"
                        href="/bancassurance/portal/report/detail"
                      >
                        Detailed Report
                      </a>
                    </div>
                    {/* <div class="col">
                      <a
                        className="nav-link"
                        href="/bancassurance/portal/report/rejected"
                      >
                        Rejected Onboardings
                      </a>
                    </div> */}
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End of Main Content --> */}

          {/* <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>
                  Copyright &copy; {new Date().getFullYear()} StarLife
                  Assurance. Powered by <b>StarLife I.C.T</b>
                </span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/* <!-- End of Content Wrapper --> */}
      </div>
      {/* //   <!-- End of Page Wrapper --> */}

      {/* <!-- Scroll to Top Button--> */}
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </>
  );
}

export default AdminReport;
