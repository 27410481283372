import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import awutu from "../../AWUTU-LOGO.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { getAge } from "../func/utils";
import axios from "axios";

import ProductDetailsCLP from "./ProductDetailsCLP";
import ProductDetailsWPP from "./ProductDetailsWPP";
import ProductDetailsUPP from "./ProductDetailsUPP";
import ProductDetailsEHP from "./ProductDetailsEHP";

export class ProductDetails extends PureComponent {
  state = {
    productError: "",
    termError: "",
    tpdError: "",
    retrechmentError: "",
    dreadDiseaseError: "",
    paError: "",
    productChangeCLP: false,
    productChangeWPP: false,
    productChangeUPP: false,
    productChangeEHP: false,
    premiumError: "",
    escalationError: "",
    paSurnameError: "",
    paOthernameError: "",
    paDOB: "",
    paGender: "",
    escalationRateError: "",
    loading: false,
  };

  handleProductChange = (e) => {
    // console.log(e.target.value);
    const product = e.target.value;
    const productCode = e.target.value.split("-")[0];
    this.props.valueChange(["product", product]);
    this.props.valueChange(["productCode", productCode]);

    if (product.split("-")[1] === "CLP") {
      this.setState({ productChangeCLP: true });
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: false });
      // this.setState({
      //   termError: null,
      // });
      this.props.valueChange(["dependentRequired", false]);
    } else if (product.split("-")[1] === "WPP") {
      this.setState({ productChangeWPP: true });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: false });
      this.props.valueChange(["dependentRequired", false]);
      // this.setState({
      //   termError: null,
      // });
    } else if (product.split("-")[1] === "UPP") {
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: true });
      this.setState({ productChangeEHP: false });
      this.props.valueChange(["dependentRequired", false]);
    } else if (product.split("-")[1] === "EHP") {
      this.setState({ productChangeWPP: false });
      this.setState({ productChangeCLP: false });
      this.setState({ productChangeUPP: false });
      this.setState({ productChangeEHP: true });
      this.props.valueChange(["dependentRequired", true]);
    }
  };

  continue = (e) => {
    e.preventDefault();
    console.log(e);
    // console.log(e.target.form[0].value);
    // console.log(e.target.form[1].value);
    let productError = "";
    let termError = "";
    let tpdError = "";
    let retrechmentError = "";
    let dreadDiseaseError = "";
    let paError = "";
    let premiumError = "";
    let escalationError = "";
    let paSurnameError = "";
    let paOthernameError = "";
    let paDOB = "";
    let paGender = "";
    let escalationRateError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      productError = "Please select product";
    }

    if (e.target.form[0].value.split("-")[1] === "CLP") {
      if (e.target.form[1].value < 10 || e.target.form[1].value > 25) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        tpdError = "Please select option";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        retrechmentError = "Please select option";
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        dreadDiseaseError = "Please select option";
      }

      if (!e.target.form[5].value) {
        // Checks if field is empty
        paError = "Please select option";
      }

      if (e.target.form[5].value === "Yes") {
        if (!e.target.form[6].value) {
          // Checks if field is empty
          paSurnameError = "Please enter surname for child";
        }

        if (!e.target.form[7].value) {
          // Checks if field is empty
          paOthernameError = "Please enter othernames for child";
        }

        if (!e.target.form[8].value) {
          // Checks if field is empty
          paDOB = "Please enter date of birth for child";
        }

        if (getAge(e.target.form[8].value) > 18) {
          paDOB = "Child should be below 18 years";
        }

        if (!e.target.form[9].value) {
          // Checks if field is empty
          paGender = "Please select gender of child";
        }

        if (!e.target.form[10].value || e.target.form[10].value < 100) {
          // Checks if field is empty
          premiumError = "Please enter a valid basic premium";
        }

        if (!e.target.form[11].value) {
          // Checks if field is empty
          escalationError = "Please select option";
        }

        if (e.target.form[11].value === "Yes") {
          if (!e.target.form[12].value) {
            // Checks if field is empty
            escalationRateError = "Please select escalation rate";
          }
        }
      } else {
        if (!e.target.form[6].value || e.target.form[6].value < 100) {
          // Checks if field is empty
          premiumError = "Please enter a valid basic premium";
        }

        if (!e.target.form[7].value) {
          // Checks if field is empty
          escalationError = "Please select option";
        }

        if (e.target.form[7].value === "Yes") {
          if (!e.target.form[8].value) {
            // Checks if field is empty
            escalationRateError = "Please select escalation rate";
          }
        }
      }
    } else if (e.target.form[0].value.split("-")[1] === "WPP") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value || e.target.form[2].value < 100) {
        // Checks if field is empty
        premiumError = "Please enter a valid basic premium";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        escalationError = "Please select option";
      }

      if (e.target.form[3].value === "Yes") {
        if (!e.target.form[4].value) {
          // Checks if field is empty
          escalationRateError = "Please select escalation rate";
        }
      }
    } else if (e.target.form[0].value.split("-")[1] === "UPP") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        dreadDiseaseError = "Please select option";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        premiumError = "Please select basic sum assured";
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        escalationError = "Please select option";
      }

      if (e.target.form[4].value === "Yes") {
        if (!e.target.form[5].value) {
          // Checks if field is empty
          escalationRateError = "Please select escalation rate";
        }
      }
    } else if (e.target.form[0].value.split("-")[1] === "EHP") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        premiumError = "Please select basic sum assured";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        escalationError = "Please select option";
      }

      if (e.target.form[2].value === "Yes") {
        if (!e.target.form[3].value) {
          // Checks if field is empty
          escalationRateError = "Please select escalation rate";
        }
      }
    }

    // if (!e.target.form[1].value) {
    //   // Checks if field is empty
    //   termError = "Please enter policy term";
    // }

    // if (e.target.form[1].value) {
    //   if (e.target.form[1].value < 8) {
    //     // Checks if field is empty
    //     termError = "Please enter a valid policy term";
    //   }

    //   if (!e.target.form[2].value) {
    //     // Checks if field is empty
    //     tpdError = "Please select option";
    //   }
    // }

    // if (!e.target.form[2].value) {
    //   // Checks if field is empty
    //   tpdError = "Please select option";
    // }

    if (
      productError ||
      termError ||
      tpdError ||
      retrechmentError ||
      dreadDiseaseError ||
      paError ||
      premiumError ||
      escalationError ||
      paSurnameError ||
      paOthernameError ||
      paDOB ||
      paGender ||
      escalationRateError
    ) {
      this.setState({
        productError,
        termError,
        tpdError,
        retrechmentError,
        dreadDiseaseError,
        paError,
        premiumError,
        escalationError,
        paSurnameError,
        paOthernameError,
        paDOB,
        paGender,
        escalationRateError,
      });
      return false;
    }

    const existingProduct = {
      Customer_Mobile: this.props.values.phoneNumber,
      Product_Code: e.target.form[0].value.split("-")[1],
    };

    this.setState({ loading: true });

    //Verify if client already has product
    axios
      .post("/api/customer/product/verify", existingProduct)
      .then((response) => {
        if (response.data.message_code === 100) {
          this.setState({ loading: false });
          productError = `You are already signed up on the ${
            e.target.form[0].value.split("-")[2]
          }`;
          if (productError) {
            this.setState({
              productError,
            });
            return false;
          }
        } else {
          this.setState({ loading: false });
          this.props.nextStep();
          return true;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
    // this.props.nextStep();
    // return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep(3);
  };

  render() {
    const { values, handleChange, valueChange } = this.props;

    const products = [
      {
        id: 3,
        label: "Child Education Plan",
        value: "3-CLP-Child Education Plan",
      },
      {
        id: 20,
        label: "Wealth Master Plan",
        value: "20-WPP-WeathMaster Plan",
      },
      // {
      //   id: 42,
      //   label: "Ultimate Protection Plan",
      //   value: "42-UPP-Ultimate Protection Plan",
      // },
      {
        id: 2018518,
        label: "Home Call Plan",
        value: "2018518-EHP-HomeCall Plus",
      },
    ];

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-ga"}></div>
            <br></br>
            <img src={awutu} alt="logo" style={{ height: "150px" }} />
            <br></br>
            <br></br>
            <h2>
              Which of our bancassurance products are you interested in,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              ?
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <Form.Group>
                    <div style={{ border: "3px solid #fff" }}>
                      <div
                        style={{
                          width: "90%",
                          float: "left",
                          // padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FloatingLabel
                            controlId="products"
                            label="Select Product"
                            className="mb-2"
                          >
                            <Form.Control
                              as="select"
                              onChange={this.handleProductChange}
                              // defaultValue={values.product}
                              style={{
                                border: this.state.productError
                                  ? "1px solid red"
                                  : "",
                              }}
                            >
                              <option></option>
                              {products.map((product) => (
                                <option key={product.id} value={product.value}>
                                  {product.label}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                              }}
                            >
                              {this.state.productError}
                            </div>
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>

                    {this.state.productChangeCLP && (
                      <ProductDetailsCLP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                    {this.state.productChangeWPP && (
                      <ProductDetailsWPP
                        termError={this.state.termError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                    {this.state.productChangeUPP && (
                      <ProductDetailsUPP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                  </Form.Group>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "yellow",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <br></br>
            <img src={awutu} alt="logo" style={{ height: "150px" }} />
            <br></br>
            <br></br>
            <h2>
              Which of our bancassurance products are you interested in,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              ?
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <Form.Group>
                    <div style={{ border: "3px solid #fff" }}>
                      <div
                        style={{
                          width: "90%",
                          float: "left",
                          // padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FloatingLabel
                            controlId="products"
                            label="Select Product"
                            className="mb-2"
                          >
                            <Form.Control
                              as="select"
                              onChange={this.handleProductChange}
                              // defaultValue={values.product}
                              style={{
                                border: this.state.productError
                                  ? "1px solid red"
                                  : "",
                              }}
                            >
                              <option></option>
                              {products.map((product) => (
                                <option key={product.id} value={product.value}>
                                  {product.label}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                              }}
                            >
                              {this.state.productError}
                            </div>
                          </FloatingLabel>
                        </div>
                      </div>
                    </div>

                    {this.state.productChangeCLP && (
                      <ProductDetailsCLP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                    {this.state.productChangeWPP && (
                      <ProductDetailsWPP
                        termError={this.state.termError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                    {this.state.productChangeUPP && (
                      <ProductDetailsUPP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                    {this.state.productChangeEHP && (
                      <ProductDetailsEHP
                        termError={this.state.termError}
                        tpdError={this.state.tpdError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                      />
                    )}
                  </Form.Group>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "yellow",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProductDetails;
