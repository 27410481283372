const GCBBankBranchesData = [
  {
    bbrCode: 9448,
    bbrBnkCode: 41,
    bbrBranchName: "HARPER ROAD, KUMASI",
    bbrShtDesc: "041625",
  },
  {
    bbrCode: 9449,
    bbrBnkCode: 41,
    bbrBranchName: "31ST DECEMBER MARKET",
    bbrShtDesc: "041124",
  },
  {
    bbrCode: 9450,
    bbrBnkCode: 41,
    bbrBranchName: "ABELENKPE",
    bbrShtDesc: "041133",
  },
  {
    bbrCode: 9451,
    bbrBnkCode: 41,
    bbrBranchName: "ABOR",
    bbrShtDesc: "041515",
  },
  {
    bbrCode: 9452,
    bbrBnkCode: 41,
    bbrBranchName: "ABURA DUNKWA",
    bbrShtDesc: "041305",
  },
  {
    bbrCode: 9453,
    bbrBnkCode: 41,
    bbrBranchName: "ABURI",
    bbrShtDesc: "041222",
  },
  {
    bbrCode: 9454,
    bbrBnkCode: 41,
    bbrBranchName: "ACCRA NEW TOWN",
    bbrShtDesc: "041118",
  },
  {
    bbrCode: 9455,
    bbrBnkCode: 41,
    bbrBranchName: "ACCRA NORTH CIRCLE",
    bbrShtDesc: "041139",
  },
  {
    bbrCode: 9456,
    bbrBnkCode: 41,
    bbrBranchName: "ADA FOAH",
    bbrShtDesc: "041213",
  },
  {
    bbrCode: 9457,
    bbrBnkCode: 41,
    bbrBranchName: "ADENTA MARKET",
    bbrShtDesc: "041147",
  },
  {
    bbrCode: 9458,
    bbrBnkCode: 41,
    bbrBranchName: "AFLAO",
    bbrShtDesc: "041502",
  },
  {
    bbrCode: 9459,
    bbrBnkCode: 41,
    bbrBranchName: "AGOGO",
    bbrShtDesc: "041622",
  },
  {
    bbrCode: 9460,
    bbrBnkCode: 41,
    bbrBranchName: "AGONA ASHANTI",
    bbrShtDesc: "041620",
  },
  {
    bbrCode: 9461,
    bbrBnkCode: 41,
    bbrBranchName: "AGONA SWEDRU",
    bbrShtDesc: "041303",
  },
  {
    bbrCode: 9462,
    bbrBnkCode: 41,
    bbrBranchName: "AHINSAN-KUMASI",
    bbrShtDesc: "041619",
  },
  {
    bbrCode: 9463,
    bbrBnkCode: 41,
    bbrBranchName: "AKATSI",
    bbrShtDesc: "041517",
  },
  {
    bbrCode: 9464,
    bbrBnkCode: 41,
    bbrBranchName: "AKIM ODA",
    bbrShtDesc: "041202",
  },
  {
    bbrCode: 9465,
    bbrBnkCode: 41,
    bbrBranchName: "AKOSOMBO",
    bbrShtDesc: "041218",
  },
  {
    bbrCode: 9466,
    bbrBnkCode: 41,
    bbrBranchName: "AKROPONG-AKWAPIM",
    bbrShtDesc: "041209",
  },
  {
    bbrCode: 9467,
    bbrBnkCode: 41,
    bbrBranchName: "AKOMADAN",
    bbrShtDesc: "041616",
  },
  {
    bbrCode: 9468,
    bbrBnkCode: 41,
    bbrBranchName: "AKUSE",
    bbrShtDesc: "041220",
  },
  {
    bbrCode: 9469,
    bbrBnkCode: 41,
    bbrBranchName: "AMASAMAN",
    bbrShtDesc: "041163",
  },
  {
    bbrCode: 9470,
    bbrBnkCode: 41,
    bbrBranchName: "ANYINAM",
    bbrShtDesc: "041212",
  },
  {
    bbrCode: 9471,
    bbrBnkCode: 41,
    bbrBranchName: "ASAFO MARKET",
    bbrShtDesc: "041602",
  },
  {
    bbrCode: 9472,
    bbrBnkCode: 41,
    bbrBranchName: "ASAMANKESE",
    bbrShtDesc: "041208",
  },
  {
    bbrCode: 9473,
    bbrBnkCode: 41,
    bbrBranchName: "ASHIAMAN",
    bbrShtDesc: "041121",
  },
  {
    bbrCode: 9474,
    bbrBnkCode: 41,
    bbrBranchName: "ASSIN FOSU",
    bbrShtDesc: "041304",
  },
  {
    bbrCode: 9475,
    bbrBnkCode: 41,
    bbrBranchName: "AXIM",
    bbrShtDesc: "041408",
  },
  {
    bbrCode: 9476,
    bbrBnkCode: 41,
    bbrBranchName: "AYINAM",
    bbrShtDesc: "041207",
  },
  {
    bbrCode: 9477,
    bbrBnkCode: 41,
    bbrBranchName: "BANTAMA",
    bbrShtDesc: "041627",
  },
  {
    bbrCode: 9478,
    bbrBnkCode: 41,
    bbrBranchName: "BAWKU",
    bbrShtDesc: "041903",
  },
  {
    bbrCode: 9479,
    bbrBnkCode: 41,
    bbrBranchName: "BECHEM",
    bbrShtDesc: "041702",
  },
  {
    bbrCode: 9480,
    bbrBnkCode: 41,
    bbrBranchName: "BEKWAI",
    bbrShtDesc: "041609",
  },
  {
    bbrCode: 9481,
    bbrBnkCode: 41,
    bbrBranchName: "BEREKUM",
    bbrShtDesc: "041703",
  },
  {
    bbrCode: 9482,
    bbrBnkCode: 41,
    bbrBranchName: "BIMBILA",
    bbrShtDesc: "041806",
  },
  {
    bbrCode: 9483,
    bbrBnkCode: 41,
    bbrBranchName: "BOGOSO",
    bbrShtDesc: "041414",
  },
  {
    bbrCode: 9613,
    bbrBnkCode: 41,
    bbrBranchName: "UNIVERSITY OF CAPE COAST",
    bbrShtDesc: "041071",
  },
  {
    bbrCode: 9614,
    bbrBnkCode: 41,
    bbrBranchName: "WA",
    bbrShtDesc: "041072",
  },
  {
    bbrCode: 9615,
    bbrBnkCode: 41,
    bbrBranchName: "WALEWALE",
    bbrShtDesc: "041073",
  },
  {
    bbrCode: 9616,
    bbrBnkCode: 41,
    bbrBranchName: "WENCHI",
    bbrShtDesc: "041074",
  },
  {
    bbrCode: 9617,
    bbrBnkCode: 41,
    bbrBranchName: "WINNEBA",
    bbrShtDesc: "041075",
  },
  {
    bbrCode: 9618,
    bbrBnkCode: 41,
    bbrBranchName: "YEJI",
    bbrShtDesc: "041076",
  },
  {
    bbrCode: 9619,
    bbrBnkCode: 41,
    bbrBranchName: "YENDI",
    bbrShtDesc: "041077",
  },
  {
    bbrCode: 9620,
    bbrBnkCode: 41,
    bbrBranchName: "ABEKA LAPAZ",
    bbrShtDesc: "041150",
  },
  {
    bbrCode: 9621,
    bbrBnkCode: 41,
    bbrBranchName: "ADJIRIGANO",
    bbrShtDesc: "041164",
  },
  {
    bbrCode: 9622,
    bbrBnkCode: 41,
    bbrBranchName: "CENTRAL PROCESSING CENT",
    bbrShtDesc: "041555",
  },
  {
    bbrCode: 9623,
    bbrBnkCode: 41,
    bbrBranchName: "HAATSO",
    bbrShtDesc: "041162",
  },
  {
    bbrCode: 9624,
    bbrBnkCode: 41,
    bbrBranchName: "SABOBA",
    bbrShtDesc: "041810",
  },
  {
    bbrCode: 9625,
    bbrBnkCode: 41,
    bbrBranchName: "WEIJA",
    bbrShtDesc: "041165",
  },
  {
    bbrCode: 9885,
    bbrBnkCode: 41,
    bbrBranchName: "ASHAIMAN MANDELA PARK",
    bbrShtDesc: "041178",
  },
  {
    bbrCode: 9925,
    bbrBnkCode: 41,
    bbrBranchName: "ACHIMOTA",
    bbrShtDesc: "041174",
  },
  {
    bbrCode: 9927,
    bbrBnkCode: 41,
    bbrBranchName: "CORONATION PARK",
    bbrShtDesc: "041317",
  },
  {
    bbrCode: 9934,
    bbrBnkCode: 41,
    bbrBranchName: "AIRPORT CITY",
    bbrShtDesc: "041168",
  },
  {
    bbrCode: 9938,
    bbrBnkCode: 41,
    bbrBranchName: "OKAISHIE",
    bbrShtDesc: "041171",
  },
  {
    bbrCode: 9942,
    bbrBnkCode: 41,
    bbrBranchName: "OXFORD STREET",
    bbrShtDesc: "041179",
  },
  {
    bbrCode: 9889,
    bbrBnkCode: 41,
    bbrBranchName: "A&C MALL",
    bbrShtDesc: "041175",
  },
  {
    bbrCode: 9892,
    bbrBnkCode: 41,
    bbrBranchName: "ABBOSEY OKAI",
    bbrShtDesc: "041177",
  },
  {
    bbrCode: 9929,
    bbrBnkCode: 41,
    bbrBranchName: "MADINA ZONGO JUNCTION",
    bbrShtDesc: "041182",
  },
  {
    bbrCode: 9931,
    bbrBnkCode: 41,
    bbrBranchName: "TEMA COMM. 2",
    bbrShtDesc: "041210",
  },
  {
    bbrCode: 9886,
    bbrBnkCode: 41,
    bbrBranchName: "CIRCLE",
    bbrShtDesc: "041127",
  },
  {
    bbrCode: 9896,
    bbrBnkCode: 41,
    bbrBranchName: "ADUM",
    bbrShtDesc: "041521",
  },
  {
    bbrCode: 9898,
    bbrBnkCode: 41,
    bbrBranchName: "ADABRAKA",
    bbrShtDesc: "041167",
  },
  {
    bbrCode: 9945,
    bbrBnkCode: 41,
    bbrBranchName: "SUAME",
    bbrShtDesc: "041630",
  },
  {
    bbrCode: 9933,
    bbrBnkCode: 41,
    bbrBranchName: "CENTRAL UNIVERSITY",
    bbrShtDesc: "041172",
  },
  {
    bbrCode: 9941,
    bbrBnkCode: 41,
    bbrBranchName: "ABOABO",
    bbrShtDesc: "041812",
  },
  {
    bbrCode: 9484,
    bbrBnkCode: 41,
    bbrBranchName: "BOLE",
    bbrShtDesc: "041004",
  },
  {
    bbrCode: 9485,
    bbrBnkCode: 41,
    bbrBranchName: "BOLGATANGA",
    bbrShtDesc: "041901",
  },
  {
    bbrCode: 9486,
    bbrBnkCode: 41,
    bbrBranchName: "BORTIANOR",
    bbrShtDesc: "0411115",
  },
  {
    bbrCode: 9487,
    bbrBnkCode: 41,
    bbrBranchName: "BOUNDARY ROAD",
    bbrShtDesc: "041116",
  },
  {
    bbrCode: 9488,
    bbrBnkCode: 41,
    bbrBranchName: "BREMAN ASIKUMA",
    bbrShtDesc: "041306",
  },
  {
    bbrCode: 9489,
    bbrBnkCode: 41,
    bbrBranchName: "BURMA CAMP",
    bbrShtDesc: "041102",
  },
  {
    bbrCode: 9490,
    bbrBnkCode: 41,
    bbrBranchName: "CAPE COAST MAIN",
    bbrShtDesc: "041301",
  },
  {
    bbrCode: 9491,
    bbrBnkCode: 41,
    bbrBranchName: "CLEARING",
    bbrShtDesc: "041125",
  },
  {
    bbrCode: 9492,
    bbrBnkCode: 41,
    bbrBranchName: "DADIESO",
    bbrShtDesc: "041413",
  },
  {
    bbrCode: 9493,
    bbrBnkCode: 41,
    bbrBranchName: "DAMBAI",
    bbrShtDesc: "041527",
  },
  {
    bbrCode: 9494,
    bbrBnkCode: 41,
    bbrBranchName: "DAMONGO",
    bbrShtDesc: "041803",
  },
  {
    bbrCode: 9495,
    bbrBnkCode: 41,
    bbrBranchName: "DANSOMAN",
    bbrShtDesc: "041146",
  },
  {
    bbrCode: 9496,
    bbrBnkCode: 41,
    bbrBranchName: "DERBY AVENUE",
    bbrShtDesc: "041115",
  },
  {
    bbrCode: 9497,
    bbrBnkCode: 41,
    bbrBranchName: "DIAMOND HOUSE",
    bbrShtDesc: "0411116",
  },
  {
    bbrCode: 9498,
    bbrBnkCode: 41,
    bbrBranchName: "DODOWA",
    bbrShtDesc: "0411117",
  },
  {
    bbrCode: 9499,
    bbrBnkCode: 41,
    bbrBranchName: "DOME (KWABENYA)",
    bbrShtDesc: "041132",
  },
  {
    bbrCode: 9500,
    bbrBnkCode: 41,
    bbrBranchName: "DONKOKROM",
    bbrShtDesc: "041311",
  },
  {
    bbrCode: 9501,
    bbrBnkCode: 41,
    bbrBranchName: "DONKORKROM",
    bbrShtDesc: "041221",
  },
  {
    bbrCode: 9502,
    bbrBnkCode: 41,
    bbrBranchName: "DORMAA AHENKRO",
    bbrShtDesc: "041704",
  },
  {
    bbrCode: 9503,
    bbrBnkCode: 41,
    bbrBranchName: "DUAYAW-NKWANTA",
    bbrShtDesc: "041705",
  },
  {
    bbrCode: 9504,
    bbrBnkCode: 41,
    bbrBranchName: "DUNKWA-ON- OFFIN",
    bbrShtDesc: "041410",
  },
  {
    bbrCode: 9505,
    bbrBnkCode: 41,
    bbrBranchName: "DZODZE",
    bbrShtDesc: "041503",
  },
  {
    bbrCode: 9506,
    bbrBnkCode: 41,
    bbrBranchName: "EFFIDUASE - ASHANTI",
    bbrShtDesc: "041610",
  },
  {
    bbrCode: 9507,
    bbrBnkCode: 41,
    bbrBranchName: "EJISU",
    bbrShtDesc: "041612",
  },
  {
    bbrCode: 9508,
    bbrBnkCode: 41,
    bbrBranchName: "EJURA",
    bbrShtDesc: "041614",
  },
  {
    bbrCode: 9509,
    bbrBnkCode: 41,
    bbrBranchName: "ELMINA",
    bbrShtDesc: "041315",
  },
  {
    bbrCode: 9510,
    bbrBnkCode: 41,
    bbrBranchName: "ELUBO",
    bbrShtDesc: "041415",
  },
  {
    bbrCode: 9511,
    bbrBnkCode: 41,
    bbrBranchName: "ENCHI",
    bbrShtDesc: "041407",
  },
  {
    bbrCode: 9512,
    bbrBnkCode: 41,
    bbrBranchName: "GLOBAL TRANSFER SERVICES",
    bbrShtDesc: "041129",
  },
  {
    bbrCode: 9513,
    bbrBnkCode: 41,
    bbrBranchName: "GOASO",
    bbrShtDesc: "041707",
  },
  {
    bbrCode: 9514,
    bbrBnkCode: 41,
    bbrBranchName: "HALF-ASSINI",
    bbrShtDesc: "041409",
  },
  {
    bbrCode: 9515,
    bbrBnkCode: 41,
    bbrBranchName: "HEAD OFFICE",
    bbrShtDesc: "041199",
  },
  {
    bbrCode: 9516,
    bbrBnkCode: 41,
    bbrBranchName: "HIGH STREET",
    bbrShtDesc: "041101",
  },
  {
    bbrCode: 9517,
    bbrBnkCode: 41,
    bbrBranchName: "HO MAIN",
    bbrShtDesc: "041501",
  },
  {
    bbrCode: 9518,
    bbrBnkCode: 41,
    bbrBranchName: "HO MARKET",
    bbrShtDesc: "041526",
  },
  {
    bbrCode: 9519,
    bbrBnkCode: 41,
    bbrBranchName: "HO POLYTECHNIC",
    bbrShtDesc: "041530",
  },
  {
    bbrCode: 9520,
    bbrBnkCode: 41,
    bbrBranchName: "HOHOE",
    bbrShtDesc: "041511",
  },
  {
    bbrCode: 9521,
    bbrBnkCode: 41,
    bbrBranchName: "HWIDIEM",
    bbrShtDesc: "041706",
  },
  {
    bbrCode: 9522,
    bbrBnkCode: 41,
    bbrBranchName: "INTERNATIONAL TRADE FINANCE",
    bbrShtDesc: "041128",
  },
  {
    bbrCode: 9523,
    bbrBnkCode: 41,
    bbrBranchName: "JANSIKAN",
    bbrShtDesc: "041102",
  },
  {
    bbrCode: 9524,
    bbrBnkCode: 41,
    bbrBranchName: "DROBO/JAPEKROM",
    bbrShtDesc: "041710",
  },
  {
    bbrCode: 9525,
    bbrBnkCode: 41,
    bbrBranchName: "JASIKAN",
    bbrShtDesc: "041504",
  },
  {
    bbrCode: 9526,
    bbrBnkCode: 41,
    bbrBranchName: "JUASO",
    bbrShtDesc: "041617",
  },
  {
    bbrCode: 9527,
    bbrBnkCode: 41,
    bbrBranchName: "JUBILEE HOUSE",
    bbrShtDesc: "041621",
  },
  {
    bbrCode: 9528,
    bbrBnkCode: 41,
    bbrBranchName: "KADE",
    bbrShtDesc: "041203",
  },
  {
    bbrCode: 9529,
    bbrBnkCode: 41,
    bbrBranchName: "KADJEBI",
    bbrShtDesc: "041506",
  },
  {
    bbrCode: 9530,
    bbrBnkCode: 41,
    bbrBranchName: "KANESHIE INDUSTRIAL AREA",
    bbrShtDesc: "041120",
  },
  {
    bbrCode: 9531,
    bbrBnkCode: 41,
    bbrBranchName: "KANESHIE MARKET",
    bbrShtDesc: "041112",
  },
  {
    bbrCode: 9532,
    bbrBnkCode: 41,
    bbrBranchName: "KASOA",
    bbrShtDesc: "041131",
  },
  {
    bbrCode: 9533,
    bbrBnkCode: 41,
    bbrBranchName: "KASOA MAIN",
    bbrShtDesc: "041134",
  },
  {
    bbrCode: 9534,
    bbrBnkCode: 41,
    bbrBranchName: "KEJETIA",
    bbrShtDesc: "041604",
  },
  {
    bbrCode: 9535,
    bbrBnkCode: 41,
    bbrBranchName: "KETA",
    bbrShtDesc: "041507",
  },
  {
    bbrCode: 9536,
    bbrBnkCode: 41,
    bbrBranchName: "KETE-KRACHI",
    bbrShtDesc: "041508",
  },
  {
    bbrCode: 9537,
    bbrBnkCode: 41,
    bbrBranchName: "KIBI",
    bbrShtDesc: "041217",
  },
  {
    bbrCode: 9538,
    bbrBnkCode: 41,
    bbrBranchName: "KINTAMPO",
    bbrShtDesc: "041712",
  },
  {
    bbrCode: 9539,
    bbrBnkCode: 41,
    bbrBranchName: "KISSIEMAN",
    bbrShtDesc: "041136",
  },
  {
    bbrCode: 9540,
    bbrBnkCode: 41,
    bbrBranchName: "KNUST KUMASI",
    bbrShtDesc: "041603",
  },
  {
    bbrCode: 9541,
    bbrBnkCode: 41,
    bbrBranchName: "KOFORIDUA",
    bbrShtDesc: "041201",
  },
  {
    bbrCode: 9542,
    bbrBnkCode: 41,
    bbrBranchName: "KONONGO",
    bbrShtDesc: "041605",
  },
  {
    bbrCode: 9543,
    bbrBnkCode: 41,
    bbrBranchName: "KORLE BU",
    bbrShtDesc: "041001",
  },
  {
    bbrCode: 9544,
    bbrBnkCode: 41,
    bbrBranchName: "KPANDO",
    bbrShtDesc: "041509",
  },
  {
    bbrCode: 9545,
    bbrBnkCode: 41,
    bbrBranchName: "KUMASI MAIN",
    bbrShtDesc: "041003",
  },
  {
    bbrCode: 9546,
    bbrBnkCode: 41,
    bbrBranchName: "KWAME NKRUMAH CIRCLE",
    bbrShtDesc: "041904",
  },
  {
    bbrCode: 9547,
    bbrBnkCode: 41,
    bbrBranchName: "LABONE",
    bbrShtDesc: "041005",
  },
  {
    bbrCode: 9548,
    bbrBnkCode: 41,
    bbrBranchName: "LAWRA",
    bbrShtDesc: "041006",
  },
  {
    bbrCode: 9549,
    bbrBnkCode: 41,
    bbrBranchName: "LEGON",
    bbrShtDesc: "041007",
  },
  {
    bbrCode: 9550,
    bbrBnkCode: 41,
    bbrBranchName: "LIBERTY HOUSE",
    bbrShtDesc: "041008",
  },
  {
    bbrCode: 9551,
    bbrBnkCode: 41,
    bbrBranchName: "MADINA",
    bbrShtDesc: "041009",
  },
  {
    bbrCode: 9552,
    bbrBnkCode: 41,
    bbrBranchName: "MAKOLA",
    bbrShtDesc: "041010",
  },
  {
    bbrCode: 9553,
    bbrBnkCode: 41,
    bbrBranchName: "MAMPONG-AKWAPIM",
    bbrShtDesc: "041011",
  },
  {
    bbrCode: 9554,
    bbrBnkCode: 41,
    bbrBranchName: "MAMPONG-ASHANTI",
    bbrShtDesc: "041012",
  },
  {
    bbrCode: 9555,
    bbrBnkCode: 41,
    bbrBranchName: "MANKESSIM",
    bbrShtDesc: "041013",
  },
  {
    bbrCode: 9556,
    bbrBnkCode: 41,
    bbrBranchName: "MARTEY TSURU",
    bbrShtDesc: "041014",
  },
  {
    bbrCode: 9557,
    bbrBnkCode: 41,
    bbrBranchName: "MERIDIAN HOUSE",
    bbrShtDesc: "041015",
  },
  {
    bbrCode: 9558,
    bbrBnkCode: 41,
    bbrBranchName: "MIM",
    bbrShtDesc: "041016",
  },
  {
    bbrCode: 9559,
    bbrBnkCode: 41,
    bbrBranchName: "MINISTRIES",
    bbrShtDesc: "041017",
  },
  {
    bbrCode: 9560,
    bbrBnkCode: 41,
    bbrBranchName: "MPRAESO",
    bbrShtDesc: "041018",
  },
  {
    bbrCode: 9561,
    bbrBnkCode: 41,
    bbrBranchName: "NAVRONGO",
    bbrShtDesc: "041019",
  },
  {
    bbrCode: 9562,
    bbrBnkCode: 41,
    bbrBranchName: "NEW EDUBIASE",
    bbrShtDesc: "041020",
  },
  {
    bbrCode: 9563,
    bbrBnkCode: 41,
    bbrBranchName: "NEW OFFINSO",
    bbrShtDesc: "041021",
  },
  {
    bbrCode: 9564,
    bbrBnkCode: 41,
    bbrBranchName: "NEW TAFO",
    bbrShtDesc: "041022",
  },
  {
    bbrCode: 9565,
    bbrBnkCode: 41,
    bbrBranchName: "NIMA",
    bbrShtDesc: "041023",
  },
  {
    bbrCode: 9566,
    bbrBnkCode: 41,
    bbrBranchName: "NKAWIE",
    bbrShtDesc: "041024",
  },
  {
    bbrCode: 9567,
    bbrBnkCode: 41,
    bbrBranchName: "NKAWKAW",
    bbrShtDesc: "041025",
  },
  {
    bbrCode: 9568,
    bbrBnkCode: 41,
    bbrBranchName: "NKORANZA",
    bbrShtDesc: "041026",
  },
  {
    bbrCode: 9569,
    bbrBnkCode: 41,
    bbrBranchName: "NKWANTA",
    bbrShtDesc: "041027",
  },
  {
    bbrCode: 9570,
    bbrBnkCode: 41,
    bbrBranchName: "NSAWAM",
    bbrShtDesc: "041028",
  },
  {
    bbrCode: 9571,
    bbrBnkCode: 41,
    bbrBranchName: "NUNGUA",
    bbrShtDesc: "041029",
  },
  {
    bbrCode: 9572,
    bbrBnkCode: 41,
    bbrBranchName: "OBUASI",
    bbrShtDesc: "041030",
  },
  {
    bbrCode: 9573,
    bbrBnkCode: 41,
    bbrBranchName: "OSU",
    bbrShtDesc: "041031",
  },
  {
    bbrCode: 9574,
    bbrBnkCode: 41,
    bbrBranchName: "PEKI",
    bbrShtDesc: "041032",
  },
  {
    bbrCode: 9575,
    bbrBnkCode: 41,
    bbrBranchName: "PRESTEA",
    bbrShtDesc: "041033",
  },
  {
    bbrCode: 9576,
    bbrBnkCode: 41,
    bbrBranchName: "REPUBLIC HOUSE",
    bbrShtDesc: "041034",
  },
  {
    bbrCode: 9577,
    bbrBnkCode: 41,
    bbrBranchName: "RING ROAD WEST",
    bbrShtDesc: "041035",
  },
  {
    bbrCode: 9578,
    bbrBnkCode: 41,
    bbrBranchName: "SAFE BOND (JUBILEE) TEMA",
    bbrShtDesc: "041036",
  },
  {
    bbrCode: 9579,
    bbrBnkCode: 41,
    bbrBranchName: "SALAGA",
    bbrShtDesc: "041037",
  },
  {
    bbrCode: 9580,
    bbrBnkCode: 41,
    bbrBranchName: "SALTPOND",
    bbrShtDesc: "041038",
  },
  {
    bbrCode: 9581,
    bbrBnkCode: 41,
    bbrBranchName: "SAMPA",
    bbrShtDesc: "041039",
  },
  {
    bbrCode: 9582,
    bbrBnkCode: 41,
    bbrBranchName: "SAMREBOI",
    bbrShtDesc: "041040",
  },
  {
    bbrCode: 9583,
    bbrBnkCode: 41,
    bbrBranchName: "SANKORE",
    bbrShtDesc: "041041",
  },
  {
    bbrCode: 9584,
    bbrBnkCode: 41,
    bbrBranchName: "SEFWI WIAWSO",
    bbrShtDesc: "041042",
  },
  {
    bbrCode: 9585,
    bbrBnkCode: 41,
    bbrBranchName: "SEKONDI",
    bbrShtDesc: "041043",
  },
  {
    bbrCode: 9586,
    bbrBnkCode: 41,
    bbrBranchName: "SOGAKOPE",
    bbrShtDesc: "041044",
  },
  {
    bbrCode: 9587,
    bbrBnkCode: 41,
    bbrBranchName: "SOMANYA",
    bbrShtDesc: "041045",
  },
  {
    bbrCode: 9588,
    bbrBnkCode: 41,
    bbrBranchName: "SPINTEX ROAD",
    bbrShtDesc: "041046",
  },
  {
    bbrCode: 9589,
    bbrBnkCode: 41,
    bbrBranchName: "SUHUM",
    bbrShtDesc: "041047",
  },
  {
    bbrCode: 9590,
    bbrBnkCode: 41,
    bbrBranchName: "SUNYANI",
    bbrShtDesc: "041048",
  },
  {
    bbrCode: 9591,
    bbrBnkCode: 41,
    bbrBranchName: "SUNYANI MARKET",
    bbrShtDesc: "041049",
  },
  {
    bbrCode: 9592,
    bbrBnkCode: 41,
    bbrBranchName: "TAKORADI HARBOUR",
    bbrShtDesc: "041050",
  },
  {
    bbrCode: 9593,
    bbrBnkCode: 41,
    bbrBranchName: "TAKORADI MAIN",
    bbrShtDesc: "041051",
  },
  {
    bbrCode: 9594,
    bbrBnkCode: 41,
    bbrBranchName: "TAKORADI MARKET CIRCLE",
    bbrShtDesc: "041052",
  },
  {
    bbrCode: 9595,
    bbrBnkCode: 41,
    bbrBranchName: "TAMALE HOSPITAL ROAD",
    bbrShtDesc: "041053",
  },
  {
    bbrCode: 9596,
    bbrBnkCode: 41,
    bbrBranchName: "TAMALE MAIN",
    bbrShtDesc: "041054",
  },
  {
    bbrCode: 9597,
    bbrBnkCode: 41,
    bbrBranchName: "TAMALE MARKET",
    bbrShtDesc: "041055",
  },
  {
    bbrCode: 9598,
    bbrBnkCode: 41,
    bbrBranchName: "TANTRA HILL",
    bbrShtDesc: "041056",
  },
  {
    bbrCode: 9599,
    bbrBnkCode: 41,
    bbrBranchName: "TARKWA",
    bbrShtDesc: "041057",
  },
  {
    bbrCode: 9600,
    bbrBnkCode: 41,
    bbrBranchName: "TECH JUNCTION, KUMASI",
    bbrShtDesc: "041058",
  },
  {
    bbrCode: 9601,
    bbrBnkCode: 41,
    bbrBranchName: "TECHIMAN MAIN",
    bbrShtDesc: "041059",
  },
  {
    bbrCode: 9602,
    bbrBnkCode: 41,
    bbrBranchName: "TECHIMAN MARKET",
    bbrShtDesc: "041060",
  },
  {
    bbrCode: 9603,
    bbrBnkCode: 41,
    bbrBranchName: "TEMA FISHING HARBOUR",
    bbrShtDesc: "041061",
  },
  {
    bbrCode: 9604,
    bbrBnkCode: 41,
    bbrBranchName: "TEMA INDUSTRIAL AREA",
    bbrShtDesc: "041062",
  },
  {
    bbrCode: 9605,
    bbrBnkCode: 41,
    bbrBranchName: "TEMA MAIN",
    bbrShtDesc: "041063",
  },
  {
    bbrCode: 9606,
    bbrBnkCode: 41,
    bbrBranchName: "TEMA MARKET",
    bbrShtDesc: "041064",
  },
  {
    bbrCode: 9607,
    bbrBnkCode: 41,
    bbrBranchName: "TEPA",
    bbrShtDesc: "041065",
  },
  {
    bbrCode: 9608,
    bbrBnkCode: 41,
    bbrBranchName: "TESANO",
    bbrShtDesc: "041066",
  },
  {
    bbrCode: 9609,
    bbrBnkCode: 41,
    bbrBranchName: "TETTEH QUARSHIE CIRCLE",
    bbrShtDesc: "041067",
  },
  {
    bbrCode: 9610,
    bbrBnkCode: 41,
    bbrBranchName: "TRADE FAIR SITE",
    bbrShtDesc: "041068",
  },
  {
    bbrCode: 9611,
    bbrBnkCode: 41,
    bbrBranchName: "TUMU",
    bbrShtDesc: "041069",
  },
  {
    bbrCode: 9612,
    bbrBnkCode: 41,
    bbrBranchName: "TWIFO PRASO",
    bbrShtDesc: "041070",
  },
  {
    bbrCode: 9963,
    bbrBnkCode: 41,
    bbrBranchName: "KANTAMATO",
    bbrShtDesc: "041166",
  },
  {
    bbrCode: 9965,
    bbrBnkCode: 41,
    bbrBranchName: "MIOTSU",
    bbrShtDesc: "041280",
  },
  {
    bbrCode: 9970,
    bbrBnkCode: 41,
    bbrBranchName: "TETTEH QUARSHIE CIRCLE",
    bbrShtDesc: "041126",
  },
  {
    bbrCode: 9975,
    bbrBnkCode: 41,
    bbrBranchName: "AGBOBLOSHIE",
    bbrShtDesc: "041180",
  },
  {
    bbrCode: 10005,
    bbrBnkCode: 41,
    bbrBranchName: "AKUMADAN",
    bbrShtDesc: "041199",
  },
  {
    bbrCode: 10051,
    bbrBnkCode: 41,
    bbrBranchName: "DZORWULU",
    bbrShtDesc: "041199",
  },
  {
    bbrCode: 10325,
    bbrBnkCode: 41,
    bbrBranchName: "SOFOLINE",
    bbrShtDesc: "041631",
  },
  {
    bbrCode: 10375,
    bbrBnkCode: 41,
    bbrBranchName: "KATAMANTO",
    bbrShtDesc: "041632",
  },
  {
    bbrCode: 10376,
    bbrBnkCode: 41,
    bbrBranchName: "ASANTE AKYEM",
    bbrShtDesc: "041633",
  },
  {
    bbrCode: 10451,
    bbrBnkCode: 41,
    bbrBranchName: "TEMA COMM. 25",
    bbrShtDesc: "041223",
  },
];

export default GCBBankBranchesData;
