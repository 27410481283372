const BankBranchesData = [
  {
    bbrCode: 10402,
    bbrBnkCode: 1031,
    bbrBranchName: "EJISU",
    bbrShtDesc: "085011",
  },
  {
    bbrCode: 10403,
    bbrBnkCode: 1031,
    bbrBranchName: "ATONSU",
    bbrShtDesc: "085012",
  },
  {
    bbrCode: 10404,
    bbrBnkCode: 1031,
    bbrBranchName: "DANYAME",
    bbrShtDesc: "085013",
  },
  {
    bbrCode: 10405,
    bbrBnkCode: 1031,
    bbrBranchName: "BONWIRE",
    bbrShtDesc: "085014",
  },
  {
    bbrCode: 10406,
    bbrBnkCode: 1031,
    bbrBranchName: "BANTAMA",
    bbrShtDesc: "085015",
  },
  {
    bbrCode: 10407,
    bbrBnkCode: 1031,
    bbrBranchName: "KEJETIA",
    bbrShtDesc: "085016",
  },
];

export default BankBranchesData;
