import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
// import "./sb-admin-2.min.css";
import "../../static/vendor/fontawesome-free/css/all.min.css";
// import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
import logo from "../../static/img/starlife-logo.png";
import DataTable from "react-data-table-component";
import Export from "react-data-table-component";
import { Modal, Button, InputGroup } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Viewer from "react-viewer";
import pdfmodal from "./pdf_modal";
import FlashMessage from "react-flash-message";
import AuthContext from "../../store/auth-context";
import proposalData from "./proposalsdata";
import atwima from "../../akrb_logo22.png";
import amenfiman from "../../Amenfi-Man-logo2.png";
import gcb from "../../gcb-logo.png";
import access from "../../access-logo-main.png";
import okomfo from "../../Logo-okomfo-new.png";
import ahantaman from "../../ahantaman-logo3-1.png";
// import { env } from "process";
import { titleCase } from "../func/utils";
import Form from "react-bootstrap/Form";
import * as XLSX from "xlsx";

function AdminReportSuccessfulCentral() {
  // componentDidMount() {
  //   const script = document.createElement("script");
  //   script.src = "static/vendor/datatables/jquery.dataTables.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }

  const authCtx = useContext(AuthContext);

  const [pending, setPending] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [proposals, setProposals] = React.useState([]);
  const [url, setUrl] = React.useState([]);
  const [proposalurl, setProposalurl] = React.useState([]);
  const [showModal, setShow] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [showRejectMessage, setShowRejectMessage] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [policyDetails, setPolicyDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [policyNo, setPolicyNo] = React.useState(null);
  const [pDetails, setPDetails] = React.useState([]);
  const [proposerId, setProposerId] = React.useState(null);
  const [yesChecked, setYesChecked] = React.useState(null);
  const [noChecked, setNoChecked] = React.useState(null);
  const [dso, setDSO] = React.useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  const bank = authCtx.bank;
  const branch = authCtx.bbrCode;

  useEffect(() => {
    const branchDSO = {
      Bank: bank,
      Bank_Branch: branch,
    };

    axios
      .post("/api/merchantportal/branch/dso", branchDSO)
      .then((response) => {
        setDSO(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  let inputStyle = {
    backgroundColor: "#763984",
    color: "white",
  };

  let proposal_logo = "";

  if (bank === "ATWIMA KWANWOMA RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#009900",
      color: "#fff",
    };
    proposal_logo = atwima;
  } else if (bank === "AMENFIMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = amenfiman;
  } else if (bank === "GCB BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ffc52a",
      color: "#4a4a4a",
    };
    proposal_logo = gcb;
  } else if (bank === "ACCESS BANK BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ee7e01",
      color: "#fff",
    };
    proposal_logo = access;
  } else if (bank === "OKOMFO ANOKYE RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = okomfo;
  } else if (bank === "AHANTAMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#A80404",
      color: "#fff",
    };
    proposal_logo = ahantaman;
  }

  const handleSearchAction = (e) => {
    e.preventDefault();

    setPending(true);

    const searchDate = {
      Bank_Branch: bank,
      //   DSO: e.target.dso.value,
      date_from: e.target.date_from.value,
      date_to: e.target.date_to.value,
    };

    axios
      .post("/api/merchantportal/successful/onboarding", searchDate)
      .then((response) => {
        let i;
        let pr = [];
        for (i = 0; i < response.data.length; i++) {
          let other_names = "";
          if (response.data[i].data.otherNames !== null) {
            other_names = response.data[i].data.otherNames;
          }
          const p = {
            firstname: response.data[i].data.firstName,
            lastname: response.data[i].data.lastName,
            othername: other_names,
            phone: response.data[i].data.phoneNumber,
            product: response.data[i].data.product.split("-")[2],
            branch: response.data[i].data.bankBranch.split("-")[1],
            dso: response.data[i].data.agentName,
            date: moment(response.data[i].date["$date"]).format("DD-MM-YYYY"),
            status: response.data[i].status,
          };
          pr.push(p);
        }
        setProposals(pr);
        // setTitle(
        //   "Bancassurance Proposals between " +
        //     moment(searchDate.date_from).format("DD/MM/YYYY") +
        //     " and " +
        //     moment(searchDate.date_to).format("DD/MM/YYYY")
        // );
        setTitle(
          `Successful Onboarding between ` +
            moment(searchDate.date_from).format("DD/MM/YYYY") +
            ` and ` +
            moment(searchDate.date_to).format("DD/MM/YYYY")
        );
        setPending(false);
      })
      .catch((error) => {
        setProposals([]);
        setPending(false);
      });
  };

  const exportFile = () => {
    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(proposals);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "report_export.xlsx");
  };

  const handleViewAction = (e, data, id) => {
    e.preventDefault();
    setLoading(true);

    setProposerId(id);
    setPDetails(data);
    if (data.dataProtection === "Yes") {
      setYesChecked(true);
    } else {
      setNoChecked(true);
    }
    setLoading(false);
    handleShow();
  };

  const handleApproveAction = (id) => {
    setLoading(true);

    handleClose();
    const proposal = {
      Id: id,
      User: authCtx.userName,
      Status: "Approved",
      Reason: null,
    };

    axios
      .post("/api/merchantportal/onboarding/approval", proposal)
      .then((response) => {
        setProposals(
          proposals.filter(function (p) {
            return p._id !== id;
          })
        );
        // setPending(true);
        setShowMessage(true);
        setLoading(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleRejectAction = (id, reason) => {
    setLoading(true);
    // setShowMessage(false);
    // e.preventDefault();

    handleClose();
    const proposal = {
      Id: id,
      User: authCtx.userName,
      Status: "Rejected",
      Reason: reason,
    };

    axios
      .post("/api/merchantportal/onboarding/approval", proposal)
      .then((response) => {
        setProposals(
          proposals.filter(function (p) {
            return p._id !== id;
          })
        );
        // setPending(true);
        setShowRejectMessage(true);
        setLoading(false);
        setTimeout(() => {
          setShowRejectMessage(false);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      // sortable: true,
      // reorder: true,
      width: "100px",
      // style: {
      //   backgroundColor: "#763984",
      //   color: "white",
      // },
    },
    {
      name: "Name",
      selector: (row) =>
        row.firstname + " " + row.othername + " " + row.lastname,
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    // {
    //   name: "First Name",
    //   selector: (row) => row.FirstName,
    //   // sortable: true,
    //   reorder: true,
    //   width: "120px",
    // },
    // {
    //   name: "Last Name",
    //   selector: (row) => row.LastName,
    //   // sortable: true,
    //   reorder: true,
    //   width: "120px",
    // },
    {
      name: "Phone Number",
      selector: (row) => row.phone,
      // sortable: true,
      // reorder: true,
      width: "150px",
    },
    {
      name: "Product",
      selector: (row) => row.product,
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Transaction Branch",
      selector: (row) => row.branch,
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Direct Sales Officer",
      selector: (row) => row.dso,
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      // reorder: true,
      width: "100px",
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          {/* // <!-- Page Wrapper --> */}
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
              id="accordionSidebar"
            >
              {/* <!-- Sidebar - Brand --> */}
              <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/bancassurance/portal/home"
              >
                <div
                  className="sidebar-brand-icon"
                  style={{ backgroundColor: "white", marginLeft: "50px" }}
                >
                  <img src={logo} height="70" />
                </div>
              </a>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider my-0"></hr>
              {/* <!-- Nav Item - Dashboard --> */}
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/home">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span>Merchant Dashboard</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/report">
                  <i className="fa fa-file"></i>
                  <span>Reports</span>
                </a>
              </li>
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/change-password"
                >
                  <i className="fa fa-file"></i>
                  <span>Change Password</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fas fa-sign-out-alt"></i>
                  <span onClick={logoutHandler}>Logout</span>
                </a>
              </li>
            </ul>
            {/* <!-- End of Sidebar --> */}

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              {/* <!-- Main Content --> */}
              <div id="content">
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* <!-- Sidebar Toggle (Topbar) --> */}
                  <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {% if current_user.is_authenticated %} */}
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                          {authCtx.userName}
                          {/* <font color="#763984">DELIJAH</font> */}
                        </span>
                        {/* {% endif %} */}
                        <i className="fas fa-user"></i>
                      </a>
                      {/* <!-- Dropdown - User Information --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logoutModal"
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
                  {/* <!-- Page Heading --> */}
                  {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
                  {/* <!-- DataTales Example --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h3 className="m-0 font-weight-bold text-primary">
                        Successful Onboardings
                      </h3>
                      <div>
                        <form method="POST" onSubmit={handleSearchAction}>
                          <input
                            type="date"
                            id="date_from"
                            name="date_from"
                            required
                            // value=""
                          />{" "}
                          -to-
                          <input
                            type="date"
                            id="date_to"
                            name="date_to"
                            required
                            // value=""
                          />
                          &nbsp;
                          <button className="btn btn-info" type="submit">
                            <i className="fas fa-search"></i> Search by Date{" "}
                          </button>
                        </form>
                      </div>
                      <br />
                    </div>
                    <div className="card-body">
                      {showMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-success">
                              <strong>Proposal approved successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      {showRejectMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-danger">
                              <strong>Proposal rejected successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      <div className="table-responsive">
                        {/* <button
                      id="export"
                      name="export"
                      value="export"
                      data-toggle="modal"
                      data-target="#ExportModal"
                      className="btn btn-primary"
                      type="submit"
                      style={{ float: "right", height: "31px" }}
                    >
                      <i className="fas fa-download"></i> Export
                    </button> */}

                        {proposals.length > 0 ? (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={proposals}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        ) : (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={[]}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of Main Content --> */}

              {/* PDF Modal */}
              <Modal
                fullscreen={true}
                // size="xxl"
                show={showModal}
                onHide={handleClose}
                // fullscreen={"xl-down"}
              >
                <Modal.Header closeButton style={inputStyle}>
                  <Modal.Title>Proposal and Mandate Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div style={{ width: "50%", float: "left" }}>
                      <font size="1">
                        <table
                          style={{
                            borderWidth: "2px",
                            borderColor: "#aaaaaa",
                            borderStyle: "solid",
                            width: "90%",
                          }}
                        >
                          <tr>
                            <td colSpan={"3"} style={{ color: "#763984" }}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td align="center">
                                    <img src={proposal_logo} alt="logo" />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width={"50%"} valign="top">
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td colSpan={"2"} style={inputStyle}>
                                    PERSONAL DETAILS
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"30%"}>
                                    Surname:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .surname}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Middle Name(s):
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .middle_name}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    First Name:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .first_name}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Date of Birth:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .dob}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Gender:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .gender}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Mobile:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .mobile}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                {/* <tr>
                              <td align="left" width={"40%"}>
                                Email:
                              </td>
                              <td align="left">
                                <u>
                                  <b>
                                    {Object.keys(policyDetails).length > 0 &&
                                      policyDetails.data.proposerDetails[0]
                                        .prpEmail}
                                  </b>
                                </u>
                              </td>
                            </tr> */}
                              </table>
                            </td>
                            <td width={"50%"} valign="top">
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td colSpan={"2"} style={inputStyle}>
                                    PAYMENT DETAILS
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Bank:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .bank}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Branch:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .branch}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Account Number:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .account_number}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Payment Frequency:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .payment_frequency}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Deduction Date:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .deduction_date}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td style={inputStyle} align="center">
                                    PRODUCT DETAILS
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Product Name:{" "}
                              <u>
                                <b>
                                  {Object.keys(policyDetails).length > 0 &&
                                    policyDetails.data.proposerDetails
                                      .prod_name}
                                </b>
                              </u>
                            </td>
                            <td align="left">
                              Policy Term:{" "}
                              <u>policyDetails.data.proposerDetails .term</u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Medical Diagnostics: <u></u>
                            </td>
                            <td align="left">
                              Critical Illness: <u></u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Premium (Ghc):{" "}
                              <u>
                                <b>
                                  {Object.keys(policyDetails).length > 0 &&
                                    policyDetails.data.proposerDetails.premium}
                                </b>
                              </u>
                            </td>
                            <td align="left">
                              Sum Assured (Ghc):{" "}
                              <u>
                                <b>
                                  {Object.keys(policyDetails).length > 0 &&
                                    policyDetails.data.proposerDetails.sa}
                                </b>
                              </u>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td
                                    colSpan={"8"}
                                    style={inputStyle}
                                    align="center"
                                  >
                                    BENEFICIARIES
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td>Surname</td> */}
                                  <td>Name</td>
                                  <td>Date of Birth</td>
                                  <td>Relationship</td>
                                  <td>Percentage (%)</td>
                                  <td>Contact No.</td>
                                </tr>
                                {Object.keys(policyDetails).length > 0 &&
                                  policyDetails.data.myPolicyBeneficiaries.map(
                                    (ben) => (
                                      <tr key={ben.id}>
                                        {/* <td>
                                          <b>{ben.benOtherNames}</b>
                                        </td> */}
                                        <td>
                                          <b>{ben.name}</b>
                                        </td>
                                        <td>
                                          <b>{ben.dob}</b>
                                        </td>
                                        <td>
                                          <b>{ben.relationship}</b>
                                        </td>
                                        <td>
                                          <b>{ben.percentage}</b>
                                        </td>
                                        <td>
                                          <b>{ben.contact}</b>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </table>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td
                                    colSpan={"8"}
                                    style={inputStyle}
                                    align="center"
                                  >
                                    TRUSTEES
                                  </td>
                                </tr>
                                <tr>
                                  <td>Surname</td>
                                  <td>Other Names</td>
                                  <td>Date of Birth</td>
                                  <td>Relationship</td>
                                  <td>Contact No.</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <br />
                          {/* <tr>
                        <td colSpan={"2"}>
                          <table border="0px" width={"100%"}>
                            <tr>
                              <td
                                colSpan={"2"}
                                style={inputStyle}
                                align="center"
                              >
                                DATA PROTECTION
                              </td>
                            </tr>
                            <tr>
                              <td align="left" width={"40%"}>
                                I hereby consent to the processing of my
                                personal data for business relationship and
                                further acknowledge and agree that my personal
                                data may be disclosed to entities associated,
                                affiliated or in relation to this business to
                                achieve the purpose of processing under this
                                consent.
                                <div>
                                  <InputGroup
                                    className="mb-3"
                                    // onChange={handleChange("paymentFrequency")}
                                    // defaultChecked={true}
                                  >
                                    <InputGroup.Radio
                                      name="data"
                                      value="Yes"
                                      aria-label="Radio 1"
                                      defaultChecked={true}
                                      disabled={true}
                                    />{" "}
                                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <InputGroup.Radio
                                      name="data"
                                      value="No"
                                      aria-label="Radio 2"
                                      disabled={true}
                                    />{" "}
                                    &nbsp; No
                                  </InputGroup>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr> */}
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td
                                    colSpan={"2"}
                                    style={inputStyle}
                                    align="center"
                                  >
                                    DECLARATION
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Declaration by Applicant. <br /> I{" "}
                                    {Object.keys(policyDetails).length > 0 && (
                                      <u>
                                        <b>
                                          {
                                            policyDetails.data.proposerDetails
                                              .first_name
                                          }{" "}
                                          {policyDetails.data.proposerDetails
                                            .middle_name &&
                                            policyDetails.data.proposerDetails
                                              .middle_name}{" "}
                                          {
                                            policyDetails.data.proposerDetails
                                              .surname
                                          }
                                        </b>
                                      </u>
                                    )}{" "}
                                    , declare that every statement in response
                                    to questions asked in this application is
                                    true and correct to the best of my
                                    knowledge. I agree that this application
                                    shall serve as the basis and form part of
                                    the contract. All the questions have been
                                    explained to me in the language that I
                                    understand and I have been made to
                                    understand that this contract shall become
                                    operative until all of the following
                                    conditions have been met:
                                    <ol>
                                      <li>
                                        This application has to be approved by
                                        StarLife Assurance Company Limited.
                                      </li>
                                      <li>
                                        The appropriate premium will be paid.
                                      </li>
                                      <li>
                                        I satisfy all the conditions precedent
                                        to the policy especially those
                                        pertaining to my health.
                                      </li>
                                      <li>
                                        All the persons proposed for cover are
                                        alive and in good health.
                                      </li>
                                    </ol>
                                    <InputGroup
                                      className="mb-3"
                                      // onChange={handleChange("paymentFrequency")}
                                      // defaultChecked={true}
                                    >
                                      <InputGroup.Checkbox
                                        name="confirm"
                                        aria-label="Checkbox 1"
                                        defaultChecked={true}
                                        disabled={true}
                                      />{" "}
                                      &nbsp; I confirm and agree to the terms of
                                      conditions
                                    </InputGroup>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          {/* <tr>
                  <td>
                    Proposer's Signature:&nbsp;
                    <img
                      width="100"
                      height="30"
                      src={`data:image/png;base64,${this.props.values.signature}`}
                      alt="signature"
                    />
                  </td>
                  <td>Date: {moment().format("DD-MM-YYYY HH:mm:ss")}</td>
                </tr> */}
                        </table>
                      </font>
                    </div>
                    <div style={{ width: "50%", float: "right" }}>
                      <embed
                        id="pdf"
                        src={url}
                        // src="http://127.0.0.1:5000/StarLife/SL_PDF/PWPP1006003221032052/YAW%20CHAMFORO/FIRST%20ATLANTIC%20BANK/01092022#toolbar=0&navpanes=0&scrollbar=0"
                        frameBorder="0"
                        width="100%"
                        height="600px"
                      />
                    </div>
                    <br style={{ clear: "both" }} />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* End of PDF Modal */}

              {/* <!-- Footer --> */}
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()} StarLife
                      Assurance. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
                </div>
              </footer>
              {/* <!-- End of Footer --> */}
            </div>
            {/* <!-- End of Content Wrapper --> */}
          </div>
          {/* //   <!-- End of Page Wrapper --> */}

          {/* <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      ) : (
        <>
          {/* // <!-- Page Wrapper --> */}
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
              id="accordionSidebar"
            >
              {/* <!-- Sidebar - Brand --> */}
              <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/bancassurance/portal/home"
              >
                <div
                  className="sidebar-brand-icon"
                  style={{ backgroundColor: "white", marginLeft: "50px" }}
                >
                  <img src={logo} height="70" />
                </div>
              </a>
              <br></br>
              {/* <!-- Divider --> */}
              {/* <hr className="sidebar-divider my-0"></hr> */}
              {/* <!-- Nav Item - Dashboard --> */}
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/home">
                  <i class="fa fa-home" aria-hidden="true"></i>
                  <span>Home</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/report/successful"
                >
                  <i className="fa fa-file"></i>
                  <span>Reports</span>
                </a>
              </li>
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/change-password"
                >
                  <i className="fa fa-file"></i>
                  <span>Change Password</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fas fa-sign-out-alt"></i>
                  <span onClick={logoutHandler}>Logout</span>
                </a>
              </li>
            </ul>
            {/* <!-- End of Sidebar --> */}

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              {/* <!-- Main Content --> */}
              <div id="content">
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* <!-- Sidebar Toggle (Topbar) --> */}
                  <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {% if current_user.is_authenticated %} */}
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                          {authCtx.userName}
                          {/* <font color="#763984">DELIJAH</font> */}
                        </span>
                        {/* {% endif %} */}
                        <i className="fas fa-user"></i>
                      </a>
                      {/* <!-- Dropdown - User Information --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logoutModal"
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
                  {/* <!-- Page Heading --> */}
                  {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
                  {/* <!-- DataTales Example --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h3 className="m-0 font-weight-bold text-primary">
                        Successful Onboardings Report
                      </h3>
                      <div></div>
                      <br />
                    </div>
                    {/* <div className="card-header py-3"> */}
                    <br />
                    <div>
                      <form method="POST" onSubmit={handleSearchAction}>
                        <input
                          type="date"
                          id="date_from"
                          name="date_from"
                          required
                          // value=""
                        />{" "}
                        -to-
                        <input
                          type="date"
                          id="date_to"
                          name="date_to"
                          required
                          // value=""
                        />
                        &nbsp; &nbsp;
                        {/* <select
                          id="dso"
                          name="dso"
                          required
                          width="100"
                          style={{ width: "100" }}
                        >
                          <option value="">Select DSO</option>
                          <option value="all">All</option>
                          {dso.map((d) => (
                            <option key={d.Agent_Code} value={d.Agent_Code}>
                              {d.Agent_Name}
                            </option>
                          ))}
                        </select>
                        &nbsp; &nbsp; */}
                        <button className="btn btn-info" type="submit">
                          <i className="fas fa-search"></i> Search by Date{" "}
                        </button>
                      </form>
                    </div>
                    <br />
                    {/* </div> */}
                    <div className="card-body">
                      {showMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-success">
                              <strong>Proposal approved successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      {showRejectMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-danger">
                              <strong>Proposal rejected successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      <div className="table-responsive">
                        {proposals.length > 0 ? (
                          <>
                            <div
                              style={{
                                width: "100%",
                                float: "left",
                                // textAlign: "centre",
                              }}
                            >
                              <div
                                style={{
                                  width: "25%",
                                  float: "left",
                                  textAlign: "right",
                                }}
                              >
                                Total Count:{" "}
                                <b>
                                  {proposals.length} (
                                  {(
                                    (proposals.length / proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                              <div style={{ width: "25%", float: "left" }}>
                                Total Pending:{" "}
                                <b>
                                  {
                                    proposals.filter(
                                      (element) => element.status === "Pending"
                                    ).length
                                  }{" "}
                                  (
                                  {(
                                    (proposals.filter(
                                      (element) => element.status === "Pending"
                                    ).length /
                                      proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                              <div style={{ width: "25%", float: "left" }}>
                                Total Approved:{" "}
                                <b>
                                  {
                                    proposals.filter(
                                      (element) => element.status === "Approved"
                                    ).length
                                  }{" "}
                                  (
                                  {(
                                    (proposals.filter(
                                      (element) => element.status === "Approved"
                                    ).length /
                                      proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                              <div style={{ width: "25%", float: "left" }}>
                                Total Rejected:{" "}
                                <b>
                                  {
                                    proposals.filter(
                                      (element) => element.status === "Rejected"
                                    ).length
                                  }{" "}
                                  (
                                  {(
                                    (proposals.filter(
                                      (element) => element.status === "Rejected"
                                    ).length /
                                      proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                            </div>
                            <DataTable
                              title={title}
                              columns={columns}
                              data={proposals}
                              defaultSortFieldId={1}
                              pagination
                              BuiltinStory
                              theme="default"
                              fixedHeader
                              progressPending={pending}
                              // fixedHeaderScrollHeight="300px"
                              // selectableRows
                            />
                            {/* <div
                              style={{
                                width: "100%",
                                float: "left",
                                // textAlign: "centre",
                              }}
                            >
                              <div
                                style={{
                                  width: "25%",
                                  float: "left",
                                  textAlign: "right",
                                }}
                              >
                                Total Count:{" "}
                                <b>
                                  {proposals.length} (
                                  {(
                                    (proposals.length / proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                              <div style={{ width: "25%", float: "left" }}>
                                Total Pending:{" "}
                                <b>
                                  {
                                    proposals.filter(
                                      (element) => element.status === "Pending"
                                    ).length
                                  }{" "}
                                  (
                                  {(
                                    (proposals.filter(
                                      (element) => element.status === "Pending"
                                    ).length /
                                      proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                              <div style={{ width: "25%", float: "left" }}>
                                Total Approved:{" "}
                                <b>
                                  {
                                    proposals.filter(
                                      (element) => element.status === "Approved"
                                    ).length
                                  }{" "}
                                  (
                                  {(
                                    (proposals.filter(
                                      (element) => element.status === "Approved"
                                    ).length /
                                      proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                              <div style={{ width: "25%", float: "left" }}>
                                Total Rejected:{" "}
                                <b>
                                  {
                                    proposals.filter(
                                      (element) => element.status === "Rejected"
                                    ).length
                                  }{" "}
                                  (
                                  {(
                                    (proposals.filter(
                                      (element) => element.status === "Rejected"
                                    ).length /
                                      proposals.length) *
                                    100
                                  ).toFixed(1) + "%"}
                                  )
                                </b>
                              </div>
                            </div> */}
                            <Button
                              style={{ float: "right" }}
                              // onClick={this.downloadCSV}
                              onClick={exportFile}
                              title="Export"
                            >
                              <i className="fa fa-file-export fa-sm fa-fw"></i>{" "}
                              Export
                            </Button>
                          </>
                        ) : (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={[]}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of Main Content --> */}

              {/* <!-- Footer --> */}
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()} StarLife
                      Assurance. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
                </div>
              </footer>
              {/* <!-- End of Footer --> */}
            </div>
            {/* <!-- End of Content Wrapper --> */}
          </div>
          {/* //   <!-- End of Page Wrapper --> */}

          {/* <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      )}
    </>
  );
}

export default AdminReportSuccessfulCentral;
