import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import validator from "validator";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import amanano from "../../amanano-logo.jpg";
import { InputGroup } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

export class UserAccount extends PureComponent {
  state = {
    existingPolError: "",
    existingPhoneError: "",
    showPhoneTextBox: false,
    showOTPTextBox: false,
    loading: false,
  };

  handleRadioChange = (e) => {
    if (e.target.form[0].checked) {
      this.setState({ showPhoneTextBox: true });
      //   this.props.nextStep(5);
      // alert("Client has existing policy.");
      // return true;
    } else {
      this.setState({ showPhoneTextBox: false });
    }
  };

  continue = (e) => {
    e.preventDefault();

    let existingPolError = "";
    let existingPhoneError = "";

    // console.log(e);

    if (!e.target.form[0].checked & !e.target.form[1].checked) {
      // Checks if field is empty
      existingPolError =
        "Please indicated whether you have an existing policy or not";
    }

    if (e.target.form[0].checked) {
      const pattern = new RegExp(/^[0-9\b]+$/);
      if (!e.target.form[2].value) {
        existingPhoneError = "Please enter the phone number on your policy";
      }

      if (
        !pattern.test(e.target.form[2].value) ||
        e.target.form[2].value.length !== 10
      ) {
        existingPhoneError = "Please enter a valid phone number";
      }

      const pnumber = e.target.form[2].value;
      // const API_USER = process.env.REACT_APP_API_USER;
      // const API_KEY = process.env.REACT_APP_API_KEY;
      // const API_URL = process.env.REACT_APP_BASE_URL;
      // const EXPRESS_URL = process.env.EXPRESS_APP_BASE_URL;

      if (pnumber.length === 10) {
        const existingClient = {
          Customer_Mobile: pnumber,
        };

        this.setState({ loading: true });
        //Verify client phone
        axios
          .post("/api/customer/verify", existingClient)
          .then((response) => {
            // console.log(response.data);
            if (response.data.message_code === 100) {
              console.log(response.data);
              const clntCode = response.data.clntCode;
              const newFirstName = response.data.webClntFirstName;
              const otherNames = response.data.webClntMiddleName;
              const newLastName = response.data.webClntLastName;
              const dob = response.data.webClntDob;
              const gender = response.data.webClntGender;
              const maritalStatus = response.data.webClntMaritalStatus;
              const idType = response.data.webClntIdRegDoc;
              const idNumber = response.data.webClntIdRegNo;
              const occupation = response.data.clntOccupation;
              const postalAddress = response.data.webClntPostalAddress;
              const residentialAddress = response.data.webClntPhysicalAddress;
              const nationality = response.data.webClntNationality;
              const religion = response.data.webClntReligion;

              // this.props.valueChange({ newFirstName, newLastName });
              this.props.valueChange(["clntCode", clntCode]);
              this.props.valueChange(["firstName", newFirstName]);
              this.props.valueChange(["otherNames", otherNames]);
              this.props.valueChange(["lastName", newLastName]);
              this.props.valueChange(["dob", dob]);
              this.props.valueChange(["gender", gender]);
              this.props.valueChange(["maritalStatus", maritalStatus]);
              this.props.valueChange(["idType", idType]);
              this.props.valueChange(["idNumber", idNumber]);
              this.props.valueChange(["phoneNumber", pnumber]);
              this.props.valueChange(["occupation", +occupation]);
              this.props.valueChange(["postalAddress", postalAddress]);
              this.props.valueChange([
                "residentialAddress",
                residentialAddress,
              ]);
              this.props.valueChange(["nationality", nationality]);
              this.props.valueChange(["religion", religion]);

              this.props.nextStep(4);
              const getOTP = {
                Phone_Number: pnumber,
              };

              //Get OTP
              axios
                .post("/api/customer/otp", getOTP)
                .then((response) => {
                  console.log(response.data);
                })
                .catch((error) => {
                  console.log(error);
                });
              //   this.setState({ loading: false });
              //   alert("Client has existing policy.");
              return true;
            } else {
              //   existingPhoneError = response.data.message;
              existingPhoneError =
                "Sorry, but this phone number does not exist!";
              if (existingPhoneError) {
                // alert(existingPhoneError);
                this.setState({
                  loading: false,
                  existingPhoneError,
                });
                return false;
              }
              console.log(existingPhoneError);
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log(error);
          });

        // const vClientRes = this.vClient(existingClient, API_URL);
        // here is how you call this function
        // const data = this.vfClients(existingClient, API_URL);

        // console.log(data);
        return false;
      }

      //   if (verifyClientRes.message_code === 100) {
      //   } else {
      //     existingPhoneError = verifyClientRes.message;
      //   }

      if (existingPhoneError) {
        this.setState({
          existingPhoneError,
        });
        return false;
      }

      //   axios
      //     .post(API_URL + "/SL_ClientVerificationByPhone", existingClient)
      //     .then((response) => {
      //       console.log(response.data);
      //       if (response.data.message_code === 100) {
      //         console.log("Success");
      //       } else {
      //         existingPhoneError = response.data.message;
      //         if (existingPhoneError) {
      //           alert(existingPhoneError);
      //           return false;
      //         }
      //         console.log(existingPhoneError);
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });

      const newFirstName = "Sandra";
      const newLastName = "Elijah";

      this.props.valueChange({ newFirstName, newLastName });

      //   console.log(this.props.values);

      //   console.log(this.props);

      //   this.setState({values: {this.firstName: firstName}});

      //   console.log(this.props.values);

      //   this.setState({ showPhoneTextBox: true });
      //   this.props.nextStep(4);
      //   alert("Client has existing policy.");
      //   return true;
    } else {
      //   this.setState({ showPhoneTextBox: false });
    }

    if (existingPolError || existingPhoneError) {
      this.setState({
        existingPolError,
        existingPhoneError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  render() {
    const { values, handleChange } = this.props;

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-ga"}></div>
            <br></br>
            <img
              src={amanano}
              alt="logo"
              style={{
                height: "150px",
              }}
            />
            <br></br>
            <br></br>
            <h3>
              Do you already have a policy with Amanano Rural Bancassurance?
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="Yes"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="No"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; No
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.existingPolError}
                    </div>
                  </InputGroup>
                  {this.state.showPhoneTextBox && (
                    <Form.Group>
                      {/* <PhoneInput
                    placeholder="Enter phone number"
                    value={values}
                    onChange={handleChange("phone")}
                  /> */}
                      <FloatingLabel
                        controlId="phoneno"
                        label="Enter phone number on the policy"
                        className="mb-2"
                      >
                        <Form.Control
                          className="smaller-input"
                          type="number"
                          placeholder="Enter Phone number"
                          autoComplete="off"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onChange={handleChange("phoneNumber")}
                          defaultValue={values.phone}
                          style={{
                            border: this.state.existingPhoneError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.existingPhoneError}
                        </div>
                      </FloatingLabel>
                    </Form.Group>
                  )}
                </Form.Group>
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "yellow",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Next
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <br></br>
            <img
              src={amanano}
              alt="logo"
              style={{
                height: "150px",
              }}
            />
            <br></br>
            <br></br>
            <h3>
              Do you already have a policy with Amanano Rural Bancassurance?
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="Yes"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="No"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; No
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.existingPolError}
                    </div>
                  </InputGroup>
                  {this.state.showPhoneTextBox && (
                    <Form.Group>
                      {/* <PhoneInput
                    placeholder="Enter phone number"
                    value={values}
                    onChange={handleChange("phone")}
                  /> */}
                      <FloatingLabel
                        controlId="phoneno"
                        label="Enter phone number on the policy"
                        className="mb-2"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone number"
                          autoComplete="off"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onChange={handleChange("phoneNumber")}
                          defaultValue={values.phoneNumber}
                          style={{
                            border: this.state.existingPhoneError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.existingPhoneError}
                        </div>
                      </FloatingLabel>
                    </Form.Group>
                  )}
                </Form.Group>
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "yellow",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Next
                </Button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default UserAccount;
