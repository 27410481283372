const BankBranchesData = [
  {
    bbrCode: 10464,
    bbrBnkCode: 1035,
    bbrBranchName: "BANTAMA",
    bbrShtDesc: "089093",
  },
  {
    bbrCode: 10465,
    bbrBnkCode: 1035,
    bbrBranchName: "BIBIANI",
    bbrShtDesc: "089098",
  },
  {
    bbrCode: 10466,
    bbrBnkCode: 1035,
    bbrBranchName: "MANKRANSO",
    bbrShtDesc: "089094",
  },
  {
    bbrCode: 10467,
    bbrBnkCode: 1035,
    bbrBranchName: "SUAME",
    bbrShtDesc: "089095",
  },
  {
    bbrCode: 10468,
    bbrBnkCode: 1035,
    bbrBranchName: "AKROPONG",
    bbrShtDesc: "089092",
  },
  {
    bbrCode: 10469,
    bbrBnkCode: 1035,
    bbrBranchName: "SOFOLINE",
    bbrShtDesc: "089097",
  },
  {
    bbrCode: 10470,
    bbrBnkCode: 1035,
    bbrBranchName: "TOASE-HEAD OFFICE",
    bbrShtDesc: "089090",
  },
  {
    bbrCode: 10471,
    bbrBnkCode: 1035,
    bbrBranchName: "ABUAKWA",
    bbrShtDesc: "089091",
  },
  {
    bbrCode: 10472,
    bbrBnkCode: 1035,
    bbrBranchName: "TOASE",
    bbrShtDesc: "089096",
  },
];

export default BankBranchesData;
