import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import starLife from "../../StarLife_Chatbot-06.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
// import { v4 as uuid } from "uuid";

import BenButton from "../ui/Button";

import ProductDetailsUPP from "./ProductDetailsUPP";
import ProductDetailsWPP from "./ProductDetailsWPP";
import Quotation from "./Quotation";
import axios from "axios";
import { getAge } from "../func/utils";
import Menu from "./menu";

import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faTrash,
  faEdit,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

import FormMemberModal from "./FormMemberModal";
import EditMemberModal from "./EditMemberModal";

import moment from "moment";

export class ProductDetails extends PureComponent {
  constructor(props) {
    super(props);
    // this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.state = {
      productError: "",
      termError: "",
      tpdError: "",
      retrechmentError: "",
      dreadDiseaseError: "",
      paError: "",
      productChangeUPP: false,
      premiumError: "",
      escalationError: "",
      paSurnameError: "",
      paOthernameError: "",
      paDOB: "",
      paGender: "",
      escalationRateError: "",
      riderError: "",
      planError: "",
      loading: false,
      prodDesc: "",
      showQuotation: false,
      paymentFrequencyError: "",
      quoteError: "",
      familyError: "",
      mChecked: "",
      aChecked: "",
      escalationSpectraError: "",
      escalationSpectraRateError: "",
      assuredPicture: "",
      assuredPictureError: "",
    };
  }

  componentDidMount() {
    if (this.props.values.product === "UPP") {
      this.setState({ productChangeUPP: true });
      this.setState({
        prodDesc:
          "The dreams and aspiration of every family ceases upon the demise of the breadwinner. It is during this trying time that the essence of the role of the breadwinner is truly appreciated.",
      });
    } else {
      this.setState({ productChangeUPP: false, prodDesc: "" });
    }
  }

  handleProductChange = (e) => {
    const product = e.target.value.split("-")[1];
    const productCode = e.target.value.split("-")[0];
    this.props.valueChange(["product", product]);
    this.props.valueChange(["productCode", productCode]);
    if (product === "UPP") {
      this.setState({ productChangeUPP: true });
      this.setState({
        prodDesc:
          "The dreams and aspiration of every family ceases upon the demise of the breadwinner. It is during this trying time that the essence of the role of the breadwinner is truly appreciated.",
      });
    } else {
      this.setState({ productChangeUPP: false, prodDesc: "" });
    }
  };

  spectraQuote = async () => {
    let rate = "";

    if (this.props.values.escalationRateSpectra === "5") {
      rate = "Option 1";
    } else if (this.props.values.escalationRateSpectra === "10") {
      rate = "Option 2";
    } else if (this.props.values.escalationRateSpectra === "15") {
      rate = "Option 3";
    } else if (this.props.values.escalationRateSpectra === "20") {
      rate = "Option 4";
    } else {
      rate = "Option 0";
    }
    //Spectra Quote
    let quote = {
      track_id: this.props.values.track_id,
      policy_id: this.props.values.rider.split("-")[0],
      surname: this.props.values.lastName,
      first_name: this.props.values.firstName,
      middle_name: this.props.values.otherNames,
      dob: moment(this.props.values.dob, "DD-MM-YYYY").format("YYYY-MM-DD"),
      gender: this.props.values.gender,
      contact: this.props.values.phoneNumber,
      nhis_no: "",
      paymentmode: "CA",
      payment_freq: this.props.values.paymentFrequency,
      yearly_increase: rate,
      photo: this.props.values.assuredPicture,
    };

    // const track_id = "DA1878";
    let data = "";
    let res = "";

    if ("track_id" in localStorage) {
      if (String(localStorage.getItem("track_id")) === quote.track_id) {
        const deleteTrackid = await axios.post(
          "/api/spectra/quotation/delete",
          { track_id: quote.track_id }
        );

        localStorage.removeItem("track_id");

        res = await axios.post("/api/spectra/quotation", quote);

        localStorage.setItem("track_id", quote.track_id);

        if (this.props.values.totalMember > 0) {
          let i;
          for (i = 0; i < this.props.values.familyList.length; i++) {
            res = await axios.post("/api/spectra/quotation/beneficiary", {
              track_id: quote.track_id,
              id: this.props.values.familyList[i].id,
              surname: this.props.values.familyList[i].surname,
              first_name: this.props.values.familyList[i].othernames,
              middle_name: "",
              dob: moment(
                this.props.values.familyList[i].dob,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD"),
              gender: this.props.values.familyList[i].gender,
              contact: "",
              nhis_no: "",
              relation:
                this.props.values.familyList[i].relationship.split("-")[0],
              photo: this.props.values.familyList[i].photo,
            });
            data = res.data.data;
          }
        }
        data = res.data.data;
      } else {
        res = await axios.post("/api/spectra/quotation", quote);

        localStorage.setItem("track_id", quote.track_id);

        if (this.props.values.totalMember > 0) {
          let i;
          for (i = 0; i < this.props.values.familyList.length; i++) {
            res = await axios.post("/api/spectra/quotation/beneficiary", {
              track_id: quote.track_id,
              id: this.props.values.familyList[i].id,
              surname: this.props.values.familyList[i].surname,
              first_name: this.props.values.familyList[i].othernames,
              middle_name: "",
              dob: moment(
                this.props.values.familyList[i].dob,
                "DD-MM-YYYY"
              ).format("YYYY-MM-DD"),
              gender: this.props.values.familyList[i].gender,
              contact: "",
              nhis_no: "",
              relation:
                this.props.values.familyList[i].relationship.split("-")[0],
              photo: this.props.values.familyList[i].photo,
            });
            data = res.data.data;
          }
        }
        data = res.data.data;
      }
    } else {
      res = await axios.post("/api/spectra/quotation", quote);

      localStorage.setItem("track_id", quote.track_id);

      if (this.props.values.totalMember > 0) {
        let i;
        for (i = 0; i < this.props.values.familyList.length; i++) {
          res = await axios.post("/api/spectra/quotation/beneficiary", {
            track_id: quote.track_id,
            id: this.props.values.familyList[i].id,
            surname: this.props.values.familyList[i].surname,
            first_name: this.props.values.familyList[i].othernames,
            middle_name: "",
            dob: moment(
              this.props.values.familyList[i].dob,
              "DD-MM-YYYY"
            ).format("YYYY-MM-DD"),
            gender: this.props.values.familyList[i].gender,
            contact: "",
            nhis_no: "",
            relation:
              this.props.values.familyList[i].relationship.split("-")[0],
            photo: this.props.values.familyList[i].photo,
          });
          data = res.data.data;
        }
      }
      data = res.data.data;
    }

    return data;
  };

  quotation = (e) => {
    e.preventDefault();
    let productError = "";
    let termError = "";
    let riderError = "";
    let retrechmentError = "";
    let dreadDiseaseError = "";
    let paError = "";
    let premiumError = "";
    let escalationError = "";
    let paSurnameError = "";
    let paOthernameError = "";
    let paDOB = "";
    let paGender = "";
    let escalationRateError = "";
    let planError = "";
    let paymentFrequencyError = "";
    let familyError = "";
    let escalationSpectraError = "";
    let escalationSpectraRateError = "";
    let assuredPictureError = "";

    this.setState({
      productError,
      termError,
      riderError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
      planError,
      paymentFrequencyError,
      familyError,
      escalationSpectraError,
      escalationSpectraRateError,
      assuredPictureError,
    });

    if (!e.target.form[0].value) {
      // Checks if field is empty
      productError = "Please select product";
    }

    if (e.target.form[0].value.split("-")[1] === "UPP") {
      if (e.target.form[1].value < 8 || e.target.form[1].value > 20) {
        // Checks if field is empty
        termError = "Please select a valid policy term";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        riderError = "Please select option";
      }

      if (
        e.target.form[2].value === "Bronze" ||
        e.target.form[2].value === "Silver" ||
        e.target.form[2].value === "Gold"
      ) {
        if (!e.target.form[3].value) {
          // Checks if field is empty
          planError = "Please select option";
        }

        if (!e.target.form[4].value) {
          // Checks if field is empty
          dreadDiseaseError = "Please select option";
        }

        if (!e.target.form[5].value) {
          // Checks if field is empty
          premiumError = "Please select basic sum assured";
        }

        if (!e.target.form[6].checked & !e.target.form[7].checked) {
          // Checks if field is empty
          paymentFrequencyError = "Please indicated your payment frequency";
        }

        if (!e.target.form[8].value) {
          // Checks if field is empty
          escalationError = "Please select option";
        }

        if (e.target.form[8].value === "Yes") {
          if (!e.target.form[9].value) {
            // Checks if field is empty
            escalationRateError = "Please select escalation rate";
          }
          if (!e.target.form[10].value) {
            // Checks if field is empty
            escalationSpectraError = "Please select option";
          }
          if (e.target.form[10].value === "Yes") {
            if (!e.target.form[11].value) {
              // Checks if field is empty
              escalationSpectraRateError = "Please select escalation rate";
            }

            if (!e.target.form[12].value) {
              // Checks if field is empty
              assuredPictureError = "Please provide picture of assured";
            }
          }
          if (!e.target.form[11].value) {
            // Checks if field is empty
            assuredPictureError = "Please provide picture of assured";
          }
        } else {
          if (!e.target.form[9].value) {
            // Checks if field is empty
            escalationSpectraError = "Please select option";
          }
          if (e.target.form[9].value === "Yes") {
            if (!e.target.form[10].value) {
              // Checks if field is empty
              escalationSpectraRateError = "Please select escalation rate";
            }

            if (!e.target.form[11].value) {
              // Checks if field is empty
              assuredPictureError = "Please provide picture of assured";
            }
          }

          if (!e.target.form[10].value) {
            // Checks if field is empty
            assuredPictureError = "Please provide picture of assured";
          }
        }
      } else {
        if (!e.target.form[3].value) {
          // Checks if field is empty
          dreadDiseaseError = "Please select option";
        }

        if (!e.target.form[4].value) {
          // Checks if field is empty
          premiumError = "Please select basic sum assured";
        }

        if (!e.target.form[5].checked & !e.target.form[6].checked) {
          // Checks if field is empty
          paymentFrequencyError = "Please indicated your payment frequency";
        }

        if (!e.target.form[7].value) {
          // Checks if field is empty
          escalationError = "Please select option";
        }

        if (e.target.form[7].value === "Yes") {
          if (!e.target.form[8].value) {
            // Checks if field is empty
            escalationRateError = "Please select escalation rate";
          }
          if (!e.target.form[9].value) {
            // Checks if field is empty
            escalationSpectraError = "Please select option";
          }

          if (e.target.form[9].value === "Yes") {
            if (!e.target.form[10].value) {
              // Checks if field is empty
              escalationSpectraRateError = "Please select escalation rate";
            }

            if (!e.target.form[11].value) {
              // Checks if field is empty
              assuredPictureError = "Please provide picture of assured";
            }
          }

          if (!e.target.form[10].value) {
            // Checks if field is empty
            assuredPictureError = "Please provide picture of assured";
          }
        } else {
          if (!e.target.form[8].value) {
            // Checks if field is empty
            escalationSpectraError = "Please select option";
          }

          if (e.target.form[8].value === "Yes") {
            if (!e.target.form[9].value) {
              // Checks if field is empty
              escalationSpectraRateError = "Please select option";
            }
          }

          if (!e.target.form[9].value) {
            // Checks if field is empty
            assuredPictureError = "Please provide picture of assured";
          }
        }
      }
    }

    if (
      (this.props.values.familyList.length < this.props.values.totalMember) &
      (this.props.values.totalMember > 0)
    ) {
      familyError = `Kindly add ${
        this.props.values.totalMember - this.props.values.familyList.length
      } additional member(s) for this package`;
    } else if (
      (this.props.values.familyList.length > this.props.values.totalMember) &
      (this.props.values.totalMember > 0)
    ) {
      familyError = `Only ${this.props.values.totalMember} additional member(s) are required for this package`;
    }

    if (
      productError ||
      termError ||
      riderError ||
      retrechmentError ||
      dreadDiseaseError ||
      paError ||
      premiumError ||
      escalationError ||
      paSurnameError ||
      paOthernameError ||
      paDOB ||
      paGender ||
      escalationRateError ||
      planError ||
      paymentFrequencyError ||
      familyError ||
      escalationSpectraError ||
      escalationSpectraRateError ||
      assuredPictureError
    ) {
      this.setState({
        productError,
        termError,
        riderError,
        retrechmentError,
        dreadDiseaseError,
        paError,
        premiumError,
        escalationError,
        paSurnameError,
        paOthernameError,
        paDOB,
        paGender,
        escalationRateError,
        planError,
        paymentFrequencyError,
        familyError,
        escalationSpectraError,
        escalationSpectraRateError,
        assuredPictureError,
      });
      return false;
    }

    const existingProduct = {
      Customer_Mobile: this.props.values.phoneNumber,
      Product_Code: e.target.form[0].value.split("-")[1],
      Market: "RETAIL",
    };

    this.setState({
      loading: true,
      mChecked: e.target.form[6].checked,
      aChecked: e.target.form[7].checked,
    });

    if ("product" in localStorage) {
      if (
        JSON.parse(localStorage.getItem("product")) !== null &&
        JSON.parse(localStorage.getItem("product")) !== undefined
      ) {
        if (JSON.parse(localStorage.getItem("product")) === false) {
          axios
            .post("/api/customer/quotation", this.props.values)
            .then((response) => {
              if (response.status === 200) {
                if (Object.keys(response.data).length === 2) {
                  const clntCode = response.data.client.clntCode;
                  const newFirstName = response.data.client.webClntFirstName;
                  const otherNames = response.data.client.webClntMiddleName;
                  const newLastName = response.data.client.webClntLastName;
                  const dob = response.data.client.webClntDob;
                  const gender = response.data.client.webClntGender;
                  const maritalStatus =
                    response.data.client.webClntMaritalStatus;
                  const idType = response.data.client.webClntIdRegDoc;
                  const idNumber = response.data.client.webClntIdRegNo;
                  const occupation = response.data.client.clntOccupation;
                  const postalAddress =
                    response.data.client.webClntPostalAddress;
                  const residentialAddress =
                    response.data.client.webClntPhysicalAddress;
                  const nationality = response.data.client.webClntNationality;
                  const religion = response.data.client.webClntReligion;
                  const pnumber = response.data.client.webClntMobileNo;

                  // this.props.valueChange({ newFirstName, newLastName });
                  this.props.valueChange(["clntCode", clntCode]);
                  this.props.valueChange(["firstName", newFirstName]);
                  this.props.valueChange(["otherNames", otherNames]);
                  this.props.valueChange(["lastName", newLastName]);
                  this.props.valueChange(["dob", dob]);
                  this.props.valueChange(["gender", gender]);
                  this.props.valueChange(["maritalStatus", maritalStatus]);
                  this.props.valueChange(["idType", idType]);
                  this.props.valueChange(["idNumber", idNumber]);
                  this.props.valueChange(["phoneNumber", pnumber]);
                  this.props.valueChange(["occupation", +occupation]);
                  this.props.valueChange(["postalAddress", postalAddress]);
                  this.props.valueChange([
                    "residentialAddress",
                    residentialAddress,
                  ]);
                  this.props.valueChange(["nationality", nationality]);
                  this.props.valueChange(["religion", religion]);

                  //Spectra Call
                  let squote = this.spectraQuote();

                  squote.then((a) => {
                    this.props.valueChange(["assuredPrem", a]);
                    this.props.valueChange([
                      "premium",
                      response.data.quote.premium,
                    ]);
                    this.props.valueChange([
                      "sa",
                      response.data.quote.sumInsured,
                    ]);
                    this.props.valueChange([
                      "quoteNo",
                      response.data.quote.quoCode,
                    ]);

                    localStorage.setItem(
                      "quotation",
                      JSON.stringify(response.data.quote)
                    );
                    this.setState({
                      loading: false,
                      // coverDetails: response.data.coverTypeAllocations,
                      showQuotation: true,
                    });
                  });
                } else {
                  //Spectra Call
                  let squote = this.spectraQuote();

                  squote.then((a) => {
                    this.props.valueChange(["assuredPrem", a]);
                    this.props.valueChange(["premium", response.data.premium]);
                    this.props.valueChange(["sa", response.data.sumInsured]);
                    this.props.valueChange(["quoteNo", response.data.quoCode]);

                    localStorage.setItem(
                      "quotation",
                      JSON.stringify(response.data)
                    );

                    this.setState({
                      loading: false,
                      // coverDetails: response.data.coverTypeAllocations,
                      showQuotation: true,
                    });
                  });
                }
              } else {
                this.setState({ loading: false, showQuotation: false });
                alert("An Error Occured");
              }
            })
            .catch((error) => {
              this.setState({ loading: false, showQuotation: false });
              alert("An error occured.");
            });
        }
      }
    } else {
      //Verify if client already has product
      axios
        .post("/api/customer/product/verify", existingProduct)
        .then((response) => {
          if (response.data.message_code === 100) {
            this.setState({ loading: false });
            localStorage.setItem("product", true);
            productError = `${response.data.message} ${
              e.target.form[0].value.split("-")[2]
            }`;
            if (productError) {
              this.setState({
                productError,
              });
              return false;
            }
          } else if (response.data.message_code === 200) {
            this.setState({ loading: false });
            localStorage.setItem("product", true);
            productError = `${response.data.message} ${
              e.target.form[0].value.split("-")[2]
            } this year`;
            if (productError) {
              this.setState({
                productError,
              });
              return false;
            }
          } else {
            localStorage.setItem("product", false);
            axios
              .post("/api/customer/quotation", this.props.values)
              .then((response) => {
                if (response.status === 200) {
                  if (Object.keys(response.data).length === 2) {
                    const clntCode = response.data.client.clntCode;
                    const newFirstName = response.data.client.webClntFirstName;
                    const otherNames = response.data.client.webClntMiddleName;
                    const newLastName = response.data.client.webClntLastName;
                    const dob = response.data.client.webClntDob;
                    const gender = response.data.client.webClntGender;
                    const maritalStatus =
                      response.data.client.webClntMaritalStatus;
                    const idType = response.data.client.webClntIdRegDoc;
                    const idNumber = response.data.client.webClntIdRegNo;
                    const occupation = response.data.client.clntOccupation;
                    const postalAddress =
                      response.data.client.webClntPostalAddress;
                    const residentialAddress =
                      response.data.client.webClntPhysicalAddress;
                    const nationality = response.data.client.webClntNationality;
                    const religion = response.data.client.webClntReligion;
                    const pnumber = response.data.client.webClntMobileNo;

                    // this.props.valueChange({ newFirstName, newLastName });
                    this.props.valueChange(["clntCode", clntCode]);
                    this.props.valueChange(["firstName", newFirstName]);
                    this.props.valueChange(["otherNames", otherNames]);
                    this.props.valueChange(["lastName", newLastName]);
                    this.props.valueChange(["dob", dob]);
                    this.props.valueChange(["gender", gender]);
                    this.props.valueChange(["maritalStatus", maritalStatus]);
                    this.props.valueChange(["idType", idType]);
                    this.props.valueChange(["idNumber", idNumber]);
                    this.props.valueChange(["phoneNumber", pnumber]);
                    this.props.valueChange(["occupation", +occupation]);
                    this.props.valueChange(["postalAddress", postalAddress]);
                    this.props.valueChange([
                      "residentialAddress",
                      residentialAddress,
                    ]);
                    this.props.valueChange(["nationality", nationality]);
                    this.props.valueChange(["religion", religion]);

                    //Spectra Call
                    let squote = this.spectraQuote();

                    squote.then((a) => {
                      this.props.valueChange(["assuredPrem", a]);
                      this.props.valueChange([
                        "premium",
                        response.data.quote.premium,
                      ]);
                      this.props.valueChange([
                        "sa",
                        response.data.quote.sumInsured,
                      ]);
                      this.props.valueChange([
                        "quoteNo",
                        response.data.quote.quoCode,
                      ]);

                      localStorage.setItem(
                        "quotation",
                        JSON.stringify(response.data.quote)
                      );
                      this.setState({
                        loading: false,
                        // coverDetails: response.data.coverTypeAllocations,
                        showQuotation: true,
                      });
                    });
                  } else {
                    //Spectra Call
                    let squote = this.spectraQuote();

                    squote.then((a) => {
                      this.props.valueChange(["assuredPrem", a]);
                      this.props.valueChange([
                        "premium",
                        response.data.premium,
                      ]);
                      this.props.valueChange(["sa", response.data.sumInsured]);
                      this.props.valueChange([
                        "quoteNo",
                        response.data.quoCode,
                      ]);

                      localStorage.setItem(
                        "quotation",
                        JSON.stringify(response.data)
                      );

                      this.setState({
                        loading: false,
                        // coverDetails: response.data.coverTypeAllocations,
                        showQuotation: true,
                      });
                    });
                  }
                } else {
                  this.setState({ loading: false, showQuotation: false });
                  alert("An Error Occured");
                }
              })
              .catch((error) => {
                this.setState({ loading: false, showQuotation: false });
                alert("An Error Occured");
              });
            // this.props.nextStep();
            // return true;
          }
        })
        .catch((error) => {
          this.setState({ loading: false, showQuotation: false });
          alert("An Error Occured");
        });
      // this.props.nextStep();
      // return true;
    }
  };

  continue = (e) => {
    e.preventDefault();

    let quoteError = "";

    this.setState({
      quoteError,
    });

    if (
      this.props.values.premium !== null &&
      this.props.values.sa !== undefined &&
      this.props.values.premium > 0 &&
      this.props.values.sa > 0
    ) {
      this.setState({ showQuotation: false });
      this.props.nextStep();
      return true;
    } else {
      quoteError = "Quotation Error. Kindly recalculate";
    }

    if (quoteError) {
      this.setState({
        quoteError,
      });
      return false;
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep(3);
  };

  hideModal = () => {
    this.setState({ showQuotation: false });
  };

  render() {
    const {
      values,
      handleChange,
      valueChange,
      addMember,
      updateMember,
      showMemberModalHandler,
      hideMemberModalHandler,
      hideEditMemberModalHandler,
      showEditMemberModalHandler,
      removeMember,
    } = this.props;

    const products = [
      {
        id: 42,
        label: "ULTIMATE PROTECTION PLUS",
        value: "42-UPP",
      },
    ];

    let qNo = null;

    if ("quotation" in localStorage) {
      if (
        JSON.parse(localStorage.getItem("quotation")).quoCode !== null &&
        JSON.parse(localStorage.getItem("quotation")).quoCode !== undefined
      ) {
        qNo = JSON.parse(localStorage.getItem("quotation")).quoCode;

        this.props.valueChange(["quoteNo", qNo]);
      } else {
        this.props.valueChange(["quoteNo", qNo]);
      }
    }

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-retail"}></div>
            <Menu />
            <hr />
            <br></br>
            <h2>
              Which of our products are you interested in,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              ?
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <Form.Group>
                    <div style={{ border: "3px solid #fff" }}>
                      <div
                        style={{
                          width: "90%",
                          float: "left",
                          // padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FloatingLabel
                            controlId="products"
                            label="Select Product"
                            className="mb-2"
                          >
                            <Form.Control
                              as="select"
                              onChange={this.handleProductChange}
                              // defaultValue={values.product}
                              style={{
                                border: this.state.productError
                                  ? "1px solid red"
                                  : "",
                              }}
                            >
                              <option></option>
                              {products.map((product) => (
                                <option key={product.id} value={product.value}>
                                  {product.label}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                              }}
                            >
                              {this.state.productError}
                            </div>
                          </FloatingLabel>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "10%",
                          float: "left",
                          padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            data-tip
                            data-for="prodTip"
                          />
                          <ReactTooltip
                            id="prodTip"
                            place="right"
                            effect="solid"
                            data-multiline="true"
                          >
                            {this.state.prodDesc
                              ? this.state.prodDesc
                              : "Select Product for Product Description"}
                          </ReactTooltip>
                        </div>
                      </div>
                    </div>

                    {/* <FloatingLabel
                  controlId="products"
                  label="Select Product"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleProductChange}
                    // defaultValue={values.product}
                    style={{
                      border: this.state.productError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {products.map((product) => (
                      <option key={product.id} value={product.value}>
                        {product.label}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.productError}
                  </div>
                </FloatingLabel> */}
                    {this.state.productChangeUPP && (
                      <ProductDetailsUPP
                        termError={this.state.termError}
                        riderError={this.state.riderError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                        planError={this.state.planError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                      />
                    )}
                    {this.state.productChangeWPP && <ProductDetailsWPP />}
                  </Form.Group>
                </Form.Group>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                  }}
                  onClick={this.Quotation}
                >
                  Get Quote
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <Menu />
            <hr />
            <br></br>
            <h2>
              Which of our products are you interested in,{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              ?
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <Form.Group>
                    <div style={{ border: "3px solid #fff" }}>
                      <div
                        style={{
                          width: "90%",
                          float: "left",
                          // padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FloatingLabel
                            controlId="products"
                            label="Select Product"
                            className="mb-2"
                          >
                            <Form.Control
                              as="select"
                              onChange={this.handleProductChange}
                              defaultValue={
                                values.productCode + "-" + values.product
                              }
                              style={{
                                border: this.state.productError
                                  ? "1px solid red"
                                  : "",
                              }}
                            >
                              <option></option>
                              {products.map((product) => (
                                <option key={product.id} value={product.value}>
                                  {product.label}
                                </option>
                              ))}
                            </Form.Control>
                            <div
                              style={{
                                textAlign: "left",
                                color: "red",
                              }}
                            >
                              {this.state.productError}
                            </div>
                          </FloatingLabel>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "10%",
                          float: "left",
                          padding: "20px",
                          // border: "2px solid red",
                        }}
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faQuestionCircle}
                            data-tip
                            data-for="prodTip"
                          />
                          <ReactTooltip
                            id="prodTip"
                            place="right"
                            effect="solid"
                            data-multiline="true"
                          >
                            {this.state.prodDesc
                              ? this.state.prodDesc
                              : "Select Product for Product Description"}
                          </ReactTooltip>
                        </div>
                      </div>
                    </div>

                    {/* <FloatingLabel
                  controlId="products"
                  label="Select Product"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleProductChange}
                    // defaultValue={values.product}
                    style={{
                      border: this.state.productError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {products.map((product) => (
                      <option key={product.id} value={product.value}>
                        {product.label}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.productError}
                  </div>
                </FloatingLabel> */}
                    {this.state.productChangeUPP && (
                      <ProductDetailsUPP
                        termError={this.state.termError}
                        riderError={this.state.riderError}
                        retrechmentError={this.state.retrechmentError}
                        dreadDiseaseError={this.state.dreadDiseaseError}
                        paError={this.state.paError}
                        handleChange={handleChange}
                        valueChange={valueChange}
                        values={values}
                        premiumError={this.state.premiumError}
                        escalationError={this.state.escalationError}
                        paSurnameError={this.state.paSurnameError}
                        paOthernameError={this.state.paOthernameError}
                        paDOB={this.state.paDOB}
                        paGender={this.state.paGender}
                        escalationRateError={this.state.escalationRateError}
                        planError={this.state.planError}
                        paymentFrequencyError={this.state.paymentFrequencyError}
                        mChecked={this.state.mChecked}
                        aChecked={this.state.aChecked}
                        escalationSpectraError={
                          this.state.escalationSpectraError
                        }
                        escalationSpectraRateError={
                          this.state.escalationSpectraRateError
                        }
                        assuredPictureError={this.state.assuredPictureError}
                      />
                    )}
                    {this.state.productChangeWPP && <ProductDetailsWPP />}
                  </Form.Group>
                </Form.Group>
                {values.memberRequired && (
                  <div>
                    <div style={{ float: "right" }}>
                      <BenButton type="button" onClick={showMemberModalHandler}>
                        Add Members
                      </BenButton>
                    </div>
                    <br></br>
                    <br></br>
                    {values.showMemberModal && (
                      <FormMemberModal
                        title="Member Details"
                        message="Message"
                        onConfirm=""
                        backdrop={hideMemberModalHandler}
                        addMember={addMember}
                        valueChange={valueChange}
                        values={values}
                      />
                    )}
                    {values.showEditMemberModal && (
                      <EditMemberModal
                        title="Edit Member Details"
                        message="Message"
                        onConfirm=""
                        backdrop={hideEditMemberModalHandler}
                        // addBen={addBen}
                        updateMember={updateMember}
                        othernames={this.state.newMember.othernames}
                        surname={this.state.newMember.surname}
                        dob={this.state.newMember.dob}
                        relationship={this.state.newMember.relationship}
                        id={this.state.newMember.id}
                        valueChange={valueChange}
                        gender={this.state.newMember.gender}
                        photo={this.state.newMember.photo}
                      />
                    )}
                    {/* <br></br>
                    <br></br> */}
                    <h5>
                      {/* <FontAwesomeIcon
                        icon={faUserFriends}
                        style={{ color: "#763984" }}
                      />{" "} */}
                      &nbsp;{" "}
                      <u>
                        Additional {this.props.values.totalMember} Member(s)
                        Details
                      </u>
                    </h5>
                    {/* <Card> */}
                    <table
                      border="1"
                      width="100%"
                      className="table table-bordered table-hover table-sm w-auto"
                    >
                      <thead border="1">
                        <tr>
                          <th>Surname</th>
                          <th>Othernames</th>
                          <th>DOB</th>
                          <th>Relationship</th>
                          {/* <th>Gender</th> */}
                          <th colSpan="2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.familyList.map((member) => (
                          <tr key={member.id}>
                            <td>{member.surname}</td>
                            <td>{member.othernames}</td>
                            <td>{member.dob}</td>
                            <td>{member.relationship.split("-")[1]}</td>
                            {/* <td>{member.gender}</td> */}
                            <td>
                              <a
                                href="#"
                                onClick={() => {
                                  this.setState({ newMember: member });
                                  showEditMemberModalHandler();
                                }}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </a>
                            </td>
                            <td>
                              {/* <span
                      style={{ color: "red" }}
                      onClick={() => removeBen(ben.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span> */}
                              <a
                                href="#"
                                style={{ color: "red" }}
                                onClick={() =>
                                  removeMember(
                                    member.id,
                                    member.newId,
                                    member.track_id
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                      {/* <thead>
              <tr>
                <th>Surname</th>
                <th>Othernames</th>
                <th>Date of Birth</th>
                <th>Relationship</th>
                <th>Contact Number</th>
                <th>Percentage</th>
                <th colSpan="2">Action</th>
              </tr>
            </thead> */}
                    </table>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.familyError}
                    </div>
                    <br></br>
                  </div>
                )}
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                  }}
                  onClick={this.quotation}
                >
                  Get Quote
                </Button>
              </Form>
              {this.state.showQuotation && (
                <Quotation
                  title="Quotation Details"
                  message="Message"
                  backdrop={this.hideModal}
                  continue={this.continue}
                  // addBen={addBen}
                  // trusteeRequred={values.trusteeRequred}
                  // trusteeRequredHandler={trusteeRequredHandler}
                  // valueChange={valueChange}
                  values={values}
                  // coverDetails={
                  //   this.state.coverDetails &&
                  //   this.state.coverDetails.filter(
                  //     (cover) => cover.cvtPremium !== 0
                  //   )
                  // }
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProductDetails;
