import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import logo from "../../static/img/starlife-logo.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPassword() {
  const [userNameError, setUserNameError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setUserNameError(null);

    let unameError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      unameError = "Please enter your username";
    }

    if (unameError) {
      setUserNameError(unameError);
      return false;
    }

    const userLogin = {
      User_Name: e.target.form[0].value,
    };

    setLoading(true);

    //Verify client phone
    axios
      .post("/api/merchant/forgot-password", userLogin)
      .then((response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          toast.success("Password reset link has been sent to your email", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setLoading(false);
          toast.error("User not identified", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occured. Kindly try again later", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <br></br>
          <img
            src={logo}
            alt="logo"
            //   style={{ width: "150px", height: "150px", padding: 10 }}
          />
          <br></br>
          <br></br>

          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp;{" "}
                <u>Personal Details of the Account Holder</u>
              </h4> */}
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="username"
                  label="User Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Your Username Name"
                    style={{
                      border: userNameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {userNameError}
                  </div>
                </FloatingLabel>
              </Form.Group>

              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Send Email
              </Button>
            </Form>
          </div>
        </>
      ) : (
        <>
          <br></br>
          <img
            src={logo}
            alt="logo"
            //   style={{ width: "150px", height: "150px", padding: 10 }}
          />
          <br></br>
          <br></br>

          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            {/* <h4>
                <FontAwesomeIcon icon={faUser} /> &nbsp; <u>Login</u>
              </h4> */}
            <Form
              onSubmit={handleSubmit}
              style={{
                color: "purple",
              }}
            >
              <Form.Group
                style={{
                  color: "purple",
                }}
              >
                <FloatingLabel
                  controlId="username"
                  label="User Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Your Username Name"
                    style={{
                      border: userNameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {userNameError}
                  </div>
                </FloatingLabel>
              </Form.Group>

              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Send Email
              </Button>
              <br></br>
              <br></br>
              <a href="/bancassurance/portal/login">Back to Login Page</a>
            </Form>
            <ToastContainer autoClose={20000} />
          </div>
        </>
      )}
    </div>
  );
}

export default ForgotPassword;
