import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import BankData from "./data/BankData";
import SignatureCanvas from "react-signature-canvas";
import "../sigCanvas.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import InstData from "./data/InstData";

export class InstitutionDetails extends PureComponent {
  sigRef = React.createRef({});

  clear = (e) => {
    e.preventDefault();
    // console.log(this.sigRef.current.getTrimmedCanvas().toDataURL("image/png"));
    this.sigRef.current.clear();
  };
  render() {
    const institutions = InstData;
    const {
      staffNumberError,
      values,
      handleChange,
      deductionDateError,
      institutionError,
    } = this.props;

    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var day = tomorrow.getDay();
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (day === 0) {
      dd = dd + 1;
    }

    if (day === 6) {
      dd = dd + 2;
    }

    var tm = yyyy + "-" + mm + "-" + dd;
    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="bank"
            label="Select Your Institution"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={handleChange("institution")}
              defaultValue={values.institution}
              style={{
                border: institutionError ? "1px solid red" : "",
              }}
            >
              <option></option>
              {/* {banks.map((bank) => (
                      <option
                        key={bank.id}
                        value={bank.value + "-" + bank.label}
                      >
                        {bank.label}
                      </option>
                    ))} */}
              {institutions.map((institution) => (
                <option
                  key={institution.Institution_Code}
                  value={
                    institution.Institution_Code +
                    "-" +
                    institution.Institution_Name
                  }
                >
                  {institution.Institution_Name}
                </option>
              ))}
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {institutionError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="staffid"
            label="Staff ID Number"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Enter Your Staff ID Number"
              onChange={handleChange("staffNumber")}
              defaultValue={values.staffNumber}
              style={{
                border: staffNumberError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {staffNumberError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="deductionDate"
            label="Date of First Deduction"
            className="mb-2"
          >
            <Form.Control
              type="date"
              min={tm}
              // placeholder="Enter Beneficiary Date of Birth"
              // defaultValue={moment(newDOB).format("YYYY-MM-DD")}
              onChange={handleChange("deductionDate")}
              defaultValue={values.deductionDate}
              style={{
                border: deductionDateError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {deductionDateError}
            </div>
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default InstitutionDetails;
