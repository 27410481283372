import React, { useContext } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
// import "./sb-admin-2.min.css";
import "../../static/vendor/fontawesome-free/css/all.min.css";
// import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
import logo from "../../static/img/starlife-logo.png";
import DataTable from "react-data-table-component";
import Export from "react-data-table-component";
import { Modal, Button, InputGroup } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Viewer from "react-viewer";
import pdfmodal from "./pdf_modal";
import FlashMessage from "react-flash-message";
import AuthContext from "../../store/auth-context";
import proposalData from "./proposalsdata";
import atwima from "../../akrb_logo22.png";
import amenfiman from "../../Amenfi-Man-logo2.png";
import gcb from "../../gcb-logo.png";
import access from "../../access-logo-main.png";
import okomfo from "../../Logo-okomfo-new.png";
import ahantaman from "../../ahantaman-logo3-1.png";
// import { env } from "process";
import { titleCase } from "../func/utils";
import Form from "react-bootstrap/Form";
import GCBBankBranchesData from "./data/GCBBankBranchesData";
import AccessBankBranchesData from "./data/AccessBankBranchesData";
import omni from "../../omnibsic_bank_logo.png";
import OmniBankBranchesData from "./data/OmniBankBranchesData";

function AdminPortalCentral() {
  // componentDidMount() {
  //   const script = document.createElement("script");
  //   script.src = "static/vendor/datatables/jquery.dataTables.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // }

  const authCtx = useContext(AuthContext);

  const [pending, setPending] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [proposals, setProposals] = React.useState([]);
  const [url, setUrl] = React.useState([]);
  const [proposalurl, setProposalurl] = React.useState([]);
  const [showModal, setShow] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [showRejectMessage, setShowRejectMessage] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [policyDetails, setPolicyDetails] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [policyNo, setPolicyNo] = React.useState(null);
  const [pDetails, setPDetails] = React.useState([]);
  const [proposerId, setProposerId] = React.useState(null);
  const [yesChecked, setYesChecked] = React.useState(null);
  const [noChecked, setNoChecked] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState("all");

  const API_USER = process.env.REACT_APP_API_USER;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const API_URL = process.env.REACT_APP_BASE_URL;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
  };

  const bank = authCtx.bank;

  let inputStyle = {
    backgroundColor: "#763984",
    color: "white",
  };

  let proposal_logo = "";
  let bank_branch = [];

  if (bank === "ATWIMA KWANWOMA RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#009900",
      color: "#fff",
    };
    proposal_logo = atwima;
  } else if (bank === "AMENFIMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = amenfiman;
  } else if (bank === "GCB BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ffc52a",
      color: "#4a4a4a",
    };
    proposal_logo = gcb;
    bank_branch = GCBBankBranchesData;
  } else if (bank === "ACCESS BANK BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#ee7e01",
      color: "#fff",
    };
    proposal_logo = access;
    bank_branch = AccessBankBranchesData;
  } else if (bank === "OKOMFO ANOKYE RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#296F24",
      color: "#fff",
    };
    proposal_logo = okomfo;
  } else if (bank === "AHANTAMAN RURAL BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#A80404",
      color: "#fff",
    };
    proposal_logo = ahantaman;
  } else if (bank === "BSIC BANCASSURANCE") {
    inputStyle = {
      backgroundColor: "#191c4a",
      color: "#fff",
    };
    proposal_logo = omni;
    bank_branch = OmniBankBranchesData;
  } 

  const handleSearchAction = (e) => {
    e.preventDefault();
    setPending(true);

    const searchDate = {
      Bank_Branch: e.target.bank_branch.value,
      Date_From: e.target.date_from.value,
      Date_To: e.target.date_to.value,
      Api_User: API_USER,
      Api_Key: API_KEY,
    };

    axios
      .post(API_URL + "/SL_DisplayDigitalOnboarding", searchDate)
      .then((response) => {
        setProposals(response.data);
        setTitle(
          "Bancassurance Proposals between " +
            moment(searchDate.Date_From).format("DD/MM/YYYY") +
            " and " +
            moment(searchDate.Date_To).format("DD/MM/YYYY")
        );
        // setTitle(
        //   `${titleCase(authCtx.bank)} Proposals between ` +
        //     moment(searchDate.Date_From).format("DD/MM/YYYY") +
        //     ` and ` +
        //     moment(searchDate.Date_To).format("DD/MM/YYYY")
        // );
        setPending(false);
      })
      .catch((error) => {
        setProposals([]);
        setPending(false);
      });
  };

  const handleViewAction = (e, data, id) => {
    e.preventDefault();
    setLoading(true);
    setProposerId(id);
    setPDetails(data);
    if (data.dataProtection === "Yes") {
      setYesChecked(true);
    } else {
      setNoChecked(true);
    }
    setLoading(false);
    handleShow();
  };

  const handleApproveAction = (id) => {
    setLoading(true);
    handleClose();
    const proposal = {
      Id: id,
      User: authCtx.userName,
      Status: "Approved",
      Reason: null,
    };

    axios
      .post("/api/merchantportal/onboarding/approval", proposal)
      .then((response) => {
        setProposals(
          proposals.filter(function (p) {
            return p._id !== id;
          })
        );
        // setPending(true);
        setShowMessage(true);
        setLoading(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleRejectAction = (id, reason) => {
    setLoading(true);
    // setShowMessage(false);
    // e.preventDefault();

    handleClose();
    const proposal = {
      Id: id,
      User: authCtx.userName,
      Status: "Rejected",
      Reason: reason,
    };

    axios
      .post("/api/merchantportal/onboarding/approval", proposal)
      .then((response) => {
        setProposals(
          proposals.filter(function (p) {
            return p._id !== id;
          })
        );
        // setPending(true);
        setShowRejectMessage(true);
        setLoading(false);
        setTimeout(() => {
          setShowRejectMessage(false);
        }, 5000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    let interval = setInterval(() => {
      (async () => {
        const timeout = await setTimeout(() => {
          const proposals = {
            Bank_Branch: bank,
            Api_User: API_USER,
            Api_Key: API_KEY,
          };

          axios
            .post(API_URL + "/SL_DisplayDigitalOnboarding", proposals)
            .then((response) => {
              setProposals(response.data);
              setPending(false);
              setTitle("Bancassurance Proposals Today");
              // setTitle(`${titleCase(bank)} Proposals Today`);
            })
            .catch((error) => {
              setProposals([]);
              setPending(false);
            });

          // setRows(data);
          // setPending(false);
        }, 7000);
        return () => clearTimeout(timeout);
      })();
    }, 1800000); //1800000
    return () => {
      clearInterval(interval);
    };
  }, [bank, API_URL, API_USER, API_KEY]);

  React.useEffect(() => {
    (async () => {
      const timeout = await setTimeout(() => {
        const proposals = {
          Bank_Branch: bank,
          Api_User: API_USER,
          Api_Key: API_KEY,
        };

        axios
          .post(API_URL + "/SL_DisplayDigitalOnboarding", proposals)
          .then((response) => {
            setProposals(response.data);
            setPending(false);
            setTitle("Bancassurance Proposals Today");
            // setTitle(`${titleCase(bank)} Proposals Today`);
          })
          .catch((error) => {
            setProposals([]);
            setPending(false);
          });

        // setRows(data);
        // setPending(false);
      }, 7000);
      return () => clearTimeout(timeout);
    })();
  }, [bank, API_URL, API_USER, API_KEY]);

  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.date["$date"]).format("DD-MM-YYYY"),
      sortable: true,
      // reorder: true,
      width: "100px",
      // style: {
      //   backgroundColor: "#763984",
      //   color: "white",
      // },
    },
    {
      name: "Name",
      selector: (row) =>
        row.data.firstName +
        " " +
        row.data.otherNames +
        " " +
        row.data.lastName,
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    // {
    //   name: "First Name",
    //   selector: (row) => row.FirstName,
    //   // sortable: true,
    //   reorder: true,
    //   width: "120px",
    // },
    // {
    //   name: "Last Name",
    //   selector: (row) => row.LastName,
    //   // sortable: true,
    //   reorder: true,
    //   width: "120px",
    // },
    {
      name: "Phone Number",
      selector: (row) => row.data.phoneNumber,
      // sortable: true,
      // reorder: true,
      width: "150px",
    },
    {
      name: "Product",
      selector: (row) => row.data.product.split("-")[2],
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Transaction Branch",
      selector: (row) => row.data.bankBranch.split("-")[1],
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Direct Sales Officer",
      selector: (row) => row.data.agentName,
      // sortable: true,
      // reorder: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <span
            className="btn btn-info"
            title="View Proposal"
            onClick={(e) => handleViewAction(e, row.data, row._id)}
          >
            View <i className="fas fa-eye"></i>
          </span>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          {/* // <!-- Page Wrapper --> */}
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
              id="accordionSidebar"
            >
              {/* <!-- Sidebar - Brand --> */}
              <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/bancassurance/portal/home"
              >
                <div
                  className="sidebar-brand-icon"
                  style={{ backgroundColor: "white", marginLeft: "50px" }}
                >
                  <img src={logo} height="70" />
                </div>
              </a>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider my-0"></hr>
              {/* <!-- Nav Item - Dashboard --> */}
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/home">
                  <i className="fas fa-fw fa-tachometer-alt"></i>
                  <span>Merchant Dashboard</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/report/successful"
                >
                  <i className="fa fa-file"></i>
                  <span>Reports</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/change-password"
                >
                  <i className="fa fa-file"></i>
                  <span>Change Password</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fas fa-sign-out-alt"></i>
                  <span onClick={logoutHandler}>Logout</span>
                </a>
              </li>
            </ul>
            {/* <!-- End of Sidebar --> */}

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              {/* <!-- Main Content --> */}
              <div id="content">
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* <!-- Sidebar Toggle (Topbar) --> */}
                  <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {% if current_user.is_authenticated %} */}
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                          {authCtx.userName}
                          {/* <font color="#763984">DELIJAH</font> */}
                        </span>
                        {/* {% endif %} */}
                        <i className="fas fa-user"></i>
                      </a>
                      {/* <!-- Dropdown - User Information --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logoutModal"
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
                  {/* <!-- Page Heading --> */}
                  {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
                  {/* <!-- DataTales Example --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Search by Date{" "}
                      </h6>
                      <div>
                        <form method="POST" onSubmit={handleSearchAction}>
                          <input
                            type="date"
                            id="date_from"
                            name="date_from"
                            required
                            // value=""
                          />{" "}
                          -to-
                          <input
                            type="date"
                            id="date_to"
                            name="date_to"
                            required
                            // value=""
                          />
                          &nbsp;
                          <select
                            id="bank_branch"
                            name="bank_branch"
                            required
                            // width="100"
                            // style={{ width: "100" }}
                          >
                            <option>Select Branch</option>
                            <option value="all">All</option>
                            {bank_branch.map((d) => (
                              <option key={d.bbrCode} value={d.bbrCode}>
                                {d.bbrBranchName}
                              </option>
                            ))}
                          </select>
                          &nbsp;
                          <button className="btn btn-info" type="submit">
                            <i className="fas fa-search"></i> Search by Date{" "}
                          </button>
                        </form>
                      </div>
                      <br />
                    </div>
                    <div className="card-body">
                      {showMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-success">
                              <strong>Proposal approved successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      {showRejectMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-danger">
                              <strong>Proposal rejected successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      <div className="table-responsive">
                        {/* <button
                      id="export"
                      name="export"
                      value="export"
                      data-toggle="modal"
                      data-target="#ExportModal"
                      className="btn btn-primary"
                      type="submit"
                      style={{ float: "right", height: "31px" }}
                    >
                      <i className="fas fa-download"></i> Export
                    </button> */}

                        {proposals.length > 0 ? (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={proposals}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        ) : (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={[]}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of Main Content --> */}

              {/* PDF Modal */}
              <Modal
                fullscreen={true}
                // size="xxl"
                show={showModal}
                onHide={handleClose}
                // fullscreen={"xl-down"}
              >
                <Modal.Header closeButton style={inputStyle}>
                  <Modal.Title>Proposal and Mandate Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div style={{ width: "50%", float: "left" }}>
                      <font size="1">
                        <table
                          style={{
                            borderWidth: "2px",
                            borderColor: "#aaaaaa",
                            borderStyle: "solid",
                            width: "90%",
                          }}
                        >
                          <tr>
                            <td colSpan={"3"} style={{ color: "#763984" }}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td align="center">
                                    <img src={proposal_logo} alt="logo" />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width={"50%"} valign="top">
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td colSpan={"2"} style={inputStyle}>
                                    PERSONAL DETAILS
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"30%"}>
                                    Surname:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .surname}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Middle Name(s):
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .middle_name}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    First Name:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .first_name}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Date of Birth:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .dob}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Gender:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .gender}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Mobile:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .mobile}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                {/* <tr>
                              <td align="left" width={"40%"}>
                                Email:
                              </td>
                              <td align="left">
                                <u>
                                  <b>
                                    {Object.keys(policyDetails).length > 0 &&
                                      policyDetails.data.proposerDetails[0]
                                        .prpEmail}
                                  </b>
                                </u>
                              </td>
                            </tr> */}
                              </table>
                            </td>
                            <td width={"50%"} valign="top">
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td colSpan={"2"} style={inputStyle}>
                                    PAYMENT DETAILS
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Bank:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .bank}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Branch:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .branch}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Account Number:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .account_number}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Payment Frequency:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .payment_frequency}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Deduction Date:
                                  </td>
                                  <td align="left">
                                    <u>
                                      <b>
                                        {Object.keys(policyDetails).length >
                                          0 &&
                                          policyDetails.data.proposerDetails
                                            .deduction_date}
                                      </b>
                                    </u>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td style={inputStyle} align="center">
                                    PRODUCT DETAILS
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Product Name:{" "}
                              <u>
                                <b>
                                  {Object.keys(policyDetails).length > 0 &&
                                    policyDetails.data.proposerDetails
                                      .prod_name}
                                </b>
                              </u>
                            </td>
                            <td align="left">
                              Policy Term:{" "}
                              <u>policyDetails.data.proposerDetails .term</u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Medical Diagnostics: <u></u>
                            </td>
                            <td align="left">
                              Critical Illness: <u></u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Premium (Ghc):{" "}
                              <u>
                                <b>
                                  {Object.keys(policyDetails).length > 0 &&
                                    policyDetails.data.proposerDetails.premium}
                                </b>
                              </u>
                            </td>
                            <td align="left">
                              Sum Assured (Ghc):{" "}
                              <u>
                                <b>
                                  {Object.keys(policyDetails).length > 0 &&
                                    policyDetails.data.proposerDetails.sa}
                                </b>
                              </u>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td
                                    colSpan={"8"}
                                    style={inputStyle}
                                    align="center"
                                  >
                                    BENEFICIARIES
                                  </td>
                                </tr>
                                <tr>
                                  {/* <td>Surname</td> */}
                                  <td>Name</td>
                                  <td>Date of Birth</td>
                                  <td>Relationship</td>
                                  <td>Percentage (%)</td>
                                  <td>Contact No.</td>
                                </tr>
                                {Object.keys(policyDetails).length > 0 &&
                                  policyDetails.data.myPolicyBeneficiaries.map(
                                    (ben) => (
                                      <tr key={ben.id}>
                                        {/* <td>
                                          <b>{ben.benOtherNames}</b>
                                        </td> */}
                                        <td>
                                          <b>{ben.name}</b>
                                        </td>
                                        <td>
                                          <b>{ben.dob}</b>
                                        </td>
                                        <td>
                                          <b>{ben.relationship}</b>
                                        </td>
                                        <td>
                                          <b>{ben.percentage}</b>
                                        </td>
                                        <td>
                                          <b>{ben.contact}</b>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </table>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td
                                    colSpan={"8"}
                                    style={inputStyle}
                                    align="center"
                                  >
                                    TRUSTEES
                                  </td>
                                </tr>
                                <tr>
                                  <td>Surname</td>
                                  <td>Other Names</td>
                                  <td>Date of Birth</td>
                                  <td>Relationship</td>
                                  <td>Contact No.</td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <br />
                          {/* <tr>
                        <td colSpan={"2"}>
                          <table border="0px" width={"100%"}>
                            <tr>
                              <td
                                colSpan={"2"}
                                style={inputStyle}
                                align="center"
                              >
                                DATA PROTECTION
                              </td>
                            </tr>
                            <tr>
                              <td align="left" width={"40%"}>
                                I hereby consent to the processing of my
                                personal data for business relationship and
                                further acknowledge and agree that my personal
                                data may be disclosed to entities associated,
                                affiliated or in relation to this business to
                                achieve the purpose of processing under this
                                consent.
                                <div>
                                  <InputGroup
                                    className="mb-3"
                                    // onChange={handleChange("paymentFrequency")}
                                    // defaultChecked={true}
                                  >
                                    <InputGroup.Radio
                                      name="data"
                                      value="Yes"
                                      aria-label="Radio 1"
                                      defaultChecked={true}
                                      disabled={true}
                                    />{" "}
                                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <InputGroup.Radio
                                      name="data"
                                      value="No"
                                      aria-label="Radio 2"
                                      disabled={true}
                                    />{" "}
                                    &nbsp; No
                                  </InputGroup>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr> */}
                          <tr>
                            <td colSpan={"2"}>
                              <table border="0px" width={"100%"}>
                                <tr>
                                  <td
                                    colSpan={"2"}
                                    style={inputStyle}
                                    align="center"
                                  >
                                    DECLARATION
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" width={"40%"}>
                                    Declaration by Applicant. <br /> I{" "}
                                    {Object.keys(policyDetails).length > 0 && (
                                      <u>
                                        <b>
                                          {
                                            policyDetails.data.proposerDetails
                                              .first_name
                                          }{" "}
                                          {policyDetails.data.proposerDetails
                                            .middle_name &&
                                            policyDetails.data.proposerDetails
                                              .middle_name}{" "}
                                          {
                                            policyDetails.data.proposerDetails
                                              .surname
                                          }
                                        </b>
                                      </u>
                                    )}{" "}
                                    , declare that every statement in response
                                    to questions asked in this application is
                                    true and correct to the best of my
                                    knowledge. I agree that this application
                                    shall serve as the basis and form part of
                                    the contract. All the questions have been
                                    explained to me in the language that I
                                    understand and I have been made to
                                    understand that this contract shall become
                                    operative until all of the following
                                    conditions have been met:
                                    <ol>
                                      <li>
                                        This application has to be approved by
                                        StarLife Assurance Company Limited.
                                      </li>
                                      <li>
                                        The appropriate premium will be paid.
                                      </li>
                                      <li>
                                        I satisfy all the conditions precedent
                                        to the policy especially those
                                        pertaining to my health.
                                      </li>
                                      <li>
                                        All the persons proposed for cover are
                                        alive and in good health.
                                      </li>
                                    </ol>
                                    <InputGroup
                                      className="mb-3"
                                      // onChange={handleChange("paymentFrequency")}
                                      // defaultChecked={true}
                                    >
                                      <InputGroup.Checkbox
                                        name="confirm"
                                        aria-label="Checkbox 1"
                                        defaultChecked={true}
                                        disabled={true}
                                      />{" "}
                                      &nbsp; I confirm and agree to the terms of
                                      conditions
                                    </InputGroup>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          {/* <tr>
                  <td>
                    Proposer's Signature:&nbsp;
                    <img
                      width="100"
                      height="30"
                      src={`data:image/png;base64,${this.props.values.signature}`}
                      alt="signature"
                    />
                  </td>
                  <td>Date: {moment().format("DD-MM-YYYY HH:mm:ss")}</td>
                </tr> */}
                        </table>
                      </font>
                    </div>
                    <div style={{ width: "50%", float: "right" }}>
                      <embed
                        id="pdf"
                        src={url}
                        // src="http://127.0.0.1:5000/StarLife/SL_PDF/PWPP1006003221032052/YAW%20CHAMFORO/FIRST%20ATLANTIC%20BANK/01092022#toolbar=0&navpanes=0&scrollbar=0"
                        frameBorder="0"
                        width="100%"
                        height="600px"
                      />
                    </div>
                    <br style={{ clear: "both" }} />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* End of PDF Modal */}

              {/* <!-- Footer --> */}
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()} StarLife
                      Assurance. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
                </div>
              </footer>
              {/* <!-- End of Footer --> */}
            </div>
            {/* <!-- End of Content Wrapper --> */}
          </div>
          {/* //   <!-- End of Page Wrapper --> */}

          {/* <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      ) : (
        <>
          {/* // <!-- Page Wrapper --> */}
          <div id="wrapper">
            {/* <!-- Sidebar --> */}
            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
              id="accordionSidebar"
            >
              {/* <!-- Sidebar - Brand --> */}
              <a
                className="sidebar-brand d-flex align-items-center justify-content-center"
                href="/bancassurance/portal/home"
              >
                <div
                  className="sidebar-brand-icon"
                  style={{ backgroundColor: "white", marginLeft: "50px" }}
                >
                  <img src={logo} height="70" />
                </div>
              </a>
              <br></br>
              {/* <!-- Divider --> */}
              {/* <hr className="sidebar-divider my-0"></hr> */}
              {/* <!-- Nav Item - Dashboard --> */}
              <li className="nav-item">
                <a className="nav-link" href="/bancassurance/portal/home">
                  <i class="fa fa-home" aria-hidden="true"></i>
                  <span>Home</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/report/successful"
                >
                  <i className="fa fa-file"></i>
                  <span>Reports</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/bancassurance/portal/change-password"
                >
                  <i className="fa fa-key"></i>
                  <span>Change Password</span>
                </a>
              </li>
              {/* <!-- Divider --> */}
              <hr className="sidebar-divider"></hr>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <i className="fas fa-sign-out-alt"></i>
                  <span onClick={logoutHandler}>Logout</span>
                </a>
              </li>
            </ul>
            {/* <!-- End of Sidebar --> */}

            {/* <!-- Content Wrapper --> */}
            <div id="content-wrapper" className="d-flex flex-column">
              {/* <!-- Main Content --> */}
              <div id="content">
                {/* <!-- Topbar --> */}
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                  {/* <!-- Sidebar Toggle (Topbar) --> */}
                  <button
                    id="sidebarToggleTop"
                    className="btn btn-link d-md-none rounded-circle mr-3"
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <ul className="navbar-nav ml-auto">
                    <div className="topbar-divider d-none d-sm-block"></div>
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="userDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {/* {% if current_user.is_authenticated %} */}
                        <span className="mr-2 d-none d-lg-inline text-gray-600">
                          {authCtx.userName}
                          {/* <font color="#763984">DELIJAH</font> */}
                        </span>
                        {/* {% endif %} */}
                        <i className="fas fa-user"></i>
                      </a>
                      {/* <!-- Dropdown - User Information --> */}
                      <div
                        className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="userDropdown"
                      >
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                          Settings
                        </a>
                        <a className="dropdown-item" href="#">
                          <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                          Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <a
                          className="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#logoutModal"
                        >
                          <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                          Logout
                        </a>
                      </div>
                    </li>
                  </ul>
                </nav>

                {/* <!-- Begin Page Content --> */}
                <div className="container-fluid">
                  {/* <!-- Page Heading --> */}
                  {/* <h1 className="h3 mb-2 text-gray-800">Successful Proposals</h1> */}
                  {/* <!-- DataTales Example --> */}
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Search by Date{" "}
                      </h6>{" "}
                      <br />
                      <div>
                        <form method="POST" onSubmit={handleSearchAction}>
                          <input
                            type="date"
                            id="date_from"
                            name="date_from"
                            required
                            // value=""
                          />{" "}
                          -to-
                          <input
                            type="date"
                            id="date_to"
                            name="date_to"
                            required
                            // value=""
                          />
                          &nbsp;
                          <select
                            id="bank_branch"
                            name="bank_branch"
                            required
                            // width="100"
                            // style={{ width: "100" }}
                          >
                            <option value="">Select Branch</option>
                            <option value={bank}>All</option>
                            {bank_branch.map((d) => (
                              <option key={d.bbrCode} value={d.bbrCode}>
                                {d.bbrBranchName}
                              </option>
                            ))}
                          </select>
                          &nbsp;
                          <button className="btn btn-info" type="submit">
                            <i className="fas fa-search"></i> Search by Date{" "}
                          </button>
                        </form>
                      </div>
                      <br />
                    </div>
                    <div className="card-body">
                      {showMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-success">
                              <strong>Proposal approved successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      {showRejectMessage && (
                        <div>
                          <FlashMessage>
                            <div className="alert alert-danger">
                              <strong>Proposal rejected successfully.</strong>
                            </div>
                          </FlashMessage>
                        </div>
                      )}
                      <div className="table-responsive">
                        {/* <button
                      id="export"
                      name="export"
                      value="export"
                      data-toggle="modal"
                      data-target="#ExportModal"
                      className="btn btn-primary"
                      type="submit"
                      style={{ float: "right", height: "31px" }}
                    >
                      <i className="fas fa-download"></i> Export
                    </button> */}

                        {proposals.length > 0 ? (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={proposals}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        ) : (
                          <DataTable
                            title={title}
                            columns={columns}
                            data={[]}
                            defaultSortFieldId={1}
                            pagination
                            BuiltinStory
                            theme="default"
                            fixedHeader
                            progressPending={pending}
                            // fixedHeaderScrollHeight="300px"
                            // selectableRows
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End of Main Content --> */}

              {/* PDF Modal */}
              <Modal
                // fullscreen={"true"}
                dialogClassName="modal-90w"
                // size="xl"
                show={showModal}
                onHide={handleClose}
                position="fixed"
                // fullscreen={"xl-down"}
              >
                <Modal.Header closeButton style={inputStyle}>
                  <Modal.Title>Proposal Details</Modal.Title>
                </Modal.Header>
                {/* <Modal.Header>
                  <Button variant="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Header> */}

                <Modal.Body>
                  <table
                    style={{
                      borderWidth: "2px",
                      borderColor: "#aaaaaa",
                      borderStyle: "solid",
                      width: "100%",
                    }}
                  >
                    <tr>
                      <td colSpan={"3"} style={{ color: "#763984" }}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td align="center">
                              <img src={proposal_logo} alt="logo" />
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <br></br>
                    {/* <tr>
                      <td colSpan={"3"} style={{ color: "#4a4a4a" }}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td>
                              {" "}
                              <h4>
                                {pDetails.product &&
                                  pDetails.product.split("-")[2]}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr> */}
                    <tr>
                      <td width={"50%"} valign="top">
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td colSpan={"2"} style={inputStyle}>
                              PERSONAL DETAILS
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"30%"}>
                              Surname:
                            </td>
                            <td align="left">
                              <u>
                                <b>{pDetails.lastName}</b>
                              </u>
                            </td>
                            {/* <td>box</td> */}
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Middle Name(s):
                            </td>
                            <td align="left">
                              <u>
                                <b>{pDetails.otherNames}</b>
                              </u>
                            </td>
                            {/* <td>box</td> */}
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              First Name:
                            </td>
                            <td align="left">
                              <u>
                                <b>{pDetails.firstName}</b>
                              </u>
                            </td>
                          </tr>
                          {pDetails.clntCode ? (
                            <tr>
                              <td align="left" width={"40%"}>
                                Date of Birth:{" "}
                                <u>
                                  <b>{pDetails.dob}</b>
                                </u>
                              </td>
                              <td align="left">
                                Gender:{" "}
                                <u>
                                  <b>{pDetails.gender}</b>
                                </u>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td align="left" width={"40%"}>
                                Date of Birth:{" "}
                                <u>
                                  <b>
                                    {moment(pDetails.dob).format("DD-MM-YYYY")}
                                  </b>
                                </u>
                              </td>
                              <td align="left">
                                Gender:{" "}
                                <u>
                                  <b>{pDetails.gender}</b>
                                </u>
                              </td>
                            </tr>
                          )}

                          {/* <tr>
                          <td align="left" width={"40%"}>
                            Postal Address:
                          </td>
                          <td align="left">
                            <u>{this.props.values.postalAddress}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Residential Address:
                          </td>
                          <td align="left">
                            <u>{this.props.values.residentialAddress}</u>
                          </td>
                        </tr> */}
                          <tr>
                            <td align="left" width={"40%"}>
                              Mobile:{" "}
                              <u>
                                <b>{pDetails.phoneNumber}</b>
                              </u>
                            </td>
                            <td align="left">
                              Email:{" "}
                              <u>
                                <b>{pDetails.email}</b>
                              </u>
                            </td>
                          </tr>
                        </table>
                      </td>
                      {/* <td width="5px">Space</td> */}
                      <td width={"50%"} valign="top">
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td colSpan={"2"} style={inputStyle}>
                              PAYMENT DETAILS
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Bank:
                            </td>
                            <td align="left">
                              <u>
                                <b>
                                  {pDetails.bank && pDetails.bank.split("-")[1]}
                                </b>
                              </u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Branch:
                            </td>
                            <td align="left">
                              <u>
                                <b>
                                  {pDetails.bankBranch &&
                                    pDetails.bankBranch.split("-")[1]}
                                </b>
                              </u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Account Number:
                            </td>
                            <td align="left">
                              <u>
                                <b>{pDetails.accountNumber}</b>
                              </u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Payment Frequency:
                            </td>
                            <td align="left">
                              <u>
                                <b>{pDetails.paymentFrequency}</b>
                              </u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Deduction Date:
                            </td>
                            <td align="left">
                              <u>
                                <b>
                                  {moment(pDetails.deductionDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </b>
                              </u>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td style={inputStyle} align="center">
                              PRODUCT DETAILS
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" width={"40%"}>
                        Product Name:{" "}
                        <u>
                          <b>
                            {pDetails.product && pDetails.product.split("-")[2]}
                          </b>
                        </u>
                      </td>
                      <td align="left">
                        Policy Term:{" "}
                        <u>
                          <b>{pDetails.term} Years</b>
                        </u>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" width={"40%"}>
                        Total and permanent Disability:{" "}
                        <u>
                          <b>{pDetails.tpd}</b>
                        </u>
                      </td>
                      <td align="left">
                        Dread Disease:{" "}
                        <u>
                          <b>{pDetails.dreadDisease}</b>
                        </u>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" width={"40%"}>
                        Premium (Ghc):{" "}
                        <u>
                          <b>
                            {" "}
                            {pDetails.premium
                              ? pDetails.premium
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                          </b>
                        </u>
                      </td>
                      <td align="left">
                        Sum Assured (Ghc):{" "}
                        <u>
                          <b>
                            {" "}
                            {pDetails.sa
                              ? pDetails.sa
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                          </b>
                        </u>
                      </td>
                    </tr>
                    {pDetails.escalationRate !== "" && (
                      <tr>
                        <td align="left">
                          Escalation Rate (%):{" "}
                          <u>
                            <b>{pDetails.escalationRate}</b>
                          </u>
                        </td>
                      </tr>
                    )}
                    {/* {this.props.values.premium !== "" && (
                    <tr>
                      <td align="left" width={"40%"}>
                        Premium (Ghc):{" "}
                        <u>
                          <b>
                            {" "}
                            {this.props.values.premium
                              ? this.props.values.premium
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                          </b>
                        </u>
                      </td>
                      <td align="left">
                        Escalation Rate (%):{" "}
                        <u>
                          <b>{this.props.values.escalationRate}</b>
                        </u>
                      </td>
                    </tr>
                  )}
                  {this.props.values.sa !== "" && (
                    <tr>
                      <td align="left" width={"40%"}>
                        Sum Assured (Ghc):{" "}
                        <u>
                          <b>
                            {" "}
                            {this.props.values.sa
                              ? this.props.values.sa
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                          </b>
                        </u>
                      </td>
                      <td align="left">
                        Escalation Rate (%):{" "}
                        <u>
                          <b>{this.props.values.escalationRate}</b>
                        </u>
                      </td>
                    </tr>
                  )} */}

                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td colSpan={"8"} style={inputStyle} align="center">
                              BENEFICIARIES
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <u>Surname</u>
                            </td>
                            <td>
                              <u>Other Names</u>
                            </td>
                            <td>
                              <u>Date of Birth</u>
                            </td>
                            <td>
                              <u>Relationship</u>
                            </td>
                            <td>
                              <u>Percentage (%)</u>
                            </td>
                            <td>
                              <u>Contact No.</u>
                            </td>
                          </tr>
                          {pDetails.benList &&
                            pDetails.benList.map((ben) => (
                              <tr key={ben.id}>
                                <td>
                                  <b>{ben.surname}</b>
                                </td>
                                <td>
                                  <b>{ben.othernames}</b>
                                </td>
                                <td>
                                  <b>{ben.dob}</b>
                                </td>
                                <td>
                                  <b>{ben.relationship}</b>
                                </td>
                                <td>
                                  <b>{ben.percent}</b>
                                </td>
                                <td>
                                  <b>{ben.phone}</b>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </td>
                    </tr>
                    {pDetails.trusteeRequired && (
                      <tr>
                        <td colSpan={"2"}>
                          <table border="0px" width={"100%"}>
                            <tr>
                              <td
                                colSpan={"8"}
                                style={inputStyle}
                                align="center"
                              >
                                TRUSTEES
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <u>Surname</u>
                              </td>
                              <td>
                                <u>Other Names</u>
                              </td>
                              <td>
                                <u>Date of Birth</u>
                              </td>
                              <td>
                                <u>Relationship</u>
                              </td>
                              <td>
                                <u>Contact No.</u>
                              </td>
                            </tr>
                            {pDetails.trustee.map((trustee) => (
                              <tr key={trustee.id}>
                                <td>
                                  <b>{trustee.surname}</b>
                                </td>
                                <td>
                                  <b>{trustee.othernames}</b>
                                </td>
                                <td>
                                  <b>{trustee.dob}</b>
                                </td>
                                <td>
                                  <b>{trustee.relationship}</b>
                                </td>
                                <td>
                                  <b>{trustee.phone}</b>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </td>
                      </tr>
                    )}
                    {pDetails.pa === "Yes" && (
                      <tr>
                        <td colSpan={"2"}>
                          <table border="0px" width={"100%"}>
                            <tr>
                              <td
                                colSpan={"8"}
                                style={inputStyle}
                                align="center"
                              >
                                PERSONAL ACCIDENT FOR CHILD
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <u>Surname</u>
                              </td>
                              <td>
                                <u>Other Names</u>
                              </td>
                              <td>
                                <u>Date of Birth</u>
                              </td>
                              <td>
                                <u>Gender</u>
                              </td>
                            </tr>
                            {/* {this.props.values.familyList.map((member) => ( */}
                            <tr>
                              <td>
                                <b>{pDetails.paSurname}</b>
                              </td>
                              <td>
                                <b>{pDetails.paOthername}</b>
                              </td>
                              <td>
                                <b>
                                  {moment(pDetails.padob).format("DD-MM-YYYY")}
                                </b>
                              </td>
                              <td>
                                <b>{pDetails.pagender}</b>
                              </td>
                            </tr>
                            {/* ))} */}
                          </table>
                        </td>
                      </tr>
                    )}
                    {pDetails.dependentList &&
                      pDetails.dependentList.length > 0 && (
                        <tr>
                          <td colSpan={"2"}>
                            <table border="0px" width={"100%"}>
                              <tr>
                                <td
                                  colSpan={"8"}
                                  style={inputStyle}
                                  align="center"
                                >
                                  DEPENDENTS
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <u>Surname</u>
                                </td>
                                <td>
                                  <u>Other Names</u>
                                </td>
                                <td>
                                  <u>Date of Birth</u>
                                </td>
                                <td>
                                  <u>Relationship</u>
                                </td>
                                <td>
                                  <u>Gender</u>
                                </td>
                              </tr>
                              {pDetails.dependentList.map((dependent) => (
                                <tr key={dependent.id}>
                                  <td>
                                    <b>{dependent.surname}</b>
                                  </td>
                                  <td>
                                    <b>{dependent.othernames}</b>
                                  </td>
                                  <td>
                                    <b>{dependent.dob}</b>
                                  </td>
                                  <td>
                                    <b>
                                      {dependent.relationship.split("-")[1]}
                                    </b>
                                  </td>
                                  <td>
                                    <b>{dependent.gender}</b>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td colSpan={"2"} style={inputStyle} align="center">
                              MEDICAL HISTORY
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Are you presently in good health?:{" "}
                              <u>
                                <b>{pDetails.md1}</b>
                              </u>
                            </td>
                            <td align="left" width={"40%"}>
                              Have you been diagnosed with any medical condition
                              or illness?:{" "}
                              <u>
                                <b>{pDetails.md2}</b>
                              </u>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Are you on any medication?:{" "}
                              <u>
                                <b>{pDetails.md3}</b>
                              </u>
                            </td>
                            <td align="left" width={"40%"}>
                              Have you undergone any surgical operation or
                              procedure?:{" "}
                              <u>
                                <b>{pDetails.md4}</b>
                              </u>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td colSpan={"2"} style={inputStyle} align="center">
                              DATA PROTECTION
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              I hereby consent to the processing of my personal
                              data for business relationship and further
                              acknowledge and agree that my personal data may be
                              disclosed to entities associated, affiliated or in
                              relation to this business to achieve the purpose
                              of processing under this consent.
                              <div>
                                <Form.Group>
                                  <InputGroup
                                    className="mb-3"
                                    disabled={true}
                                    // onChange={this.props.handleChange(
                                    //   "dataProtection"
                                    // )}
                                  >
                                    <InputGroup.Radio
                                      // style={{
                                      //   border: this.state.dataError
                                      //     ? "1px solid red"
                                      //     : "",
                                      // }}
                                      name="data"
                                      value="Yes"
                                      aria-label="Radio 1"
                                      disabled={true}
                                      defaultChecked={yesChecked}
                                    />{" "}
                                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp;
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <InputGroup.Radio
                                      // style={{
                                      //   border: this.state.dataError
                                      //     ? "1px solid red"
                                      //     : "",
                                      // }}
                                      name="data"
                                      value="No"
                                      aria-label="Radio 2"
                                      defaultChecked={noChecked}
                                      disabled={true}
                                    />{" "}
                                    &nbsp; No
                                  </InputGroup>
                                </Form.Group>

                                <div
                                  style={{
                                    textAlign: "left",
                                    color: "red",
                                  }}
                                >
                                  {/* {this.state.dataError} */}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td colSpan={"2"} style={inputStyle} align="center">
                              DECLARATION
                            </td>
                          </tr>
                          <tr>
                            <td align="left" width={"40%"}>
                              Declaration by Applicant. <br /> I{" "}
                              <u>
                                <b>
                                  {pDetails.firstName}{" "}
                                  {pDetails.otherNames && pDetails.otherNames}{" "}
                                  {pDetails.lastName}
                                </b>
                              </u>{" "}
                              , declare that every statement in response to
                              questions asked in this application is true and
                              correct to the best of my knowledge. I agree that
                              this application shall serve as the basis and form
                              part of the contract. All the questions have been
                              explained to me in the language that I understand
                              and I have been made to understand that this
                              contract shall become operative until all of the
                              following conditions have been met:
                              <ol>
                                <li>
                                  This application has to be approved by
                                  StarLife Assurance Company Limited.
                                </li>
                                <li>The appropriate premium will be paid.</li>
                                <li>
                                  I satisfy all the conditions precedent to the
                                  policy especially those pertaining to my
                                  health.
                                </li>
                                <li>
                                  All the persons proposed for cover are alive
                                  and in good health.
                                </li>
                              </ol>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    {/* <br /> */}
                    {pDetails.agnCode && (
                      <tr>
                        <td>
                          Agent Code:{" "}
                          <u>
                            <b>{pDetails.agentCode}</b>
                          </u>
                        </td>
                        <td>
                          Agent Name:{" "}
                          <u>
                            <b>{pDetails.agentName}</b>
                          </u>
                        </td>
                      </tr>
                    )}
                    {/* <br /> */}
                    <tr>
                      <td>
                        Proposer's Signature:&nbsp;
                        {/* `data:image/png;base64,${this.props.values.signature}` */}
                        <img
                          width="100"
                          height="30"
                          src={`data:image/png;base64,${pDetails.signature}`}
                          alt="signature"
                        />
                      </td>
                      <td>
                        Date:{" "}
                        <u>
                          <b>{moment(pDetails.date).format("DD-MM-YYYY")}</b>
                        </u>
                      </td>
                    </tr>
                    <tr>
                      {/* <td align="left" width={"40%"}>
                  Confirm:{" "}
                  <input
                    type="checkbox"
                    style={{ border: "1px solid red" }}
                    // style={{
                    //   border: this.state.confirmError ? "1px solid red" : "",
                    // }}
                    defaultChecked={false}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.confirmError}
                  </div>
                </td> */}

                      <td align="left" width={"40%"} colSpan="2">
                        {/* <br /> */}
                        <div>
                          <Form.Group>
                            <InputGroup
                              className="mb-3"
                              // onChange={handleChange("paymentFrequency")}
                              // defaultChecked={true}
                            >
                              <InputGroup.Checkbox
                                defaultChecked={true}
                                disabled={true}
                              />{" "}
                              &nbsp; I confirm and agree to the terms of
                              conditions
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={"2"} style={inputStyle} align="center">
                        GHANA CARD (IMAGE OF FRONT AND BACK)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          width="550"
                          height="400"
                          src={`data:image/png;base64,${pDetails.ghanaCard}`}
                          alt="signature"
                        />
                      </td>
                      <td>
                        <img
                          width="550"
                          height="400"
                          src={`data:image/png;base64,${pDetails.ghanaCardBack}`}
                          alt="signature"
                        />
                      </td>
                    </tr>
                  </table>
                </Modal.Body>
                <Modal.Footer
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button variant="primary" onClick={handleClose}>
                    Close
                  </Button>
                  &nbsp;&nbsp;
                  <span
                    className="btn btn-danger"
                    title="Reject Proposal"
                    onClick={() => {
                      var reason = prompt(
                        "Are you sure you want to Reject this proposal? Kindly provide the reason"
                      );
                      if (reason === "") {
                        // user pressed OK, but the input field was empty
                        alert("Kindly provide reason for rejecting");
                        return false;
                      } else if (reason) {
                        handleRejectAction(proposerId, reason);
                      } else {
                        // alert("user hit cancel");
                      }
                    }}
                  >
                    {" "}
                    Reject <i className="fas fa-times"></i>
                  </span>
                  &nbsp;&nbsp;
                  <span
                    className="btn btn-success"
                    title="Approve Proposal"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to Approve this proposal?"
                        )
                      )
                        handleApproveAction(proposerId);
                    }}
                  >
                    {" "}
                    Approve <i className="fas fa-check"></i>
                  </span>
                </Modal.Footer>
              </Modal>
              {/* End of PDF Modal */}

              {/* <!-- Footer --> */}
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span>
                      Copyright &copy; {new Date().getFullYear()} StarLife
                      Assurance. Powered by <b>StarLife I.C.T</b>
                    </span>
                  </div>
                </div>
              </footer>
              {/* <!-- End of Footer --> */}
            </div>
            {/* <!-- End of Content Wrapper --> */}
          </div>
          {/* //   <!-- End of Page Wrapper --> */}

          {/* <!-- Scroll to Top Button--> */}
          <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
          </a>
        </>
      )}
    </>
  );
}

export default AdminPortalCentral;
