import React, { PureComponent } from "react";
import logo from "../../StarLife_Chatbot-06.png";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputGroup } from "react-bootstrap";
import "../Welcome.styles.css";
import "../../static/vendor/fontawesome-free/css/all.min.css";
import BanksData from "./data/BanksData";
// import Button from "react-bootstrap/Button";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import check from "../../../src/Green-Round-Tick.png";
import sampleexcel from "./Sample_Excel_Sheet_muxx6s.xlsx";
import DataTable from "react-data-table-component";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import * as XLSX from "xlsx";

export class AccountVerification extends PureComponent {
  constructor(props) {
    super(props);
    this.readExcel = this.readExcel.bind(this);
    this.state = {
      accountNoError: "",
      accountTypeError: "",
      bankError: "",
      momoError: "",
      showBanks: false,
      showMoMo: false,
      loading: false,
      showModal: false,
      accountNo: "",
      accountName: "",
      bankName: "",
      errorMessage: "",
      items: [],
      count: "",
    };
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleRadioChange = (e) => {
    console.log(e);
    if (e.target.form[1].checked) {
      this.setState({ showBanks: true, showMoMo: false });
    } else {
      this.setState({ showBanks: false, showMoMo: true });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };
  handleShow = () => {
    this.setState({ showModal: true });
  };

  setActiveTab = (e) => {
    alert(e);
  };

  verifyAccount = async (bankAccount) => {
    // console.log("Sleeping 5 seconds");
    // console.time();
    // await this.sleep(5000);
    // console.timeEnd();
    let res = await axios.post("/api/customer/account", bankAccount);
    // console.log(res.data);
    let data = res.data;
    let stat = res.data.message.split(" ");

    const account = {
      Account: bankAccount.Account_Number,
      Bank_Name: bankAccount.Bank_Name,
      Name_On_Account: data.data ? data.data.account_name : "",
      Verification_Status: stat[stat.length - 1],
    };

    // console.log("Account", account);

    return account;

    // this.setState({ items: data });
    // this.setState({
    //   items: [...this.state.items, account],
    // });

    // console.log("Items in verifyAccount", this.state.items);
    // return;
  };

  verifyMomo = async (momo) => {
    // console.log("Sleeping 5 seconds");
    // console.time();
    // await this.sleep(5000);
    // console.timeEnd();
    // console.log(momo);
    let res = await axios.post("/api/customer/momoaccount", momo);
    // console.log(res.data.message.split(" ")[-1]);
    let data = res.data;
    let stat = res.data.message.split(" ");
    // console.log(stat[stat.length - 1]);

    const account = {
      Account: momo.Phone_Number,
      Bank_Name: momo.Network_Name,
      Name_On_Account: data.data ? data.data.account_name : "",
      Verification_Status: stat[stat.length - 1],
    };

    return account;

    // this.setState({ items: data });
    // this.setState({
    //   items: [...this.state.items, account],
    // });

    // console.log("Items in verifyAccount", this.state.items);
    // return;
  };

  readExcel(file) {
    let errorMessage = "";
    this.setState({
      items: [],
    });
    if (!file || !file.target.files[0]) {
      return;
    }
    const isExcel =
      file.target.files[0].type === "application/vnd.ms-excel" ||
      file.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "You can only upload Excel file!";
      alert(errorMessage);
    }
    // console.log("file", file.target.files[0].type);
    const isLt2M = file.target.files[0].size / 1024 / 1024 <= 1000;
    if (!isLt2M) {
      errorMessage = "File must be smaller than 1GB!";
      alert(errorMessage);
    }
    // console.log("errorMessage", errorMessage);
    // return false;
    if (errorMessage) {
      return false;
    }
    this.setState({ loading: true });
    localStorage.setItem("activeTab", "batch");

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file.target.files[0]);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        // console.log(data.length);

        if (data.length > 0) {
          this.setState({ count: data.length });

          resolve(data);
        } else {
          this.setState({ loading: false });
        }
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      // console.log(d);
      // setItems(d);
      let i;
      const mobileNetwork = ["mtn", "vodafone", "airteltigo"];
      // let promises = [];

      for (i = 0; i < d.length; i++) {
        // console.log("Sleeping 5 seconds");
        this.sleep(5000);
        // console.log(d[i].Account_Or_Momo_Number);
        // console.log(d[i].Bank_Or_Network.toLowerCase());

        if (mobileNetwork.includes(d[i].Bank_Or_Network.toLowerCase())) {
          // console.log("Mobile verification API");
          const momo = {
            Phone_Number: d[i].Account_Or_Momo_Number,
            Network_Name: d[i].Bank_Or_Network,
          };

          new Promise((r) => setTimeout(r, 2000));

          let momoAccount = this.verifyMomo(momo);

          momoAccount.then((a) => {
            // console.log(a);
            this.setState({
              items: [...this.state.items, a],
            });
            // console.log(this.state.items.length, this.state.count);
            if (this.state.items.length === this.state.count) {
              this.setState({ loading: false });
            }
          });
        } else {
          // console.log("Bank verification API");
          const bankAccount = {
            Account_Number: d[i].Account_Or_Momo_Number,
            Bank_Name: d[i].Bank_Or_Network,
          };

          let account = this.verifyAccount(bankAccount);
          // console.log(account);
          account.then((a) => {
            // console.log(a);
            this.setState({
              items: [...this.state.items, a],
            });
            // console.log(this.state.items.length, this.state.count);
            if (this.state.items.length === this.state.count) {
              this.setState({ loading: false });
            }
          });
          // this.setState({
          //   items: [...this.state.items, account],
          // });
          // promises.push(
          //   axios
          //     .post("/api/customer/account", bankAccount)
          //     .then((response) => {
          //       console.log(response);
          //     })
          //     .catch((error) => {
          //       console.log(error);
          //     })
          // );
        }
      }

      // Promise.all(promises).then(() => console.log("Done"));
      // this.setState({ items: d });

      // console.log(this.state.items);
      // this.setState({ loading: false });
    });
  }

  exportFile = () => {
    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(this.state.items);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "verification_export.xlsx");
  };

  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  downloadCSV = (args) => {
    var data, filename, link;
    // console.log(this.state.items);
    var csv = this.convertArrayOfObjectsToCSV({
      data: this.state.items,
    });
    if (csv == null) return;

    filename = args.filename || "verification_export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    data = encodeURI(csv);

    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    let accountNoError = "";
    let accountTypeError = "";
    let bankError = "";
    let momoError = "";

    this.setState({
      accountNoError,
      accountTypeError,
      bankError,
      momoError,
    });

    if (!e.target.form[0].value) {
      // Checks if field is empty
      accountNoError = "Kindly provide Account or MoMo number";
    }

    if (!e.target.form[1].checked & !e.target.form[2].checked) {
      // Checks if field is empty
      accountTypeError = "Kindly select option";
    }

    if (e.target.form[1].checked) {
      if (!e.target.form[3].value) {
        // Checks if field is empty
        bankError = "Kindly select bank";
      }
    }

    if (e.target.form[2].checked) {
      if (!e.target.form[3].value) {
        // Checks if field is empty
        momoError = "Kindly select network";
      }
    }

    if (accountNoError || accountTypeError || bankError || momoError) {
      this.setState({
        accountNoError,
        accountTypeError,
        bankError,
        momoError,
      });
      return false;
    }

    localStorage.setItem("activeTab", "single");

    if (e.target.form[1].checked) {
      const bankAccount = {
        Account_Number: e.target.form[0].value,
        Bank_Name: e.target.form[3].value,
        Code: e.target.form[3].value.split("-")[0],
      };

      this.setState({ loading: true, bankName: e.target.form[3].value });
      console.log(bankAccount);
      axios
        .post("/api/customer/account", bankAccount)
        .then((response) => {
          console.log(response);
          if (response.data.message_code === 100) {
            this.setState({
              loading: false,
              showModal: true,
              accountNo: response.data.data.account_number,
              accountName: response.data.data.account_name,
            });
            return true;
          } else if (response.data.message_code === 102) {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: response.data.message,
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          } else {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: "Account number verification failed",
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            showModal: false,
            accountNoError: "Account number verification failed",
          });
          if (accountNoError) {
            this.setState({
              accountNoError,
            });
            return false;
          }
        });
    }

    if (e.target.form[2].checked) {
      const momoAccount = {
        Phone_Number: e.target.form[0].value,
        Network_Name: e.target.form[3].value,
      };

      this.setState({ loading: true, bankName: e.target.form[3].value });
      console.log(momoAccount);
      axios
        .post("/api/customer/momoaccount", momoAccount)
        .then((response) => {
          console.log(response);
          if (response.data.message_code === 100) {
            this.setState({
              loading: false,
              showModal: true,
              accountNo: response.data.data.account_number,
              accountName: response.data.data.account_name,
            });
            return true;
          } else if (response.data.message_code === 102) {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: response.data.message,
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          } else {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: "Momo number verification failed",
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            showModal: false,
            accountNoError: "Momo number verification failed",
          });
          if (accountNoError) {
            this.setState({
              accountNoError,
            });
            return false;
          }
        });
    }
  };

  handleBatchSubmit = (e) => {
    e.preventDefault();
    console.log(e);
    console.log(e.target.form[0].value);

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(e.target.form[0].value);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      // setItems(d);
      this.setState({ items: d });
      console.log(this.state.items);
    });
  };

  render() {
    const banks = BanksData;
    let activeTab = "";
    // console.log(activeTab);
    if ("activeTab" in localStorage) {
      activeTab = localStorage.getItem("activeTab");
    } else {
      activeTab = "single";
    }
    // console.log(activeTab);

    // console.log(this.state.items.length === 0);
    // if (this.state.items.length === 0) {
    //   activeTab = "single";
    //   // console.log("activeTab", activeTab);
    // } else {
    //   activeTab = "batch";
    //   // console.log("activeTab", activeTab);
    // }

    const columns = [
      {
        name: "Account or Momo No",
        selector: (row) => row.Account,
        // sortable: true,
        reorder: true,
        width: "160px",
      },
      {
        name: "Bank or Network",
        selector: (row) => row.Bank_Name,
        // sortable: true,
        reorder: true,
        width: "200px",
      },
      {
        name: "Name on Account",
        selector: (row) => row.Name_On_Account,
        // sortable: true,
        reorder: true,
        width: "200px",
      },
      {
        name: "Verification Status",
        selector: (row) => row.Verification_Status,
        // sortable: true,
        reorder: true,
        width: "150px",
      },
    ];

    return (
      <React.Fragment>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-retail"}></div>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
              }}
            />
            <br /> <br />
            <h1>
              <b>Account/MoMo Number Verification</b>
            </h1>
            <hr />
            {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="accountNo"
                    label="Account or Phone Number"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="number"
                      autoComplete="off"
                      min="0"
                      onWheel={(event) => event.currentTarget.blur()}
                      style={{
                        border: this.state.accountNoError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountNoError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <div className="mb-3" style={{ textAlign: "left" }}>
                    Account Type
                  </div>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    <InputGroup.Radio
                      style={{
                        border: this.state.accountTypeError
                          ? "1px solid red"
                          : "",
                      }}
                      value="M"
                      name="accountType"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Bank &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.accountTypeError
                          ? "1px solid red"
                          : "",
                      }}
                      value="A"
                      name="accountType"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; MoMo
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountTypeError}
                    </div>
                  </InputGroup>
                </Form.Group>
                {this.state.showBanks && (
                  <Form.Group>
                    <FloatingLabel
                      controlId="bank"
                      label="Select Bank"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        // onChange={this.handleIncrementOption}
                        // defaultValue={values.escalation}
                        style={{
                          border: this.state.bankError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        {banks.map((bank) => (
                          <option key={bank.id} value={bank.name}>
                            {bank.name}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                )}

                {this.state.showMoMo && (
                  <Form.Group>
                    <FloatingLabel
                      controlId="network"
                      label="Select Network"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        // onChange={this.handleIncrementOption}
                        // defaultValue={values.escalation}
                        style={{
                          border: this.state.momoError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        <option value={"MTN"}>MTN</option>
                        <option value={"ATL"}>AirtelTigo</option>
                        <option value={"VOD"}>Vodafone</option>
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.momoError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                )}
                {/* {this.state.showBanks && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify Account Number
              </Button>
            )}
            {this.state.showMoMo && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify MoMo Number
              </Button>
            )} */}
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                    color: "white",
                  }}
                  onClick={this.handleSubmit}
                >
                  Verify &nbsp;
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </Form>

              {/*Modal */}
              <Modal
                // className="modal-dialog-centered"
                size="md"
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                // aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {/* <Modal.Header
              closeButton
              style={{ backgroundColor: "#763984", color: "white" }}
            >
              <Modal.Title>Mandate Form</Modal.Title>
            </Modal.Header> */}
                <Modal.Body
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="head">
                <h3 style={{ marginTop: "5px" }}>Lorem ipsum dolor sit amet</h3>
                <h4>Lorem ipsum dolor sit amet</h4>
              </div>
              <FontAwesomeIcon icon={faCheck} size="lg" fade /> */}
                  {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                  {/* <img
                src={check}
                alt="logo"
                style={{
                  width: "20%",
                  //   alignContent: "center",
                }}
              /> */}
                  <table border="0" width="100%">
                    <tr>
                      <td colSpan="2" align="center">
                        <img
                          src={check}
                          alt="logo"
                          style={{
                            width: "20%",
                            //   alignContent: "center",
                          }}
                        />
                        <hr />
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="50%">Account number:</td>
                      <td>
                        <b>{this.state.accountNo && this.state.accountNo}</b>
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="50%">Account holder's name: </td>
                      <td>
                        <b>
                          {this.state.accountName && this.state.accountName}
                        </b>
                      </td>
                    </tr>
                  </table>
                  {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* End of Modal */}
            </div>
          </>
        ) : (
          <>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "100%",
              }}
            />
            <br /> <br />
            <h1>
              <b>Account/MoMo Number Verification</b>
            </h1>
            <hr />
            {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Tabs
                // {this.state.items ? defaultActiveKey={{batch}} : defaultActiveKey={{single}}}
                defaultActiveKey={activeTab}
                // defaultActiveKey="batch"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="single" title="Single Verification">
                  <Form
                    style={{
                      color: "purple",
                    }}
                  >
                    <Form.Group
                      style={{
                        color: "purple",
                      }}
                    >
                      <FloatingLabel
                        controlId="accountNo"
                        label="Account or Phone Number"
                        className="mb-2"
                      >
                        <Form.Control
                          required
                          type="number"
                          autoComplete="off"
                          min="0"
                          onWheel={(event) => event.currentTarget.blur()}
                          style={{
                            border: this.state.accountNoError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.accountNoError}
                        </div>
                      </FloatingLabel>
                    </Form.Group>
                    <Form.Group>
                      <div className="mb-3" style={{ textAlign: "left" }}>
                        Account Type
                      </div>
                      <InputGroup
                        className="mb-3"
                        onChange={this.handleRadioChange}
                      >
                        <InputGroup.Radio
                          style={{
                            border: this.state.accountTypeError
                              ? "1px solid red"
                              : "",
                          }}
                          value="M"
                          name="accountType"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Bank &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <InputGroup.Radio
                          style={{
                            border: this.state.accountTypeError
                              ? "1px solid red"
                              : "",
                          }}
                          value="A"
                          name="accountType"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; MoMo
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.accountTypeError}
                        </div>
                      </InputGroup>
                    </Form.Group>
                    {this.state.showBanks && (
                      <Form.Group>
                        <FloatingLabel
                          controlId="bank"
                          label="Select Bank"
                          className="mb-2"
                        >
                          <Form.Control
                            as="select"
                            // onChange={this.handleIncrementOption}
                            // defaultValue={values.escalation}
                            style={{
                              border: this.state.bankError
                                ? "1px solid red"
                                : "",
                            }}
                          >
                            <option></option>
                            {banks.map((bank) => (
                              <option
                                key={bank.id}
                                value={bank.code + "-" + bank.name}
                              >
                                {bank.name}
                              </option>
                            ))}
                          </Form.Control>
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                            }}
                          >
                            {this.state.bankError}
                          </div>
                        </FloatingLabel>
                      </Form.Group>
                    )}

                    {this.state.showMoMo && (
                      <Form.Group>
                        <FloatingLabel
                          controlId="network"
                          label="Select Network"
                          className="mb-2"
                        >
                          <Form.Control
                            as="select"
                            // onChange={this.handleIncrementOption}
                            // defaultValue={values.escalation}
                            style={{
                              border: this.state.momoError
                                ? "1px solid red"
                                : "",
                            }}
                          >
                            <option></option>
                            <option value={"MTN"}>MTN</option>
                            <option value={"AirtelTigo"}>AirtelTigo</option>
                            <option value={"Vodafone"}>Vodafone</option>
                          </Form.Control>
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                            }}
                          >
                            {this.state.momoError}
                          </div>
                        </FloatingLabel>
                      </Form.Group>
                    )}
                    {/* {this.state.showBanks && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify Account Number
              </Button>
            )}
            {this.state.showMoMo && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify MoMo Number
              </Button>
            )} */}
                    <Button
                      variant="primary"
                      style={{
                        backgroundColor: "#763984",
                        outlineColor: "#763984",
                        borderColor: "#763984",
                        color: "white",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Verify &nbsp;
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                  </Form>

                  {/*Modal */}
                  <Modal
                    // className="modal-dialog-centered"
                    size="md"
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    // aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    {/* <Modal.Header
              closeButton
              style={{ backgroundColor: "#763984", color: "white" }}
            >
              <Modal.Title>Mandate Form</Modal.Title>
            </Modal.Header> */}
                    <Modal.Body
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <div className="head">
                <h3 style={{ marginTop: "5px" }}>Lorem ipsum dolor sit amet</h3>
                <h4>Lorem ipsum dolor sit amet</h4>
              </div>
              <FontAwesomeIcon icon={faCheck} size="lg" fade /> */}
                      {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                      {/* <img
                src={check}
                alt="logo"
                style={{
                  width: "20%",
                  //   alignContent: "center",
                }}
              /> */}
                      <table border="0" width="100%">
                        <tr>
                          <td colSpan="2" align="center">
                            <img
                              src={check}
                              alt="logo"
                              style={{
                                width: "20%",
                                //   alignContent: "center",
                              }}
                            />
                            <hr />
                          </td>
                        </tr>
                        {this.state.showBanks && (
                          <>
                            <tr align="center">
                              <td width="50%">Bank Name:</td>
                              <td>
                                <b>
                                  {this.state.bankName &&
                                    this.state.bankName.split("-")[1]}
                                </b>
                              </td>
                            </tr>
                            <tr align="center">
                              <td width="50%">Account number:</td>
                              <td>
                                <b>
                                  {this.state.accountNo && this.state.accountNo}
                                </b>
                              </td>
                            </tr>
                            <tr align="center">
                              <td width="50%">Account holder's name: </td>
                              <td>
                                <b>
                                  {this.state.accountName &&
                                    this.state.accountName}
                                </b>
                              </td>
                            </tr>
                          </>
                        )}

                        {this.state.showMoMo && (
                          <>
                            <tr align="center">
                              <td width="50%">Network:</td>
                              <td>
                                <b>
                                  {this.state.bankName && this.state.bankName}
                                </b>
                              </td>
                            </tr>
                            <tr align="center">
                              <td width="50%">MoMo number:</td>
                              <td>
                                <b>
                                  {this.state.accountNo && this.state.accountNo}
                                </b>
                              </td>
                            </tr>
                            <tr align="center">
                              <td width="50%">Account holder's name: </td>
                              <td>
                                <b>
                                  {this.state.accountName &&
                                    this.state.accountName}
                                </b>
                              </td>
                            </tr>
                          </>
                        )}
                      </table>
                      {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="primary" onClick={this.handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* End of Modal */}
                </Tab>
                <Tab eventKey="batch" title="Batch Verification">
                  {/* <h3>Upload File</h3> */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="page-title">Upload Data to Verify</div>
                    &nbsp; &nbsp; &nbsp;
                    <a
                      // href="https://res.cloudinary.com/bryta/raw/upload/v1562751445/Sample_Excel_Sheet_muxx6s.xlsx"
                      href={sampleexcel}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      title="Download"
                    >
                      <i className="fa fa-download fa-sm fa-fw"></i> Download
                      Sample excel sheet
                    </a>
                  </div>
                  <br />
                  <Form
                    style={{
                      color: "purple",
                    }}
                  >
                    <Form.Group
                      style={{
                        color: "purple",
                      }}
                    >
                      <FloatingLabel
                        controlId="batchfile"
                        label=""
                        className="mb-2"
                      >
                        <Form.Control
                          required
                          type="file"
                          autoComplete="off"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          min="0"
                          onInput={this.readExcel}
                          style={{
                            border: this.state.errorMessage
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.errorMessage}
                        </div>
                      </FloatingLabel>
                    </Form.Group>
                  </Form>{" "}
                  <br />
                  <DataTable
                    // title="Verification"
                    columns={columns}
                    data={this.state.items}
                    defaultSortFieldId={1}
                    pagination
                    BuiltinStory
                    theme="default"
                    fixedHeader
                    // actions={this.downloadCSV}
                    // progressPending={pending}
                    // fixedHeaderScrollHeight="300px"
                    // selectableRows
                  />
                  {this.state.items.length > 0 && (
                    <Button
                      style={{ float: "right" }}
                      // onClick={this.downloadCSV}
                      onClick={this.exportFile}
                      title="Export"
                    >
                      <i className="fa fa-file-export fa-sm fa-fw"></i> Export
                    </Button>
                  )}
                </Tab>
              </Tabs>
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

export default AccountVerification;
