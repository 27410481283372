import React, { useContext } from "react";
import onboarding from "../../icons8-onboarding-50-retail.png";
import trans from "../../icons8-transactions-64.png";
import inquiry from "../../icons8-binoculars-48-retail.png";
import edit from "../../icons8-edit-64-retail.png";
import reports from "../../icons8-combo-chart-50-retail.png";
import profile from "../../icons8-admin-settings-male-48-retail.png";
import verify from "../../icons8-verified-account-48.png";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import "../Welcome.styles.css";
import axios from "axios";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import Menu from "./menu";

function RetailHome() {
  const [policies, setPolicies] = React.useState([]);
  const [chartData, setChartData] = React.useState({});
  const [pieData, setPieData] = React.useState({});

  React.useEffect(function effectFunction() {
    const pols = {
      Agent_Code: JSON.parse(localStorage.getItem("dso"))["agnShtDesc"],
    };
    axios
      .post("/api/agent/business", pols)
      .then((response) => {
        setPolicies(response.data);
        setChartData({
          labels: response.data.Proposal_Labels,
          datasets: [
            {
              label: "Number of Proposals",
              data: response.data.Proposal_Data,
              backgroundColor: "#EA5F89",
            },
            {
              label: "Number of Policies",
              data: response.data.Policy_Data,
              backgroundColor: "#763984",
            },
          ],
        });

        setPieData({
          labels: response.data.Prod_Labels,
          datasets: [
            {
              label: "",
              data: response.data.Prod_Data,
              backgroundColor: [
                "#f3a22b",
                "#515e69",
                "#20409a",
                "#7b3b8a",
                "#ef332f",
                "#1cc88a",
                "#36b9cc",
                "#4e73df",
                "#00FF00",
                "#FF00FF",
                "#008000",
                "#A52A2A",
              ],
              hoverBackgroundColor: [
                "#d18a21",
                "#54626e",
                "#18327d",
                "#622e6e",
                "#c92824",
                "#17a673",
                "#2c9faf",
                "#2e59d9",
                "#02cf02",
                "#FF00FF",
                "#016101",
                "#8c2323",
              ],
              hoverBorderColor: "rgba(234, 236, 244, 1)",
            },
          ],
        });
      })
      .catch((error) => {
        setPolicies([]);
        setChartData({});
      });
  }, []);

  return (
    <React.Fragment>
      {/* <img
        src={gcb}
        alt="logo"
        style={{
          align: "right",
        }}
      /> */}
      {/* <br /> <br />
      <h1>
        <b>Digital Onboarding Platforms</b>
      </h1> */}
      <Menu />
      <hr />
      {/* <div style={{ backgroundColor: "red" }}>Red</div> */}
      {/* <!-- Image and text -->
      <ul className="nav justify-content-end">
        <li className="nav-item">
          <a className="nav-link active" href="#">
            Active
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
            Link
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
            Link
          </a>
        </li>
      </ul> */}
      {/* <Menu /> */}
      Hello <b>{JSON.parse(localStorage.getItem("dso"))["agnName"]}</b>, what
      would you like to do today? <br></br>
      {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
      <div id="wrapper">
        <div id="content-wrapper">
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    href="/retail/starlife/accountverification"
                    // target="_blank"
                    class=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              Account Number Verification
                            </div>
                            {/* <div className="h5 mb-0 font-weight-bold text-gray-800">
                        100
                      </div> */}
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={verify}
                              alt="logo"
                              style={{
                                height: "50px",
                                // marginLeft: "auto",
                                // marginRight: "auto",
                                // display: "block",
                                // border: "3px solid #73AD21",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    href="/retail/starlife/onboarding"
                    class=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              Onboarding
                            </div>
                            {/* <div className="h5 mb-0 font-weight-bold text-gray-800">
                        100
                      </div> */}
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={onboarding}
                              alt="logo"
                              style={{
                                height: "50px",
                                // marginLeft: "auto",
                                // marginRight: "auto",
                                // display: "block",
                                // border: "3px solid #73AD21",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    href="#"
                    // target="_blank"
                    class=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              Customer Enquiries
                            </div>
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={inquiry}
                              alt="logo"
                              style={{ height: "50px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    href="#"
                    // target="_blank"
                    class=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              Endorsements
                            </div>
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={edit}
                              alt="logo"
                              style={{ height: "50px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    href="#"
                    // target="_blank"
                    class=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              My Profile
                            </div>
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={profile}
                              alt="logo"
                              style={{ height: "50px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-xl-2 col-md-5 mb-3">
                  <a href="#" target="" class=" text-decoration-none">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              Reports
                            </div>
                          </div>
                          <div className="col-auto">
                            <img
                              src={reports}
                              alt="logo"
                              style={{ height: "50px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              {/* <!-- Content Row --> */}
              <div className="row">
                <div className="col-xl-8 col-lg-7">
                  {/* <!-- Bar Chart --> */}
                  <div className="shadow">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-black">
                        Number of successful purchased policies from year to
                        date by Month
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="chart-container">
                        {Object.keys(chartData).length > 0 && (
                          <div
                            style={{
                              // width: "700vw",
                              margin: "auto",
                              width: "60vw",
                              // height: "60vh",
                              position: "relative",
                            }}
                          >
                            <BarChart chartData={chartData} />
                          </div>
                        )}
                      </div>
                      <hr></hr>
                    </div>
                  </div>
                </div>

                {/* <!-- Donut Chart --> */}
                <div className="col-xl-4 col-lg-5">
                  <div className="shadow mb-4">
                    {/* <!-- Card Header - Dropdown --> */}
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-black">
                        Number of policies by Product Type
                      </h6>
                    </div>
                    {/* <!-- Card Body --> */}
                    <div className="card-body">
                      <div>
                        {/* <PieChart pieData={pieData} /> */}
                        {Object.keys(pieData).length > 0 && (
                          // <div
                          //   style={{
                          //     // width: "700vw",
                          //     margin: "auto",
                          //     width: "60vw",
                          //     // height: "60vh",
                          //     position: "relative",
                          //   }}
                          // >
                          <PieChart pieData={pieData} />
                          // </div>
                        )}
                      </div>
                      <hr></hr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {labels && <Bar options={options} data={data} />} */}
          {/* <Bar options={options} data={chartData} />; */}
        </div>
      </div>
      {/* <Bar options={options} data={chartData} />; */}
      {/* {Object.keys(chartData).length > 0 && (
        <div style={{ width: 700 }}>
          <BarChart chartData={chartData} />
        </div>
      )} */}
      {/* {chartData && (
        <div style={{ width: 500 }}>
          <BarChart chartData={chartData} />
        </div>
      )} */}
    </React.Fragment>
  );
}

export default RetailHome;
