import React from "react";

export const Receipt = React.forwardRef((props, ref) => {
  const { values } = props;
  return (
    <div ref={ref}>
      {/* <br></br> */}
      <br></br>
      <div
        className="container center_div"
        style={{
          display: "block",
          width: 500,
          padding: 10,
        }}
      >
        <table
          style={{
            borderWidth: "2px",
            borderColor: "#aaaaaa",
            borderStyle: "solid",
            width: "100%",
          }}
        >
          <tr>
            <td
              colSpan={"2"}
              style={{
                backgroundColor: "#0f3660",
                color: "#fff",
              }}
            >
              <h5>PROPOSAL DETAILS</h5>
            </td>
          </tr>
          <tr>
            <td align="left" width={""}>
              Proposal Number:
            </td>
            <td align="left">
              <b>{values.ProposalNumber}</b>
            </td>
          </tr>
          <tr>
            <td align="left" width={""}>
              Policy Holder:
            </td>
            <td align="left">
              <b>{values.PolicyHolder}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Product Name:</td>
            <td align="left">
              <b>{values.ProductName}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Premium (Ghȼ):</td>
            <td align="left">
              <b>{values.Premium.toFixed(2)}</b>
            </td>
          </tr>
          <tr>
            <td align="left">Sum Assured (Ghȼ):</td>
            <td align="left">
              <b>{values.InitialSumAssured}</b>
            </td>
          </tr>
          {values.PaymentFrequency === "M" && (
            <tr>
              <td align="left">Payment Frequency:</td>
              <td align="left">
                <b>MONTHLY</b>
              </td>
            </tr>
          )}
          {values.PaymentFrequency === "A" && (
            <tr>
              <td align="left">Payment Frequency:</td>
              <td align="left">
                <b>ANNUALLY</b>
              </td>
            </tr>
          )}
          {/* {values.ProductName !== "GCB HOMECALL PLUS" && (
            <tr>
              <td align="left">Maturity Date:</td>
              <td align="left">
                <b>{values.MaturityDate}</b>
              </td>
            </tr>
          )} */}
          {/* <tr>
            <td align="left">Total Premium (Ghȼ):</td>
            <td align="left">
              <b>
                {Math.round(
                  (values.spectra_policy.InitPremium +
                    values.spectra_policy.PackagePremium) *
                    100
                ) / 100}
              </b>
            </td>
          </tr> */}
        </table>{" "}
      </div>
    </div>
  );
});
