import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import BankData from "./data/BankData";
import SignatureCanvas from "react-signature-canvas";
import "../sigCanvas.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

export class PreInstitutionDetails extends PureComponent {
  sigRef = React.createRef({});

  clear = (e) => {
    e.preventDefault();
    this.sigRef.current.clear();
  };
  render() {
    const {
      staffNumberError,
      valueChange,
      values,
      handleBankChange,
      handleChange,
      deductionDateError,
      signatureError,
      clear,
      validateAccountNumber,
      accountName,
      handleDateChange,
    } = this.props;

    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var day = tomorrow.getDay();
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (day === 0) {
      dd = dd + 1;
    }

    if (day === 6) {
      dd = dd + 2;
    }

    var tm = yyyy + "-" + mm + "-" + dd;
    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="staffid"
            label="Staff ID Number"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Enter Your Staff ID Number"
              onChange={handleChange("staffNumber")}
              defaultValue={values.staffNumber}
              style={{
                border: staffNumberError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {staffNumberError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="deductionDate"
            label="Date of First Deduction"
            className="mb-2"
          >
            <Form.Control
              type="month"
              min={tm}
              // placeholder="Enter Beneficiary Date of Birth"
              // defaultValue={moment(newDOB).format("YYYY-MM-DD")}
              onChange={handleDateChange}
              defaultValue={values.deductionDate}
              style={{
                border: deductionDateError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {deductionDateError}
            </div>
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default PreInstitutionDetails;
