import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import validator from "validator";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import gcb from "../../gcb-logo.png";
import { InputGroup } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Menu from "./menu";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { parsePhoneNumber, getNumberType } from "libphonenumber-js";
import GCBNewHeader from "./header/GCBNewHeader";

export class UserAccount extends PureComponent {
  state = {
    existingPolError: "",
    existingPhoneError: "",
    showPhoneTextBox: false,
    showOTPTextBox: false,
    loading: false,
    phoneNumber: "",
    isValid: false,
    pError: "",
  };

  handlePhoneChange = (value) => {
    this.setState({
      phoneNumber: value,
      // isValid,
    });
  };

  handlePhoneChangeNew = (isValid, phoneNumber, selectedCountry) => {
    const pnum = phoneNumber;
    // Parse phone number and format it with the country code
    const parsedNumber = parsePhoneNumber(`+${pnum}`, selectedCountry);
    const formattedNumber = parsedNumber
      ? parsedNumber.formatInternational()
      : "";

    // Check if phone number meets minimum length requirement for the selected country
    const numberType = getNumberType(`+${phoneNumber}`, selectedCountry);
    const isValidLength = parsedNumber
      ? phoneNumber.length >=
        parsedNumber.countryCallingCode.length +
          parsedNumber.nationalNumber.length
      : false;

    this.setState({
      phoneNumber: formattedNumber,
      isValid: isValid && isValidLength && numberType !== "UNKNOWN",
    });
  };

  handleRadioChange = (e) => {
    if (e.target.form[0].checked) {
      this.setState({ showPhoneTextBox: true });
      //   this.props.nextStep(5);
      // alert("Client has existing policy.");
      // return true;
    } else {
      this.setState({ showPhoneTextBox: false });
    }
  };

  continue = (e) => {
    e.preventDefault();

    let existingPolError = "";
    let existingPhoneError = "";
    let pError = "";
    let pNumber = "";

    this.setState({
      existingPolError,
      existingPhoneError,
      pError,
    });

    if (!e.target.form[0].checked & !e.target.form[1].checked) {
      // Checks if field is empty
      existingPolError =
        "Please indicated whether you have an existing policy or not";
    }

    if (e.target.form[0].checked) {
      this.props.handleChangeValue("existingPolicy", "Yes");
      const pattern = new RegExp(/^[0-9\b]+$/);
      if (!this.state.phoneNumber) {
        existingPhoneError = "Please enter the phone number on your policy";
      }

      if (this.state.phoneNumber) {
        pNumber = parsePhoneNumber(this.state.phoneNumber);

        if (!isValidPhoneNumber(pNumber.number)) {
          existingPhoneError = "Please enter a valid phone number";
        }

        if (existingPhoneError) {
          this.setState({
            existingPhoneError,
          });
          return false;
        }
      }

      const pnumber = pNumber.nationalNumber;
      // const API_USER = process.env.REACT_APP_API_USER;
      // const API_KEY = process.env.REACT_APP_API_KEY;
      // const API_URL = process.env.REACT_APP_BASE_URL;
      // const EXPRESS_URL = process.env.EXPRESS_APP_BASE_URL;

      if (pnumber) {
        const existingClient = {
          Customer_Mobile: "0" + pnumber,
          // Customer_Mobile: this.state.phoneNumber,
        };

        this.setState({ loading: true });
        //Verify client phone
        axios
          .post("/api/customer/verify", existingClient)
          .then((response) => {
            if (response.data.message_code === 100) {
              const clntCode = response.data.clntCode;
              const newFirstName = response.data.webClntFirstName;
              const otherNames = response.data.webClntMiddleName;
              const newLastName = response.data.webClntLastName;
              const dob = response.data.webClntDob;
              const gender = response.data.webClntGender;
              const maritalStatus = response.data.webClntMaritalStatus;
              const idType = response.data.webClntIdRegDoc;
              const idNumber = response.data.webClntIdRegNo;
              const occupation = response.data.clntOccupation;
              const postalAddress = response.data.webClntPostalAddress;
              const residentialAddress = response.data.webClntPhysicalAddress;
              const nationality = response.data.webClntNationality;
              const religion = response.data.webClntReligion;
              const staffId = response.data.wclntPayrollNo;

              // this.props.valueChange({ newFirstName, newLastName });
              this.props.handleChangeValue("clntCode", clntCode);
              this.props.handleChangeValue("firstName", newFirstName);
              this.props.handleChangeValue("otherNames", otherNames);
              this.props.handleChangeValue("lastName", newLastName);
              this.props.handleChangeValue("dob", dob);
              this.props.handleChangeValue("gender", gender);
              this.props.handleChangeValue("maritalStatus", maritalStatus);
              this.props.handleChangeValue("idType", idType);
              this.props.handleChangeValue("idNumber", idNumber);
              this.props.handleChangeValue("phoneNumber", "0" + pnumber);
              this.props.handleChangeValue("occupation", +occupation);
              this.props.handleChangeValue("postalAddress", postalAddress);
              this.props.handleChangeValue(
                "residentialAddress",
                residentialAddress
              );
              this.props.handleChangeValue("nationality", nationality);
              this.props.handleChangeValue("religion", religion);
              this.props.handleChangeValue("staffNumber", staffId);

              this.props.handleChangeValue(
                "smsNumber",
                pNumber.countryCallingCode + pNumber.nationalNumber
              );

              this.props.nextStep(4);
              const getOTP = {
                Phone_Number:
                  // pNumber.countryCallingCode + pNumber.nationalNumber,
                  this.props.values.smsNumber,
                Bank: "GCB Bancass",
              };

              //Get OTP
              axios
                .post("/api/tele/customer/otp", getOTP)
                .then((response) => {})
                .catch((error) => {});
              //   this.setState({ loading: false });
              //   alert("Client has existing policy.");
              return true;
            } else {
              //   existingPhoneError = response.data.message;
              existingPhoneError =
                "Sorry, but this phone number does not exist!";
              if (existingPhoneError) {
                // alert(existingPhoneError);
                this.setState({
                  loading: false,
                  existingPhoneError,
                });
                return false;
              }
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
          });
        return false;
      }

      if (existingPhoneError || pError) {
        this.setState({
          existingPhoneError,
          pError,
        });
        return false;
      }
    } else {
      this.props.handleChangeValue("existingPolicy", "No");
      this.props.handleChangeValue("clntCode", "");
      this.props.handleChangeValue("firstName", "");
      this.props.handleChangeValue("otherNames", "");
      this.props.handleChangeValue("lastName", "");
      this.props.handleChangeValue("dob", "");
      this.props.handleChangeValue("gender", "");
      this.props.handleChangeValue("maritalStatus", "");
      this.props.handleChangeValue("idNumber", "");
      this.props.handleChangeValue("phoneNumber", "");
      this.props.handleChangeValue("smsNumber", "");
      this.props.handleChangeValue("occupation", "");
      this.props.handleChangeValue("postalAddress", "");
      this.props.handleChangeValue("residentialAddress", "");
      this.props.handleChangeValue("nationality", "");
      this.props.handleChangeValue("religion", "");
      this.props.handleChangeValue("staffNumber", "");
      this.props.handleChangeValue("staffId", "");
      this.props.handleChangeValue("email", "");
      this.props.handleChangeValue("product", "");
      this.props.handleChangeValue("productCode", "");
      this.props.handleChangeValue("premium", "");
      this.props.handleChangeValue("sa", "");

      if (this.props.values.dependentList) {
        this.props.handleChangeValue("dependentList", []);
      }

      if (this.props.values.benList) {
        this.props.handleChangeValue("benList", []);
      }

      if (this.props.values.trustee) {
        this.props.handleChangeValue("trustee", []);
      }

      if (this.props.values.familyList) {
        this.props.handleChangeValue("familyList", []);
      }
    }

    if (existingPolError || existingPhoneError || pError) {
      this.setState({
        existingPolError,
        existingPhoneError,
        pError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  render() {
    const { values, handleChange } = this.props;

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-gcb"}></div>
            <GCBNewHeader />
            <br></br>
            <h3>Do you already have a policy with GCB Bancassurance?</h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="Yes"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="No"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; No
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.existingPolError}
                    </div>
                  </InputGroup>
                  {this.state.showPhoneTextBox && (
                    <Form.Group>
                      {/* <PhoneInput
                    placeholder="Enter phone number"
                    value={values}
                    onChange={handleChange("phone")}
                  /> */}
                      <FloatingLabel
                        controlId="phoneno"
                        label="Enter phone number on the policy or your 7 digit client code"
                        className="mb-2"
                      >
                        <Form.Control
                          className="smaller-input"
                          type="number"
                          placeholder="Enter Phone number"
                          autoComplete="off"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onChange={handleChange("phoneNumber")}
                          defaultValue={values.phone}
                          style={{
                            border: this.state.existingPhoneError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.existingPhoneError}
                        </div>
                      </FloatingLabel>
                    </Form.Group>
                  )}
                </Form.Group>
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#ffc52a",
                    outlineColor: "#ffc52a",
                    borderColor: "#ffc52a",
                    color: "#4a4a4a",
                  }}
                  onClick={this.continue}
                >
                  Next
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            {/* <br></br> */}
            {/* <img
              src={gcb}
              alt="logo"
              // style={{ width: "150px", height: "150px", padding: 10 }}
            /> */}
            <GCBNewHeader />

            {/* <br></br> */}
            <br></br>
            <h3>Do you already have a policy with GCB Bancassurance?</h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    {" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="Yes"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.existingPolError
                          ? "1px solid red"
                          : "",
                      }}
                      value="No"
                      name="existingpolicy"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; No
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.existingPolError}
                    </div>
                  </InputGroup>
                  {this.state.showPhoneTextBox && (
                    <Form.Group>
                      {/* <FloatingLabel
                        controlId="phoneno"
                        label="Enter phone number on the policy or your 7 digit client code"
                        className="mb-2"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Enter Phone number"
                          autoComplete="off"
                          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                          onChange={handleChange("phoneNumber")}
                          defaultValue={values.phoneNumber}
                          onWheel={(event) => event.currentTarget.blur()}
                          style={{
                            border: this.state.existingPhoneError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.existingPhoneError}
                        </div>
                      </FloatingLabel> */}
                      <PhoneInput
                        defaultCountry="GH"
                        placeholder="Enter phone number on your policy"
                        value={this.state.phoneNumber}
                        onChange={this.handlePhoneChange}
                        // onBlur={this.handlePhoneChange}
                        style={{
                          border: this.state.existingPhoneError
                            ? "1px solid red"
                            : "",
                        }}
                      />
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.existingPhoneError}
                      </div>
                      {/* <IntlTelInput
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        defaultCountry="gh"
                        separateDialCode={false}
                        defaultValue={this.state.phoneNumber}
                        onPhoneNumberBlur={this.handlePhoneChangeNew}
                      /> */}
                    </Form.Group>
                  )}
                </Form.Group>
                <div>&nbsp;</div>

                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#ffc52a",
                    outlineColor: "#ffc52a",
                    borderColor: "#ffc52a",
                    color: "#4a4a4a",
                  }}
                  onClick={this.continue}
                >
                  Next
                </Button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default UserAccount;
