import React, { PureComponent } from "react";
import starLife from "../../StarLife_Chatbot-06.png";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile,
  faCheckCircle,
  faPrint,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import { Receipt } from "./Receipt";
import Menu from "./menu";

export class Success extends PureComponent {
  render() {
    const { values } = this.props;
    // const spectra_policy = {
    //   InitPremium: 107.64,
    //   PackageName: "Bronze - Family (2 Members)",
    //   PackagePremium: 55.0,
    //   PaymentFrequency: "Monthly",
    //   ProposalNumber: "PUPP1001001220982783",
    //   message: "1 Proposal(s) found",
    //   message_code: 100,
    // };

    // this.props.valueChange(["pNumber", "CLP1006001170227245"]);
    // console.log(values.spectra_policy);
    // console.log(values["spectra_policy"]);
    return (
      <div>
        <Menu />
        <hr />
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 600,
            padding: 10,
          }}
        >
          {values.md2 === "Yes" ? (
            <h4>
              Proposal submitted successfully and is pending review because of
              your answer(s) to a medical question(s). You will soon be
              contacted.{" "}
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "#763984" }}
              />
            </h4>
          ) : (
            <h4>
              Proposal submitted successfully.{" "}
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ color: "#763984" }}
              />
            </h4>
          )}
          <div>
            <Receipt values={values} ref={(el) => (this.componentRef = el)} />
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <div>
                    <div>
                      <Button
                        variant="primary"
                        style={{ backgroundColor: "#763984" }}
                      >
                        Print <FontAwesomeIcon icon={faPrint} />
                      </Button>
                    </div>
                    &nbsp;
                  </div>
                );
              }}
              content={() => this.componentRef}
            />
          </div>
          {/* <Button
            variant="primary"
            style={{ backgroundColor: "#763984" }}
            onClick={() => window.print()}
          >
            Print <FontAwesomeIcon icon={faPrint} />
          </Button> */}
          {/* <br /> */}
          <h5>
            Thank you!{" "}
            {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
              values["firstName"].toLowerCase().slice(1)}{" "}
            <FontAwesomeIcon icon={faSmile} style={{ color: "#763984" }} />
          </h5>
          <a style={{ color: "#763984" }} href="/retail/spectra/onboarding">
            Return to Home{" "}
            <FontAwesomeIcon icon={faHome} style={{ color: "#763984" }} />
          </a>
        </div>
      </div>
    );
  }
}

export default Success;
