const BankBranchesData = [
  {
    bbrCode: 10501,
    bbrBnkCode: 1038,
    bbrBranchName: "NANKESE",
    bbrShtDesc: "091011",
  },
  {
    bbrCode: 10502,
    bbrBnkCode: 1038,
    bbrBranchName: "SUHUM",
    bbrShtDesc: "091012",
  },
  {
    bbrCode: 10503,
    bbrBnkCode: 1038,
    bbrBranchName: "ASAMANKESE",
    bbrShtDesc: "091013",
  },
  {
    bbrCode: 10504,
    bbrBnkCode: 1038,
    bbrBranchName: "KOFORIDUA",
    bbrShtDesc: "091014",
  },
  {
    bbrCode: 10505,
    bbrBnkCode: 1038,
    bbrBranchName: "ADOAGYIRI",
    bbrShtDesc: "091015",
  },
  {
    bbrCode: 10506,
    bbrBnkCode: 1038,
    bbrBranchName: "OSENASE",
    bbrShtDesc: "091016",
  },
  {
    bbrCode: 10507,
    bbrBnkCode: 1038,
    bbrBranchName: "ADWESO",
    bbrShtDesc: "091017",
  },
  {
    bbrCode: 10508,
    bbrBnkCode: 1038,
    bbrBranchName: "KADE",
    bbrShtDesc: "091018",
  },
];

export default BankBranchesData;
