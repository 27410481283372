import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faHome,
  faSearch,
  faSignOutAlt,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import gcb from "../../gcb-logo.png";
import share from "../../icons8-share-50.png";
import AuthContext from "../../store/auth-context";
import { RWebShare } from "react-web-share";

function Menu() {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.dsoLogout();
  };
  return (
    <div>
      {/* <!-- Image and text --> */}
      {authCtx.isDSOLoggedIn ? (
        <ul className="nav justify-content-end">
          <img
            src={gcb}
            alt="logo"
            // style={{ width: "150px", height: "150px", padding: 10 }}
            // style={{
            //   align: "left",
            //   left: 0,
            // }}
          />
          {/* <div style={{ flex: 0.15 }} /> */}
          <div style={{ flex: 0.42 }} />
          <li className="nav-item">
            <a
              className="nav-link active"
              href="/bancassurance/gcb"
              style={{ color: "#555555" }}
            >
              {/* Home  */}
              <FontAwesomeIcon icon={faHome} title="Home" />
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="/bancassurance/gcb/enquiry"
              style={{ color: "#555555" }}
            >
              {/* Customer Enquiry  */}
              <FontAwesomeIcon icon={faSearch} title="Customer Enquiry" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" style={{ color: "#555555" }}>
              {/* Endorsements  */}
              <FontAwesomeIcon icon={faEdit} title="Endorsements" />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" style={{ color: "#555555" }}>
              {/* Logout  */}
              <span onClick={logoutHandler}>
                <FontAwesomeIcon icon={faSignOutAlt} title="Logout" />
              </span>
            </a>
          </li>
          <RWebShare
            data={{
              text: "Share - GCB Digital Onboarding",
              url: "http://localhost:3000/bancassurance/gcb/onboarding",
              title: "GCB Digital Onboarding",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <li className="nav-item">
              <a className="nav-link" href="#" style={{ color: "#555555" }}>
                {/* Customer Enquiry  */}
                {/* <FontAwesomeIcon icon={faShare} title="Customer Enquiry" /> */}
                <img
                  src={share}
                  alt="logo"
                  style={{ width: "20px", height: "20px" }}
                  title="Share - Digital Onboarding"
                  // style={{
                  //   align: "left",
                  //   left: 0,
                  // }}
                />
              </a>
            </li>
          </RWebShare>
        </ul>
      ) : (
        <img
          src={gcb}
          alt="logo"
          style={{
            align: "right",
          }}
        />
      )}
    </div>
  );
}

export default Menu;
