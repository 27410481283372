import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import mypadie from "../StarLife-Chatbot-paddie.png";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "./Spinner.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { min } from "date-fns";
import AuthContext from "../store/auth-context";
import digitallogo from "../starlife-logo.png";

function AgentFormOTP(props) {
  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();
  // const { minutes, seconds } = this.state;
  const [passcodeError, setPasscodeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  let [minutes, setMinutes] = useState(3);
  let [seconds, setSeconds] = useState(0);

  const handleOTPChange = (otp) => setOtp(otp);

  async function resendOTP(phone) {
    let getOTP = "";

    if ((await props.getDecryptedData("currDSO")["bank"]) === "") {
      getOTP = {
        Phone_Number: phone,
      };
    } else {
      getOTP = {
        Phone_Number: phone,
        Bank: await props.getDecryptedData("currDSO")["bank"],
      };
    }

    setLoading(true);
    setPasscodeError("");

    // this.setState({ loading: true, passcodeError: "" });

    //Get OTP
    axios
      .post("/api/agent/resend/otp", getOTP)
      .then((response) => {
        setLoading(false);
        setOtp("");
        setMinutes(5);
        setSeconds(0);
        // this.setState({ loading: false, otp: "", minutes: 3, seconds: 0 });

        const myInterval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes - 1);
              setSeconds(59);
            }
          }
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        setOtp("");
        // this.setState({ loading: false, otp: "" });
      });

    // this.setState({ minutes: 1, seconds: 0 });
  }

  async function submitOTP(e) {
    e.preventDefault();
    let passcodeError = "";

    setPasscodeError(passcodeError);

    if (!e.target.form[0].value) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (!otp) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (passcodeError) {
      setPasscodeError(passcodeError);
      return false;
    }

    // const API_USER = process.env.REACT_APP_API_USER;
    // const API_KEY = process.env.REACT_APP_API_KEY;
    // const API_URL = process.env.REACT_APP_BASE_URL;

    const OTP = {
      OTP: otp,
      Phone_Number: await props.getDecryptedData("currDSO")["agnTel1"],
    };

    setLoading(true);

    //Verify OTP
    axios
      .post("/api/customer/verify/otp", OTP)
      .then((response) => {
        if (response.data.Verification === true) {
          props.nextStep();
          return true;
        } else {
          passcodeError = "Verification failed!";
          if (passcodeError) {
            // alert(existingPhoneError);
            setPasscodeError(passcodeError);
            setLoading(false);
            setOtp("");

            return false;
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setOtp("");
      });
  }

  //   back = (e) => {
  //     e.preventDefault();
  //     this.props.prevStep();
  //   };

  return (
    <div>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 500,
              padding: 10,
            }}
          >
            {/* <h2>
              <img src={mypadie} alt="logo" style={{ width: "70px" }} />
              Nice to meet you, {values["firstName"]}! Please add your employment
              details.
            </h2> */}
            {/* <br></br> */}
            <h4>
              <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
              <u>Verify Your One Time Passcode</u>
            </h4>
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <OtpInput
                  className="mt-3"
                  inputStyle={{
                    width: 50,
                    height: 50,
                    fontSize: 30,
                    color: "purple",
                    textAlign: "center",
                  }}
                  numInputs={6}
                  separator={<span> --- </span>}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                ></div>
              </Form.Group>
              &nbsp;
              <Button
                variant="primary"
                className="mt-2"
                style={{
                  backgroundColor: "#763984",
                  borderColor: "#763984",
                  outlineColor: "#763984",
                }}
              >
                Verify <FontAwesomeIcon icon={faCheckCircle} />
              </Button>{" "}
              &nbsp;
              <button type="button" className="btn btn-link">
                Send again
              </button>
            </Form>
          </div>
        </>
      ) : (
        <>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          {/* <br /> <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 500,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
              <u>Verify Your One Time Passcode</u>
            </h4>
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <OtpInput
                  className="mt-3"
                  inputStyle={{
                    width: 50,
                    height: 50,
                    fontSize: 30,
                    color: "purple",
                    border: passcodeError ? "1px solid red" : "",
                    textAlign: "center",
                  }}
                  value={otp}
                  onChange={handleOTPChange}
                  numInputs={6}
                  separator={<span> --- </span>}
                />
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {passcodeError}
                </div>
              </Form.Group>
              {/* &nbsp;
              <div>
                {minutes === 0 && seconds === 0 ? (
                  <h5>OTP has Expired!</h5>
                ) : (
                  <h5>
                    Resend OTP in: {minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </h5>
                )}
              </div> */}
              {/* <br></br> */}
              &nbsp;
              <Button
                variant="primary"
                className="mt-2"
                style={{
                  backgroundColor: "#763984",
                  borderColor: "#763984",
                  outlineColor: "#763984",
                }}
                onClick={submitOTP}
              >
                Verify <FontAwesomeIcon icon={faCheckCircle} />
              </Button>{" "}
              &nbsp;
              <button
                type="button"
                className="btn btn-link"
                onClick={async () =>
                  resendOTP(await props.getDecryptedData("currDSO")["agnTel1"])
                }
              >
                Send again
              </button>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}

export default AgentFormOTP;
