import React, { Component } from "react";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import gcb from "../../gcb-logo.png";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import Menu from "./menu";
import Toolbar from "./Toolbar";
import ToolbarSideDrawer from "./ToolbarSideDrawer";
import NewHeader from "./header/NewHeader";

export class Welcome extends Component {
  continue = (e) => {
    e.preventDefault();
    if (this.props.getDecryptedData("currDSO") !== null) {
      this.props.valueChange([
        "agentCode",
        this.props.getDecryptedData("currDSO")["agnShtDesc"],
      ]);
      this.props.valueChange([
        "agnCode",
        this.props.getDecryptedData("currDSO")["agnCode"],
      ]);
      this.props.valueChange([
        "agentName",
        this.props.getDecryptedData("currDSO")["agnName"],
      ]);
    }
    // this.props.getDecryptedData("currDSO")["bank"]
    this.props.nextStep();
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ width: "100%", overflow: "hidden" }}>
          <NewHeader />
          <br></br>
          <div>
            <h3>
              <FontAwesomeIcon icon={faHandshake} /> Welcome to Fiaseman Rural
              Bancassurance <FontAwesomeIcon icon={faHandshake} />
            </h3>
            {/* <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
            <br />
            <Button
              variant="primary"
              style={{
                backgroundColor: "#204698",
                outlineColor: "#ffc52a",
                borderColor: "#ffc52a",
                color: "#fff",
              }}
              onClick={this.continue}
            >
              Start Application
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Welcome;
