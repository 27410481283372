/* eslint-disable default-case */
import React, { Component } from "react";
import Welcome from "./Welcome";
import UserAccount from "./UserAccount";
import FormOTP from "./FormOTP";
import FormUserDetails from "./FormUserDetails";
import FormPersonalDetails from "./FormPersonalDetails";
import ProductDetails from "./ProductDetails";
import FormPaymentDetails from "./FormPaymentDetails";
import Confirm from "./Confirm";
// import Success from "./Success";

export class UserForm extends Component {
  state = {
    step: 1,
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    occupation: "",
    employer: "",
    product: "",
    paymentMode: "",
    bank: "",
    bankBranch: "",
    accountNumber: "",
    paymentFrequency: "",
    signature: "",
    city: "",
    bio: "",
  };

  // Proceed to the next step
  nextStep = (stepno) => {
    const { step } = this.state;

    // console.log(step);
    // console.log("Stepno:" + stepno);

    if (stepno) {
      this.setState({
        step: stepno,
      });
    } else
      this.setState({
        step: step + 1,
      });
  };

  // Proceed to the previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  //Handle fields change
  handleChange = (input) => (e) => {
    // console.log(e);
    this.setState({ [input]: e.target.value });
  };

  valueChange = (input) => {
    this.setState({
      ...this.state,
      [input[0]]: input[1],
    });
    // this.setState({
    //   ...this.state,
    //   firstName: input.newFirstName,
    //   lastName: input.newLastName,
    //   paymentMode: input.paymentMode,
    // });
  };

  render() {
    const { step } = this.state;
    const {
      firstName,
      lastName,
      dob,
      gender,
      phone,
      idType,
      idNumber,
      email,
      occupation,
      employer,
      product,
      paymentMode,
      bank,
      bankBranch,
      accountNumber,
      paymentFrequency,
      signature,
      city,
      bio,
    } = this.state;
    const values = {
      firstName,
      dob,
      lastName,
      gender,
      phone,
      idType,
      idNumber,
      email,
      occupation,
      employer,
      product,
      paymentMode,
      bank,
      bankBranch,
      accountNumber,
      paymentFrequency,
      signature,
      city,
      bio,
    };

    switch (step) {
      case 1:
        return (
          <Welcome
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <UserAccount
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 3:
        return (
          <FormUserDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 4:
        return (
          <FormOTP
            // prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 5:
        return (
          <FormPersonalDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 6:
        return (
          <ProductDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 7:
        return (
          <FormPaymentDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 8:
        return (
          <Confirm
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
          />
        );
      // case 6:
      //   return <Success />;
    }
  }
}

export default UserForm;
