/* eslint-disable default-case */
import React, { PureComponent } from "react";
import WelcomeGcb from "./WelcomeGcb";
import UserAccount from "./UserAccount";
import FormOTP from "./FormOTP";
import FormUserDetails from "./FormUserDetails";
import FormBeneficiaryDetails from "./FormBeneficiaryDetails";
import ProductDetails from "./ProductDetails";
import FormPaymentDetails from "./FormPaymentDetails";
import FormMedicalDetails from "./FormMedicalDetails";
import FormAgent from "./FormAgent";
import GCBConfirm from "./GCBConfirm";
import axios from "axios";
// import Confirm from "./Confirm";
import Success from "./Success";
// import AuthContext from "../../store/auth-context";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import moment from "moment";
import { getAge } from "../func/utils";

export class UserFormGcb extends PureComponent {
  // static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      values: {
        id: uuidv4(),
        otpVerified: false,
        existingPolicy: "",
        clntCode: "",
        firstName: "",
        otherNames: "",
        lastName: "",
        dob: "",
        gender: "",
        email: "",
        maritalStatus: "",
        phoneNumber: "",
        occupation: "",
        idType: "Ghana Card",
        idNumber: "",
        employer: "",
        product: "",
        productCode: "",
        term: "",
        tpd: "",
        retrenchment: "",
        dreadDisease: "",
        pa: "",
        premium: "",
        sa: "",
        escalation: "",
        escalationRate: "",
        paSurname: "",
        paOthername: "",
        padob: "",
        pagender: "",
        paymentMode: "DD",
        bank: "1033-OKOMFO ANOKYE RURAL BANCASSURANCE",
        bankBranch: "",
        accountNumber: "",
        accountName: "",
        deductionDate: "",
        paymentFrequency: "",
        signature: "",
        ghanaCard: "",
        ghanaCardBack: "",
        city: "",
        bio: "",
        benList: [],
        showBenModal: false,
        showEditBenModal: false,
        trustee: [],
        trusteeRequired: false,
        showTrusteeModal: false,
        showEditTrusteeModal: false,
        agentCode: "",
        agentName: "",
        agnCode: "",
        postalAddress: "",
        residentialAddress: "",
        nationality: "",
        religion: "",
        pNumber: "",
        md1: "",
        md2: "",
        md3: "",
        md4: "",
        condition: "",
        medication: "",
        surgery: "",
        proposal_details: {},
        dependentRequired: false,
        dependentList: [],
        showDepModal: false,
        showEditDepModal: false,
        quoteNo: "",
        pepStatus: "",
        dataProtection: "",
        loading: false,
        institution: "",
        staffNumber: "",
        smsNumber: "",
      },
    };
  }

  componentDidMount() {
    // console.log("Auth", AuthContext);
    // const { agnCode, agtCode, agtName } = this.context;
    // console.log("agncCode", agnCode);
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        // agentCode: agtCode,
        // agentName: agtName,
        // agnCode: agnCode,
      },
    }));
    this.loadStepProgress();
  }

  // Encrypt data and save to localStorage
  // saveEncryptedData = (key, data) => {
  //   const encryptedData = CryptoJS.AES.encrypt(
  //     JSON.stringify(data),
  //     process.env.REACT_APP_ENCRYPTION_KEY
  //   ).toString();
  //   localStorage.setItem(key, encryptedData);
  // };

  //break the encrypted data into smaller chunks and store them in localStorage
  saveEncryptedData = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
    const totalChunks = Math.ceil(encryptedData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, encryptedData.length);
      const chunk = encryptedData.substring(start, end);

      // Store each chunk in localStorage with a unique key
      localStorage.setItem(`${key}_${i}`, chunk);
    }
  };

  // Retrieve encrypted data from localStorage and decrypt it
  // getDecryptedData = (key) => {
  //   console.log("Getting decrypted data");
  //   const encryptedData = localStorage.getItem(key);
  //   console.log("decrypted data", encryptedData);
  //   console.log(encryptedData);
  //   if (encryptedData) {
  //     const bytes = CryptoJS.AES.decrypt(
  //       encryptedData,
  //       process.env.REACT_APP_ENCRYPTION_KEY
  //     );
  //     const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     console.log("final decryption", JSON.parse(decryptedData));
  //     return JSON.parse(decryptedData);
  //   }
  //   return null;
  // };

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    console.log("Chunkkeys", chunkKeys);

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      // console.log("Noo chunk keys");
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // console.log("decrypted data", decryptedData);
    return decryptedData;
  };

  saveStepProgress = async () => {
    try {
      const { step, values } = this.state;
      const dataToSave = JSON.stringify({ step, values });
      console.log("data to save", dataToSave);
      
      // const encryptedData = CryptoJS.AES.encrypt(
      //   dataToSave,
      //   "iGN26da,k3c?w7bFB-v?"
      // ).toString();
      const encryptedData = await this.saveEncryptedData("curr", dataToSave);
      // localStorage.setItem("encryptedData", encryptedData);
      // localStorage.setItem("CurrentData", dataToSave);
    } catch (error) {}
  };

  isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  loadStepProgress = async () => {
    try {
      console.log("Loading Step Progress");
      // const dataString = JSON.parse(localStorage.getItem("CurrentData"));
      // const encryptedData = localStorage.getItem("encryptedData");
      const dataString = await this.getDecryptedData("curr");
      // const dataString = "";
      // console.log("datastring", dataString);
      console.log("loading ecrypted Data", dataString);

      console.log("JSON", this.isJSON(dataString));

      this.isJSON(dataString);
      // console.log("encryptedData", encryptedData);
      // if (encryptedData) {
      //   const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
      //   const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      //   console.log("decryptedData", decryptedData);
      // }
      if (dataString) {
        // console.log("datastring available", dataString);

        // console.log("type of datastring", typeof dataString);
        
        
        let step, values;
        if (typeof dataString === "object") {
          ({ step, values } = dataString);
          console.log("datastring is object");
        } else {
          console.log("datastring is a string");
          try {
            // Try parsing the data into an object
            const parsedData = JSON.parse(dataString);
            // Destructure the parsed data
            ({ step, values } = parsedData);
          } catch (error) {
            // Handle parsing error
            console.error("Error parsing JSON:", error);
          }
        }
        // const { step, values } = dataString;
        console.log("step", step, "values", values);
        if (step == null) {
          this.setState({ step: 1, values });
        } else {
          // this.setState({ step: 1, values });
          this.setState({ step, values });
        }
      } else {
        const newId = uuidv4();
        this.setState((prevState) => ({
          values: {
            ...prevState.values,
            id: newId,
          },
        }));
      }
    } catch (error) {}
  };

  // Proceed to the next step
  nextStep = (stepno) => {
    const { step } = this.state;

    // console.log(step);
    // console.log("Stepno:" + stepno);

    if (stepno) {
      this.setState(
        {
          step: stepno,
        },
        () => this.saveStepProgress()
      );
    } else
      this.setState(
        {
          step: step + 1,
        },
        () => this.saveStepProgress()
      );
  };

  // Proceed to the previous step
  prevStep = (stepno) => {
    const { step } = this.state;

    if (stepno) {
      this.setState(
        {
          step: stepno,
        },
        () => this.saveStepProgress()
      );
    } else
      this.setState(
        {
          step: step - 1,
        },
        () => this.saveStepProgress()
      );
  };

  // Proceed to the next step
  resetStep = (stepno) => {
    const { step } = this.state;

    // console.log(step);
    // console.log("Stepno:" + stepno);

    if (stepno) {
      this.setState(
        {
          step: stepno,
          values: {
            id: uuidv4(),
            otpVerified: false,
            existingPolicy: "",
            clntCode: "",
            firstName: "",
            otherNames: "",
            lastName: "",
            dob: "",
            gender: "",
            email: "",
            maritalStatus: "",
            phoneNumber: "",
            occupation: "",
            idType: "Ghana Card",
            idNumber: "",
            employer: "",
            product: "",
            productCode: "",
            term: "",
            tpd: "",
            retrenchment: "",
            dreadDisease: "",
            pa: "",
            premium: "",
            sa: "",
            escalation: "",
            escalationRate: "",
            paSurname: "",
            paOthername: "",
            padob: "",
            pagender: "",
            paymentMode: "DD",
            bank: "1033-OKOMFO ANOKYE RURAL BANCASSURANCE",
            bankBranch: "",
            accountNumber: "",
            accountName: "",
            deductionDate: "",
            paymentFrequency: "",
            signature: "",
            ghanaCard: "",
            ghanaCardBack: "",
            city: "",
            bio: "",
            benList: [],
            showBenModal: false,
            showEditBenModal: false,
            trustee: [],
            trusteeRequired: false,
            showTrusteeModal: false,
            showEditTrusteeModal: false,
            agentCode: "",
            agentName: "",
            agnCode: "",
            postalAddress: "",
            residentialAddress: "",
            nationality: "",
            religion: "",
            pNumber: "",
            md1: "",
            md2: "",
            md3: "",
            md4: "",
            condition: "",
            medication: "",
            surgery: "",
            proposal_details: {},
            dependentRequired: false,
            dependentList: [],
            showDepModal: false,
            showEditDepModal: false,
            quoteNo: "",
            pepStatus: "",
            dataProtection: "",
            loading: false,
            institution: "",
            staffNumber: "",
            smsNumber: "",
          },
        },
        () => this.saveStepProgress()
      );
    }
  };

  //Handle fields change
  handleChange = (input) => (e) => {
    console.log(e);
    // this.setState({ [input]: e.target.value }, () => this.saveStepProgress());
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          [input]: e.target.value,
        },
      }),
      () => this.saveStepProgress()
    );
  };

  handleChangeValue = (key, value) => {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          [key]: value,
        },
      }),
      () => this.saveStepProgress()
    );
  };

  valueChange = (input) => {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          [input[0]]: input[1],
        },
      }),
      () => this.saveStepProgress()
    );
  };

  addBen = (ben) => {
    // console.log(ben);
    // console.log(this.state.benList);
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          benList: [...this.state.values.benList, ben],
        },
      }),
      () => {
        for (let i = 0; i < this.state.values.benList.length; i++) {
          if (
            getAge(
              moment(this.state.values.benList[i].dob, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )
            ) < 18
          ) {
            this.handleChangeValue("trusteeRequired", true);
            break;
          } else {
            this.handleChangeValue("trusteeRequired", false);
          }
        }
      },
      () => this.saveStepProgress()
    );
    this.hideBenModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  updateBen = (ben) => {
    const benIndex = this.state.values.benList.findIndex(
      (element) => element.id === ben.id
    );
    let newBenList = [...this.state.values.benList];
    newBenList[benIndex] = {
      ...newBenList[benIndex],
      id: ben.id,
      surname: ben.surname,
      othernames: ben.othernames,
      dob: ben.dob,
      gender: ben.gender,
      relationship: ben.relationship,
      phone: ben.phone,
      percent: ben.percent,
    };
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          benList: newBenList,
        },
      }),
      () => {
        for (let i = 0; i < this.state.values.benList.length; i++) {
          if (
            getAge(
              moment(this.state.values.benList[i].dob, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )
            ) < 18
          ) {
            this.handleChangeValue("trusteeRequired", true);
            break;
          } else {
            this.handleChangeValue("trusteeRequired", false);
          }
        }
      },
      () => this.saveStepProgress()
    );
    this.hideEditBenModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  removeBen = (benId) => {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          benList: this.state.values.benList.filter(function (ben) {
            return ben.id !== benId;
          }),
        },
      }),
      () => this.saveStepProgress()
    );
  };

  showBenModalHandler = (e) => {
    // this.setState({ showBenModal: true });
    this.handleChangeValue("showBenModal", true);
  };

  hideBenModalHandler = (e) => {
    // this.setState({ showBenModal: false });
    this.handleChangeValue("showBenModal", false);
  };

  showEditBenModalHandler = (e) => {
    // this.setState({ showEditBenModal: true });
    this.handleChangeValue("showEditBenModal", true);
  };

  hideEditBenModalHandler = (e) => {
    // this.setState({ showEditBenModal: false });
    this.handleChangeValue("showEditBenModal", false);
  };

  addTrust = (trustee) => {
    // console.log(ben);
    // console.log(this.state.benList);
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          trustee: [...this.state.values.trustee, trustee],
        },
      }),
      () => this.saveStepProgress()
    );
    this.hideTrusteeModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  removeTrust = (trustId) => {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          trustee: this.state.values.trustee.filter(function (trustee) {
            return trustee.id !== trustId;
          }),
        },
      }),
      () => this.saveStepProgress()
    );
  };

  updateTrust = (trust) => {
    const trustIndex = this.state.values.trustee.findIndex(
      (element) => element.id === trust.id
    );
    let newTrustee = [...this.state.values.trustee];
    newTrustee[trustIndex] = {
      ...newTrustee[trustIndex],
      id: trust.id,
      surname: trust.surname,
      othernames: trust.othernames,
      dob: trust.dob,
      relationshiop: trust.relationshiop,
      phone: trust.phone,
    };
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          trustee: newTrustee,
        },
      }),
      () => this.saveStepProgress()
    );
    this.hideEditTrusteeModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  showTrusteeModalHandler = (e) => {
    // this.setState({ showTrusteeModal: true });
    this.handleChangeValue("showTrusteeModal", true);
  };

  hideTrusteeModalHandler = (e) => {
    // this.setState({ showTrusteeModal: false });
    this.handleChangeValue("showTrusteeModal", false);
  };

  showEditTrusteeModalHandler = (e) => {
    // this.setState({ showEditTrusteeModal: true });
    this.handleChangeValue("showEditTrusteeModal", true);
  };

  hideEditTrusteeModalHandler = (e) => {
    // this.setState({ showEditTrusteeModal: false });
    this.handleChangeValue("showEditTrusteeModal", false);
  };

  trusteeRequredHandler = (e) => {
    // this.setState({ trusteeRequred: true });
    this.handleChangeValue("trusteeRequred", true);
  };

  showDepModalHandler = (e) => {
    // this.setState({ showDepModal: true });
    this.handleChangeValue("showDepModal", true);
  };

  hideDepModalHandler = (e) => {
    // this.setState({ showDepModal: false });
    this.handleChangeValue("showDepModal", false);
  };

  showEditDepModalHandler = (e) => {
    // this.setState({ showEditDepModal: true });
    this.handleChangeValue("showEditDepModal", true);
  };

  hideEditDepModalHandler = (e) => {
    // this.setState({ showEditDe8pModal: false });
    this.handleChangeValue("showEditDepModal", false);
  };

  addDependent = (dep) => {
    // console.log(ben);
    // console.log(this.state.benList);
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          dependentList: [...this.state.values.dependentList, dep],
        },
      }),
      () => this.saveStepProgress()
    );
    this.hideDepModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  updateDependent = (dep) => {
    const memberIndex = this.state.values.dependentList.findIndex(
      (element) => element.id === dep.id
    );
    let newdependentList = [...this.state.values.dependentList];
    newdependentList[memberIndex] = {
      ...newdependentList[memberIndex],
      id: dep.id,
      surname: dep.surname,
      othernames: dep.othernames,
      dob: dep.dob,
      relationship: dep.relationship,
      gender: dep.gender,
    };
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          dependentList: newdependentList,
        },
      }),
      () => this.saveStepProgress()
    );
    this.hideEditDepModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  // removeDependent = (depId) => {
  //   this.setState({
  //     dependentList: this.state.dependentList.filter(function (dep) {
  //       return dep.id !== depId;
  //     }),
  //   });
  // };

  removeDependent = (depId) => {
    this.setState({ loading: true });
    const searchIndex = this.state.values.dependentList.findIndex(
      (dep) => dep.id === depId
    );
    const depToDel = {
      quote_code: `${this.state.quoteNo}`,
      dep_code: null,
      surname: `${this.state.dependentList[searchIndex].surname}`,
      othernames: `${this.state.dependentList[searchIndex].othernames}`,
      dob: `${this.state.dependentList[searchIndex].dob}`,
      relationship: `${
        this.state.dependentList[searchIndex].relationship.split("-")[1]
      }`,
      gender: `${this.state.dependentList[searchIndex].gender}`,
    };

    axios
      .post("/api/customer/quotation/deletedependent", depToDel)
      .then((response) => {
        // console.log(response.data);

        if (response.data.message_code === 100) {
          this.setState(
            (prevState) => ({
              values: {
                ...prevState.values,
                dependentList: this.state.values.dependentList.filter(function (
                  dep
                ) {
                  return dep.id !== depId;
                }),
              },
            }),
            () => this.saveStepProgress()
          );
          this.handleChangeValue("loading", false);
        } else {
          this.setState({ loading: false });
          alert("Error Occured Deleting Dependent");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        alert("Error Occured Deleting Dependent");
      });
  };

  render() {
    const { step, values } = this.state;
    console.log("Values", values.id);

    switch (step) {
      case 1:
        return (
          <WelcomeGcb
            step={step}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      // return <Success values={values} />;
      // return <GCBConfirm values={values} valueChange={this.valueChange} />;
      case 2:
        return (
          <UserAccount
            step={step}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 3:
        return (
          <FormUserDetails
            step={step}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            valueChange={this.valueChange}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 4:
        return (
          <FormOTP
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      // case 5:
      //   return (
      //     <FormPersonalDetails
      //       prevStep={this.prevStep}
      //       nextStep={this.nextStep}
      //       handleChange={this.handleChange}
      //       values={values}
      //     />
      //   );
      case 5:
        return (
          <ProductDetails
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            addDependent={this.addDependent}
            updateDependent={this.updateDependent}
            removeDependent={this.removeDependent}
            showDepModalHandler={this.showDepModalHandler}
            hideDepModalHandler={this.hideDepModalHandler}
            showEditDepModalHandler={this.showEditDepModalHandler}
            hideEditDepModalHandler={this.hideEditDepModalHandler}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 6:
        return (
          <FormBeneficiaryDetails
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            addBen={this.addBen}
            updateBen={this.updateBen}
            showBenModalHandler={this.showBenModalHandler}
            hideBenModalHandler={this.hideBenModalHandler}
            removeBen={this.removeBen}
            showEditBenModalHandler={this.showEditBenModalHandler}
            hideEditBenModalHandler={this.hideEditBenModalHandler}
            showTrusteeModalHandler={this.showTrusteeModalHandler}
            hideTrusteeModalHandler={this.hideTrusteeModalHandler}
            showEditTrusteeModalHandler={this.showEditTrusteeModalHandler}
            hideEditTrusteeModalHandler={this.hideEditTrusteeModalHandler}
            trusteeRequredHandler={this.trusteeRequredHandler}
            addTrust={this.addTrust}
            removeTrust={this.removeTrust}
            updateTrust={this.updateTrust}
            addDependent={this.addDependent}
            updateDependent={this.updateDependent}
            removeDependent={this.removeDependent}
            showDepModalHandler={this.showDepModalHandler}
            hideDepModalHandler={this.hideDepModalHandler}
            showEditDepModalHandler={this.showEditDepModalHandler}
            hideEditDepModalHandler={this.hideEditDepModalHandler}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 7:
        return (
          <FormMedicalDetails
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 8:
        return (
          <FormPaymentDetails
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 9:
        return (
          <FormAgent
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 10:
        return (
          <GCBConfirm
            step={step}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            valueChange={this.valueChange}
            handleChange={this.handleChange}
            handleChangeValue={this.handleChangeValue}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            resetStep={this.resetStep}
          />
        );
      case 11:
        return (
          <Success step={step} values={values} resetStep={this.resetStep} />
        );
    }
  }
}

export default UserFormGcb;
