import React, { useContext } from "react";
import gcb from "../../gcb-logo.png";
import onboarding from "../../icons8-onboarding-50-amenfiman.png";
import trans from "../../icons8-transactions-64.png";
import inquiry from "../../icons8-binoculars-48-amenfiman.png";
import edit from "../../icons8-edit-64-amenfiman.png";
import reports from "../../icons8-combo-chart-50-amenfiman.png";
import profile from "../../icons8-admin-settings-male-48-amenfiman.png";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import "../Welcome.styles.css";
import axios from "axios";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import NewHeader from "./header/NewHeaderAmenfiman";
import CryptoJS from "crypto-js";

function GCBHome() {
  const [policies, setPolicies] = React.useState([]);
  const [chartData, setChartData] = React.useState({});
  const [pieData, setPieData] = React.useState({});

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  const getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    console.log("Chunkkeys", chunkKeys);

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      console.log("Noo chunk keys");
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("decrypted data", decryptedData);
    return decryptedData;
  };

  React.useEffect(function effectFunction() {
    const pols = {
      Branch: getDecryptedData("currDSO")["partner"],
      Agent_Code: getDecryptedData("currDSO")["agnShtDesc"],
    };
    axios
      .post("/api/agent/business", pols)
      .then((response) => {
        console.log(response.data);
        setPolicies(response.data);
        // console.log("Policies", policies);
        setChartData({
          labels: response.data.Proposal_Labels,
          datasets: [
            {
              label: "Number of Proposals",
              data: response.data.Proposal_Data,
              backgroundColor: "#FDD102",
            },
            {
              label: "Number of Policies",
              data: response.data.Policy_Data,
              backgroundColor: "#296F24",
            },
          ],
        });

        setPieData({
          labels: response.data.Prod_Labels,
          datasets: [
            {
              label: "",
              data: response.data.Prod_Data,
              backgroundColor: [
                "#f3a22b",
                "#515e69",
                "#20409a",
                "#7b3b8a",
                "#ef332f",
                "#1cc88a",
                "#36b9cc",
                "#4e73df",
                "#00FF00",
                "#FF00FF",
                "#008000",
                "#A52A2A",
              ],
              hoverBackgroundColor: [
                "#d18a21",
                "#54626e",
                "#18327d",
                "#622e6e",
                "#c92824",
                "#17a673",
                "#2c9faf",
                "#2e59d9",
                "#02cf02",
                "#FF00FF",
                "#016101",
                "#8c2323",
              ],
              hoverBorderColor: "rgba(234, 236, 244, 1)",
            },
          ],
        });
        // console.log("ChartData", chartData);
      })
      .catch((error) => {
        console.log("error");
        setPolicies([]);
        setChartData({});
      });
  }, []);

  console.log("Policies", policies);
  console.log("ChartData", chartData);
  console.log(Object.keys(chartData).length);

  // console.log("ChartData", chartData);

  // function Gcontinue(e) {
  //   e.preventDefault();
  //   this.props.nextStep();
  // }

  // console.log(policies);

  return (
    <React.Fragment>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <NewHeader />

        <div>
          Hello <b>{getDecryptedData("currDSO")["agnName"]}</b>, what would you
          like to do today? <br></br>
          <div className="row">
            <div className="col-xl-2 col-md-5 mb-3">
              <a
                href="/bancassurance/amenfiman/onboarding"
                // target="_blank"
                class=" text-decoration-none"
              >
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                          Onboarding
                        </div>
                        {/* <div className="h5 mb-0 font-weight-bold text-gray-800">
                        100
                      </div> */}
                      </div>
                      <div className="col-auto" style={{ margin: "auto" }}>
                        <img
                          src={onboarding}
                          alt="logo"
                          style={{
                            height: "50px",
                            // marginLeft: "auto",
                            // marginRight: "auto",
                            // display: "block",
                            // border: "3px solid #73AD21",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-2 col-md-5 mb-3">
              <a
                href="/bancassurance/amenfiman/enquiry"
                // target="_blank"
                class=" text-decoration-none"
              >
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                          Customer Enquiries
                        </div>
                      </div>
                      <div className="col-auto" style={{ margin: "auto" }}>
                        <img
                          src={inquiry}
                          alt="logo"
                          style={{ height: "50px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-2 col-md-5 mb-3">
              <a
                href="#"
                // target="_blank"
                class=" text-decoration-none"
              >
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                          Endorsements
                        </div>
                      </div>
                      <div className="col-auto" style={{ margin: "auto" }}>
                        <img src={edit} alt="logo" style={{ height: "50px" }} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-2 col-md-5 mb-3">
              <a
                href="#"
                // target="_blank"
                class=" text-decoration-none"
              >
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                          My Profile
                        </div>
                      </div>
                      <div className="col-auto" style={{ margin: "auto" }}>
                        <img
                          src={profile}
                          alt="logo"
                          style={{ height: "50px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-2 col-md-5 mb-3">
              <a href="#" target="" class=" text-decoration-none">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                          Reports
                        </div>
                      </div>
                      <div className="col-auto">
                        <img
                          src={reports}
                          alt="logo"
                          style={{ height: "50px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          {/* <!-- Content Row --> */}
          <div className="row">
            <div className="col-xl-8 col-lg-7">
              {/* <!-- Bar Chart --> */}
              <div className="shadow">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-black">
                    Number of successful purchased policies from year to date by
                    Month
                  </h6>
                </div>
                <div className="card-body">
                  <div className="chart-container">
                    {Object.keys(chartData).length > 0 && (
                      <div
                        style={{
                          // width: "700vw",
                          margin: "auto",
                          width: "60vw",
                          // height: "60vh",
                          position: "relative",
                        }}
                      >
                        <BarChart chartData={chartData} />
                      </div>
                    )}
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>

            {/* <!-- Donut Chart --> */}
            <div className="col-xl-4 col-lg-5">
              <div className="shadow mb-4">
                {/* <!-- Card Header - Dropdown --> */}
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-black">
                    Number of policies by Product Type
                  </h6>
                </div>
                {/* <!-- Card Body --> */}
                <div className="card-body">
                  <div>
                    {/* <PieChart pieData={pieData} /> */}
                    {Object.keys(pieData).length > 0 && (
                      // <div
                      //   style={{
                      //     // width: "700vw",
                      //     margin: "auto",
                      //     width: "60vw",
                      //     // height: "60vh",
                      //     position: "relative",
                      //   }}
                      // >
                      <PieChart pieData={pieData} />
                      // </div>
                    )}
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h1>Your App Title</h1> */}
      </div>
    </React.Fragment>
  );
}

export default GCBHome;
