/* eslint-disable default-case */
import React, { PureComponent } from "react";
import Welcome from "./Welcome";
import UserAccount from "./UserAccount";
import FormOTP from "./FormOTP";
import FormUserDetails from "./FormUserDetails";
import FormBeneficiaryDetails from "./FormBeneficiaryDetails";
import ProductDetails from "./ProductDetails";
import FormPaymentDetails from "./FormPaymentDetails";
import FormMedicalDetails from "./FormMedicalDetails";
import FormAgent from "./FormAgent";
import Confirm from "./Confirm";

// import Confirm from "./Confirm";
import Success from "./Success";

export class UserForm extends PureComponent {
  state = {
    step: 1,
    clntCode: "",
    firstName: "",
    otherNames: "",
    lastName: "",
    dob: "",
    gender: "",
    email: "",
    maritalStatus: "",
    phoneNumber: "",
    occupation: "",
    idType: "",
    idNumber: "",
    employer: "",
    product: "",
    productCode: "",
    term: "",
    tpd: "",
    retrenchment: "",
    dreadDisease: "",
    pa: "",
    premium: "",
    sa: "",
    escalation: "",
    escalationRate: "",
    paSurname: "",
    paOthername: "",
    padob: "",
    pagender: "",
    paymentMode: "",
    bank: "1028-AMANSIE WEST RURAL BANCASSURANCE",
    bankBranch: "",
    accountNumber: "",
    deductionDate: "",
    paymentFrequency: "",
    signature: "",
    city: "",
    bio: "",
    benList: [],
    showBenModal: false,
    showEditBenModal: false,
    trustee: [],
    trusteeRequired: false,
    showTrusteeModal: false,
    showEditTrusteeModal: false,
    agentCode: "",
    agentName: "",
    agnCode: "",
    postalAddress: "",
    residentialAddress: "",
    nationality: "",
    religion: "",
    pNumber: "",
    md1: "",
    md2: "",
    md3: "",
    md4: "",
    condition: "",
    medication: "",
    surgery: "",
    proposal_details: {},
    dependentRequired: false,
    dependentList: [],
    showDepModal: false,
    showEditDepModal: false,
  };

  // Proceed to the next step
  nextStep = (stepno) => {
    const { step } = this.state;

    // console.log(step);
    // console.log("Stepno:" + stepno);

    if (stepno) {
      this.setState({
        step: stepno,
      });
    } else
      this.setState({
        step: step + 1,
      });
  };

  // Proceed to the previous step
  prevStep = (stepno) => {
    const { step } = this.state;

    if (stepno) {
      this.setState({
        step: stepno,
      });
    } else
      this.setState({
        step: step - 1,
      });
  };

  //Handle fields change
  handleChange = (input) => (e) => {
    // console.log(e);
    this.setState({ [input]: e.target.value });
  };

  valueChange = (input) => {
    this.setState({
      [input[0]]: input[1],
    });
  };

  addBen = (ben) => {
    // console.log(ben);
    // console.log(this.state.benList);
    this.setState({
      benList: [...this.state.benList, ben],
    });
    this.hideBenModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  updateBen = (ben) => {
    const benIndex = this.state.benList.findIndex(
      (element) => element.id === ben.id
    );
    let newBenList = [...this.state.benList];
    newBenList[benIndex] = {
      ...newBenList[benIndex],
      id: ben.id,
      surname: ben.surname,
      othernames: ben.othernames,
      dob: ben.dob,
      gender: ben.gender,
      relationship: ben.relationship,
      phone: ben.phone,
      percent: ben.percent,
    };
    this.setState({
      benList: newBenList,
    });
    this.hideEditBenModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  removeBen = (benId) => {
    this.setState({
      benList: this.state.benList.filter(function (ben) {
        return ben.id !== benId;
      }),
    });
  };

  showBenModalHandler = (e) => {
    this.setState({ showBenModal: true });
  };

  hideBenModalHandler = (e) => {
    this.setState({ showBenModal: false });
  };

  showEditBenModalHandler = (e) => {
    this.setState({ showEditBenModal: true });
  };

  hideEditBenModalHandler = (e) => {
    this.setState({ showEditBenModal: false });
  };

  addTrust = (trustee) => {
    // console.log(ben);
    // console.log(this.state.benList);
    this.setState({
      trustee: [...this.state.trustee, trustee],
    });
    this.hideTrusteeModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  removeTrust = (trustId) => {
    this.setState({
      trustee: this.state.trustee.filter(function (trustee) {
        return trustee.id !== trustId;
      }),
    });
  };

  updateTrust = (trust) => {
    const trustIndex = this.state.trustee.findIndex(
      (element) => element.id === trust.id
    );
    let newTrustee = [...this.state.trustee];
    newTrustee[trustIndex] = {
      ...newTrustee[trustIndex],
      id: trust.id,
      surname: trust.surname,
      othernames: trust.othernames,
      dob: trust.dob,
      relationshiop: trust.relationshiop,
      phone: trust.phone,
    };
    this.setState({
      trustee: newTrustee,
    });
    this.hideEditTrusteeModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  showTrusteeModalHandler = (e) => {
    this.setState({ showTrusteeModal: true });
  };

  hideTrusteeModalHandler = (e) => {
    this.setState({ showTrusteeModal: false });
  };

  showEditTrusteeModalHandler = (e) => {
    this.setState({ showEditTrusteeModal: true });
  };

  hideEditTrusteeModalHandler = (e) => {
    this.setState({ showEditTrusteeModal: false });
  };

  trusteeRequredHandler = (e) => {
    this.setState({ trusteeRequred: true });
  };

  showDepModalHandler = (e) => {
    this.setState({ showDepModal: true });
  };

  hideDepModalHandler = (e) => {
    this.setState({ showDepModal: false });
  };

  showEditDepModalHandler = (e) => {
    this.setState({ showEditDepModal: true });
  };

  hideEditDepModalHandler = (e) => {
    this.setState({ showEditDepModal: false });
  };

  addDependent = (dep) => {
    // console.log(ben);
    // console.log(this.state.benList);
    this.setState({
      dependentList: [...this.state.dependentList, dep],
    });
    this.hideDepModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  updateDependent = (dep) => {
    const memberIndex = this.state.dependentList.findIndex(
      (element) => element.id === dep.id
    );
    let newdependentList = [...this.state.dependentList];
    newdependentList[memberIndex] = {
      ...newdependentList[memberIndex],
      id: dep.id,
      surname: dep.surname,
      othernames: dep.othernames,
      dob: dep.dob,
      relationship: dep.relationship,
      gender: dep.gender,
    };
    this.setState({
      dependentList: newdependentList,
    });
    this.hideEditDepModalHandler();
    // { name: uName, age: uAge, id: Math.random().toString() }
  };

  removeDependent = (depId) => {
    this.setState({
      dependentList: this.state.dependentList.filter(function (dep) {
        return dep.id !== depId;
      }),
    });
  };

  render() {
    const { step } = this.state;
    const {
      clntCode,
      firstName,
      otherNames,
      lastName,
      dob,
      gender,
      phoneNumber,
      maritalStatus,
      idType,
      idNumber,
      email,
      occupation,
      employer,
      product,
      productCode,
      term,
      tpd,
      retrenchment,
      dreadDisease,
      pa,
      sa,
      premium,
      escalation,
      escalationRate,
      paSurname,
      paOthername,
      padob,
      pagender,
      paymentMode,
      bank,
      bankBranch,
      accountNumber,
      deductionDate,
      paymentFrequency,
      signature,
      city,
      bio,
      benList,
      showBenModal,
      showEditBenModal,
      trustee,
      trusteeRequired,
      showTrusteeModal,
      showEditTrusteeModal,
      agentCode,
      agentName,
      agnCode,
      postalAddress,
      residentialAddress,
      nationality,
      religion,
      pNumber,
      md1,
      md2,
      md3,
      md4,
      condition,
      medication,
      surgery,
      proposal_details,
      dependentRequired,
      dependentList,
      showDepModal,
      showEditDepModal,
    } = this.state;
    const values = {
      clntCode,
      firstName,
      otherNames,
      dob,
      lastName,
      gender,
      maritalStatus,
      phoneNumber,
      idType,
      idNumber,
      email,
      occupation,
      employer,
      product,
      productCode,
      term,
      tpd,
      retrenchment,
      dreadDisease,
      pa,
      premium,
      sa,
      escalation,
      escalationRate,
      paSurname,
      paOthername,
      padob,
      pagender,
      paymentMode,
      bank,
      bankBranch,
      accountNumber,
      deductionDate,
      paymentFrequency,
      signature,
      city,
      bio,
      benList,
      showBenModal,
      showEditBenModal,
      trustee,
      trusteeRequired,
      showTrusteeModal,
      showEditTrusteeModal,
      agentCode,
      agentName,
      agnCode,
      postalAddress,
      residentialAddress,
      nationality,
      religion,
      pNumber,
      md1,
      md2,
      md3,
      md4,
      condition,
      medication,
      surgery,
      proposal_details,
      dependentRequired,
      dependentList,
      showDepModal,
      showEditDepModal,
    };

    switch (step) {
      case 1:
        return (
          <Welcome
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      // return <Success values={values} />;
      // return <Confirm values={values} valueChange={this.valueChange} />;
      case 2:
        return (
          <UserAccount
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 3:
        return (
          <FormUserDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 4:
        return (
          <FormOTP
            // prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      // case 5:
      //   return (
      //     <FormPersonalDetails
      //       prevStep={this.prevStep}
      //       nextStep={this.nextStep}
      //       handleChange={this.handleChange}
      //       values={values}
      //     />
      //   );
      case 5:
        return (
          <ProductDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 6:
        return (
          <FormBeneficiaryDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
            addBen={this.addBen}
            updateBen={this.updateBen}
            showBenModalHandler={this.showBenModalHandler}
            hideBenModalHandler={this.hideBenModalHandler}
            removeBen={this.removeBen}
            showEditBenModalHandler={this.showEditBenModalHandler}
            hideEditBenModalHandler={this.hideEditBenModalHandler}
            showTrusteeModalHandler={this.showTrusteeModalHandler}
            hideTrusteeModalHandler={this.hideTrusteeModalHandler}
            showEditTrusteeModalHandler={this.showEditTrusteeModalHandler}
            hideEditTrusteeModalHandler={this.hideEditTrusteeModalHandler}
            trusteeRequredHandler={this.trusteeRequredHandler}
            addTrust={this.addTrust}
            removeTrust={this.removeTrust}
            updateTrust={this.updateTrust}
            addDependent={this.addDependent}
            updateDependent={this.updateDependent}
            removeDependent={this.removeDependent}
            showDepModalHandler={this.showDepModalHandler}
            hideDepModalHandler={this.hideDepModalHandler}
            showEditDepModalHandler={this.showEditDepModalHandler}
            hideEditDepModalHandler={this.hideEditDepModalHandler}
          />
        );
      case 7:
        return (
          <FormMedicalDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 8:
        return (
          <FormPaymentDetails
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 9:
        return (
          <FormAgent
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            valueChange={this.valueChange}
            values={values}
          />
        );
      case 10:
        return (
          <Confirm
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            values={values}
            valueChange={this.valueChange}
          />
        );
      case 11:
        return <Success values={values} />;
    }
  }
}

export default UserForm;
