/* eslint-disable default-case */
import React, { PureComponent, useContext } from "react";
import AgentWelcome from "./AgentWelcome";
import AgentFormOTP from "./AgentFormOTP";
import AgentWelcomeRegister from "./AgentWelcomeRegister";
import AgentFormTC from "./AgentFormTC";
import CryptoJS from "crypto-js";

export class UserFormAgencyRegister extends PureComponent {
  state = {
    step: 1,
    values: {
      userName: "",
      password: "",
    },
  };

  // Proceed to the next step
  nextStep = (stepno) => {
    const { step } = this.state;

    if (stepno) {
      this.setState({
        step: stepno,
      });
    } else
      this.setState({
        step: step + 1,
      });
  };

  handleChangeValue = (key, value) => {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [key]: value,
      },
    }));
  };

  //break the encrypted data into smaller chunks and store them in localStorage
  saveEncryptedData = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
    const totalChunks = Math.ceil(encryptedData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, encryptedData.length);
      const chunk = encryptedData.substring(start, end);

      // Store each chunk in localStorage with a unique key
      localStorage.setItem(`${key}_${i}`, chunk);
    }
  };

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  render() {
    const { step, values } = this.state;

    switch (step) {
      case 1:
        return (
          <AgentWelcomeRegister
            nextStep={this.nextStep}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            values={values}
            handleChangeValue={this.handleChangeValue}
          />
        );
      case 2:
        return (
          <AgentFormOTP
            nextStep={this.nextStep}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            values={values}
            handleChangeValue={this.handleChangeValue}
          />
        );
      case 3:
        return (
          <AgentFormTC
            nextStep={this.nextStep}
            saveEncryptedData={this.saveEncryptedData}
            getDecryptedData={this.getDecryptedData}
            values={values}
            handleChangeValue={this.handleChangeValue}
          />
        );
    }
  }
}

export default UserFormAgencyRegister;
