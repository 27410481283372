import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

import qmark from "../../question-mark.svg";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";

export class ProductDetailsEHP extends PureComponent {
  state = {
    paChange: false,
    incrementOption: false,
  };

  handleIncrementOption = (e) => {
    const escalation = e.target.value;
    if (escalation === "Yes") {
      this.setState({ incrementOption: true });
      this.props.valueChange(["escalation", escalation]);
    } else {
      this.setState({ incrementOption: false });
      this.props.valueChange(["escalation", escalation]);
    }
  };

  render() {
    const {
      values,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      handleChange,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
    } = this.props;

    const popoverRight = (
      <Popover id="popover-positioned-right" title="Popover right">
        <strong>Holy guacamole!</strong> Check this info.
      </Popover>
    );

    return (
      <div>
        {/* <div style={{ width: "100%" }}>
          <dv style={{ width: "50%", height: "100px", float: "left" }}>A</dv>
          <div style={{ marginLeft: "50%", height: "100px" }}>?</div>
        </div> */}

        <Form.Group>
          <div>
            {/* premium */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="sa"
                  label="Select Sum Assured"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("sa")}
                    defaultValue={values.sa}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: premiumError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"2500"}>Basic (Sum Assured = 2,500)</option>
                    <option value={"5000"}>
                      Standard (Sum Assured = 5,000)
                    </option>
                    <option value={"10000"}>
                      Prestige (Sum Assured = 10,000)
                    </option>
                    <option value={"15000"}>
                      Elite (Sum Assured = 15,000)
                    </option>
                    <option value={"30000"}>
                      Ultimate (Sum Assured = 30,000)
                    </option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {premiumError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="premiumTip"
                  style={{
                    color: "#21bdce",
                  }}
                />
                <ReactTooltip
                  id="premiumTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  A sum assured is a fixed amount <br />
                  that is paid to the beneficiary <br />
                  of the policyholder in the unfortunate <br />
                  event of the policyholder's demise.
                </ReactTooltip>
              </div>
            </div>

            {/* Escalation */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="escalation"
                  label="Do you wish to have incremental option?"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleIncrementOption}
                    // defaultValue={values.escalation}
                    style={{
                      border: escalationError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {escalationError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="escalationTip"
                  style={{
                    color: "#21bdce",
                  }}
                />
                <ReactTooltip
                  id="escalationTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  The option to increase the <br />
                  contractual premium on <br />
                  an annual basis in order <br /> for the benefits to be
                  <br />
                  inflation-linked.
                </ReactTooltip>
              </div>
            </div>
            {this.state.incrementOption && (
              <ProductDetailsEscalation
                escalationRateError={escalationRateError}
                handleChange={handleChange}
                values={values}
              />
            )}
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsEHP;
