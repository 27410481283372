import React, { PureComponent } from "react";
import starLife from "../../StarLife_Chatbot-06.png";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import Menu from "./menu";

export class WelcomeSpectra extends PureComponent {
  continue = (e) => {
    e.preventDefault();
    if (localStorage.getItem("dso") !== null) {
      this.props.valueChange([
        "agentCode",
        JSON.parse(localStorage.getItem("dso"))["agnShtDesc"],
      ]);
      this.props.valueChange([
        "agnCode",
        JSON.parse(localStorage.getItem("dso"))["agnCode"],
      ]);
      this.props.valueChange([
        "agentName",
        JSON.parse(localStorage.getItem("dso"))["agnName"],
      ]);
    }
    this.props.nextStep();
  };

  render() {
    return (
      <React.Fragment>
        <Menu />
        <hr />
        <br></br>
        <h2>
          {/* <FontAwesomeIcon icon={faHandshake} />  */}
          Welcome to StarLife Spectra Health Policy Onboarding
          {/* <FontAwesomeIcon icon={faHandshake} /> */}
        </h2>
        <p>
          {/* I'm your virtual assistant and will guide you through your policy
          onboarding process. */}
        </p>
        <br />
        <Button
          variant="primary"
          style={{
            backgroundColor: "#763984",
            outlineColor: "#763984",
            borderColor: "#763984",
          }}
          onClick={this.continue}
        >
          Start Application
        </Button>
      </React.Fragment>
    );
  }
}

export default WelcomeSpectra;
