import CryptoJS from "crypto-js";

// export const getAge = (dob) => {
//   // //calculate month difference from current date in time
//   const month_diff = Date.now() - new Date(dob).getTime();

//   // convert the calculated difference in date format
//   const age_dt = new Date(month_diff);

//   // extract year from date
//   const year = age_dt.getUTCFullYear();

//   // now calculate the age of the user
//   const age = Math.abs(year - 1970);
//   return age;
// };

export const getAge = (dob) => {
  const birthDate = new Date(dob);
  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDiff = currentDate.getMonth() - birthDate.getMonth();
  const dayDiff = currentDate.getDate() - birthDate.getDate();

  // Adjust age if the birth month has not occurred yet or if it is the birth month but the day has not occurred yet
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

export const phonePattern = (phone) => {
  const pattern = new RegExp(/^[0-9\b]+$/);

  if (!pattern.test(phone) || phone.length !== 10) {
    return false;
  } else {
    return true;
  }
};

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

//retrieve all the chunk data stored in localStorage and decrypt it to get the original data
export const getDecryptedData = (key) => {
  const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
    storedKey.startsWith(`${key}_`)
  );

  if (chunkKeys.length === 0) {
    // No chunks found for the given key
    return null;
  }

  // Concatenate and decrypt all chunks to get the encrypted data
  let encryptedData = "";
  for (const chunkKey of chunkKeys) {
    encryptedData += localStorage.getItem(chunkKey);
  }

  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

//break the encrypted data into smaller chunks and store them in localStorage
export const saveEncryptedData = (key, data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_ENCRYPTION_KEY
  ).toString();

  const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
  const totalChunks = Math.ceil(encryptedData.length / chunkSize);

  for (let i = 0; i < totalChunks; i++) {
    const start = i * chunkSize;
    const end = Math.min(start + chunkSize, encryptedData.length);
    const chunk = encryptedData.substring(start, end);

    // Store each chunk in localStorage with a unique key
    localStorage.setItem(`${key}_${i}`, chunk);
  }
};

//retrieve all the chunk data stored in localStorage and decrypt it to get the original data
export const removeEncryptedData = (key) => {
  const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
    storedKey.startsWith(`${key}_`)
  );

  for (const chunkKey of chunkKeys) {
    localStorage.removeItem(chunkKey);
  }
};

export const removeKey = (key) => {
  const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
    storedKey.startsWith(`${key}_`)
  );

  for (const chunkKey of chunkKeys) {
    localStorage.removeItem(chunkKey);
  }
};
