import React, { PureComponent } from "react";
import logo from "../../StarLife_Chatbot-06.png";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputGroup } from "react-bootstrap";
import "../Welcome.styles.css";
import BanksData from "./data/BanksData";
// import Button from "react-bootstrap/Button";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import check from "../../../src/Green-Round-Tick.png";
import Menu from "./menu";

export class AccountVerification extends PureComponent {
  state = {
    accountNoError: "",
    accountTypeError: "",
    bankError: "",
    momoError: "",
    showBanks: false,
    showMoMo: false,
    loading: false,
    showModal: false,
    accountNo: "",
    accountName: "",
    bankName: "",
  };

  handleRadioChange = (e) => {
    if (e.target.form[1].checked) {
      this.setState({ showBanks: true, showMoMo: false });
    } else {
      this.setState({ showBanks: false, showMoMo: true });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };
  handleShow = () => {
    this.setState({ showModal: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let accountNoError = "";
    let accountTypeError = "";
    let bankError = "";
    let momoError = "";

    this.setState({
      accountNoError,
      accountTypeError,
      bankError,
      momoError,
    });

    if (!e.target.form[0].value) {
      // Checks if field is empty
      accountNoError = "Kindly provide Account or MoMo number";
    }

    if (!e.target.form[1].checked & !e.target.form[2].checked) {
      // Checks if field is empty
      accountTypeError = "Kindly select option";
    }

    if (e.target.form[1].checked) {
      if (!e.target.form[3].value) {
        // Checks if field is empty
        bankError = "Kindly select bank";
      }
    }

    if (e.target.form[2].checked) {
      if (!e.target.form[3].value) {
        // Checks if field is empty
        momoError = "Kindly select network";
      }
    }

    if (accountNoError || accountTypeError || bankError || momoError) {
      this.setState({
        accountNoError,
        accountTypeError,
        bankError,
        momoError,
      });
      return false;
    }

    if (e.target.form[1].checked) {
      const bankAccount = {
        Account_Number: e.target.form[0].value,
        Bank_Name: e.target.form[3].value,
        Code: e.target.form[3].value.split("-")[0],
      };

      this.setState({ loading: true, bankName: e.target.form[3].value });
      axios
        .post("/api/customer/account", bankAccount)
        .then((response) => {
          if (response.data.message_code === 100) {
            this.setState({
              loading: false,
              showModal: true,
              accountNo: response.data.data.account_number,
              accountName: response.data.data.account_name,
            });
            return true;
          } else if (response.data.message_code === 102) {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: response.data.message,
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          } else {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: "Account number verification failed",
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            showModal: false,
            accountNoError: "Account number verification failed",
          });
          if (accountNoError) {
            this.setState({
              accountNoError,
            });
            return false;
          }
        });
    }

    if (e.target.form[2].checked) {
      const momoAccount = {
        Phone_Number: e.target.form[0].value,
        Network_Name: e.target.form[3].value,
      };

      this.setState({ loading: true, bankName: e.target.form[3].value });

      axios
        .post("/api/customer/momoaccount", momoAccount)
        .then((response) => {
          if (response.data.message_code === 100) {
            this.setState({
              loading: false,
              showModal: true,
              accountNo: response.data.data.account_number,
              accountName: response.data.data.account_name,
            });
            return true;
          } else if (response.data.message_code === 102) {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: response.data.message,
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          } else {
            this.setState({
              loading: false,
              showModal: false,
              accountNoError: "Momo number verification failed",
            });
            if (accountNoError) {
              this.setState({
                accountNoError,
              });
              return false;
            }
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            showModal: false,
            accountNoError: "Momo number verification failed",
          });
          if (accountNoError) {
            this.setState({
              accountNoError,
            });
            return false;
          }
        });
    }
  };
  render() {
    const banks = BanksData;
    return (
      <React.Fragment>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-retail"}></div>
            <Menu />
            <hr />
            <h3>
              <b>Account/MoMo Number Verification</b>
            </h3>
            {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 400,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="accountNo"
                    label="Account or Phone Number"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="number"
                      autoComplete="off"
                      min="0"
                      onWheel={(event) => event.currentTarget.blur()}
                      style={{
                        border: this.state.accountNoError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountNoError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <div className="mb-3" style={{ textAlign: "left" }}>
                    Account Type
                  </div>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    <InputGroup.Radio
                      style={{
                        border: this.state.accountTypeError
                          ? "1px solid red"
                          : "",
                      }}
                      value="M"
                      name="accountType"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Bank &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.accountTypeError
                          ? "1px solid red"
                          : "",
                      }}
                      value="A"
                      name="accountType"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; MoMo
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountTypeError}
                    </div>
                  </InputGroup>
                </Form.Group>
                {this.state.showBanks && (
                  <Form.Group>
                    <FloatingLabel
                      controlId="bank"
                      label="Select Bank"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        // onChange={this.handleIncrementOption}
                        // defaultValue={values.escalation}
                        style={{
                          border: this.state.bankError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        {banks.map((bank) => (
                          <option key={bank.id} value={bank.name}>
                            {bank.name}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                )}

                {this.state.showMoMo && (
                  <Form.Group>
                    <FloatingLabel
                      controlId="network"
                      label="Select Network"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        // onChange={this.handleIncrementOption}
                        // defaultValue={values.escalation}
                        style={{
                          border: this.state.momoError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        <option value={"MTN"}>MTN</option>
                        <option value={"ATL"}>AirtelTigo</option>
                        <option value={"VOD"}>Vodafone</option>
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.momoError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                )}
                {/* {this.state.showBanks && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify Account Number
              </Button>
            )}
            {this.state.showMoMo && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify MoMo Number
              </Button>
            )} */}
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                    color: "white",
                  }}
                  onClick={this.handleSubmit}
                >
                  Verify &nbsp;
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </Form>

              {/*Modal */}
              <Modal
                // className="modal-dialog-centered"
                size="md"
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                // aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {/* <Modal.Header
              closeButton
              style={{ backgroundColor: "#763984", color: "white" }}
            >
              <Modal.Title>Mandate Form</Modal.Title>
            </Modal.Header> */}
                <Modal.Body
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="head">
                <h3 style={{ marginTop: "5px" }}>Lorem ipsum dolor sit amet</h3>
                <h4>Lorem ipsum dolor sit amet</h4>
              </div>
              <FontAwesomeIcon icon={faCheck} size="lg" fade /> */}
                  {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                  {/* <img
                src={check}
                alt="logo"
                style={{
                  width: "20%",
                  //   alignContent: "center",
                }}
              /> */}
                  <table border="0" width="100%">
                    <tr>
                      <td colSpan="2" align="center">
                        <img
                          src={check}
                          alt="logo"
                          style={{
                            width: "20%",
                            //   alignContent: "center",
                          }}
                        />
                        <hr />
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="50%">Account number:</td>
                      <td>
                        <b>{this.state.accountNo && this.state.accountNo}</b>
                      </td>
                    </tr>
                    <tr align="center">
                      <td width="50%">Account holder's name: </td>
                      <td>
                        <b>
                          {this.state.accountName && this.state.accountName}
                        </b>
                      </td>
                    </tr>
                  </table>
                  {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* End of Modal */}
            </div>
          </>
        ) : (
          <>
            <Menu />
            <hr />
            <h3>
              <b>Account/MoMo Number Verification</b>
            </h3>
            {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 400,
                padding: 10,
              }}
            >
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="accountNo"
                    label="Account or Phone Number"
                    className="mb-2"
                  >
                    <Form.Control
                      required
                      type="number"
                      autoComplete="off"
                      min="0"
                      onWheel={(event) => event.currentTarget.blur()}
                      style={{
                        border: this.state.accountNoError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountNoError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group>
                  <div className="mb-3" style={{ textAlign: "left" }}>
                    Account Type
                  </div>
                  <InputGroup
                    className="mb-3"
                    onChange={this.handleRadioChange}
                  >
                    <InputGroup.Radio
                      style={{
                        border: this.state.accountTypeError
                          ? "1px solid red"
                          : "",
                      }}
                      value="M"
                      name="accountType"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Bank &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: this.state.accountTypeError
                          ? "1px solid red"
                          : "",
                      }}
                      value="A"
                      name="accountType"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; MoMo
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountTypeError}
                    </div>
                  </InputGroup>
                </Form.Group>
                {this.state.showBanks && (
                  <Form.Group>
                    <FloatingLabel
                      controlId="bank"
                      label="Select Bank"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        // onChange={this.handleIncrementOption}
                        // defaultValue={values.escalation}
                        style={{
                          border: this.state.bankError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        {banks.map((bank) => (
                          <option
                            key={bank.id}
                            value={bank.code + "-" + bank.name}
                          >
                            {bank.name}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                )}

                {this.state.showMoMo && (
                  <Form.Group>
                    <FloatingLabel
                      controlId="network"
                      label="Select Network"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        // onChange={this.handleIncrementOption}
                        // defaultValue={values.escalation}
                        style={{
                          border: this.state.momoError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        <option value={"MTN"}>MTN</option>
                        <option value={"AirtelTigo"}>AirtelTigo</option>
                        <option value={"Vodafone"}>Vodafone</option>
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.momoError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                )}
                {/* {this.state.showBanks && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify Account Number
              </Button>
            )}
            {this.state.showMoMo && (
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                  color: "white",
                }}
                onClick={this.handleSubmit}
              >
                Verify MoMo Number
              </Button>
            )} */}
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                    color: "white",
                  }}
                  onClick={this.handleSubmit}
                >
                  Verify &nbsp;
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </Form>

              {/*Modal */}
              <Modal
                // className="modal-dialog-centered"
                size="md"
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                // aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {/* <Modal.Header
              closeButton
              style={{ backgroundColor: "#763984", color: "white" }}
            >
              <Modal.Title>Mandate Form</Modal.Title>
            </Modal.Header> */}
                <Modal.Body
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="head">
                <h3 style={{ marginTop: "5px" }}>Lorem ipsum dolor sit amet</h3>
                <h4>Lorem ipsum dolor sit amet</h4>
              </div>
              <FontAwesomeIcon icon={faCheck} size="lg" fade /> */}
                  {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                  {/* <img
                src={check}
                alt="logo"
                style={{
                  width: "20%",
                  //   alignContent: "center",
                }}
              /> */}
                  <table border="0" width="100%">
                    <tr>
                      <td colSpan="2" align="center">
                        <img
                          src={check}
                          alt="logo"
                          style={{
                            width: "20%",
                            //   alignContent: "center",
                          }}
                        />
                        <hr />
                      </td>
                    </tr>
                    {this.state.showBanks && (
                      <>
                        <tr align="center">
                          <td width="50%">Bank Name:</td>
                          <td>
                            <b>
                              {this.state.bankName &&
                                this.state.bankName.split("-")[1]}
                            </b>
                          </td>
                        </tr>
                        <tr align="center">
                          <td width="50%">Account number:</td>
                          <td>
                            <b>
                              {this.state.accountNo && this.state.accountNo}
                            </b>
                          </td>
                        </tr>
                        <tr align="center">
                          <td width="50%">Account holder's name: </td>
                          <td>
                            <b>
                              {this.state.accountName && this.state.accountName}
                            </b>
                          </td>
                        </tr>
                      </>
                    )}

                    {this.state.showMoMo && (
                      <>
                        <tr align="center">
                          <td width="50%">Network:</td>
                          <td>
                            <b>{this.state.bankName && this.state.bankName}</b>
                          </td>
                        </tr>
                        <tr align="center">
                          <td width="50%">MoMo number:</td>
                          <td>
                            <b>
                              {this.state.accountNo && this.state.accountNo}
                            </b>
                          </td>
                        </tr>
                        <tr align="center">
                          <td width="50%">Account holder's name: </td>
                          <td>
                            <b>
                              {this.state.accountName && this.state.accountName}
                            </b>
                          </td>
                        </tr>
                      </>
                    )}
                  </table>
                  {/* Account number: 112365444 Account holder's name: Daniel ELijah */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* End of Modal */}
            </div>
          </>
        )}
      </React.Fragment>
    );
  }
}

export default AccountVerification;
