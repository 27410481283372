import React, { useContext } from "react";
import onboarding from "../../icons8-onboarding-50-retail.png";
import trans from "../../icons8-transactions-64.png";
import inquiry from "../../icons8-binoculars-48-retail.png";
import edit from "../../icons8-edit-64-retail.png";
import reports from "../../icons8-combo-chart-50-retail.png";
import profile from "../../icons8-admin-settings-male-48-retail.png";
import verify from "../../icons8-verified-account-48.png";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Card, Modal } from "react-bootstrap";
import "../Welcome.styles.css";
import Menu from "./menu";
// import useIosInstallPrompt from "../hooks/useIosInstallPrompt";
// import useWebInstallPrompt from "../hooks/useWebInstallPrompt";
import spectra from "../../spectra-logo-removebg-preview.png";
import spectralogo from "../../spectra-download.jfif";
import gpcc from "../../GPCC_Logo.jpg";

function DashboardHome() {
  // const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  // const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
  //   useWebInstallPrompt();

  // if (!iosInstallPrompt && !webInstallPrompt) {
  //   return null;
  // }

  return (
    <React.Fragment>
      {/* <img
        src={gcb}
        alt="logo"
        style={{
          align: "right",
        }}
      /> */}
      {/* <br /> <br />
      <h1>
        <b>Digital Onboarding Platforms</b>
      </h1> */}
      <Menu />
      <hr />
      {/* <div style={{ backgroundColor: "red" }}>Red</div> */}
      {/* <!-- Image and text -->
      <ul className="nav justify-content-end">
        <li className="nav-item">
          <a className="nav-link active" href="#">
            Active
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
            Link
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">
            Link
          </a>
        </li>
      </ul> */}
      {/* <Menu /> */}
      Hello <b>{JSON.parse(localStorage.getItem("dso"))["agnName"]}</b>, what
      would you like to do today? <br></br>
      {/* <img src={mypadie} alt="logo" style={{ width: "200px" }} />
        <h2>Hello My Padie</h2>
        <p>
          I'm your virtual assistant and will guide you through your policy
          onboarding process.
        </p> */}
      <div id="wrapper">
        <div id="content-wrapper" style={{ backgroundColor: "#fff" }}>
          <div id="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    href="/retail/spectra/onboarding"
                    className=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              Spectra Health UPP Onboarding
                            </div>
                            {/* <div className="h5 mb-0 font-weight-bold text-gray-800">
                        100
                      </div> */}
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={spectralogo}
                              alt="logo"
                              style={{
                                height: "50px",
                                width: "172px",
                                // marginLeft: "auto",
                                // marginRight: "auto",
                                // display: "block",
                                // border: "3px solid #73AD21",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                {/* GPCC Onbarding */}
                <div className="col-xl-2 col-md-5 mb-3">
                  <a
                    // href="/retail/gpcc/onboarding"
                    href="/retail/gpcc/onboarding"
                    // target="_blank"
                    className=" text-decoration-none"
                  >
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-black text-uppercase mb-1">
                              GPCC Onboarding
                            </div>
                            {/* <div className="h5 mb-0 font-weight-bold text-gray-800">
                        100
                      </div> */}
                          </div>
                          <div className="col-auto" style={{ margin: "auto" }}>
                            <img
                              src={gpcc}
                              alt="logo"
                              style={{
                                height: "50px",
                                width: "172px",
                                // marginLeft: "auto",
                                // marginRight: "auto",
                                // display: "block",
                                // border: "3px solid #73AD21",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DashboardHome;
