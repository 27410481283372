const OmniBankBranchesData = [
  {
    bbrCode: 9355,
    bbrBnkCode: 271,
    bbrBranchName: "ADABRAKA",
    bbrShtDesc: "271100",
  },
  {
    bbrCode: 9356,
    bbrBnkCode: 271,
    bbrBranchName: "ACCRA CENTRAL",
    bbrShtDesc: "271101",
  },
  {
    bbrCode: 9357,
    bbrBnkCode: 271,
    bbrBranchName: "SPINTEX",
    bbrShtDesc: "271102",
  },
  {
    bbrCode: 9358,
    bbrBnkCode: 271,
    bbrBranchName: "NIMA",
    bbrShtDesc: "271103",
  },
  {
    bbrCode: 9359,
    bbrBnkCode: 271,
    bbrBranchName: "NORTH INDUSTRIAL AREA",
    bbrShtDesc: "271104",
  },
  {
    bbrCode: 9360,
    bbrBnkCode: 271,
    bbrBranchName: "MADINA",
    bbrShtDesc: "271105",
  },
  {
    bbrCode: 9361,
    bbrBnkCode: 271,
    bbrBranchName: "TEMA",
    bbrShtDesc: "271106",
  },
  {
    bbrCode: 9362,
    bbrBnkCode: 271,
    bbrBranchName: "DARKUMAN",
    bbrShtDesc: "271107",
  },
  {
    bbrCode: 9363,
    bbrBnkCode: 271,
    bbrBranchName: "TAKORADI",
    bbrShtDesc: "271108",
  },
  {
    bbrCode: 9364,
    bbrBnkCode: 271,
    bbrBranchName: "KUMASI",
    bbrShtDesc: "271109",
  },
  {
    bbrCode: 9365,
    bbrBnkCode: 271,
    bbrBranchName: "ACHIMOTA",
    bbrShtDesc: "271110",
  },
  {
    bbrCode: 9366,
    bbrBnkCode: 271,
    bbrBranchName: "HEAD OFFICE",
    bbrShtDesc: "271111",
  },
  {
    bbrCode: 9367,
    bbrBnkCode: 271,
    bbrBranchName: "TAMALE",
    bbrShtDesc: "271112",
  },
  {
    bbrCode: 9368,
    bbrBnkCode: 271,
    bbrBranchName: "KUMASI (ALABAR)",
    bbrShtDesc: "271113",
  },
  {
    bbrCode: 10264,
    bbrBnkCode: 271,
    bbrBranchName: "OSU BRANCH",
    bbrShtDesc: "271430",
  },
  {
    bbrCode: 9888,
    bbrBnkCode: 271,
    bbrBranchName: "KASOA",
    bbrShtDesc: "271020",
  },
  {
    bbrCode: 10006,
    bbrBnkCode: 271,
    bbrBranchName: "OSU",
    bbrShtDesc: "271114",
  },
  {
    bbrCode: 10261,
    bbrBnkCode: 271,
    bbrBranchName: "TEMA HARBOUR BRANCH",
    bbrShtDesc: "271480",
  },
  {
    bbrCode: 10262,
    bbrBnkCode: 271,
    bbrBranchName: "TAKORADI BRANCH",
    bbrShtDesc: "271460",
  },
  {
    bbrCode: 10263,
    bbrBnkCode: 271,
    bbrBranchName: "SPINTEX ROAD",
    bbrShtDesc: "271440",
  },
  {
    bbrCode: 10265,
    bbrBnkCode: 271,
    bbrBranchName: "MADINA BRANCH",
    bbrShtDesc: "271400",
  },
  {
    bbrCode: 10266,
    bbrBnkCode: 271,
    bbrBranchName: "KUMASI - ADUM",
    bbrShtDesc: "271360",
  },
  {
    bbrCode: 10267,
    bbrBnkCode: 271,
    bbrBranchName: "TECHIMAN",
    bbrShtDesc: "271320",
  },
  {
    bbrCode: 10268,
    bbrBnkCode: 271,
    bbrBranchName: "ODORKOR",
    bbrShtDesc: "271300",
  },
  {
    bbrCode: 10269,
    bbrBnkCode: 271,
    bbrBranchName: "DANSOMAN",
    bbrShtDesc: "271290",
  },
  {
    bbrCode: 10270,
    bbrBnkCode: 271,
    bbrBranchName: "LABONE",
    bbrShtDesc: "271280",
  },
  {
    bbrCode: 10271,
    bbrBnkCode: 271,
    bbrBranchName: "AHODWO",
    bbrShtDesc: "271250",
  },
  {
    bbrCode: 10272,
    bbrBnkCode: 271,
    bbrBranchName: "EAST LEGON",
    bbrShtDesc: "271230",
  },
  {
    bbrCode: 10273,
    bbrBnkCode: 271,
    bbrBranchName: "TARKWA",
    bbrShtDesc: "271220",
  },
  {
    bbrCode: 10274,
    bbrBnkCode: 271,
    bbrBranchName: "WEIJA",
    bbrShtDesc: "271210",
  },
  {
    bbrCode: 10275,
    bbrBnkCode: 271,
    bbrBranchName: "TUDU",
    bbrShtDesc: "271170",
  },
  {
    bbrCode: 10276,
    bbrBnkCode: 271,
    bbrBranchName: "KASOA BRANCH",
    bbrShtDesc: "271150",
  },
  {
    bbrCode: 10277,
    bbrBnkCode: 271,
    bbrBranchName: "KOKOMLEMLE",
    bbrShtDesc: "271140",
  },
  {
    bbrCode: 10278,
    bbrBnkCode: 271,
    bbrBranchName: "KOFORIDUA",
    bbrShtDesc: "271130",
  },
  {
    bbrCode: 10279,
    bbrBnkCode: 271,
    bbrBranchName: "TEMA - COMM1",
    bbrShtDesc: "271120",
  },
  {
    bbrCode: 10280,
    bbrBnkCode: 271,
    bbrBranchName: "KRONUM",
    bbrShtDesc: "271011",
  },
  {
    bbrCode: 10281,
    bbrBnkCode: 271,
    bbrBranchName: "ANLOGA",
    bbrShtDesc: "271801",
  },
  {
    bbrCode: 10282,
    bbrBnkCode: 271,
    bbrBranchName: "NMAI DZORN",
    bbrShtDesc: "271090",
  },
  {
    bbrCode: 10283,
    bbrBnkCode: 271,
    bbrBranchName: "DOME",
    bbrShtDesc: "271080",
  },
  {
    bbrCode: 10284,
    bbrBnkCode: 271,
    bbrBranchName: "DZORWULU",
    bbrShtDesc: "271070",
  },
  {
    bbrCode: 10285,
    bbrBnkCode: 271,
    bbrBranchName: "ASHAIMAN",
    bbrShtDesc: "271050",
  },
  {
    bbrCode: 10286,
    bbrBnkCode: 271,
    bbrBranchName: "ABOSSEY OKAI",
    bbrShtDesc: "271040",
  },
  {
    bbrCode: 10287,
    bbrBnkCode: 271,
    bbrBranchName: "KEJETIA",
    bbrShtDesc: "271030",
  },
  {
    bbrCode: 10288,
    bbrBnkCode: 271,
    bbrBranchName: "KUMASI CENTRAL MARKET",
    bbrShtDesc: "271020",
  },
  {
    bbrCode: 10289,
    bbrBnkCode: 271,
    bbrBranchName: "MANHYIA",
    bbrShtDesc: "271010",
  },
  {
    bbrCode: 10295,
    bbrBnkCode: 271,
    bbrBranchName: "SUNYANI",
    bbrShtDesc: "271701",
  },
  {
    bbrCode: 10374,
    bbrBnkCode: 271,
    bbrBranchName: "AIRPORT CITY",
    bbrShtDesc: "271115",
  },
];

export default OmniBankBranchesData;
