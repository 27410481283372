import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import ProductDetailsPA from "./ProductDetailsPA";

export class ProductDetailsCLP extends Component {
  state = {
    paChange: false,
  };

  handlePAChange = (e) => {
    console.log(e.target.value);
    const pa = e.target.value;
    if (pa === "Yes") {
      this.setState({ paChange: true });
      this.props.valueChange(["pa", pa]);
    } else {
      this.setState({ paChange: false });
    }
  };

  render() {
    const {
      values,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      handleChange,
    } = this.props;
    return (
      <div>
        <Form.Group>
          <FloatingLabel
            controlId="term"
            label="Enter policy term (Minimum of 8 years)"
            className="mb-2"
          >
            <Form.Control
              type="number"
              autoComplete="off"
              onChange={handleChange("term")}
              defaultValue={values.term}
              style={{
                // border: this.props.termError ? "1px solid red" : "",
                border: termError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {/* {this.props.termError} */}
              {termError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="tpd"
            label="Total and Permanent Disability"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={handleChange("tpd")}
              // onChange={handleChange("paymentMode")}
              // defaultValue={values.paymentMode}
              style={{
                border: tpdError ? "1px solid red" : "",
              }}
            >
              <option></option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {tpdError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="retrenchment"
            label="Retrenchment (12 months waiver of premium)"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={handleChange("retrenchment")}
              // onChange={handleChange("paymentMode")}
              // defaultValue={values.paymentMode}
              style={{
                border: retrechmentError ? "1px solid red" : "",
              }}
            >
              <option></option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {retrechmentError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="dread"
            label="Dread Disease"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={handleChange("dreadDisease")}
              // onChange={handleChange("paymentMode")}
              // defaultValue={values.paymentMode}
              style={{
                border: dreadDiseaseError ? "1px solid red" : "",
              }}
            >
              <option></option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {dreadDiseaseError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="pa"
            label="Personal Accident for child (Cover: GH₵1,000.00)"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={this.handlePAChange}
              // onChange={handleChange("paymentMode")}
              // defaultValue={values.paymentMode}
              style={{
                border: paError ? "1px solid red" : "",
              }}
            >
              <option></option>
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {paError}
            </div>
          </FloatingLabel>
          {this.state.paChange && (
            <ProductDetailsPA handleChange={handleChange} values={values} />
          )}
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsCLP;
