import React, { Component } from "react";
// import mypadie from "../StarLife-Chatbot-paddie.png";
import capital from "../../capital-logo.png";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

export class Welcome extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    return (
      <React.Fragment>
        <br></br>
        <img src={capital} alt="logo" />
        <br></br>
        <br></br>
        <h2>
          <FontAwesomeIcon icon={faHandshake} /> Welcome to Capital Rural
          Bancassurance <FontAwesomeIcon icon={faHandshake} />
        </h2>
        <br />
        <Button
          variant="primary"
          style={{
            backgroundColor: "#2B5A9B",
            outlineColor: "#ffc000",
            borderColor: "#2B5A9B",
            color: "#fff",
          }}
          onClick={this.continue}
        >
          Start Application
        </Button>
      </React.Fragment>
    );
  }
}

export default Welcome;
