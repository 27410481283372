const proposalData = {
  message_code: 100,
  data: {
    myPolicyDetails: [
      {
        polCode: 202220221854766,
        polMaturityDate: "13-12-2026",
        prodDesc: "WEALTH MASTER PLUS",
        polCurSymbol: "GHC",
        polUnderwritingYear: 2022,
        polBranch: "ATWIMA KWANWOMA RURAL BANCASSURANCE",
        polAgentName: "OWUSU",
        polAgentShortDescription: "AGT36905",
        polAgentCode: 2020201242808,
        polPensMode: null,
        polPaidToDate: null,
        endrTotalSa: 5225.34,
        endrTotalPremium: 100,
        grossPremium: 100,
        totPremiumPaid: 0,
        endrFreqOfPayment: "M",
        endrCode: 20223456466,
        lifeAssured: "DANNY TEST K",
        gcoDesc: null,
        polRefNo: null,
        polMonthlyIncome: null,
        endrAddRefPremium: null,
        polEffectiveDate: "13-12-2022",
        polPolicyNo: null,
        polProposalNo: "PWPP1007717220982839",
        polStatus: "D",
        polStatusDesc: "DRAFT",
        polDocStatus: null,
        polOsBalance: 100,
        polInvBalance: 0,
        productOptionCode: 2018324,
        endrPercentRate: null,
        endrSARate: null,
        endrPayMethod: "DD",
        bankName: "ATWIMA KWANWOMA RURAL BANCASSURANCE",
        bankBranch: "ALABAR",
        endrBoStartDate: "30-12-2022",
        endrBoDebitDay: 30,
        endrBankAccName: "DANNY K TEST",
        endrBankAccNo: "1236547895874123",
        institution: "MINISTRY OF DEFENCE",
        endrCheckOffAgenShtDesc: "CC00043",
        endrCheckOffRate: null,
      },
    ],
    proposerDetails: [
      {
        prpCode: 20181349054,
        clntCode: 181663517,
        clntShtDesc: "0626181",
        prpSurname: "OPOKU",
        prpOtherNames: null,
        prpFirstName: "ROMEO",
        prpDob: "21-08-1982",
        prpSex: "Male",
        prpTel: "0246437443",
        prpPhysicalAddr: "HNO 1/36 AMASA COCOA BEACH \nNUNGUA",
        prpEmail: "OPOKUROMEO8279@GMAIL.COM",
      },
    ],
    myPolicyBeneficiaries: [
      {
        benFirstName: "mercy",
        benOtherNames: " opoku",
        benDob: "25-09-1982",
        benTelNo: "0243358287",
        benPctBenefit: 100,
        retDesc: "SPOUSE",
        benGuardianName: null,
        benTrusteeDob: null,
        benGuardianIdType: null,
        benGuardianIdno: null,
        benGuardianEmailAddrs: null,
        benGuardianPostAddrs: null,
        benGuardianTown: null,
        benTrusteeCellNo: null,
        benCode: 0,
      },
    ],
    myPolicyDependants: [
      {
        podSurname: "OPOKU",
        podOtherNames: "ROMEO",
        podIdNo: null,
        podDob: "21-08-1982",
        podSex: "M",
        dtyDescription: "SELF",
        coDesc: "UNKNOWN",
        sum_assured: 12000,
        premium: 3.88,
        coverTypeDescription: "CASHBUILDER PLUS DEATH COVER",
      },
    ],
  },
};

export default proposalData;
