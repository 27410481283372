import React, { useState, useEffect, useContext } from "react";
import logo from "../StarLife_Chatbot-06.png";
import "bootstrap/dist/css/bootstrap.css";
import "./Welcome.styles.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserLock,
  faCheckCircle,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BankPartners from "./data/partners";
import { useNavigate } from "react-router-dom";
// import digitallogo from "../apple-icon-72x72.png";
import digitallogo from "../starlife-logo.png";
import CryptoJS from "crypto-js";
import AuthContext from "../store/auth-context";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import OtpInput from "react-otp-input";

function AgentForgotPassword(props) {
  const bankPartners = BankPartners;
  const [agentError, setAgentError] = useState("");
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [agentReferralError, setAgentReferralError] = useState("");
  const [bankPartnerError, setBankPartnerError] = useState("");
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [enteredAGT, setEnteredAGT] = useState("");
  const [agtpassword, setAgtPassword] = useState("");
  const [agtConfirmPassword, setAgtConfirmPassword] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showResetForm, setShowResetForm] = useState(false);
  const [agentData, setAgentData] = useState("");
  const [credentialsInvalid, setCredentialsInvalid] = useState({
    agt: false,
    password: false,
    confirmPassword: false,
  });
  const [otp, setOtp] = useState("");
  let [minutes, setMinutes] = useState(5);
  let [seconds, setSeconds] = useState(0);
  const [passcodeError, setPasscodeError] = useState("");
  const [agentPwdError, setAgentPwdError] = useState("");
  const [agentPwdConfirmError, setAgentPwdConfirmError] = useState("");
  const [pwdMatchError, setPwdMatchError] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validPwdMsg, setValidPwdMsg] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();

  //retrieve all the chunk data stored in localStorage and decrypt it to get the original data
  const getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  //break the encrypted data into smaller chunks and store them in localStorage
  const saveEncryptedData = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
    const totalChunks = Math.ceil(encryptedData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, encryptedData.length);
      const chunk = encryptedData.substring(start, end);

      // Store each chunk in localStorage with a unique key
      localStorage.setItem(`${key}_${i}`, chunk);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleOTPChange = (otp) => setOtp(otp);

  function resendOTP(phone) {
    let getOTP = "";

    // return false;

    if (getDecryptedData("currDSO")["bank"] === "") {
      getOTP = {
        Phone_Number: phone,
      };
    } else {
      getOTP = {
        Phone_Number: phone,
        Bank: getDecryptedData("currDSO")["bank"],
      };
    }

    setLoading(true);
    setPasscodeError("");

    // this.setState({ loading: true, passcodeError: "" });

    //Get OTP
    axios
      .post("/api/agent/resend/otp", getOTP)
      .then((response) => {
        setLoading(false);
        setOtp("");
        setMinutes(5);
        setSeconds(0);
        // this.setState({ loading: false, otp: "", minutes: 3, seconds: 0 });

        const myInterval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes - 1);
              setSeconds(59);
            }
          }
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        setOtp("");
        // this.setState({ loading: false, otp: "" });
      });

    // this.setState({ minutes: 1, seconds: 0 });
  }

  function forgotPasswordHandler(e) {
    e.preventDefault();
    let agentErrorMsg = "";
    let bankPartnerErrorMsg = "";

    if (!e.target.form[0].value) {
      agentErrorMsg = "AGT Code is required";
    }

    if (e.target.form[0].value) {
      if (e.target.form[0].value.length < 7) {
        agentErrorMsg = "Kindly provide a valid AGT Code.";
      }
    }

    const agt = e.target.form[0].value.trim();
    const agtIsValid = agt.length > 6;

    if (agentErrorMsg) {
      setAgentError(agentErrorMsg);
      // setBankPartnerError(bankPartnerErrorMsg);
      return false;
    }

    setEnteredAGT(e.target.form[0].value);

    //Verify agent
    const agent = {
      UserName: e.target.form[0].value.trim(),
      // Agn_Type: e.target.form[1].value,
    };

    setLoading(true);

    axios
      .post("/api/dso/forgot-password", agent)
      .then(async (response) => {
        if (response.data.message_code === 100) {
          setAgentData(response.data);
          setLoading(false);

          const encryptedDSO = await saveEncryptedData(
            "currDSO",
            response.data
          );

          let getOTP = "";

          if (response.data.bank === "" && response.data.agnTel1) {
            getOTP = {
              Phone_Number: response.data.agnTel1,
            };
          } else if (response.data.bank && response.data.agnTel1) {
            getOTP = {
              Phone_Number: response.data.agnTel1,
              Bank: response.data.bank,
            };
          } else if (response.data.agnTel1 == null) {
            alert("Failed", "Phone number not identified");
            return false;
          }

          //Get OTP
          axios
            .post("/api/agent/otp", getOTP)
            .then((response) => {})
            .catch((error) => {});
          setShowOtpForm(true);
        } else if (response.data.message_code === 104) {
          setLoading(false);
          alert("User not registered");
          setCredentialsInvalid({
            agt: !agtIsValid,
          });
          return;
        } else {
          setLoading(false);
          alert("User not registered");
          setCredentialsInvalid({
            agt: !agtIsValid,
          });
          return;
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("error");
        agentErrorMsg = "SE or DSO not identified";
        bankPartnerErrorMsg = "";

        if (agentErrorMsg) {
          setAgentError(agentErrorMsg);
          setBankPartnerError(bankPartnerErrorMsg);
          return false;
        }
      });
  }

  async function submitOTP(e) {
    e.preventDefault();
    let passcodeError = "";

    setPasscodeError(passcodeError);

    if (!e.target.form[0].value) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (!otp) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (passcodeError) {
      setPasscodeError(passcodeError);
      return false;
    }

    const dataString = await getDecryptedData("currDSO");

    const OTP = {
      OTP: otp,
      Phone_Number: getDecryptedData("currDSO")["agnTel1"],
    };

    setLoading(true);

    //Verify OTP
    axios
      .post("/api/customer/verify/otp", OTP)
      .then((response) => {
        if (response.data.Verification === true) {
          setShowResetForm(true);
          setShowOtpForm(false);
          setLoading(false);
        } else {
          setLoading(false);
          alert("OTP Verification", "OTP Verification Failed");
        }
      })
      .catch((error) => {
        setLoading(false);
        setOtp("");
        alert("OTP Verification", "OTP Verification Failed");
      });
  }

  if (showOtpForm) {
    return (
      <div>
        {loading ? (
          <>
            <div className={"cover-spin-retail"}></div>
            <img
              src={digitallogo}
              alt="logo"
              // style={{
              //   width: "100%",
              // }}
            />
            <hr />
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
                <img src={mypadie} alt="logo" style={{ width: "70px" }} />
                Nice to meet you, {values["firstName"]}! Please add your employment
                details.
              </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
                <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      textAlign: "center",
                    }}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  ></div>
                </Form.Group>
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#763984",
                    borderColor: "#763984",
                    outlineColor: "#763984",
                  }}
                >
                  Verify <FontAwesomeIcon icon={faCheckCircle} />
                </Button>{" "}
                &nbsp;
                <button type="button" className="btn btn-link">
                  Send again
                </button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <img
              src={digitallogo}
              alt="logo"
              // style={{
              //   width: "100%",
              // }}
            />
            {/* <br /> <br />
            <h1>
              <b>Digital Onboarding</b>
            </h1> */}
            <hr />
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
                <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={otp}
                    onChange={handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {passcodeError}
                  </div>
                </Form.Group>
                {/* &nbsp;
                <div>
                  {minutes === 0 && seconds === 0 ? (
                    <h5>OTP has Expired!</h5>
                  ) : (
                    <h5>
                      Resend OTP in: {minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h5>
                  )}
                </div> */}
                {/* <br></br> */}
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#763984",
                    borderColor: "#763984",
                    outlineColor: "#763984",
                  }}
                  onClick={submitOTP}
                >
                  Verify <FontAwesomeIcon icon={faCheckCircle} />
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() =>
                    // resendOTP(JSON.parse(localStorage.getItem("dso"))["agnTel1"])
                    resendOTP(getDecryptedData("currDSO")["agnTel1"])
                  }
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }

  const validatePassword = (password) => {
    // Define regular expressions for password complexity
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
    const isLengthValid = password.length >= 8; // Password should be at least 8 characters long

    // Validate password based on conditions
    return (
      hasUpperCase &&
      hasLowerCase &&
      hasDigit &&
      hasSpecialChar &&
      isLengthValid
    );
  };

  function handleResetPassword(e) {
    e.preventDefault();
    let agentPwdMsg = "";
    let agentPwdConMsg = "";
    let pwdMatchMsg = "";
    let validPwdMsg = "";

    setAgentPwdError(agentPwdMsg);
    setAgentPwdConfirmError(agentPwdConMsg);
    setPwdMatchError(pwdMatchMsg);
    setValidPwdMsg(validPwdMsg);

    if (!e.target.form[0].value) {
      agentPwdMsg = "Password is required";
    }

    if (!e.target.form[1].value) {
      agentPwdConMsg = "Confirm Password is required";
    }

    if (e.target.form[0].value) {
      setValidPassword(validatePassword(e.target.form[1].value));
      const updatedValidPassword = validatePassword(e.target.form[1].value);

      if (updatedValidPassword === false) {
        alert(
          "Password does not meet password requirements. Password should be 8 characters long, must contain at least one lowercase, one uppercase, one digit and one special character."
        );
        return false;
        // validPwdMsg =
        //   "Password must meet complexity requirements \n Password should be at least 8 characters long \n Password should have at least 1 Upper Case \n Password should have at least 1 Lower Case \n Password should have at least 1 number \n Password should have at least 1 Special Character";
      }
    }

    if (e.target.form[0].value && e.target.form[1].value) {
      if (e.target.form[0].value !== e.target.form[1].value) {
        pwdMatchMsg = "Password and Confirm password does not match!";
      }
    }

    if (agentPwdMsg || agentPwdConMsg || pwdMatchMsg || validPwdMsg) {
      setAgentPwdError(agentPwdMsg);
      setAgentPwdConfirmError(agentPwdConMsg);
      setPwdMatchError(pwdMatchMsg);
      setValidPwdMsg(validPwdMsg);
      return false;
    }

    setLoading(true);

    const user = {
      username: agentData.agnShtDesc,
      password: e.target.form[0].value,
    };

    axios
      .post("/api/dso/reset-password", user)
      .then((response) => {
        if (response.data.message_code === 100) {
          const expirationTime = new Date(new Date().getTime() + 3600 * 24000);

          authCtx.dsoLogin(
            getDecryptedData("currDSO")["token"],
            getDecryptedData("currDSO")["agnCode"],
            expirationTime.toISOString()
          );
          if (getDecryptedData("currDSO")["partner"] === "GCB BANCASSURANCE") {
            // this.history.replace("/bancassurance/gcb");
            navigate("/bancassurance/gcb", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] === "ACCESS BANCASSURANCE"
          ) {
            navigate("/bancassurance/access", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "AMENFIMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/amenfiman", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "AHANTAMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/ahantaman", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ATWIMA KWANWOMA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/atwimakwanwoma", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "OKOMFO ANOKYE RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/okomfoanokye", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] === "GA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/garural", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ABOKOBI RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/abokobi", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "YAA ASANTEWAA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/yaaasantewaa", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "AMANSIE WEST RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/amansie", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ATWIMA MPONUA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/atwimamponua", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "FIASEMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/fiaseman", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "ODOTOBRI RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/odotobri", {
              replace: true,
            });
          } else if (
            getDecryptedData("currDSO")["partner"] ===
            "SOUTH AKIM RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/southakim", {
              replace: true,
            });
          } else {
            navigate("/retail/starlife", {
              replace: true,
            });
          }
          return true;
        } else {
          setLoading(false);
          alert("An error occured. Try again later");
          return false;
        }
      })
      .catch((error) => {
        setLoading(false);
        alert(error);
        return false;
      });
  }

  if (showResetForm) {
    return (
      <div>
        {loading ? (
          <>
            <div className={"cover-spin-retail"}></div>
            <img
              src={digitallogo}
              alt="logo"
              // style={{
              //   width: "100%",
              // }}
            />
            <hr />
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 400,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
                <u>Reset Password</u>
              </h4>{" "}
              <br />
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="agent"
                    label="AGT Code (AGTXXXXXX)"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      // onBlur={this.handleAgent}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue={values.agentCode}
                      style={{
                        border: agentError ? "1px solid red" : "",
                      }}
                    ></Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                      }}
                    >
                      {agentName}
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {agentError}
                    </div>
                  </FloatingLabel>
                  {/* <FloatingLabel
                  controlId="bankPartner"
                  label="Select your Banc Assurance Partner"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("bankBranch")}
                    // defaultValue={values.bankBranch}
                    style={{
                      border: bankPartnerError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {bankPartners.map((partner) => (
                      <option key={partner.id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {bankPartnerError}
                  </div>
                </FloatingLabel> */}
                </Form.Group>
                {/* <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button> */}
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                  }}
                >
                  Reset Password
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <img
              src={digitallogo}
              alt="logo"
              // style={{
              //   width: "100%",
              // }}
            />
            {/* <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
            <hr />
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 400,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
                <u>Reset Password</u>
              </h4>{" "}
              <br />
              <Form
                onSubmit={handleResetPassword}
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="agentpassword"
                    label="Password"
                    className="mb-2"
                  >
                    <Form.Control
                      type={passwordVisible ? "text" : "password"} // Show password if visible, otherwise hide
                      // type="password"
                      autoComplete="off"
                      style={{
                        border:
                          agentPwdError || validPwdMsg ? "1px solid red" : "",
                      }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      onClick={togglePasswordVisibility}
                      tabIndex={0} // Make it focusable
                      role="button" // Add ARIA role for accessibility
                    >
                      {passwordVisible ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </span>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {agentPwdError || validPwdMsg}
                    </div>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="agentpasswordconfirm"
                    label="Confirm Password"
                    className="mb-2"
                  >
                    <Form.Control
                      type={confirmPasswordVisible ? "text" : "password"}
                      autoComplete="off"
                      style={{
                        border: agentPwdError ? "1px solid red" : "",
                      }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      onClick={toggleConfirmPasswordVisibility}
                      tabIndex={0} // Make it focusable
                      role="button" // Add ARIA role for accessibility
                    >
                      {confirmPasswordVisible ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </span>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {agentPwdConfirmError}
                      {pwdMatchError}
                    </div>
                  </FloatingLabel>
                </Form.Group>

                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#763984",
                    outlineColor: "#763984",
                    borderColor: "#763984",
                  }}
                  onClick={handleResetPassword}
                >
                  Reset Password
                </Button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
              <u>Enter your AGT to Login</u>
            </h4>{" "}
            <br />
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="agent"
                  label="AGT Code (AGTXXXXXX)"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    // onBlur={this.handleAgent}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.agentCode}
                    style={{
                      border: agentError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentError}
                  </div>
                </FloatingLabel>
                {/* <FloatingLabel
                  controlId="bankPartner"
                  label="Select your Banc Assurance Partner"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("bankBranch")}
                    // defaultValue={values.bankBranch}
                    style={{
                      border: bankPartnerError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {bankPartners.map((partner) => (
                      <option key={partner.id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {bankPartnerError}
                  </div>
                </FloatingLabel> */}
              </Form.Group>
              {/* <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button> */}
              &nbsp;
              <Button
                variant="primary"
                // type="submit"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                }}
              >
                Request OTP
              </Button>
            </Form>
          </div>
        </>
      ) : (
        <>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          {/* <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
              <u>Enter your AGT</u>
            </h4>{" "}
            <br />
            <Form
              onSubmit={forgotPasswordHandler}
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="agent"
                  label="AGT Code (AGTXXXXXX)"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    // onBlur={this.handleAgent}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.agentCode}
                    style={{
                      border: agentError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentError}
                  </div>
                </FloatingLabel>
                {/* <FloatingLabel
                  controlId="bankPartner"
                  label="Select your Banc Assurance Partner"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("bankBranch")}
                    // defaultValue={values.bankBranch}
                    style={{
                      border: bankPartnerError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {bankPartners.map((partner) => (
                      <option key={partner.id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {bankPartnerError}
                  </div>
                </FloatingLabel> */}
              </Form.Group>
              {/* <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button> */}
              &nbsp;
              <Button
                variant="primary"
                // type="submit"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                }}
                onClick={forgotPasswordHandler}
              >
                Request OTP
              </Button>
            </Form>
          </div>
        </>
      )}
      {/* <button
        className="link-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        Install
      </button> */}
    </div>
  );
}

export default AgentForgotPassword;
