import React from "react";
import { Bar } from "react-chartjs-2";
// import { chart as ChartJS } from "chart.js/auto";
import { Chart as ChartJS } from "chart.js/auto";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Number of successful purchased policies from year to date by Month",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: "#4a4a4a",
      // backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: "#ffc52a",
      // backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

function BarChart({ chartData }) {
  console.log("BarChart", chartData);
  //   return <Bar data={data} options={options} />;
  return <Bar data={chartData} options={options} />;
}

export default BarChart;
