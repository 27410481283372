import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import gcb from "../../gcb-logo.png";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
import axios from "axios";
import NewHeader from "./header/NewHeaderAtwima";
import BottomButton from "./SaveandExit";
import { removeEncryptedData, removeKey } from "../func/utils";

export class GCBConfirm extends PureComponent {
  state = {
    confirmError: "",
    loading: false,
    submitError: "",
    checked: false,
    premium: "",
    sa: "",
    dataError: "",
    dataChecked: false,
    yesChecked: "",
    noChecked: "",
  };

  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    console.log(this.props.values);
    // console.log(e);
    // const checked = e.target.checked;
    // console.log(checked);
    let confirmError = "";
    let submitError = "";
    let dataError = "";

    this.setState({
      confirmError,
      dataError,
    });

    if (!e.target.form[0].checked & !e.target.form[1].checked) {
      // Checks if field is empty
      dataError = "Kindly denote [Yes] or [No]";
    }

    if (!e.target.form[2].checked) {
      // Checks if field is empty
      confirmError = "Kindly confirm and agree before you submit";
    }
    // this.props.nextStep();

    if (confirmError || dataError) {
      this.setState({
        confirmError,
        dataError,
      });
      submitError = "";
      this.setState({
        submitError,
      });

      return false;
    }

    const input = document.getElementById("GCBConfirm");

    // console.log(input);

    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("myDocument.pdf");
    // });

    // html2canvas(input, {
    //   logging: true,
    //   letterRendering: 1,
    //   useCORS: true,
    // }).then((canvas) => {
    //   const imgWidth = 208;
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "mm", "a4");
    //   pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
    //   pdf.save("myDocument.pdf");
    // });

    this.setState({
      loading: true,
      checked: true,
      dataChecked: true,
      yesChecked: e.target.form[0].checked,
      noChecked: e.target.form[1].checked,
    });
    // this.setState({ checked: true });

    axios
      .post("/api/customer/submit/data", this.props.values)
      .then((response) => {
        console.log("response " + response);
        console.log("response data " + response.data);
        console.log("response status " + response.data.status);
        // console.log("message code " + response.data.message_code);
        // console.log("");
        if (response.data.message_code === 100) {
          // const input = document.getElementById("GCBConfirm");
          // html2canvas(input, {
          //   logging: true,
          //   letterRendering: 1,
          //   useCORS: true,
          // }).then((canvas) => {
          //   const imgWidth = 208;
          //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
          //   const imgData = canvas.toDataURL("image/png");
          //   const pdf = new jsPDF("p", "mm", "a4");
          //   pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
          //   pdf.save("myDocument.pdf");
          // });
          this.setState({ loading: false });
          // console.log(response.data);
          // this.props.valueChange(["pNumber", response.data.proposalNumber]);
          this.props.valueChange(["proposal_details", response.data]);
          // localStorage.removeItem("quotation");
          // localStorage.removeItem("product");
          // localStorage.removeItem("CurrentData");
          removeEncryptedData("curr");
          removeEncryptedData("quotation");
          removeEncryptedData("product");
          removeEncryptedData("curr");
          // console.log(response.data);
          // removeKey("curr");
          this.props.nextStep();
          return true;
        } else if (response.data.status === 409) {
          this.setState({ loading: false });
          // localStorage.removeItem("CurrentData");
          // localStorage.removeItem("quotation");
          // localStorage.removeItem("product");
          removeEncryptedData("curr");
          removeEncryptedData("quotation");
          removeEncryptedData("product");
          removeEncryptedData("curr");
          // removeKey("curr");
          alert("Proposal already submitted.");
          // console.log(response.data);
          this.props.resetStep(1);
        } else {
          this.setState({ loading: false });
          // console.log("Else Error", response.data);
          // alert(response.data);
          // submitError = "Error Occurred, Please Try Again Later";
          submitError =
            response.data +
            ". Kindly call any of our contact centre numbers for assistance.";

          // console.log(response.data);

          if (response.data === "1 Client(s) already exist") {
            submitError =
              "Your details already exist. Kindly use the existing client option or call any of our contact centre numbers for assistance.";
          }
          if (submitError) {
            this.setState({
              submitError,
            });
            confirmError = "";
            this.setState({
              confirmError,
            });
            // console.log(submitError);
            return false;
          }

          // console.log(submitError);
          // return false;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("error");
        submitError =
          error.data +
          ". Kindly call any of our contact centre numbers for assistance.";

        if (submitError) {
          this.setState({
            submitError,
          });
          confirmError = "";
          this.setState({
            confirmError,
          });
          console.log(submitError);
          return false;
        }
      });
  };

  cancel = (e) => {
    e.preventDefault();

    axios
      .post("/api/customer/submit/cancel", this.props.values)
      .then((response) => {})
      .catch((error) => {});

    this.props.resetStep(1);
  };

  back = (e) => {
    e.preventDefault();
    if (localStorage.getItem("dso") !== null) {
      this.props.prevStep(8);
    } else {
      this.props.prevStep();
    }
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-amenfiman"}></div>
            <NewHeader />
            <br></br>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 994,
                padding: 10,
              }}
            >
              <form>
                <h4>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#009900" }}
                  />{" "}
                  &nbsp; <u>Confirm Details</u>
                </h4>
                {/* Confirm Details Table */}
                <table
                  style={{
                    borderWidth: "2px",
                    borderColor: "#aaaaaa",
                    borderStyle: "solid",
                    width: "100%",
                  }}
                >
                  <tr>
                    <td colSpan={"3"} style={{ color: "#009900" }}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td>
                            {" "}
                            <h4>{this.props.values.product.split("-")[2]}</h4>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td width={"50%"} valign="top">
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            PERSONAL DETAILS
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"30%"}>
                            Surname:
                          </td>
                          <td align="left">
                            <u>{this.props.values.lastName}</u>
                          </td>
                          {/* <td>box</td> */}
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Middle Name(s):
                          </td>
                          <td align="left">
                            <u>{this.props.values.otherNames}</u>
                          </td>
                          {/* <td>box</td> */}
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            First Name:
                          </td>
                          <td align="left">
                            <u>{this.props.values.firstName}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Date of Birth: <u>{this.props.values.dob}</u>
                          </td>
                          <td align="left">
                            Gender: <u>{this.props.values.gender}</u>
                          </td>
                        </tr>
                        {/* <tr>
                          <td align="left" width={"40%"}>
                            Postal Address:
                          </td>
                          <td align="left">
                            <u>{this.props.values.postalAddress}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Residential Address:
                          </td>
                          <td align="left">
                            <u>{this.props.values.residentialAddress}</u>
                          </td>
                        </tr> */}
                        <tr>
                          <td align="left" width={"40%"}>
                            Mobile: <u>{this.props.values.phoneNumber}</u>
                          </td>
                          <td align="left">
                            Email: <u>{this.props.values.email}</u>
                          </td>
                        </tr>
                      </table>
                    </td>
                    {/* <td width="5px">Space</td> */}
                    <td width={"50%"} valign="top">
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            PAYMENT DETAILS
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Bank:
                          </td>
                          <td align="left">
                            <u>{this.props.values.bank.split("-")[1]}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Branch:
                          </td>
                          <td align="left">
                            <u>{this.props.values.bankBranch.split("-")[1]}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Account Number:
                          </td>
                          <td align="left">
                            <u>{this.props.values.accountNumber}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Payment Frequency:
                          </td>
                          <td align="left">
                            <u>{this.props.values.paymentFrequency}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Deduction Date:
                          </td>
                          <td align="left">
                            <u>
                              {moment(this.props.values.deductionDate).format(
                                "DD-MM-YYYY"
                              )}
                            </u>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            PRODUCT DETAILS
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" width={"40%"}>
                      Product Name: StarLife ULTIMATE PROTECTION PLUS
                    </td>
                    <td align="left">
                      Policy Term: <u>{this.props.values.term} Years</u>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" width={"40%"}>
                      Medical Diagnostics: <u>{this.props.values.rider}</u>
                    </td>
                    <td align="left">
                      Critical Illness: <u>{this.props.values.dreadDisease}</u>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" width={"40%"}>
                      Premium (Ghc):{" "}
                      <u>
                        {this.props.values.premium
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </u>
                    </td>
                    <td align="left">
                      Escalation Rate (%):{" "}
                      <u>{this.props.values.escalationRate}</u>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"8"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            BENEFICIARIES
                          </td>
                        </tr>
                        <tr>
                          <td>Surname</td>
                          <td>Other Names</td>
                          <td>Date of Birth</td>
                          <td>Relationship</td>
                          <td>%</td>
                          <td>Contact No.</td>
                        </tr>
                        {this.props.values.benList.map((ben) => (
                          <tr key={ben.id}>
                            <td>{ben.surname}</td>
                            <td>{ben.othernames}</td>
                            <td>{ben.dob}</td>
                            <td>{ben.relationship}</td>
                            <td>{ben.percent}</td>
                            <td>{ben.phone}</td>
                          </tr>
                        ))}
                      </table>
                    </td>
                  </tr>
                  {this.props.values.trusteeRequired && (
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td
                              colSpan={"8"}
                              style={{
                                backgroundColor: "#009900",
                                color: "#fff",
                              }}
                            >
                              TRUSTEES
                            </td>
                          </tr>
                          <tr>
                            <td>Surname</td>
                            <td>Other Names</td>
                            <td>Date of Birth</td>
                            <td>Relationship</td>
                            <td>Contact No.</td>
                          </tr>
                          {this.props.values.trustee.map((trustee) => (
                            <tr key={trustee.id}>
                              <td>{trustee.surname}</td>
                              <td>{trustee.othernames}</td>
                              <td>{trustee.dob}</td>
                              <td>{trustee.relationship}</td>
                              <td>{trustee.phone}</td>
                            </tr>
                          ))}
                        </table>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            MEDICAL HISTORY
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Are you presently in good health?:{" "}
                            <u>{this.props.values.md1}</u>
                          </td>
                          <td align="left" width={"40%"}>
                            Have you been diagnosed with any medical condition
                            or illness?: <u>{this.props.values.md2}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Are you on any medication?:{" "}
                            <u>{this.props.values.md3}</u>
                          </td>
                          <td align="left" width={"40%"}>
                            Have you undergone any surgical operation or
                            procedure?: <u>{this.props.values.md4}</u>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            DECLARATION
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Declaration by Applicant. <br /> I{" "}
                            <u>
                              {this.props.values.firstName}{" "}
                              {this.props.values.otherNames &&
                                this.props.values.otherNames}{" "}
                              {this.props.values.lastName}
                            </u>{" "}
                            , declare that every statement in response to
                            questions asked in this application is true and
                            correct to the best of my knowledge. I agree that
                            this application shall serve as the basis and form
                            part of the contract. All the questions have been
                            explained to me in the language that I understand
                            and I have been made to understand that this
                            contract shall become operative until all of the
                            following conditions have been met:
                            <ol>
                              <li>
                                This application has to be approved by StarLife
                                Assurance Company Limited.
                              </li>
                              <li>The appropriate premium will be paid.</li>
                              <li>
                                I satisfy all the conditions precedent to the
                                policy especially those pertaining to my health
                                and that of all the proposed lives assured.
                              </li>
                              <li>
                                All the persons proposed for cover are alive and
                                in good health.
                              </li>
                            </ol>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <br />
                  <tr>
                    <td>Agent Code: {this.props.values.agentCode}</td>
                    <td>Agent Name: {this.props.values.agentName}</td>
                  </tr>
                  <br />
                  <tr>
                    <td>
                      Proposer's Signature:&nbsp;
                      {/* `data:image/png;base64,${this.props.values.signature}` */}
                      <img
                        width="100"
                        height="30"
                        src={`data:image/png;base64,${this.props.values.signature}`}
                        alt="signature"
                      />
                    </td>
                    <td>Date: {moment().format("DD-MM-YYYY HH:mm:ss")}</td>
                  </tr>
                  <tr>
                    {/* <td align="left" width={"40%"}>
                  Confirm:{" "}
                  <input
                    type="checkbox"
                    style={{ border: "1px solid red" }}
                    // style={{
                    //   border: this.state.confirmError ? "1px solid red" : "",
                    // }}
                    defaultChecked={false}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.confirmError}
                  </div>
                </td> */}

                    <td align="left" width={"40%"} colSpan="2">
                      <br />
                      <div>
                        <Form.Group>
                          <InputGroup
                            className="mb-3"
                            // onChange={handleChange("paymentFrequency")}
                            // defaultValue={values.paymentFrequency}
                          >
                            <InputGroup.Checkbox
                              style={{
                                border: this.state.confirmError
                                  ? "1px solid red"
                                  : "",
                              }}
                              value="M"
                              name="confirm"
                              aria-label="Checkbox 1"
                            />{" "}
                            &nbsp; I confirm and agree to the terms of
                            conditions
                          </InputGroup>
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                            }}
                          >
                            {this.state.confirmError}
                          </div>
                        </Form.Group>
                      </div>
                    </td>
                  </tr>
                </table>
                <br />
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#009900" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  style={{ backgroundColor: "#009900" }}
                  onClick={this.continue}
                >
                  Submit
                </Button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div
              id="GCBConfirm"
              className="container center_div"
              style={{
                display: "block",
                width: 994,
                padding: 10,
              }}
            >
              {/* <img
                src={gcb}
                alt="logo"
                // style={{ width: "150px", height: "150px", padding: 10 }}
              /> */}
              <NewHeader />
              <br></br>
              <form>
                {/* <h4>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ color: "#009900" }}
                  />{" "}
                  &nbsp; <u>Confirm Details</u>
                </h4> */}
                {/* Confirm Details Table */}
                <table
                  style={{
                    borderWidth: "2px",
                    borderColor: "#aaaaaa",
                    borderStyle: "solid",
                    width: "100%",
                  }}
                >
                  <tr>
                    <td colSpan={"3"} style={{ color: "#009900" }}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td>
                            {" "}
                            <h4>{this.props.values.product.split("-")[2]}</h4>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td width={"50%"} valign="top">
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            PERSONAL DETAILS
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"30%"}>
                            Surname:
                          </td>
                          <td align="left">
                            <u>
                              <b>{this.props.values.lastName}</b>
                            </u>
                          </td>
                          {/* <td>box</td> */}
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Middle Name(s):
                          </td>
                          <td align="left">
                            <u>
                              <b>{this.props.values.otherNames}</b>
                            </u>
                          </td>
                          {/* <td>box</td> */}
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            First Name:
                          </td>
                          <td align="left">
                            <u>
                              <b>{this.props.values.firstName}</b>
                            </u>
                          </td>
                        </tr>
                        {this.props.values.clntCode ? (
                          <tr>
                            <td align="left" width={"40%"}>
                              Date of Birth:{" "}
                              <u>
                                <b>{this.props.values.dob}</b>
                              </u>
                            </td>
                            <td align="left">
                              Gender:{" "}
                              <u>
                                <b>{this.props.values.gender}</b>
                              </u>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td align="left" width={"40%"}>
                              Date of Birth:{" "}
                              <u>
                                <b>
                                  {moment(this.props.values.dob).format(
                                    "DD-MM-YYYY"
                                  )}
                                </b>
                              </u>
                            </td>
                            <td align="left">
                              Gender:{" "}
                              <u>
                                <b>{this.props.values.gender}</b>
                              </u>
                            </td>
                          </tr>
                        )}

                        {/* <tr>
                          <td align="left" width={"40%"}>
                            Postal Address:
                          </td>
                          <td align="left">
                            <u>{this.props.values.postalAddress}</u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Residential Address:
                          </td>
                          <td align="left">
                            <u>{this.props.values.residentialAddress}</u>
                          </td>
                        </tr> */}
                        <tr>
                          <td align="left" width={"40%"}>
                            Mobile:{" "}
                            <u>
                              <b>{this.props.values.phoneNumber}</b>
                            </u>
                          </td>
                          <td align="left">
                            Email:{" "}
                            <u>
                              <b>{this.props.values.email}</b>
                            </u>
                          </td>
                        </tr>
                      </table>
                    </td>
                    {/* <td width="5px">Space</td> */}
                    <td width={"50%"} valign="top">
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            PAYMENT DETAILS
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Bank:
                          </td>
                          <td align="left">
                            <u>
                              <b>{this.props.values.bank.split("-")[1]}</b>
                            </u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Branch:
                          </td>
                          <td align="left">
                            <u>
                              <b>
                                {this.props.values.bankBranch.split("-")[1]}
                              </b>
                            </u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Account Number:
                          </td>
                          <td align="left">
                            <u>
                              <b>{this.props.values.accountNumber}</b>
                            </u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Payment Frequency:
                          </td>
                          <td align="left">
                            <u>
                              <b>{this.props.values.paymentFrequency}</b>
                            </u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Deduction Date:
                          </td>
                          <td align="left">
                            <u>
                              <b>
                                {moment(this.props.values.deductionDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </b>
                            </u>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            PRODUCT DETAILS
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" width={"40%"}>
                      Product Name:{" "}
                      <u>
                        <b>{this.props.values.product.split("-")[2]}</b>
                      </u>
                    </td>
                    <td align="left">
                      Policy Term:{" "}
                      <u>
                        <b>{this.props.values.term} Years</b>
                      </u>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" width={"40%"}>
                      Total and permanent Disability:{" "}
                      <u>
                        <b>{this.props.values.tpd}</b>
                      </u>
                    </td>
                    <td align="left">
                      Dread Disease:{" "}
                      <u>
                        <b>{this.props.values.dreadDisease}</b>
                      </u>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" width={"40%"}>
                      Premium (Ghc):{" "}
                      <u>
                        <b>
                          {" "}
                          {this.props.values.premium
                            ? this.props.values.premium
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : ""}
                        </b>
                      </u>
                    </td>
                    <td align="left">
                      Sum Assured (Ghc):{" "}
                      <u>
                        <b>
                          {" "}
                          {this.props.values.sa
                            ? this.props.values.sa
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : ""}
                        </b>
                      </u>
                    </td>
                  </tr>
                  {this.props.values.escalationRate !== "" && (
                    <tr>
                      <td align="left">
                        Escalation Rate (%):{" "}
                        <u>
                          <b>{this.props.values.escalationRate}</b>
                        </u>
                      </td>
                    </tr>
                  )}
                  {/* {this.props.values.premium !== "" && (
                    <tr>
                      <td align="left" width={"40%"}>
                        Premium (Ghc):{" "}
                        <u>
                          <b>
                            {" "}
                            {this.props.values.premium
                              ? this.props.values.premium
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                          </b>
                        </u>
                      </td>
                      <td align="left">
                        Escalation Rate (%):{" "}
                        <u>
                          <b>{this.props.values.escalationRate}</b>
                        </u>
                      </td>
                    </tr>
                  )}
                  {this.props.values.sa !== "" && (
                    <tr>
                      <td align="left" width={"40%"}>
                        Sum Assured (Ghc):{" "}
                        <u>
                          <b>
                            {" "}
                            {this.props.values.sa
                              ? this.props.values.sa
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""}
                          </b>
                        </u>
                      </td>
                      <td align="left">
                        Escalation Rate (%):{" "}
                        <u>
                          <b>{this.props.values.escalationRate}</b>
                        </u>
                      </td>
                    </tr>
                  )} */}

                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"8"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            BENEFICIARIES
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <u>Surname</u>
                          </td>
                          <td>
                            <u>Other Names</u>
                          </td>
                          <td>
                            <u>Date of Birth</u>
                          </td>
                          <td>
                            <u>Relationship</u>
                          </td>
                          <td>
                            <u>Percentage (%)</u>
                          </td>
                          <td>
                            <u>Contact No.</u>
                          </td>
                        </tr>
                        {this.props.values.benList.map((ben) => (
                          <tr key={ben.id}>
                            <td>
                              <b>{ben.surname}</b>
                            </td>
                            <td>
                              <b>{ben.othernames}</b>
                            </td>
                            <td>
                              <b>{ben.dob}</b>
                            </td>
                            <td>
                              <b>{ben.relationship}</b>
                            </td>
                            <td>
                              <b>{ben.percent}</b>
                            </td>
                            <td>
                              <b>{ben.phone}</b>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </td>
                  </tr>
                  {this.props.values.trusteeRequired && (
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td
                              colSpan={"8"}
                              style={{
                                backgroundColor: "#009900",
                                color: "#fff",
                              }}
                            >
                              TRUSTEES
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <u>Surname</u>
                            </td>
                            <td>
                              <u>Other Names</u>
                            </td>
                            <td>
                              <u>Date of Birth</u>
                            </td>
                            <td>
                              <u>Relationship</u>
                            </td>
                            <td>
                              <u>Contact No.</u>
                            </td>
                          </tr>
                          {this.props.values.trustee.map((trustee) => (
                            <tr key={trustee.id}>
                              <td>
                                <b>{trustee.surname}</b>
                              </td>
                              <td>
                                <b>{trustee.othernames}</b>
                              </td>
                              <td>
                                <b>{trustee.dob}</b>
                              </td>
                              <td>
                                <b>{trustee.relationship}</b>
                              </td>
                              <td>
                                <b>{trustee.phone}</b>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </td>
                    </tr>
                  )}
                  {this.props.values.pa === "Yes" && (
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td
                              colSpan={"8"}
                              style={{
                                backgroundColor: "#009900",
                                color: "#fff",
                              }}
                            >
                              PERSONAL ACCIDENT FOR CHILD
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <u>Surname</u>
                            </td>
                            <td>
                              <u>Other Names</u>
                            </td>
                            <td>
                              <u>Date of Birth</u>
                            </td>
                            <td>
                              <u>Gender</u>
                            </td>
                          </tr>
                          {/* {this.props.values.familyList.map((member) => ( */}
                          <tr>
                            <td>
                              <b>{this.props.values.paSurname}</b>
                            </td>
                            <td>
                              <b>{this.props.values.paOthername}</b>
                            </td>
                            <td>
                              <b>
                                {moment(this.props.values.padob).format(
                                  "DD-MM-YYYY"
                                )}
                              </b>
                            </td>
                            <td>
                              <b>{this.props.values.pagender}</b>
                            </td>
                          </tr>
                          {/* ))} */}
                        </table>
                      </td>
                    </tr>
                  )}
                  {this.props.values.dependentList.length > 0 && (
                    <tr>
                      <td colSpan={"2"}>
                        <table border="0px" width={"100%"}>
                          <tr>
                            <td
                              colSpan={"8"}
                              style={{
                                backgroundColor: "#009900",
                                color: "#fff",
                              }}
                            >
                              DEPENDENTS
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <u>Surname</u>
                            </td>
                            <td>
                              <u>Other Names</u>
                            </td>
                            <td>
                              <u>Date of Birth</u>
                            </td>
                            <td>
                              <u>Relationship</u>
                            </td>
                            <td>
                              <u>Gender</u>
                            </td>
                          </tr>
                          {this.props.values.dependentList.map((dependent) => (
                            <tr key={dependent.id}>
                              <td>
                                <b>{dependent.surname}</b>
                              </td>
                              <td>
                                <b>{dependent.othernames}</b>
                              </td>
                              <td>
                                <b>{dependent.dob}</b>
                              </td>
                              <td>
                                <b>{dependent.relationship.split("-")[1]}</b>
                              </td>
                              <td>
                                <b>{dependent.gender}</b>
                              </td>
                            </tr>
                          ))}
                        </table>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            MEDICAL HISTORY
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Are you presently in good health?:{" "}
                            <u>
                              <b>{this.props.values.md1}</b>
                            </u>
                          </td>
                          <td align="left" width={"40%"}>
                            Have you been diagnosed with any medical condition
                            or illness?:{" "}
                            <u>
                              <b>{this.props.values.md2}</b>
                            </u>
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Are you on any medication?:{" "}
                            <u>
                              <b>{this.props.values.md3}</b>
                            </u>
                          </td>
                          <td align="left" width={"40%"}>
                            Have you undergone any surgical operation or
                            procedure?:{" "}
                            <u>
                              <b>{this.props.values.md4}</b>
                            </u>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            DATA PROTECTION
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            I hereby consent to the processing of my personal
                            data for business relationship and further
                            acknowledge and agree that my personal data may be
                            disclosed to entities associated, affiliated or in
                            relation to this business to achieve the purpose of
                            processing under this consent.
                            <div>
                              <Form.Group>
                                <InputGroup
                                  className="mb-3"
                                  onChange={this.props.handleChange(
                                    "dataProtection"
                                  )}
                                >
                                  <InputGroup.Radio
                                    style={{
                                      border: this.state.dataError
                                        ? "1px solid red"
                                        : "",
                                    }}
                                    name="data"
                                    value="Yes"
                                    aria-label="Radio 1"
                                    defaultChecked={this.state.yesChecked}
                                  />{" "}
                                  &nbsp; Yes &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  <InputGroup.Radio
                                    style={{
                                      border: this.state.dataError
                                        ? "1px solid red"
                                        : "",
                                    }}
                                    name="data"
                                    value="No"
                                    aria-label="Radio 2"
                                    defaultChecked={this.state.noChecked}
                                  />{" "}
                                  &nbsp; No
                                </InputGroup>
                              </Form.Group>

                              <div
                                style={{
                                  textAlign: "left",
                                  color: "red",
                                }}
                              >
                                {this.state.dataError}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={"2"}>
                      <table border="0px" width={"100%"}>
                        <tr>
                          <td
                            colSpan={"2"}
                            style={{
                              backgroundColor: "#009900",
                              color: "#fff",
                            }}
                          >
                            DECLARATION
                          </td>
                        </tr>
                        <tr>
                          <td align="left" width={"40%"}>
                            Declaration by Applicant. <br /> I{" "}
                            <u>
                              <b>
                                {this.props.values.firstName}{" "}
                                {this.props.values.otherNames &&
                                  this.props.values.otherNames}{" "}
                                {this.props.values.lastName}
                              </b>
                            </u>{" "}
                            , declare that every statement in response to
                            questions asked in this application is true and
                            correct to the best of my knowledge. I agree that
                            this application shall serve as the basis and form
                            part of the contract. All the questions have been
                            explained to me in the language that I understand
                            and I have been made to understand that this
                            contract shall become operative until all of the
                            following conditions have been met:
                            <ol>
                              <li>
                                This application has to be approved by StarLife
                                Assurance Company Limited.
                              </li>
                              <li>The appropriate premium will be paid.</li>
                              <li>
                                I satisfy all the conditions precedent to the
                                policy especially those pertaining to my health.
                              </li>
                              <li>
                                All the persons proposed for cover are alive and
                                in good health.
                              </li>
                            </ol>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  {/* <br /> */}
                  {this.props.values.agnCode && (
                    <tr>
                      <td>
                        Agent Code:{" "}
                        <u>
                          <b>{this.props.values.agentCode}</b>
                        </u>
                      </td>
                      <td>
                        Agent Name:{" "}
                        <u>
                          <b>{this.props.values.agentName}</b>
                        </u>
                      </td>
                    </tr>
                  )}
                  {/* <br /> */}
                  <tr>
                    <td>
                      Proposer's Signature:&nbsp;
                      {/* `data:image/png;base64,${this.props.values.signature}` */}
                      <img
                        width="100"
                        height="30"
                        src={`data:image/png;base64,${this.props.values.signature}`}
                        alt="signature"
                      />
                    </td>
                    <td>
                      Date:{" "}
                      <u>
                        <b>{moment().format("DD-MM-YYYY")}</b>
                      </u>
                    </td>
                  </tr>
                  <tr>
                    {/* <td align="left" width={"40%"}>
                  Confirm:{" "}
                  <input
                    type="checkbox"
                    style={{ border: "1px solid red" }}
                    // style={{
                    //   border: this.state.confirmError ? "1px solid red" : "",
                    // }}
                    defaultChecked={false}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.confirmError}
                  </div>
                </td> */}

                    <td align="left" width={"40%"} colSpan="2">
                      {/* <br /> */}
                      <div>
                        <Form.Group>
                          <InputGroup
                            className="mb-3"
                            // onChange={handleChange("paymentFrequency")}
                            // defaultChecked={true}
                          >
                            <InputGroup.Checkbox
                              style={{
                                border: this.state.confirmError
                                  ? "1px solid red"
                                  : "",
                              }}
                              name="confirm"
                              aria-label="Checkbox 1"
                              defaultChecked={this.state.checked}
                            />{" "}
                            &nbsp; I confirm and agree to the terms of
                            conditions
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </td>
                  </tr>
                </table>
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.confirmError}
                  {this.state.submitError}
                </div>
                <br />
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#009900" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <a href="/bancassurance/atwimamponua/onboarding">
                  <Button
                    variant="info"
                    // type="submit"
                    // style={{ backgroundColor: "#009900" }}
                    onClick={this.cancel}
                  >
                    Cancel
                  </Button>
                </a>
                &nbsp;
                <Button
                  variant="primary"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#009900",
                    borderColor: "#009900",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Submit
                </Button>
              </form>
              <br></br>
              <BottomButton
                step={this.props.step}
                values={this.props.values}
                getDecryptedData={this.props.getDecryptedData}
                saveEncryptedData={this.props.saveEncryptedData}
                resetStep={this.props.resetStep}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default GCBConfirm;
