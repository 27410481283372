import React, { PureComponent } from "react";
import Card from "./Card";
import Button from "./Button";
import classes from "./FormBeneficiaryModal.module.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import moment from "moment";
import { getAge } from "../func/utils";
import axios from "axios";

export class EditMemberModal extends PureComponent {
  state = {
    memberFirstNameError: "",
    memberLastNameError: "",
    memberDobError: "",
    memberRelError: "",
    memberGenderError: "",
    memberPictureError: "",
    memberPicture: "",
    memberPictureURI: "",
  };

  // On file select (from the pop up)
  onPictureSelect = (event) => {
    var reader = new FileReader();
    // reader.readAsText(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.setState({
        memberPictureURI: reader.result.split(",")[1],
      });
    };
    this.setState({
      memberPicture: URL.createObjectURL(event.target.files[0]),
    });
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };

  updateMember = (e) => {
    e.preventDefault();

    let memberFirstNameError = "";
    let memberLastNameError = "";
    let memberDobError = "";
    let memberRelError = "";
    let memberGenderError = "";
    let memberPictureError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      memberFirstNameError = "Please enter member first name";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      memberLastNameError = "Please enter member last name";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      memberDobError = "Please enter member date of birth";
    }

    // Regular expression to check format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(e.target.form[2].value)) {
      memberDobError = "Please enter a valid member date of birth";
    }

    if (!e.target.form[3].value) {
      // Checks if field is empty
      memberRelError = "Please select your relationship with member";
    }

    if (!e.target.form[4].value) {
      // Checks if field is empty
      memberGenderError = "Please select gender of member";
    }

    if (!this.props.photo) {
      // Checks if field is empty
      memberPictureError = "Please provide picture of member";
    }

    if (!e.target.form[5].value && !this.props.photo) {
      // Checks if field is empty
      memberPictureError = "Please provide picture of member";
    }

    if (this.props.photo) {
      this.setState({
        memberPictureURI: this.props.photo,
      });
    }

    if (
      memberFirstNameError ||
      memberLastNameError ||
      memberDobError ||
      memberRelError ||
      memberGenderError ||
      memberPictureError
    ) {
      this.setState({
        memberFirstNameError,
        memberLastNameError,
        memberDobError,
        memberRelError,
        memberGenderError,
        memberPictureError,
      });
      return false;
    }

    const member = {
      id: this.props.id,
      surname: e.target.form[1].value,
      othernames: e.target.form[0].value,
      dob: moment(e.target.form[2].value).format("DD-MM-YYYY"),
      relationship: e.target.form[3].value,
      gender: e.target.form[4].value,
      photo: this.state.memberPictureURI,
    };

    this.props.updateMember(member);
  };

  render() {
    const { title, backdrop } = this.props;
    const rel = [
      {
        Code: "1",
        Description: "CHILD",
      },
      {
        Code: "2",
        Description: "SPOUSE",
      },
      {
        Code: "3",
        Description: "OTHER",
      },
    ];
    const [day, month, year] = this.props.dob.split("-");
    const newDOB = new Date(+year, month - 1, +day);
    return (
      <div>
        <div className={classes.backdrop} onClick={backdrop} />
        <Card className={classes.modal}>
          <header className={classes.header}>
            <h2>{title}</h2>
          </header>
          <div className={classes.content}>
            {/* <p>{props.message}</p> */}
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="memberFirstname"
                  label="Other Names"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.othernames}
                    placeholder="Enter Beneficiary Firstname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.memberFirstNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberFirstNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="memberLastname"
                  label="Last Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.surname}
                    placeholder="Enter Beneficiary Lastname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.memberLastNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberLastNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="memberdob"
                  label="Date of Birth"
                  className="mb-2"
                >
                  <Form.Control
                    type="date"
                    placeholder="Enter Member Date of Birth"
                    // onChange={handleChange("dob")}
                    defaultValue={moment(newDOB).format("YYYY-MM-DD")}
                    //moment(e.target.form[2].value).format("DD-MM-YYYY")
                    style={{
                      border: this.state.memberDobError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberDobError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="relation"
                  label="Relationship"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("occupation")}
                    // defaultValue={values.occupation}
                    style={{
                      border: this.state.memberRelError ? "1px solid red" : "",
                    }}
                  >
                    <option>{this.props.relationship.split("-")[1]}</option>
                    {rel.map((r) => (
                      <option key={r.Code} value={r.Code + "-" + r.Description}>
                        {r.Description}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberRelError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="gender"
                  label="Gender"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("occupation")}
                    // defaultValue={values.occupation}
                    style={{
                      border: this.state.memberGenderError
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <option>{this.props.gender}</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberGenderError}
                  </div>
                </FloatingLabel>
                <Form.Group>
                  <div className="mb-" style={{ textAlign: "left" }}>
                    Picture of Member
                  </div>
                  <div style={{ textAlign: "left" }}>
                    {this.props.photo && (
                      <img
                        src={`data:image/png;base64,${this.props.photo}`}
                        width="200"
                        height="100"
                      />
                    )}
                  </div>

                  <Form.Control
                    accept="image/*"
                    type="file"
                    controlId="assuredPicture"
                    label="Picture of assured"
                    className="mb-2"
                    onChange={this.onPictureSelect}
                    style={{
                      border: this.state.memberPictureError
                        ? "1px solid red"
                        : "",
                    }}
                  ></Form.Control>

                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberPictureError}
                  </div>
                </Form.Group>
              </Form.Group>
              <Button onClick={this.updateMember}>Save</Button>
            </Form>
          </div>
          {/* <footer className={classes.actions}>
            <Button onClick={this.addBeneficiary}>Add</Button>
          </footer> */}
        </Card>
      </div>
    );
  }
}

export default EditMemberModal;
