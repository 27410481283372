import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import BankData from "./data/BankData";
import SignatureCanvas from "react-signature-canvas";
import "../sigCanvas.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

export class BankDetails extends PureComponent {
  sigRef = React.createRef({});

  clear = (e) => {
    e.preventDefault();
    this.sigRef.current.clear();
  };
  render() {
    const bank = BankData;
    const {
      bankError,
      bankBranchError,
      accountNumberError,
      valueChange,
      values,
      handleBankChange,
      handleChange,
      deductionDateError,
      signatureError,
      clear,
      validateAccountNumber,
      accountName,
      handleDateChange,
    } = this.props;

    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var day = tomorrow.getDay();
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (day === 0) {
      dd = dd + 1;
    }

    if (day === 6) {
      dd = dd + 2;
    }

    var tm = yyyy + "-" + mm + "-" + dd;
    return (
      <div>
        <Form.Group>
          <FloatingLabel controlId="bank" label="Bank Name" className="mb-2">
            <Form.Control
              as="select"
              // onChange={handleChange("bank")}
              onChange={handleBankChange}
              defaultValue={values.bank}
              style={{
                border: bankError ? "1px solid red" : "",
              }}
            >
              <option></option>
              {/* {banks.map((bank) => (
                      <option
                        key={bank.id}
                        value={bank.value + "-" + bank.label}
                      >
                        {bank.label}
                      </option>
                    ))} */}
              {bank.map((bank) => (
                <option
                  key={bank.bnkCode}
                  value={
                    bank.bnkCode +
                    "-" +
                    bank.bnkBankName +
                    "-" +
                    bank.bnkMinLength +
                    "-" +
                    bank.bnkMaxLength
                  }
                >
                  {bank.bnkBankName}
                </option>
              ))}
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {bankError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="bankBranch"
            label="Bank Branch"
            className="mb-2"
          >
            <Form.Control
              as="select"
              onChange={handleChange("bankBranch")}
              defaultValue={values.bankBranch}
              style={{
                border: bankBranchError ? "1px solid red" : "",
              }}
            >
              <option></option>
              {values.bankBranchList.map((branch) => (
                <option
                  key={branch.bbrCode}
                  value={branch.bbrCode + "-" + branch.bbrBranchName}
                >
                  {branch.bbrBranchName}
                </option>
              ))}
            </Form.Control>
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {bankBranchError}
            </div>
          </FloatingLabel>
          <FloatingLabel
            controlId="accountno"
            label="Account Number"
            className="mb-2"
          >
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="Enter Your Account Number"
              onBlur={validateAccountNumber}
              //   onChange={handleChange("accountNumber")}
              defaultValue={values.accountNumber}
              style={{
                border: accountNumberError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {accountNumberError}
            </div>
            <div
              style={{
                textAlign: "left",
                color: "green",
              }}
            >
              {values.accountName && (
                <>
                  Account number verification successful{" "}
                  <FontAwesomeIcon icon={faCheck} />
                </>
              )}
            </div>
            {values.accountName && (
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <b>{accountName}</b>
              </div>
            )}
          </FloatingLabel>
          <FloatingLabel
            controlId="deductionDate"
            label="Date of First Deduction"
            className="mb-2"
          >
            <Form.Control
              type="month"
              min={tm}
              // placeholder="Enter Beneficiary Date of Birth"
              // defaultValue={moment(newDOB).format("YYYY-MM-DD")}
              // onChange={handleChange("deductionDate")}
              onChange={handleDateChange}
              defaultValue={values.deductionDate}
              style={{
                border: deductionDateError ? "1px solid red" : "",
              }}
            />
            <div
              style={{
                textAlign: "left",
                color: "red",
              }}
            >
              {deductionDateError}
            </div>
          </FloatingLabel>
        </Form.Group>
      </div>
    );
  }
}

export default BankDetails;
