import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

import qmark from "../../question-mark.svg";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";

export class ProductDetailsUPP extends PureComponent {
  state = {
    paChange: false,
    incrementOption: false,
  };

  handleIncrementOption = (e) => {
    const escalation = e.target.value;
    if (escalation === "Yes") {
      this.setState({ incrementOption: true });
      this.props.valueChange(["escalation", escalation]);
    } else {
      this.setState({ incrementOption: false });
      this.props.valueChange(["escalation", escalation]);
    }
  };

  render() {
    const {
      values,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      handleChange,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
    } = this.props;

    const popoverRight = (
      <Popover id="popover-positioned-right" title="Popover right">
        <strong>Holy guacamole!</strong> Check this info.
      </Popover>
    );

    return (
      <div>
        {/* <div style={{ width: "100%" }}>
          <dv style={{ width: "50%", height: "100px", float: "left" }}>A</dv>
          <div style={{ marginLeft: "50%", height: "100px" }}>?</div>
        </div> */}

        <Form.Group>
          <div>
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="term"
                  label="Select policy term (min of 8 years: max of 20 years)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    autoComplete="off"
                    onChange={handleChange("term")}
                    defaultValue={values.term}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: termError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"8"}>8 years</option>
                    <option value={"9"}>9 years</option>
                    <option value={"10"}>10 years</option>
                    <option value={"11"}>11 years</option>
                    <option value={"12"}>12 years</option>
                    <option value={"13"}>13 years</option>
                    <option value={"14"}>14 years</option>
                    <option value={"15"}>15 years</option>
                    <option value={"16"}>16 years</option>
                    <option value={"17"}>17 years</option>
                    <option value={"18"}>18 years</option>
                    <option value={"19"}>19 years</option>
                    <option value={"20"}>20 years</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {termError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#204697",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>

            {/* dreadDisease */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="dread"
                  label="Dread Disease"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("dreadDisease")}
                    defaultValue={values.dreadDisease}
                    style={{
                      border: dreadDiseaseError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {dreadDiseaseError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="dreadTip"
                  style={{
                    color: "#204697",
                  }}
                />
                <ReactTooltip
                  id="dreadTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Provides 50% of selected Sum <br />
                  Assured when diseases that have <br />
                  severe impact on policyholder <br />
                  lifestyle occurs e.g. stroke,
                  <br /> cancer, Kidney failure, AIDS.
                </ReactTooltip>
              </div>
            </div>

            {/* premium */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="premium"
                  label="Select your basic sum assured (minimum 100,000 ghc)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("sa")}
                    defaultValue={values.sa}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: premiumError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"100000"}>100,000</option>
                    <option value={"200000"}>200,000</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {premiumError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="premiumTip"
                  style={{
                    color: "#204697",
                  }}
                />
                <ReactTooltip
                  id="premiumTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  A sum assured is a fixed amount <br />
                  that is paid to the beneficiary <br />
                  of the policyholder in the unfortunate <br />
                  event of the policyholder's demise.
                </ReactTooltip>
              </div>
            </div>

            {/* Escalation */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="escalation"
                  label="Do you wish to have incremental option?"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleIncrementOption}
                    // defaultValue={values.escalation}
                    style={{
                      border: escalationError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {escalationError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="escalationTip"
                  style={{
                    color: "#204697",
                  }}
                />
                <ReactTooltip
                  id="escalationTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  The option to increase the <br />
                  contractual premium on <br />
                  an annual basis in order <br /> for the benefits to be
                  <br />
                  inflation-linked.
                </ReactTooltip>
              </div>
            </div>
            {this.state.incrementOption && (
              <ProductDetailsEscalation
                escalationRateError={escalationRateError}
                handleChange={handleChange}
                values={values}
              />
            )}
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsUPP;
