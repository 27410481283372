import React, { PureComponent } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { OverlayTrigger, Popover, Button, InputGroup } from "react-bootstrap";

import qmark from "../../question-mark.svg";
import ReactTooltip from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import ProductDetailsPA from "./ProductDetailsPA";
import ProductDetailsEscalation from "./ProductDetailsEscalation";

import { getAge } from "../func/utils";

export class ProductDetailsCLP extends PureComponent {
  state = {
    paChange: false,
    incrementOption: false,
  };

  handlePAChange = (e) => {
    // console.log(e.target.value);
    const pa = e.target.value;
    console.log(pa);
    if (pa === "Yes") {
      this.setState({ paChange: true });
      this.props.valueChange(["pa", pa]);
    } else {
      this.setState({ paChange: false });
      this.props.valueChange(["pa", pa]);
      // this.props.valueChange(["escalationRate", ""]);
    }
  };

  handleIncrementOption = (e) => {
    const escalation = e.target.value;
    if (escalation === "Yes") {
      this.setState({ incrementOption: true });
      this.props.valueChange(["escalation", escalation]);
    } else {
      this.setState({ incrementOption: false });
      this.props.valueChange(["escalation", escalation]);
    }
  };

  // setTerm() {}

  // componentDidMount() {
  //   const term = {
  //     value: 10,
  //     label: 10,
  //   };

  //   this.setState({
  //     policyTerm: [...this.state.policyTerm, { value: 2, label: 2 }],
  //   });

  //   this.setState({ paChange: true });

  //   console.log(this.state.policyTerm);
  //   console.log(this.state.paChange);
  // }

  render() {
    const {
      values,
      termError,
      tpdError,
      retrechmentError,
      dreadDiseaseError,
      paError,
      handleChange,
      premiumError,
      escalationError,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
      escalationRateError,
      paymentFrequencyError,
    } = this.props;

    const popoverRight = (
      <Popover id="popover-positioned-right" title="Popover right">
        <strong>Holy guacamole!</strong> Check this info.
      </Popover>
    );

    let age;
    const maxAge = 60;
    let policyTerm = [];

    // Regular expression pattern for "DD-MM-YYYY" format
    const pattern = /^\d{2}-\d{2}-\d{4}$/;

    if (values.clntCode && pattern.test(values.dob)) {
      const dob = values.dob;
      const [dob_day, dob_month, dob_year] = dob.split("-");
      const newDob = [dob_year, dob_month, dob_day].join("-");
      age = getAge(newDob);
    } else {
      const dob = values.dob;
      age = getAge(dob);
    }

    // console.log(age);

    if (maxAge - (age + 1) < 25 && maxAge - (age + 1) >= 10) {
      for (let step = 10; step <= maxAge - (age + 1); step++) {
        policyTerm.push({ value: step, label: step });
      }
    } else if (maxAge - (age + 1) >= 25) {
      for (let step = 10; step <= 25; step++) {
        policyTerm.push({ value: step, label: step });
      }
    } else {
      alert("Age Limit exceeded");
      // console.log("Age limit exceeded");
    }

    // for (let step = 10; step <= 25; step++) {
    //   // Runs 5 times, with values of step 0 through 4.
    //   policyTerm.push({ value: step, label: step });
    // }

    // console.log(policyTerm);

    return (
      <div>
        {/* <div style={{ width: "100%" }}>
          <dv style={{ width: "50%", height: "100px", float: "left" }}>A</dv>
          <div style={{ marginLeft: "50%", height: "100px" }}>?</div>
        </div> */}

        <Form.Group>
          <div>
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="term"
                  label="Select policy term (min of 10 years: max of 25 years)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    autoComplete="off"
                    onChange={handleChange("term")}
                    defaultValue={values.term}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: termError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {policyTerm.map((term) => (
                      <option key={term.value} value={term.value}>
                        {term.label + " years"}
                      </option>
                    ))}
                    {/* <option value={"10"}>10 years</option>
                    <option value={"11"}>11 years</option>
                    <option value={"12"}>12 years</option>
                    <option value={"13"}>13 years</option>
                    <option value={"14"}>14 years</option>
                    <option value={"15"}>15 years</option>
                    <option value={"16"}>16 years</option>
                    <option value={"17"}>17 years</option>
                    <option value={"18"}>18 years</option>
                    <option value={"19"}>19 years</option>
                    <option value={"20"}>20 years</option>
                    <option value={"21"}>21 years</option>
                    <option value={"22"}>22 years</option>
                    <option value={"23"}>23 years</option>
                    <option value={"24"}>24 years</option>
                    <option value={"25"}>25 years</option> */}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {termError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>
            {/* tpd */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="tpd"
                  label="Total and Permanent Disability"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("tpd")}
                    defaultValue={values.tpd}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.paymentMode}
                    style={{
                      border: tpdError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {tpdError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="dreadTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="dreadTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Provides 50% of selected Sum <br />
                  Assured when diseases that have <br />
                  severe impact on policyholder <br />
                  lifestyle occurs e.g. stroke,
                  <br /> cancer, Kidney failure, AIDS.
                </ReactTooltip>
              </div>
            </div>

            {/* retrenchment */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="retrenchment"
                  label="Retrenchment (12 months waiver of premium)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("retrenchment")}
                    defaultValue={values.retrenchment}
                    style={{
                      border: retrechmentError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {retrechmentError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="dreadTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="dreadTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Provides 50% of selected Sum <br />
                  Assured when diseases that have <br />
                  severe impact on policyholder <br />
                  lifestyle occurs e.g. stroke,
                  <br /> cancer, Kidney failure, AIDS.
                </ReactTooltip>
              </div>
            </div>

            {/* dreadDisease */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="dread"
                  label="Dread Disease"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("dreadDisease")}
                    defaultValue={values.dreadDisease}
                    style={{
                      border: dreadDiseaseError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {dreadDiseaseError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="dreadTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="dreadTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Provides 50% of selected Sum <br />
                  Assured when diseases that have <br />
                  severe impact on policyholder <br />
                  lifestyle occurs e.g. stroke,
                  <br /> cancer, Kidney failure, AIDS.
                </ReactTooltip>
              </div>
            </div>

            {/* pa */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="pa"
                  label="Personal Accident for child (Cover: GH₵1,000.00)"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handlePAChange}
                    // defaultValue={values.pa}
                    style={{
                      border: paError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {paError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="dreadTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="dreadTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Provides 50% of selected Sum <br />
                  Assured when diseases that have <br />
                  severe impact on policyholder <br />
                  lifestyle occurs e.g. stroke,
                  <br /> cancer, Kidney failure, AIDS.
                </ReactTooltip>
              </div>
            </div>
            {this.state.paChange && (
              <ProductDetailsPA
                paSurnameError={paSurnameError}
                paOthernameError={paOthernameError}
                paDOB={paDOB}
                paGender={paGender}
                handleChange={handleChange}
                values={values}
              />
            )}

            {/* premium */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="premium"
                  label="Enter your premium (minimum 100 ghc)"
                  className="mb-2"
                >
                  <Form.Control
                    type="number"
                    autoComplete="off"
                    onChange={handleChange("premium")}
                    defaultValue={values.premium}
                    style={{
                      // border: this.props.termError ? "1px solid red" : "",
                      border: premiumError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {/* {this.props.termError} */}
                    {premiumError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="premiumTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="premiumTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Premium is an amount paid periodically for an insurance
                  policy.
                </ReactTooltip>
              </div>
            </div>

            {/* Payment Frequeny */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <Form.Group>
                  <div className="mb-3" style={{ textAlign: "left" }}>
                    Payment Frequency
                  </div>
                  <InputGroup
                    className="mb-3"
                    onChange={handleChange("paymentFrequency")}
                    defaultValue={values.paymentFrequency}
                  >
                    <InputGroup.Radio
                      style={{
                        border: paymentFrequencyError ? "1px solid red" : "",
                      }}
                      value="M"
                      name="paymentFrequency"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <InputGroup.Radio
                      style={{
                        border: paymentFrequencyError ? "1px solid red" : "",
                      }}
                      value="A"
                      name="paymentFrequency"
                      aria-label="Radio 1"
                    />{" "}
                    &nbsp; Annually
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {paymentFrequencyError}
                    </div>
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="payFreqTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="payFreqTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  How often do you want to pay your premium?
                </ReactTooltip>
              </div>
            </div>

            {/* Escalation */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="escalation"
                  label="Do you wish to have incremental option?"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleIncrementOption}
                    // defaultValue={values.escalation}
                    style={{
                      border: escalationError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {escalationError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="escalationTip"
                  style={{
                    color: "#191c4a",
                  }}
                />
                <ReactTooltip
                  id="escalationTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  The option to increase the <br />
                  contractual premium on <br />
                  an annual basis in order <br /> for the benefits to be
                  <br />
                  inflation-linked.
                </ReactTooltip>
              </div>
            </div>
            {this.state.incrementOption && (
              <ProductDetailsEscalation
                escalationRateError={escalationRateError}
                handleChange={handleChange}
                values={values}
              />
            )}
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsCLP;
