import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import "bootstrap/dist/css/bootstrap.css";
import GCBNewHeader from "./header/GCBNewHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const PendingBusiness = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  // Encrypt data and save to localStorage
  const saveEncryptedData = (key, data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    const chunkSize = 1024 * 1024; // 1 MB chunk size (adjust as needed)
    const totalChunks = Math.ceil(encryptedData.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, encryptedData.length);
      const chunk = encryptedData.substring(start, end);

      // Store each chunk in localStorage with a unique key
      localStorage.setItem(`${key}_${i}`, chunk);
    }
  };

  // Retrieve encrypted data from localStorage and decrypt it
  const getDecryptedData = (key) => {
    const chunkKeys = Object.keys(localStorage).filter((storedKey) =>
      storedKey.startsWith(`${key}_`)
    );

    if (chunkKeys.length === 0) {
      // No chunks found for the given key
      return null;
    }

    // Concatenate and decrypt all chunks to get the encrypted data
    let encryptedData = "";
    for (const chunkKey of chunkKeys) {
      encryptedData += localStorage.getItem(chunkKey);
    }

    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const loadData = async () => {
    console.log("Loading data");
    try {
      const storedData = await getDecryptedData("pendcurr");

      console.log("stored data", storedData);

      if (storedData !== null) {
        const filtered = storedData.filter(
          (
            item //console.log("item", item.values.agnCode)
          ) => item.values.agnCode === getDecryptedData("currDSO")["agnCode"]
        );

        setData(filtered);
        setOriginalData(filtered);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleContinue = async (id) => {
    console.log("id to continue", id);
    try {
      const item = data.find((item) => item.values.id === id);
      console.log("item", item);

      //Check if there's data in the current storage
      const dataString = await getDecryptedData("curr");

      console.log("current data", dataString);

      let parsedData;

      if (typeof dataString === "object") {
        parsedData = dataString;
      } else {
        try {
          // Try parsing the data into an object
          parsedData = JSON.parse(dataString);
        } catch (error) {
          // Handle parsing error
          console.error("Error parsing JSON:", error);
        }
      }

      if (parsedData && parsedData["step"] > 2) {
        console.log("current data", dataString["step"]);
        // Display an alert with "Yes" and "No" options
        const confirm = window.confirm(
          "You have unsaved changes! Do you want to continue and overwrite them?"
        );
        if (confirm) {
          console.log("confirmed");
          const { step, values } = item;
          const dataToSave = { step, values };
          console.log("item to continue", dataToSave);
          saveEncryptedData("curr", dataToSave);
          const updatedData = data.filter((item) => item.values.id !== id);
          console.log("Curr pending", updatedData);
          saveEncryptedData("pendcurr", updatedData);
          // Update the state with the new data
          setData(updatedData);
          navigate("/bancassurance/omnibsic/onboarding");
        } else {
          console.log("Not confirmed");
        }
      } else {
        console.log("Noo current data");
        const { step, values } = item;
        const dataToSave = { step, values };
        console.log("item to continue", dataToSave);
        saveEncryptedData("curr", dataToSave);
        const updatedData = data.filter((item) => item.values.id !== id);
        console.log("Curr pending", updatedData);
        saveEncryptedData("pendcurr", updatedData);
        // Update the state with the new data
        setData(updatedData);
        navigate("/bancassurance/omnibsic/onboarding");
      }

      //   const dataToSave = JSON.stringify(item);
      //   await AsyncStorage.setItem("@Current:data", dataToSave);
      //   handleDelete(id);
      //   history.push("/policy-onboarding");
    } catch (error) {}
  };

  //   const handleDelete = async (id) => {
  //     try {
  //       const updatedData = data.filter((item) => item.values.id !== id);
  //       setData(updatedData);
  //       await AsyncStorage.setItem("@pending:data", JSON.stringify(updatedData));
  //     } catch (error) {}
  //   };

  const handleSearch = (text) => {
    setSearchQuery(text);

    if (text === "") {
      setData(originalData);
    } else {
      const filtered = originalData.filter((item) =>
        Object.values(item.values).some(
          (value) =>
            value && value.toString().toLowerCase().includes(text.toLowerCase())
        )
      );
      setData(filtered);
    }
  };

  const steps = [
    { 1: "Welcome Screen" },
    { 2: "Exiting User Screen" },
    { 3: "KYC Details Screen" },
    { 4: "OTP Screen" },
    { 5: "Product Screen" },
    { 6: "Beneficiary Details Screen" },
    { 7: "Medical History Screen" },
    { 8: "Payment Details Screen" },
    { 9: "DSO Details Screen" },
    { 10: "Confirmation Screen" },
  ];

  return (
    <>
      <GCBNewHeader />
      <br></br>
      {originalData.length > 0 ? (
        <>
          <div>
            <input
              placeholder="Search by any field"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* <ul>
              {data.map((item) => (
                <li key={item.values.id}>
                  <div>
                    <p>
                      Name: {item?.values?.firstName} {item?.values?.otherNames}{" "}
                      {item?.values?.lastName}
                    </p>
                    <p>Phone Number: {item?.values?.phoneNumber}</p>
                    {item.values.product && (
                      <p>Product: {item?.values?.product?.split("-")[2]}</p>
                    )}
                    <button onClick={() => handleContinue(item?.values?.id)}>
                      Continue
                    </button>
                  </div>
                </li>
              ))}
            </ul> */}
          </div>
          <br></br>
          <div>
            <table
              border="0"
              width="100%"
              cellPadding="20px"
              cellSpacing="10px"
            >
              <thead border="1">
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Product</th>
                  <th>Step</th>
                  <th>Status</th>
                  <th colSpan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <>
                    <tr key={item.values.id}>
                      <td>
                        {item?.values?.firstName} {item?.values?.otherNames}{" "}
                        {item?.values?.lastName}
                      </td>
                      <td>{item?.values?.phoneNumber}</td>
                      {item.values.product ? (
                        <td>{item?.values?.product?.split("-")[2]}</td>
                      ) : (
                        <td></td>
                      )}
                      {item?.step && (
                        <td>
                          {
                            steps
                              .map((obj) =>
                                Object.keys(obj)[0] === item.step.toString()
                                  ? Object.values(obj)[0]
                                  : null
                              )
                              .filter(Boolean)[0]
                          }
                        </td>
                      )}
                      {/* <td>{item?.step}</td> */}
                      <td
                        style={{
                          backgroundColor: "red",
                          borderRadius: "50px",
                          color: "white",
                          // height: "10px",
                        }}
                      >
                        Uncompleted
                      </td>
                      <td>
                        {/* <span
                  style={{ color: "red" }}
                  onClick={() => removeBen(ben.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </span> */}
                        {/* <a
                        href="#"
                        style={{ color: "blue" }}
                        onClick={() => handleContinue(item?.values?.id)}
                      >
                        Continue{" "}
                        <FontAwesomeIcon icon={faPlay} title="Continue" />
                      </a> */}
                        <Button
                          variant="primary"
                          style={{
                            backgroundColor: "#191c4a",
                            outlineColor: "#191c4a",
                            borderColor: "#191c4a",
                            color: "#fff",
                          }}
                          onClick={() => handleContinue(item?.values?.id)}
                        >
                          Continue{" "}
                          <FontAwesomeIcon icon={faPlay} title="Continue" />
                        </Button>
                      </td>
                    </tr>
                    {/* <br></br> */}
                    &nbsp;
                  </>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
              {/* <thead>
          <tr>
            <th>Surname</th>
            <th>Othernames</th>
            <th>Date of Birth</th>
            <th>Relationship</th>
            <th>Contact Number</th>
            <th>Percentage</th>
            <th colSpan="2">Action</th>
          </tr>
        </thead> */}
            </table>
          </div>
        </>
      ) : (
        <div>No Pending Business</div>
      )}
    </>
  );
};

export default PendingBusiness;
