import React, { PureComponent } from "react";
import Card from "./Card";
import Button from "./Button";
import classes from "./FormBeneficiaryModal.module.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import moment from "moment";
import { getAge } from "../func/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

export class EditDependentModal extends PureComponent {
  state = {
    memberFirstNameError: "",
    memberLastNameError: "",
    memberDobError: "",
    memberRelError: "",
    memberGenderError: "",
  };

  updateDependent = (e) => {
    e.preventDefault();

    let memberFirstNameError = "";
    let memberLastNameError = "";
    let memberDobError = "";
    let memberRelError = "";
    let memberGenderError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      memberFirstNameError = "Please enter member first name";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      memberLastNameError = "Please enter member last name";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      memberDobError = "Please enter member date of birth";
    }

    // Regular expression to check format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(e.target.form[2].value)) {
      memberDobError = "Please enter a valid member date of birth";
    }

    if (!e.target.form[3].value) {
      // Checks if field is empty
      memberRelError = "Please select your relationship with member";
    }

    if (e.target.form[3].value.split("-")[1] === "CHILD") {
      if (getAge(e.target.form[2].value) > 23) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be above 23 years`;
      }
    } else if (e.target.form[3].value.split("-")[1] === "SPOUSE") {
      if (
        getAge(e.target.form[2].value) > 64 ||
        getAge(e.target.form[2].value) < 18
      ) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be below 18 or above 64 years`;
      }
    } else if (e.target.form[3].value.split("-")[1] === "MOTHER") {
      if (
        getAge(e.target.form[2].value) > 74 ||
        getAge(e.target.form[2].value) < 18
      ) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be below 18 or above 74 years`;
      }
    } else if (e.target.form[3].value.split("-")[1] === "FATHER") {
      if (
        getAge(e.target.form[2].value) > 74 ||
        getAge(e.target.form[2].value) < 18
      ) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be below 18 above 74 years`;
      }
    } else if (e.target.form[3].value.split("-")[1] === "MOTHER IN LAW") {
      if (
        getAge(e.target.form[2].value) > 74 ||
        getAge(e.target.form[2].value) < 18
      ) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be below or above 74 years`;
      }
    } else if (e.target.form[3].value.split("-")[1] === "FATHER IN LAW") {
      if (
        getAge(e.target.form[2].value) > 74 ||
        getAge(e.target.form[2].value) < 18
      ) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be below 18 or above 74 years`;
      }
    } else if (e.target.form[3].value.split("-")[1] === "EXT FAMILY MEMBER") {
      if (
        getAge(e.target.form[2].value) > 74 ||
        getAge(e.target.form[2].value) < 18
      ) {
        memberDobError = `${
          e.target.form[3].value.split("-")[1]
        } should not be below 18 or above 74 years`;
      }
    }

    if (!e.target.form[4].value) {
      // Checks if field is empty
      memberGenderError = "Please select gender";
    }

    if (
      memberFirstNameError ||
      memberLastNameError ||
      memberDobError ||
      memberRelError ||
      memberGenderError
    ) {
      this.setState({
        memberFirstNameError,
        memberLastNameError,
        memberDobError,
        memberRelError,
        memberGenderError,
      });
      return false;
    }

    const member = {
      id: this.props.id,
      surname: e.target.form[1].value,
      othernames: e.target.form[0].value,
      dob: moment(e.target.form[2].value).format("DD-MM-YYYY"),
      relationship: e.target.form[3].value,
      gender: e.target.form[4].value,
    };
    this.props.updateDependent(member);
  };

  render() {
    const { title, backdrop } = this.props;
    const rel = [
      {
        Code: 1002,
        Description: "CHILD",
        value: "1002-CHILD",
      },
      {
        Code: 1001,
        Description: "SPOUSE",
        value: "1001-SPOUSE",
      },
      {
        Code: 200510,
        Description: "MOTHER",
        value: "200510-MOTHER",
      },
      {
        Code: 20059,
        Description: "FATHER",
        value: "20059-FATHER",
      },
      {
        Code: 201632,
        Description: "MOTHER IN LAW",
        value: "201632-MOTHER IN LAW",
      },
      {
        Code: 201633,
        Description: "FATHER IN LAW",
        value: "201633-FATHER IN LAW",
      },
      {
        Code: 201423,
        Description: "EXT FAMILY MEMBER",
        value: "201423-EXT FAMILY MEMBER",
      },
    ];
    const [day, month, year] = this.props.dob.split("-");
    const newDOB = new Date(+year, month - 1, +day);
    return (
      <div>
        <div className={classes.backdrop} onClick={backdrop} />
        <Card className={classes.modal}>
          <header className={classes.header}>
            <h2>
              {title}{" "}
              <span onClick={backdrop} style={{ float: "right" }}>
                <a href="#" style={{ color: "#fff" }}>
                  <FontAwesomeIcon icon={faWindowClose} />
                </a>
              </span>
            </h2>
          </header>
          <div className={classes.content}>
            {/* <p>{props.message}</p> */}
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="memberFirstname"
                  label="Other Names"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.othernames}
                    placeholder="Enter Beneficiary Firstname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.memberFirstNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberFirstNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="memberLastname"
                  label="Last Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.surname}
                    placeholder="Enter Beneficiary Lastname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.memberLastNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberLastNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="memberdob"
                  label="Date of Birth"
                  className="mb-2"
                >
                  <Form.Control
                    type="date"
                    placeholder="Enter Member Date of Birth"
                    // onChange={handleChange("dob")}
                    defaultValue={moment(newDOB).format("YYYY-MM-DD")}
                    //moment(e.target.form[2].value).format("DD-MM-YYYY")
                    style={{
                      border: this.state.memberDobError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberDobError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="relation"
                  label="Relationship"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    disabled
                    // onChange={handleChange("occupation")}
                    // defaultValue={values.occupation}
                    style={{
                      border: this.state.memberRelError ? "1px solid red" : "",
                    }}
                  >
                    <option value={this.props.relationship}>
                      {this.props.relationship.split("-")[1]}
                    </option>
                    {rel.map((r) => (
                      <option key={r.code} value={r.value}>
                        {r.Description}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberRelError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="gender"
                  label="Gender"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    style={{
                      border: this.state.memberGenderError
                        ? "1px solid red"
                        : "",
                    }}
                  >
                    <option>{this.props.gender}</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.memberGenderError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Button onClick={this.updateDependent}>Save</Button>
            </Form>
          </div>
          {/* <footer className={classes.actions}>
            <Button onClick={this.addBeneficiary}>Add</Button>
          </footer> */}
        </Card>
      </div>
    );
  }
}

export default EditDependentModal;
