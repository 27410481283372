const BankBranchesData = [
  {
    bbrCode: 10196,
    bbrBnkCode: 1025,
    bbrBranchName: "AKROPONG MAIN",
    bbrShtDesc: "078011",
  },
  {
    bbrCode: 10197,
    bbrBnkCode: 1025,
    bbrBranchName: "ASANKRANGWA NKOSO FIE",
    bbrShtDesc: "078012",
  },
  {
    bbrCode: 10198,
    bbrBnkCode: 1025,
    bbrBranchName: "PRESTEA",
    bbrShtDesc: "078013",
  },
  {
    bbrCode: 10199,
    bbrBnkCode: 1025,
    bbrBranchName: "DUNKWA",
    bbrShtDesc: "078014",
  },
  {
    bbrCode: 10200,
    bbrBnkCode: 1025,
    bbrBranchName: "ENCHI",
    bbrShtDesc: "078015",
  },
  {
    bbrCode: 10201,
    bbrBnkCode: 1025,
    bbrBranchName: "SAMREBOI",
    bbrShtDesc: "078016",
  },
  {
    bbrCode: 10202,
    bbrBnkCode: 1025,
    bbrBranchName: "BAWDIE",
    bbrShtDesc: "078017",
  },
  {
    bbrCode: 10203,
    bbrBnkCode: 1025,
    bbrBranchName: "TAMSO",
    bbrShtDesc: "078018",
  },
  {
    bbrCode: 10204,
    bbrBnkCode: 1025,
    bbrBranchName: "MANSO",
    bbrShtDesc: "078019",
  },
  {
    bbrCode: 10205,
    bbrBnkCode: 1025,
    bbrBranchName: "AKROPONG HIGH STREET",
    bbrShtDesc: "078020",
  },
  {
    bbrCode: 10206,
    bbrBnkCode: 1025,
    bbrBranchName: "ASANKRANGWA HIGH STREET",
    bbrShtDesc: "078022",
  },
  {
    bbrCode: 10207,
    bbrBnkCode: 1025,
    bbrBranchName: "SANTASI",
    bbrShtDesc: "078023",
  },
  {
    bbrCode: 10208,
    bbrBnkCode: 1025,
    bbrBranchName: "SEFWI DWENASE",
    bbrShtDesc: "078024",
  },
  {
    bbrCode: 10209,
    bbrBnkCode: 1025,
    bbrBranchName: "ABREPO",
    bbrShtDesc: "078025",
  },
  {
    bbrCode: 10210,
    bbrBnkCode: 1025,
    bbrBranchName: "TARKWA MAIN",
    bbrShtDesc: "078026",
  },
  {
    bbrCode: 10211,
    bbrBnkCode: 1025,
    bbrBranchName: "SEFWI BEKWAI",
    bbrShtDesc: "078027",
  },
  {
    bbrCode: 10212,
    bbrBnkCode: 1025,
    bbrBranchName: "SUAME",
    bbrShtDesc: "078028",
  },
  {
    bbrCode: 10298,
    bbrBnkCode: 1025,
    bbrBranchName: "WASSA AKROPONG",
    bbrShtDesc: "078029",
  },
  {
    bbrCode: 10341,
    bbrBnkCode: 1025,
    bbrBranchName: "KEJETIA",
    bbrShtDesc: "078030",
  },
  {
    bbrCode: 10585,
    bbrBnkCode: 1025,
    bbrBranchName: "UNICOM HOUSE - ADUM",
    bbrShtDesc: "078031",
  },
];

export default BankBranchesData;
