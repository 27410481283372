import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import gcb from "../../gcb-logo.png";
import axios from "axios";
import "../Spinner.css";
import OtpInput from "react-otp-input";
import Menu from "./menu";
import NewHeader from "./header/NewHeader";

export class FormOTP extends PureComponent {
  state = {
    passcodeError: "",
    loading: false,
    otp: "",
    minutes: 5,
    seconds: 0,
    disabled: true,
  };

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentDidUpdate() {
    const { seconds, minutes } = this.state;
    if (seconds === 0) {
      if (minutes === 0) {
        this.setState({ disabled: false });
        clearInterval(this.myInterval);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  handleOTPChange = (otp) => this.setState({ otp });

  resendOTP(phone) {
    const getOTP = {
      Phone_Number: phone,
      Bank: "AHANTA BANC",
    };

    this.setState({ loading: true, passcodeError: "" });

    //Get OTP
    axios
      .post("/api/customer/resend/otp", getOTP)
      .then((response) => {
        this.setState({
          loading: false,
          otp: "",
          minutes: 5,
          seconds: 0,
          disabled: true,
        });

        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;

          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.myInterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
      })
      .catch((error) => {
        this.setState({ loading: false, otp: "" });
        console.log(error);
      });

    // this.setState({ minutes: 1, seconds: 0 });
  }

  continue = (e) => {
    e.preventDefault();
    // console.log(this.state.otp);
    // console.log(e.target.form[0].value);
    let passcodeError = "";

    this.setState({
      passcodeError,
    });

    if (!e.target.form[0].value) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (!this.state.otp) {
      // Checks if field is empty
      passcodeError = "Please enter your one time passcode";
    }

    if (passcodeError) {
      this.setState({
        passcodeError,
      });
      return false;
    }

    // const API_USER = process.env.REACT_APP_API_USER;
    // const API_KEY = process.env.REACT_APP_API_KEY;
    // const API_URL = process.env.REACT_APP_BASE_URL;

    const OTP = {
      OTP: this.state.otp,
      Phone_Number: this.props.values.smsNumber,
    };

    this.setState({ loading: true });

    //Verify OTP
    axios
      .post("/api/customer/verify/otp", OTP)
      .then((response) => {
        // console.log(response.data);

        if (response.data.Verification === true) {
          //   console.log(response.data.Verification);
          // this.props.handleChange("otpVerified", true);
          this.props.handleChangeValue("otpVerified", true);
          this.props.nextStep();
          return true;
        } else {
          //   console.log("False " + response.data.Verification);
          passcodeError = "Verification failed!";
          if (passcodeError) {
            // alert(existingPhoneError);
            this.setState({
              passcodeError,
              loading: false,
              otp: "",
            });
            return false;
          }
        }
      })
      .catch((error) => {
        this.setState({ loading: false, otp: "" });
        console.log(error);
      });
  };

  back = (e) => {
    e.preventDefault();
    if (this.props.values.existingPolicy === "Yes") {
      // this.props.handleChangeValue("existingPolicy", "");
      this.props.prevStep(1);
    } else {
      this.props.prevStep();
    }
  };

  render() {
    const { values } = this.props;
    const { minutes, seconds, disabled } = this.state;

    // Using state to keep track of what the selected occupation is selected
    // this.setState(occs);

    // Using this function to update the state of fruit
    // whenever a new option is selected from the dropdown

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-ahantaman"}></div>
            {/* <br></br> */}
            <NewHeader />
            <br></br>
            <h3>
              We've just sent your one time passcode to ********
              {values["phoneNumber"].slice(8)}.
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
              <img src={mypadie} alt="logo" style={{ width: "70px" }} />
              Nice to meet you, {values["firstName"]}! Please add your employment
              details.
            </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
                <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: this.state.passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={this.state.otp}
                    onChange={this.handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.passcodeError}
                  </div>
                </Form.Group>
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#A80404",
                    outlineColor: "#A80404",
                    borderColor: "#A80404",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Verify
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.resendOTP(values["phoneNumber"])}
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        ) : (
          <>
            {/* <br></br> */}
            {/* <img
              src={gcb}
              alt="logo"
              // style={{ width: "150px", height: "150px", padding: 10 }}
            /> */}
            <NewHeader />

            {/* <br></br> */}
            <br></br>
            <h3>
              We've just sent your one time passcode to ********
              {values["phoneNumber"].slice(8)}.
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              <h4>
                <FontAwesomeIcon icon={faUserLock} /> &nbsp;{" "}
                <u>Verify Your One Time Passcode</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <OtpInput
                    className="mt-3"
                    inputStyle={{
                      width: 50,
                      height: 50,
                      fontSize: 30,
                      color: "purple",
                      border: this.state.passcodeError ? "1px solid red" : "",
                      textAlign: "center",
                    }}
                    value={this.state.otp}
                    onChange={this.handleOTPChange}
                    numInputs={6}
                    separator={<span> --- </span>}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.passcodeError}
                  </div>
                </Form.Group>
                &nbsp;
                <div>
                  {minutes === 0 && seconds === 0 ? (
                    <h5>OTP has Expired!</h5>
                  ) : (
                    <h5>
                      Resend OTP in: {minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </h5>
                  )}
                </div>
                {/* <br></br> */}
                <Button
                  variant="secondary"
                  className="mt-2"
                  // type="submit"
                  // style={{ backgroundColor: "#ffc52a" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  className="mt-2"
                  style={{
                    backgroundColor: "#A80404",
                    outlineColor: "#A80404",
                    borderColor: "#A80404",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Verify
                </Button>{" "}
                &nbsp;
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => this.resendOTP(values["smsNumber"])}
                  disabled={disabled}
                >
                  Send again
                </button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormOTP;
