import React, { Component } from "react";
import Card from "./Card";
import Button from "./Button";
import classes from "./FormBeneficiaryModal.module.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { format } from "date-fns";
import moment from "moment";
import { getAge, phonePattern } from "../func/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

export class FormTrusteeModal extends Component {
  state = {
    trFirstNameError: "",
    trLastNameError: "",
    trnDobError: "",
    trRelError: "",
    trPhoneError: "",
    trAlreadyExistError: "",
    trGenderError: "",
  };

  addTrustee = (e) => {
    e.preventDefault();
    // console.log(e);

    if (Array.isArray(this.props.trustee) && this.props.trustee.length) {
      let trAlreadyExistError = "Only one Trustee is required.";

      if (trAlreadyExistError) {
        this.setState({
          trAlreadyExistError,
        });
        return false;
      }
      // array exists and is not empty
      // console.log("Array exists and is not empty");
      // alert("Array exists and is not empty");
    } else {
      let trFirstNameError = "";
      let trLastNameError = "";
      let trDobError = "";
      let trRelError = "";
      let trPhoneError = "";
      let trGenderError = "";

      if (!e.target.form[0].value) {
        // Checks if field is empty
        trFirstNameError = "Please enter trustee first name";
      }

      if (!e.target.form[1].value) {
        // Checks if field is empty
        trLastNameError = "Please enter trustee last name";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        trDobError = "Please enter trustee date of birth";
      }

      // Regular expression to check format YYYY-MM-DD
      const regex = /^\d{4}-\d{2}-\d{2}$/;

      if (!regex.test(e.target.form[2].value)) {
        trDobError = "Please enter a valid trustee date of birth";
      }

      if (getAge(e.target.form[2].value) < 18) {
        trDobError = "Trustee should be 18 years or above";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        trGenderError = "Please select gender";
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        trRelError = "Please select your relationship with trustee";
      }

      if (!e.target.form[5].value) {
        // Checks if field is empty
        trPhoneError = "Please enter trustee contact number";
      }

      if (phonePattern(e.target.form[5].value) === false) {
        // Checks if field is valid
        trPhoneError = "Please enter a valid phone number";
      }

      if (
        trFirstNameError ||
        trLastNameError ||
        trDobError ||
        trRelError ||
        trPhoneError ||
        trGenderError
      ) {
        this.setState({
          trFirstNameError,
          trLastNameError,
          trDobError,
          trRelError,
          trPhoneError,
          trGenderError,
        });
        return false;
      }

      const trustee = {
        id: Math.random(),
        surname: e.target.form[1].value,
        othernames: e.target.form[0].value,
        dob: moment(e.target.form[2].value).format("DD-MM-YYYY"),
        gender: e.target.form[3].value,
        relationship: e.target.form[4].value,
        phone: e.target.form[5].value,
      };

      // console.log(trustee);

      this.props.addTrust(trustee);
    }
  };

  render() {
    const { title, backdrop, trusteeRequred } = this.props;
    const rel = [
      {
        Code: 200222,
        Description: "SELF",
      },
      {
        Code: 200223,
        Description: "CHILD",
      },
      {
        Code: 200224,
        Description: "FATHER",
      },
      {
        Code: 200225,
        Description: "MOTHER",
      },
      {
        Code: 200327,
        Description: "NEPHEW",
      },
      {
        Code: 201035,
        Description: "SPOUSE",
      },
      {
        Code: 201458,
        Description: "AUNT",
      },
      {
        Code: 201459,
        Description: "BROTHER-IN LAW",
      },
      {
        Code: 201460,
        Description: "SISTER-IN-LAW",
      },
      {
        Code: 201461,
        Description: "UNCLE",
      },
      {
        Code: 201462,
        Description: "COUSIN",
      },
      {
        Code: 201463,
        Description: "DAUGHTER",
      },
      {
        Code: 201464,
        Description: "SON",
      },
      {
        Code: 201465,
        Description: "FRIEND",
      },
      {
        Code: 201466,
        Description: "GRAND DAUGHTER",
      },
      {
        Code: 201467,
        Description: "GRAND SON",
      },
      {
        Code: 201468,
        Description: "GRAND FATHER",
      },
      {
        Code: 201469,
        Description: "GRAND MOTHER",
      },
      {
        Code: 201471,
        Description: "STEP SISTER",
      },
      {
        Code: 201472,
        Description: "RELATIVE",
      },
      {
        Code: 2014122,
        Description: "DEPENDENT",
      },
      {
        Code: 2017206,
        Description: "FATHER-IN-LAW",
      },
      {
        Code: 2017204,
        Description: "DOMESTIC WORKER",
      },
      {
        Code: 2017207,
        Description: "MOTHER-IN-LAW",
      },
      {
        Code: 212,
        Description: "DATA_LOADING",
      },
      {
        Code: 241,
        Description: "BROTHER",
      },
      {
        Code: 242,
        Description: "INSTITUTION",
      },
      {
        Code: 243,
        Description: "GUARDIAN/CARETAKER",
      },
      {
        Code: 244,
        Description: "DIVORCED",
      },
      {
        Code: 231,
        Description: "STEP FATHER",
      },
      {
        Code: 232,
        Description: "OTHERS",
      },
      {
        Code: 233,
        Description: "STEP BROTHER",
      },
      {
        Code: 234,
        Description: "STEP MOTHER",
      },
      {
        Code: 235,
        Description: "BROTHER",
      },
      {
        Code: 236,
        Description: "NIECE",
      },
      {
        Code: 237,
        Description: "SISTER",
      },
      {
        Code: 238,
        Description: "FIANCE",
      },
      {
        Code: 239,
        Description: "FIANCEE",
      },
      {
        Code: 240,
        Description: "DAUGTHER",
      },
      {
        Code: 245,
        Description: "OTHER",
      },
      {
        Code: 246,
        Description: "son",
      },
      {
        Code: 2021247,
        Description: "STEP SON",
      },
      {
        Code: 2021248,
        Description: "STEP DAUGHTER",
      },
    ];
    return (
      <div>
        <div className={classes.backdrop} onClick={backdrop} />
        <Card className={classes.modal}>
          <header className={classes.header}>
            <h2>
              {title}
              <span onClick={backdrop} style={{ float: "right" }}>
                <a href="#" style={{ color: "#4a4a4a" }}>
                  <FontAwesomeIcon icon={faWindowClose} />
                </a>
              </span>
            </h2>
          </header>
          <div className={classes.content}>
            {/* <p>{props.message}</p> */}
            <Form
              style={{
                color: "purple",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  color: "red",
                }}
              >
                {this.state.trAlreadyExistError}
              </div>
              <Form.Group>
                <FloatingLabel
                  controlId="trFirstname"
                  label="Other Names"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Beneficiary Firstname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.trFirstNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.trFirstNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="trLastname"
                  label="Last Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Beneficiary Lastname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.trLastNameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.trLastNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="trDob"
                  label="Date of Birth"
                  className="mb-2"
                >
                  <Form.Control
                    type="date"
                    placeholder="Enter Beneficiary Date of Birth"
                    // onChange={handleChange("dob")}
                    // defaultValue={values.dob}
                    style={{
                      border: this.state.trDobError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.trDobError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="trGender"
                  label="Gender"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    style={{
                      border: this.state.trGenderError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.trGenderError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="trRelation"
                  label="Relationship"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("occupation")}
                    // defaultValue={values.occupation}
                    style={{
                      border: this.state.trRelError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {rel.map((r) => (
                      <option key={r.code} value={r.Description}>
                        {r.Description}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.trRelError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="trPhone"
                  label="Contact Number"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Beneficiary Contact Number"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.trPhoneError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.trPhoneError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Button onClick={this.addTrustee}>Add</Button>
            </Form>
          </div>
          {/* <footer className={classes.actions}>
            <Button onClick={this.addBeneficiary}>Add</Button>
          </footer> */}
        </Card>
      </div>
    );
  }
}

export default FormTrusteeModal;
