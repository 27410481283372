import React, { useState, useEffect } from "react";
import logo from "../StarLife_Chatbot-06.png";
import "bootstrap/dist/css/bootstrap.css";
import "./Welcome.styles.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faKey, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BankPartners from "./data/partners";
import { useNavigate } from "react-router-dom";
// import digitallogo from "../apple-icon-72x72.png";
import digitallogo from "../starlife-logo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function AgentWelcomeRegister(props) {
  const bankPartners = BankPartners;
  const [agentError, setAgentError] = useState("");
  const [agentPwdError, setAgentPwdError] = useState("");
  const [agentPwdConfirmError, setAgentPwdConfirmError] = useState("");
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState("");
  const [pwdMatchError, setPwdMatchError] = useState("");
  // const [validPassword, setValidPassword] = useState(false);
  const [validPwdMsg, setValidPwdMsg] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [agentReferralError, setAgentReferralError] = useState("");
  const [bankPartnerError, setBankPartnerError] = useState("");
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  let navigate = useNavigate();

  const validatePassword = (password) => {
    // Define regular expressions for password complexity
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
    const isLengthValid = password.length >= 8; // Password should be at least 8 characters long

    // Validate password based on conditions
    return (
      hasUpperCase &&
      hasLowerCase &&
      hasDigit &&
      hasSpecialChar &&
      isLengthValid
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  function submitHandler(e) {
    e.preventDefault();
    let agentErrorMsg = "";
    let agentPwdMsg = "";
    let agentPwdConMsg = "";
    let bankPartnerErrorMsg = "";
    let pwdMatchMsg = "";
    let validPwdMsg = "";

    setAgentError(agentErrorMsg);
    setAgentPwdError(agentPwdMsg);
    setAgentPwdConfirmError(agentPwdConMsg);
    setPwdMatchError(pwdMatchMsg);
    setValidPwdMsg(validPwdMsg);

    if (!e.target.form[0].value) {
      agentErrorMsg = "AGT Code is required";
    }

    if (!e.target.form[1].value) {
      agentPwdMsg = "Password is required";
    }

    if (!e.target.form[2].value) {
      agentPwdConMsg = "Confirm Password is required";
    }

    if (e.target.form[0].value) {
      if (e.target.form[0].value.length < 7) {
        agentErrorMsg = "Kindly provide a valid AGT Code.";
      }
    }

    if (e.target.form[1].value) {
      const updatedValidPassword = validatePassword(e.target.form[1].value);

      if (updatedValidPassword === false) {
        alert(
          "Password does not meet password requirements. Password should be 8 characters long, must contain at least one lowercase, one uppercase, one digit and one special character."
        );
        return false;
        // validPwdMsg =
        //   "Password must meet complexity requirements \n Password should be at least 8 characters long \n Password should have at least 1 Upper Case \n Password should have at least 1 Lower Case \n Password should have at least 1 number \n Password should have at least 1 Special Character";
      }
    }

    if (e.target.form[1].value && e.target.form[2].value) {
      if (e.target.form[1].value !== e.target.form[2].value) {
        pwdMatchMsg = "Password and Confirm password does not match!";
      }
    }

    // if (!e.target.form[1].value) {
    //   bankPartnerErrorMsg = "Select your banc assurance partner";
    // }

    if (
      agentErrorMsg ||
      agentPwdMsg ||
      agentPwdConMsg ||
      pwdMatchMsg ||
      validPwdMsg
    ) {
      setAgentError(agentErrorMsg);
      setAgentPwdError(agentPwdMsg);
      setAgentPwdConfirmError(agentPwdConMsg);
      setPwdMatchError(pwdMatchMsg);
      setValidPwdMsg(validPwdMsg);
      return false;
    }

    props.handleChangeValue("userName", e.target.form[0].value);
    props.handleChangeValue("password", e.target.form[1].value);

    //Verify agent
    const agent = {
      Agn_Code: e.target.form[0].value,
      Agn_Password: e.target.form[1].value,
    };

    setLoading(true);

    axios
      .post("/api/dso/verify", agent)
      .then(async (response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          setAgentName("Agent Name: " + response.data.agnName);
          agentErrorMsg = "";
          setAgentError(agentErrorMsg);
          // localStorage.setItem("dso", JSON.stringify(response.data));
          const encryptedDSO = await props.saveEncryptedData(
            "currDSO",
            response.data
          );

          let getOTP = "";

          if (response.data.bank === "") {
            getOTP = {
              Phone_Number: response.data.agnTel1,
            };
          } else {
            getOTP = {
              Phone_Number: response.data.agnTel1,
              Bank: response.data.bank,
            };
          }

          //Get OTP
          axios
            .post("/api/agent/otp", getOTP)
            .then((response) => {})
            .catch((error) => {});

          props.nextStep();
          return true;
        } else if (response.data.message_code === 104) {
          alert("User is already registered");
          setLoading(false);
        } else {
          setLoading(false);
          agentErrorMsg = "SE or DSO not identified";
          bankPartnerErrorMsg = "";

          if (agentErrorMsg) {
            setAgentError(agentErrorMsg);
            setBankPartnerError(bankPartnerErrorMsg);
            return false;
          }
        }
      })
      .catch((error) => {
        if (error.status === 409) {
          alert("User is already registered");
          setLoading(false);
        } else {
          setLoading(false);
          alert(error);
          agentErrorMsg = "SE or DSO not identified";
          bankPartnerErrorMsg = "";

          if (agentErrorMsg) {
            setAgentError(agentErrorMsg);
            setBankPartnerError(bankPartnerErrorMsg);
            return false;
          }
        }
      });
  }

  return (
    <div>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
              <u>Sign Up</u>
            </h4>{" "}
            <br />
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="agent"
                  label="AGT Code (AGTXXXXXX)"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    // onBlur={this.handleAgent}
                    // onChange={handleChange("paymentMode")}
                    // defaultValue={values.agentCode}
                    style={{
                      border: agentError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentError}
                  </div>
                </FloatingLabel>
                {/* <FloatingLabel
                  controlId="bankPartner"
                  label="Select your Banc Assurance Partner"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("bankBranch")}
                    // defaultValue={values.bankBranch}
                    style={{
                      border: bankPartnerError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {bankPartners.map((partner) => (
                      <option key={partner.id} value={partner.name}>
                        {partner.name}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {bankPartnerError}
                  </div>
                </FloatingLabel> */}
              </Form.Group>
              {/* <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button> */}
              &nbsp;
              <Button
                variant="primary"
                // type="submit"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                }}
              >
                Login <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
            </Form>
          </div>
        </>
      ) : (
        <>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          {/* <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: 400,
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faKey} style={{ color: "" }} /> &nbsp;{" "}
              <u>Sign Up</u>
            </h4>{" "}
            <br />
            <Form
              onSubmit={submitHandler}
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="agent"
                  label="AGT Code (AGTXXXXXX)"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    style={{
                      border: agentError ? "1px solid red" : "",
                    }}
                  ></Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {agentName}
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="agentpassword"
                  label="Password"
                  className="mb-2"
                >
                  <Form.Control
                    type={passwordVisible ? "text" : "password"} // Show password if visible, otherwise hide
                    // type="password"
                    autoComplete="off"
                    style={{
                      border:
                        agentPwdError || validPwdMsg ? "1px solid red" : "",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={togglePasswordVisibility}
                    tabIndex={0} // Make it focusable
                    role="button" // Add ARIA role for accessibility
                  >
                    {passwordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentPwdError || validPwdMsg}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="agentpasswordconfirm"
                  label="Confirm Password"
                  className="mb-2"
                >
                  <Form.Control
                    type={confirmPasswordVisible ? "text" : "password"}
                    autoComplete="off"
                    style={{
                      border: agentPwdError ? "1px solid red" : "",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={toggleConfirmPasswordVisibility}
                    tabIndex={0} // Make it focusable
                    role="button" // Add ARIA role for accessibility
                  >
                    {confirmPasswordVisible ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {agentPwdConfirmError}
                    {pwdMatchError}
                  </div>
                </FloatingLabel>
              </Form.Group>

              <Button
                variant="primary"
                // type="submit"
                style={{
                  backgroundColor: "#763984",
                  outlineColor: "#763984",
                  borderColor: "#763984",
                }}
                onClick={submitHandler}
              >
                Register <FontAwesomeIcon icon={faSignInAlt} />
              </Button>
            </Form>
            &nbsp;
            <div
              style={{ textAlign: "center", border: "1px", color: "purple" }}
            >
              Already have an account? <a href="/">Login</a>
            </div>
          </div>
        </>
      )}
      {/* <button
        className="link-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
      >
        Install
      </button> */}
    </div>
  );
}

export default AgentWelcomeRegister;
