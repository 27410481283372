import React, { PureComponent } from "react";
import Card from "./Card";
import Button from "./Button";
import classes from "./FormBeneficiaryModal.module.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { format } from "date-fns";
import moment from "moment";
import { getAge, phonePattern } from "../func/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

export class EditBeneficiaryModal extends PureComponent {
  state = {
    benFirstNameError: "",
    benLastNameError: "",
    benDobError: "",
    benGenderError: "",
    benRelError: "",
    benPercentageError: "",
    benPhoneError: "",
  };

  updateBeneficiary = (e) => {
    e.preventDefault();
    // console.log(e);

    let benFirstNameError = "";
    let benLastNameError = "";
    let benDobError = "";
    let benGenderError = "";
    let benRelError = "";
    let benPercentageError = "";
    let benPhoneError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      benFirstNameError = "Please enter beneficiary first name";
    }

    if (!e.target.form[1].value) {
      // Checks if field is empty
      benLastNameError = "Please enter beneficiary last name";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      benDobError = "Please enter beneficiary date of birth";
    }

    // Regular expression to check format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(e.target.form[2].value)) {
      benDobError = "Please enter a valid beneficiary date of birth";
    }

    if (getAge(e.target.form[2].value) < 18) {
      this.props.valueChange(["trusteeRequired", true]);
    } else {
      this.props.valueChange(["trusteeRequired", false]);
    }

    if (
      getAge(e.target.form[2].value) > 18 &&
      this.props.values.productCode === "3"
    ) {
      benDobError = "Beneficiary should be below 18 years";
    }

    if (!e.target.form[3].value) {
      // Checks if field is empty
      benGenderError = "Please select Gender";
    }

    if (!e.target.form[4].value) {
      // Checks if field is empty
      benRelError = "Please select your relationship with beneficiary";
    }

    if (!e.target.form[5].value) {
      // Checks if field is empty
      benPercentageError = "Please enter beneficiary percentage";
    }

    if (!e.target.form[6].value) {
      // Checks if field is empty
      benPhoneError = "Please enter beneficiary contact number";
    }

    if (phonePattern(e.target.form[6].value) === false) {
      // Checks if field is empty
      benPhoneError = "Please enter a valid phone number";
    }

    if (
      benFirstNameError ||
      benLastNameError ||
      benDobError ||
      benRelError ||
      benPercentageError ||
      benPhoneError ||
      benGenderError
    ) {
      this.setState({
        benFirstNameError,
        benLastNameError,
        benDobError,
        benRelError,
        benPercentageError,
        benPhoneError,
        benGenderError,
      });
      return false;
    }

    const ben = {
      id: this.props.id,
      surname: e.target.form[1].value,
      othernames: e.target.form[0].value,
      dob: moment(e.target.form[2].value).format("DD-MM-YYYY"),
      gender: e.target.form[3].value,
      relationship: e.target.form[4].value,
      phone: e.target.form[6].value,
      percent: +e.target.form[5].value,
    };
    this.props.updateBen(ben);
  };

  render() {
    const { title, backdrop } = this.props;
    const rel = [
      {
        Code: 200222,
        Description: "SELF",
      },
      {
        Code: 200223,
        Description: "CHILD",
      },
      {
        Code: 200224,
        Description: "FATHER",
      },
      {
        Code: 200225,
        Description: "MOTHER",
      },
      {
        Code: 200327,
        Description: "NEPHEW",
      },
      {
        Code: 201035,
        Description: "SPOUSE",
      },
      {
        Code: 201458,
        Description: "AUNT",
      },
      {
        Code: 201459,
        Description: "BROTHER-IN LAW",
      },
      {
        Code: 201460,
        Description: "SISTER-IN-LAW",
      },
      {
        Code: 201461,
        Description: "UNCLE",
      },
      {
        Code: 201462,
        Description: "COUSIN",
      },
      {
        Code: 201463,
        Description: "DAUGHTER",
      },
      {
        Code: 201464,
        Description: "SON",
      },
      {
        Code: 201465,
        Description: "FRIEND",
      },
      {
        Code: 201466,
        Description: "GRAND DAUGHTER",
      },
      {
        Code: 201467,
        Description: "GRAND SON",
      },
      {
        Code: 201468,
        Description: "GRAND FATHER",
      },
      {
        Code: 201469,
        Description: "GRAND MOTHER",
      },
      {
        Code: 201471,
        Description: "STEP SISTER",
      },
      {
        Code: 201472,
        Description: "RELATIVE",
      },
      {
        Code: 2014122,
        Description: "DEPENDENT",
      },
      {
        Code: 2017206,
        Description: "FATHER-IN-LAW",
      },
      {
        Code: 2017204,
        Description: "DOMESTIC WORKER",
      },
      {
        Code: 2017207,
        Description: "MOTHER-IN-LAW",
      },
      {
        Code: 212,
        Description: "DATA_LOADING",
      },
      {
        Code: 241,
        Description: "BROTHER",
      },
      {
        Code: 242,
        Description: "INSTITUTION",
      },
      {
        Code: 243,
        Description: "GUARDIAN/CARETAKER",
      },
      {
        Code: 244,
        Description: "DIVORCED",
      },
      {
        Code: 231,
        Description: "STEP FATHER",
      },
      {
        Code: 232,
        Description: "OTHERS",
      },
      {
        Code: 233,
        Description: "STEP BROTHER",
      },
      {
        Code: 234,
        Description: "STEP MOTHER",
      },
      {
        Code: 235,
        Description: "BROTHER",
      },
      {
        Code: 236,
        Description: "NIECE",
      },
      {
        Code: 237,
        Description: "SISTER",
      },
      {
        Code: 238,
        Description: "FIANCE",
      },
      {
        Code: 239,
        Description: "FIANCEE",
      },
      {
        Code: 240,
        Description: "DAUGTHER",
      },
      {
        Code: 245,
        Description: "OTHER",
      },
      {
        Code: 246,
        Description: "son",
      },
      {
        Code: 2021247,
        Description: "STEP SON",
      },
      {
        Code: 2021248,
        Description: "STEP DAUGHTER",
      },
    ];
    const [day, month, year] = this.props.dob.split("-");
    const newDOB = new Date(+year, month - 1, +day);
    return (
      <div>
        <div className={classes.backdrop} onClick={backdrop} />
        <Card className={classes.modal}>
          <header className={classes.header}>
            <h2>
              {title}{" "}
              <span onClick={backdrop} style={{ float: "right" }}>
                <a href="#" style={{ color: "#4a4a4a" }}>
                  <FontAwesomeIcon icon={faWindowClose} />
                </a>
              </span>
            </h2>
          </header>
          <div className={classes.content}>
            {/* <p>{props.message}</p> */}
            <Form
              style={{
                color: "purple",
              }}
            >
              <Form.Group>
                <FloatingLabel
                  controlId="benFirstname"
                  label="Other Names"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.othernames}
                    placeholder="Enter Beneficiary Firstname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.benFirstNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benFirstNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="benLastname"
                  label="Last Name"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.surname}
                    placeholder="Enter Beneficiary Lastname"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.benLastNameError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benLastNameError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="bendob"
                  label="Date of Birth"
                  className="mb-2"
                >
                  <Form.Control
                    type="date"
                    placeholder="Enter Beneficiary Date of Birth"
                    // onChange={handleChange("dob")}
                    defaultValue={moment(newDOB).format("YYYY-MM-DD")}
                    //moment(e.target.form[2].value).format("DD-MM-YYYY")
                    style={{
                      border: this.state.benDobError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benDobError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="benGender"
                  label="Gender"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    style={{
                      border: this.state.benGenderError ? "1px solid red" : "",
                    }}
                  >
                    <option>{this.props.gender}</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benGenderError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="relation"
                  label="Relationship"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    // onChange={handleChange("occupation")}
                    // defaultValue={values.occupation}
                    style={{
                      border: this.state.benRelError ? "1px solid red" : "",
                    }}
                  >
                    <option>{this.props.relationship}</option>
                    {rel.map((r) => (
                      <option key={r.code} value={r.Description}>
                        {r.Description}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benRelError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="benPercentage"
                  label="Percentage"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="number"
                    autoComplete="off"
                    defaultValue={this.props.percent}
                    placeholder="Enter Beneficiary Percentage"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.benPercentageError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benPercentageError}
                  </div>
                </FloatingLabel>
                <FloatingLabel
                  controlId="benPhone"
                  label="Contact Number"
                  className="mb-2"
                >
                  <Form.Control
                    required
                    type="text"
                    autoComplete="off"
                    defaultValue={this.props.phone}
                    placeholder="Enter Beneficiary Contact Number"
                    //   onChange={handleChange("firstName")}
                    //   defaultValue={values.firstName}
                    style={{
                      border: this.state.benPhoneError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.benPhoneError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Button onClick={this.updateBeneficiary}>Save</Button>
            </Form>
          </div>
          {/* <footer className={classes.actions}>
            <Button onClick={this.addBeneficiary}>Add</Button>
          </footer> */}
        </Card>
      </div>
    );
  }
}

export default EditBeneficiaryModal;
