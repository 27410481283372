const BanksData = [
  {
    id: 53,
    name: "Standard Chartered Bank Ghana Limited",
    slug: "standard-chartered-bank-ghana-limited",
    code: "020100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2019-10-18T11:53:29.000Z",
  },
  {
    id: 44,
    name: "GCB Bank Limited",
    slug: "gcb-bank-limited",
    code: "040100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2019-10-18T11:53:01.000Z",
  },
  {
    id: 56,
    name: "United Bank for Africa Ghana Limited",
    slug: "united-bank-for-africa-ghana-limited",
    code: "060100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2019-10-18T11:53:35.000Z",
  },
  {
    id: 32,
    name: "ADB Bank Limited",
    slug: "adb-bank-limited",
    code: "080100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2019-10-18T11:53:08.000Z",
  },
  {
    id: 57,
    name: "Universal Merchant Bank Ghana Limited",
    slug: "universal-merchant-bank-ghana-limited",
    code: "100100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 47,
    name: "HFC Bank Ghana Limited",
    slug: "hfc-bank-ghana-limited",
    code: "110100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 58,
    name: "Zenith Bank Ghana",
    slug: "zenith-bank-ghana",
    code: "120100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 38,
    name: "Ecobank Ghana Limited",
    slug: "ecobank-ghana-limited",
    code: "130100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 37,
    name: "CAL Bank Limited",
    slug: "cal-bank-limited",
    code: "140100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 42,
    name: "First Atlantic Bank Limited",
    slug: "first-atlantic-bank-limited",
    code: "170100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 50,
    name: "Prudential Bank Limited",
    slug: "prudential-bank-limited",
    code: "180100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 52,
    name: "Stanbic Bank Ghana Limited",
    slug: "stanbic-bank-ghana-limited",
    code: "190100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 46,
    name: "Guaranty Trust Bank (Ghana) Limited",
    slug: "guaranty-trust-bank-(ghana)-limited",
    code: "230100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 41,
    name: "Fidelity Bank Ghana Limited",
    slug: "fidelity-bank-ghana-limited",
    code: "240100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
  {
    id: 31,
    name: "Access Bank",
    slug: "access-bank-ghana",
    code: "280100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2020-04-17T09:00:41.000Z",
  },
  {
    id: 43,
    name: "First National Bank Ghana Limited",
    slug: "first-national-bank-ghana-limited",
    code: "330100",
    createdAt: "2018-03-29T12:54:59.000Z",
    updatedAt: "2018-03-29T12:54:59.000Z",
  },
];

export default BanksData;
