import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export class ProductDetailsPA extends Component {
  render() {
    const {
      values,
      handleChange,
      paSurnameError,
      paOthernameError,
      paDOB,
      paGender,
    } = this.props;
    return (
      <div>
        <Form.Group>
          <div>
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="pasurname"
                  label="Child Surname"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    onChange={handleChange("paSurname")}
                    defaultValue={values.paSurname}
                    style={{
                      border: paSurnameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {paSurnameError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#ffc52a",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>

            {/* PA Othernames */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="paothername"
                  label="Child Othernames"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    onChange={handleChange("paOthername")}
                    defaultValue={values.paOthername}
                    style={{
                      border: paOthernameError ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {paOthernameError}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#ffc52a",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>

            {/* PA DOB */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="padob"
                  label="Date of Birth"
                  className="mb-2"
                >
                  <Form.Control
                    type="date"
                    placeholder="Enter Your Date of Birth"
                    onChange={handleChange("padob")}
                    defaultValue={values.padob}
                    style={{
                      border: paDOB ? "1px solid red" : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {paDOB}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#ffc52a",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>

            {/* PA DOB */}
            <div
              style={{
                width: "90%",
                float: "left",
                // padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FloatingLabel
                  controlId="pagender"
                  label="Gender"
                  className="mb-3"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("pagender")}
                    defaultValue={values.pagender}
                    style={{
                      border: paGender ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {paGender}
                  </div>
                </FloatingLabel>
              </div>
            </div>
            <div
              style={{
                width: "10%",
                float: "left",
                padding: "20px",
                // border: "2px solid red",
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tip
                  data-for="termTip"
                  style={{
                    color: "#ffc52a",
                  }}
                />
                <ReactTooltip
                  id="termTip"
                  place="right"
                  effect="solid"
                  data-multiline="true"
                >
                  Policy term refers to the period for <br />
                  which your policy will remain active.
                </ReactTooltip>
              </div>
            </div>
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default ProductDetailsPA;
