import React, { PureComponent } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faEraser,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import gcb from "../../gcb-logo.png";
import { InputGroup } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import "../sigCanvas.css";
import Menu from "./menu";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import BankDetails from "./BankDetails";
import PreInstitutionDetails from "./PreInstitutionDetails";
import InstitutionDetails from "./InstitutionDetails";
// import { CameraFeed } from "../camera-feed";

export class FormPaymentDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.state = {
      payModeError: "",
      bankError: "",
      bankBranchError: "",
      accountNumberError: "",
      paymentFrequencyError: "",
      signatureTypeError: "",
      signatureError: "",
      ghanaCardError: "",
      uploadSignatureError: "",
      showBankTextBoxes: false,
      showMoMoTextBoxes: false,
      deductionDateError: "",
      showSignatureCanvas: false,
      showSignatureUpload: false,
      file: "",
      ghanacard: "",
      newImage: "",
      loading: false,
      accountName: "",
      showPreInstitution: false,
      showInstitution: false,
      staffNumberError: "",
      institutionError: "",
    };
  }

  sigRef = React.createRef({});

  componentDidMount() {
    if (this.props.values.paymentMode.split("-")[0] === "DD") {
      this.setState({
        showBank: true,
        showPreInstitution: false,
        showInstitution: false,
      });
    } else if (this.props.values.paymentMode.split("-")[0] === "C") {
      this.setState({
        showBank: false,
        showInstitution: false,
        showPreInstitution: false,
      });
    } else if (this.props.values.paymentMode.split("-")[0] === "K") {
      if (this.props.values.paymentMode.split("-")[1] === "Institution") {
        this.setState({
          showBank: false,
          showInstitution: true,
          showPreInstitution: false,
        });
      } else {
        this.setState({
          showBank: false,
          showInstitution: false,
          showPreInstitution: true,
        });
      }
    } else {
      this.setState({
        showBank: false,
        showInstitution: false,
        showPreInstitution: false,
      });
    }
  }

  clear = (e) => {
    e.preventDefault();
    this.sigRef.current.clear();
  };

  handlePayModeChange = (e) => {
    const paymentMode = e.target.value;
    if (paymentMode.split("-")[0] === "DD") {
      this.setState({
        showBank: true,
        showInstitution: false,
        showPreInstitution: false,
      });
      this.props.valueChange(["paymentMode", paymentMode]);
      this.props.valueChange(["staffNumber", ""]);
      this.props.valueChange(["institution", ""]);
      //   this.props.nextStep(5);
      // alert("Client has existing policy.");
      // return true;
    } else if (paymentMode.split("-")[0] === "C") {
      this.setState({
        showBank: false,
        showInstitution: false,
        showPreInstitution: false,
      });
      this.props.valueChange(["paymentMode", paymentMode]);
      this.props.valueChange(["accountNumber", ""]);
      this.props.valueChange(["accountName", ""]);
      this.props.valueChange(["staffNumber", ""]);
      this.props.valueChange(["bank", ""]);
      this.props.valueChange(["bankBranch", ""]);
      this.props.valueChange(["deductionDate", ""]);
      this.props.valueChange(["institution", ""]);
    } else if (paymentMode.split("-")[0] === "K") {
      if (paymentMode.split("-")[1] === "Controller & Accountant General") {
        this.props.valueChange([
          "institution",
          "2018201234805-Controller & Accountant General",
        ]);
        this.props.valueChange(["paymentMode", paymentMode]);
        this.setState({
          showBank: false,
          showInstitution: false,
          showPreInstitution: true,
        });
        this.props.valueChange(["accountNumber", ""]);
        this.props.valueChange(["accountName", ""]);
        this.props.valueChange(["bank", ""]);
        this.props.valueChange(["bankBranch", ""]);
        this.props.valueChange(["deductionDate", ""]);
      } else if (paymentMode.split("-")[1] === "Ministry of Defense") {
        this.props.valueChange([
          "institution",
          "2018201234822-Ministry of Defense",
        ]);
        this.props.valueChange(["paymentMode", paymentMode]);
        this.setState({
          showBank: false,
          showInstitution: false,
          showPreInstitution: true,
        });
        this.props.valueChange(["accountNumber", ""]);
        this.props.valueChange(["accountName", ""]);
        this.props.valueChange(["bank", ""]);
        this.props.valueChange(["bankBranch", ""]);
        this.props.valueChange(["deductionDate", ""]);
      } else if (paymentMode.split("-")[1] === "Ghana Police Service") {
        this.props.valueChange([
          "institution",
          "2018201234821-Ghana Police Service",
        ]);
        this.props.valueChange(["paymentMode", paymentMode]);
        this.setState({
          showBank: false,
          showInstitution: false,
          showPreInstitution: true,
        });
        this.props.valueChange(["accountNumber", ""]);
        this.props.valueChange(["accountName", ""]);
        this.props.valueChange(["bank", ""]);
        this.props.valueChange(["bankBranch", ""]);
        this.props.valueChange(["deductionDate", ""]);
      } else if (paymentMode.split("-")[1] === "Institution") {
        this.props.valueChange(["paymentMode", paymentMode]);
        this.setState({
          showBank: false,
          showInstitution: true,
          showPreInstitution: false,
        });
        this.props.valueChange(["accountNumber", ""]);
        this.props.valueChange(["accountName", ""]);
        this.props.valueChange(["bank", ""]);
        this.props.valueChange(["bankBranch", ""]);
        this.props.valueChange(["deductionDate", ""]);
      }
    } else {
      this.setState({
        showBank: false,
        showInstitution: false,
        showPreInstitution: false,
      });
      this.props.valueChange(["paymentMode", ""]);
      this.props.valueChange(["accountNumber", ""]);
      this.props.valueChange(["accountName", ""]);
      this.props.valueChange(["bank", ""]);
      this.props.valueChange(["bankBranch", ""]);
      this.props.valueChange(["deductionDate", ""]);
      this.props.valueChange(["institution", ""]);
      this.props.valueChange(["staffNumber", ""]);
    }
  };

  handleBankChange = (e) => {
    this.props.valueChange(["bank", e.target.value]);

    const bankID = {
      id: e.target.value.split("-")[0],
    };

    //Get bank branches
    axios
      .post("/api/bank/branches", bankID)
      .then((response) => {
        this.props.valueChange(["bankBranchList", response.data]);
      })
      .catch((error) => {});
  };

  validateAccountNumber = (e) => {
    e.preventDefault();
    let accountNumberError = "";
    let accountName = "";

    this.setState({
      accountNumberError,
      accountName,
    });

    if (e.target.value) {
      if (
        e.target.value.length >= this.props.values.bank.split("-")[2] &&
        e.target.value.length <= this.props.values.bank.split("-")[3]
      ) {
        const account = {
          Account_Number: e.target.value,
          Bank_Name: this.props.values.bank.split("-")[1],
        };
        this.setState({ loading: true });

        axios
          .post("/api/customer/account", account)
          .then((response) => {
            if (response.data.message_code === 100) {
              this.setState({ loading: false });
              this.props.valueChange([
                "accountNumber",
                response.data.data.account_number,
              ]);
              this.props.valueChange([
                "accountName",
                response.data.data.account_name,
              ]);
              this.setState({
                accountName:
                  "Name on Account: " + response.data.data.account_name,
              });
              // agentError = "";
              // this.setState({ agentError });
              // alert("Kindly verify agent name before you submit");
              // // this.props.nextStep();
              return true;
            } else if (response.data.message_code === 102) {
              this.setState({
                loading: false,
                accountNumberError: response.data.message,
                accountName: "",
              });
              this.props.valueChange(["accountNumber", ""]);

              if (accountNumberError) {
                this.setState({
                  accountNumberError,
                });
                return false;
              }
            } else {
              this.setState({
                loading: false,
                accountNumberError: "Account number verification failed",
                accountName: "",
              });
              this.props.valueChange(["accountNumber", ""]);
              if (accountNumberError) {
                this.setState({
                  accountNumberError,
                });
                return false;
              }
            }
          })
          .catch((error) => {});
      }
    }
  };

  handleRadioChange = (e) => {
    if (e.target.value === "sign") {
      this.setState({
        showSignatureCanvas: true,
        showSignatureUpload: false,
        file: "",
      });
    } else if (e.target.value === "upload") {
      this.setState({ showSignatureCanvas: false, showSignatureUpload: true });
      // this.setState({ showSignatureUpload: true });
    }
  };

  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          627,
          412,
          "JPEG",
          100,
          0,
          (uri) => {
            this.setState({ newImage: uri });
            this.props.valueChange(["ghanaCard", uri.split(",")[1]]);
          },
          "base64",
          200,
          200
        );
      } catch (err) {}
    }
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    var reader = new FileReader();
    // reader.readAsText(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.props.valueChange(["signature", reader.result.split(",")[1]]);
    };
    this.setState({ file: URL.createObjectURL(event.target.files[0]) });
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };

  onCardChange = (event) => {
    var reader = new FileReader();
    // reader.readAsText(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.props.valueChange(["ghanaCard", reader.result.split(",")[1]]);
    };
    this.setState({ ghanacard: URL.createObjectURL(event.target.files[0]) });
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };

  handleDateChange = (e) => {
    const dt = [e.target.value, 15].join("-");
    this.props.valueChange(["deductionDate", dt]);
  };

  continue = (e) => {
    e.preventDefault();

    let payModeError = "";
    let bankError = "";
    let bankBranchError = "";
    let accountNumberError = "";
    let paymentFrequencyError = "";
    let signatureError = "";
    let deductionDateError = "";
    let signatureTypeError = "";
    let ghanaCardError = "";
    let uploadSignatureError = "";
    let staffNumberError = "";
    let institutionError = "";

    this.setState({
      payModeError,
      bankError,
      bankBranchError,
      accountNumberError,
      paymentFrequencyError,
      signatureError,
      deductionDateError,
      ghanaCardError,
      staffNumberError,
      signatureTypeError,
      institutionError,
    });

    if (!e.target.form[0].value) {
      // Checks if field is empty
      payModeError = "Please select your payment method";
    }

    if (!e.target.form[0].value) {
      if (!e.target.form[1].checked & !e.target.form[2].checked) {
        // Checks if field is empty
        signatureTypeError =
          "Please indicated whether you want to sign or upload signature";
      }
    }

    if (e.target.form[0].value) {
      if (e.target.form[0].value.split("-")[0] === "DD") {
        if (!e.target.form[1].value) {
          // Checks if field is empty
          bankError = "Please select your bank";
        }

        if (!e.target.form[2].value) {
          // Checks if field is empty
          bankBranchError = "Please select your bank branch";
        }

        if (!e.target.form[3].value) {
          // Checks if field is empty
          accountNumberError = "Please enter your bank account number";
        }

        if (e.target.form[3].value) {
          if (
            !(
              e.target.form[3].value.length >=
                e.target.form[1].value.split("-")[2] &&
              e.target.form[3].value.length <=
                e.target.form[1].value.split("-")[3]
            )
          ) {
            accountNumberError = "Please enter a valid bank account number";
          }
        }

        if (!e.target.form[4].value) {
          // Checks if field is empty
          deductionDateError = "Please enter first deduction date";
        }

        if (!e.target.form[5].checked & !e.target.form[6].checked) {
          // Checks if field is empty
          signatureTypeError =
            "Please indicated whether you want to sign or upload signature";
        }

        // if (!e.target.form[7].value) {
        //   // Checks if field is empty
        //   ghanaCardError = "Kindly upload picture of your Ghana Card";
        // }

        if (e.target.form[5].checked) {
          if (this.sigRef.current.isEmpty() === true) {
            // Checks if field is empty
            signatureError = "Your signature is required";
          }

          if (this.sigRef.current.isEmpty() === false) {
            // Checks if field is empty
            this.props.valueChange([
              "signature",
              this.sigRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png")
                .split(",")[1],
            ]);
          }

          if (!e.target.form[8].value) {
            // Checks if field is empty
            ghanaCardError = "Kindly upload picture of your Ghana Card";
          }
        } else if (e.target.form[6].checked) {
          if (!e.target.form[7].value) {
            // Checks if field is empty
            uploadSignatureError = "Kindly upload your signature";
          }

          if (!e.target.form[8].value) {
            // Checks if field is empty
            ghanaCardError = "Kindly upload picture of your Ghana Card";
          }
        }
      } else if (e.target.form[0].value.split("-")[0] === "C") {
        if (!e.target.form[1].checked & !e.target.form[2].checked) {
          // Checks if field is empty
          signatureTypeError =
            "Please indicated whether you want to sign or upload signature";
        }

        if (e.target.form[1].checked) {
          if (this.sigRef.current.isEmpty() === true) {
            // Checks if field is empty
            signatureError = "Your signature is required";
          }

          if (this.sigRef.current.isEmpty() === false) {
            // Checks if field is empty
            this.props.valueChange([
              "signature",
              this.sigRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png")
                .split(",")[1],
            ]);
          }
        } else if (e.target.form[2].checked) {
          if (!e.target.form[3].value) {
            // Checks if field is empty
            uploadSignatureError = "Kindly upload your signature";
          }
        }
      } else if (e.target.form[0].value.split("-")[0] === "K") {
        if (
          e.target.form[0].value.split("-")[1] ===
            "Controller & Accountant General" ||
          e.target.form[0].value.split("-")[1] === "Ministry of Defense" ||
          e.target.form[0].value.split("-")[1] === "Ghana Policy Service"
        ) {
          if (!e.target.form[1].value) {
            // Checks if field is empty
            staffNumberError = "Please enter your Staff ID number";
          }

          if (!e.target.form[2].value) {
            // Checks if field is empty
            deductionDateError = "Please enter first deduction date";
          }

          if (!e.target.form[3].checked & !e.target.form[4].checked) {
            // Checks if field is empty
            signatureTypeError =
              "Please indicated whether you want to sign or upload signature";
          }

          // if (!e.target.form[7].value) {
          //   // Checks if field is empty
          //   ghanaCardError = "Kindly upload picture of your Ghana Card";
          // }

          if (e.target.form[3].checked) {
            if (this.sigRef.current.isEmpty() === true) {
              // Checks if field is empty
              signatureError = "Your signature is required";
            }

            if (this.sigRef.current.isEmpty() === false) {
              // Checks if field is empty
              this.props.valueChange([
                "signature",
                this.sigRef.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png")
                  .split(",")[1],
              ]);
            }

            // if (!e.target.form[5].value) {
            //   // Checks if field is empty
            //   ghanaCardError = "Kindly upload picture of your Ghana Card";
            // }
          } else if (e.target.form[4].checked) {
            if (!e.target.form[5].value) {
              // Checks if field is empty
              uploadSignatureError = "Kindly upload your signature";
            }

            // if (!e.target.form[6].value) {
            //   // Checks if field is empty
            //   ghanaCardError = "Kindly upload picture of your Ghana Card";
            // }
          }
        }

        if (e.target.form[0].value.split("-")[1] === "Institution") {
          if (!e.target.form[1].value) {
            // Checks if field is empty
            institutionError = "Please select your institution";
          }

          if (!e.target.form[2].value) {
            // Checks if field is empty
            staffNumberError = "Please enter your Staff ID number";
          }

          if (!e.target.form[3].value) {
            // Checks if field is empty
            deductionDateError = "Please enter first deduction date";
          }

          if (!e.target.form[4].checked & !e.target.form[5].checked) {
            // Checks if field is empty
            signatureTypeError =
              "Please indicated whether you want to sign or upload signature";
          }

          // if (!e.target.form[7].value) {
          //   // Checks if field is empty
          //   ghanaCardError = "Kindly upload picture of your Ghana Card";
          // }

          if (e.target.form[4].checked) {
            if (this.sigRef.current.isEmpty() === true) {
              // Checks if field is empty
              signatureError = "Your signature is required";
            }

            if (this.sigRef.current.isEmpty() === false) {
              // Checks if field is empty
              this.props.valueChange([
                "signature",
                this.sigRef.current
                  .getTrimmedCanvas()
                  .toDataURL("image/png")
                  .split(",")[1],
              ]);
            }

            // if (!e.target.form[5].value) {
            //   // Checks if field is empty
            //   ghanaCardError = "Kindly upload picture of your Ghana Card";
            // }
          } else if (e.target.form[5].checked) {
            if (!e.target.form[6].value) {
              // Checks if field is empty
              uploadSignatureError = "Kindly upload your signature";
            }

            // if (!e.target.form[6].value) {
            //   // Checks if field is empty
            //   ghanaCardError = "Kindly upload picture of your Ghana Card";
            // }
          }
        }
      }
    }

    // if (!e.target.form[3].value) {
    //   // Checks if field is empty
    //   bankError = "Please select your bank";
    // }

    // if (!e.target.form[4].value) {
    //   // Checks if field is empty
    //   bankBranchError = "Please select your bank branch";
    // }

    // if (!e.target.form[5].value) {
    //   // Checks if field is empty
    //   accountNumberError = "Please enter your bank account number";
    // }

    // if (!e.target.form[6].checked & !e.target.form[7].checked) {
    //   // Checks if field is empty
    //   paymentFrequencyError = "Please indicated your payment frequency";
    // }

    // if (this.sigRef.current.isEmpty() === true) {
    //   // Checks if field is empty
    //   signatureError = "Your signature is required";
    // }

    // if (this.sigRef.current.isEmpty() === false) {
    //   // Checks if field is empty
    //   this.props.valueChange([
    //     "signature",
    //     this.sigRef.current.getTrimmedCanvas().toDataURL("image/png"),
    //   ]);
    // }

    if (
      payModeError ||
      bankError ||
      bankBranchError ||
      accountNumberError ||
      paymentFrequencyError ||
      signatureError ||
      deductionDateError ||
      signatureTypeError ||
      uploadSignatureError ||
      ghanaCardError ||
      staffNumberError ||
      institutionError
    ) {
      this.setState({
        payModeError,
        bankError,
        bankBranchError,
        accountNumberError,
        paymentFrequencyError,
        signatureError,
        deductionDateError,
        signatureTypeError,
        uploadSignatureError,
        ghanaCardError,
        staffNumberError,
        institutionError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange, valueChange } = this.props;

    const paymentMode = [
      { id: "1", label: "Mobile Money", value: "C-Mobile Money" },
      { id: "2", label: "Bank Debit", value: "DD-Bank Debit" },
      {
        id: "3",
        label: "Controller & Accountant General",
        value: "K-Controller & Accountant General",
      },
      { id: "4", label: "Ministry of Defense", value: "K-Ministry of Defense" },
      {
        id: "5",
        label: "Ghana Police Service",
        value: "K-Ghana Police Service",
      },
      { id: "6", label: "Institution", value: "K-Institution" },
      { id: "7", label: "Cash/Cheque", value: "C-Cash/Cheque" },
    ];

    // Use Javascript
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var day = tomorrow.getDay();
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (day === 0) {
      dd = dd + 1;
    }

    if (day === 6) {
      dd = dd + 2;
    }

    var tm = yyyy + "-" + mm + "-" + dd;

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-ahantaman"}></div>
            <Menu />
            <hr />
            <br></br>
            <h2>
              Thanks{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              , Please add your payment details.
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  style={{ color: "#0f366" }}
                />{" "}
                &nbsp; <u>Payment Details</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="paymentmode"
                    label="Payment Mode"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handlePayModeChange}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue="DD"
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.payModeError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      {paymentMode.map((payMode) => (
                        <option key={payMode.id} value={payMode.value}>
                          {payMode.label}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.payModeError}
                    </div>
                  </FloatingLabel>
                  {this.state.showBank && (
                    <BankDetails
                      bankError={this.state.bankError}
                      bankBranchError={this.state.bankBranchError}
                      accountNumberError={this.state.accountNumberError}
                      valueChange={valueChange}
                      values={values}
                      handleBankChange={this.handleBankChange}
                      handleChange={handleChange}
                      deductionDateError={this.state.deductionDateError}
                      signatureError={this.state.signatureError}
                      clear={this.clear}
                      validateAccountNumber={this.validateAccountNumber}
                      accountName={this.state.accountName}
                    />
                  )}
                  {this.state.showPreInstitution && <PreInstitutionDetails />}
                  <Form.Group>
                    <div className="mb-" style={{ textAlign: "left" }}>
                      Signature
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <SignatureCanvas
                        // textAlign="left"
                        ref={this.sigRef}
                        // penColor="purple"
                        canvasProps={{
                          className: "signatureCanvas",
                          // width: 500,
                          // height: 200,
                          // className: "sigCanvas",
                        }}
                        style={{
                          border: this.state.signatureError
                            ? "1px solid red"
                            : "",
                        }}
                      />

                      <br></br>
                      <Button variant="danger" onClick={this.clear}>
                        Clear&nbsp;
                        <FontAwesomeIcon icon={faEraser} />
                      </Button>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.signatureError}
                      </div>
                    </div>
                  </Form.Group>
                </Form.Group>{" "}
                <br></br>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#0f366",
                    outlineColor: "#A80404",
                    borderColor: "#A80404",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            <Menu />
            <hr />
            <br></br>
            <h2>
              Thanks{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              , Please add your payment details.
            </h2>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  style={{ color: "#0f366" }}
                />{" "}
                &nbsp; <u>Payment Details</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  <FloatingLabel
                    controlId="paymentmode"
                    label="Payment Mode"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      onChange={this.handlePayModeChange}
                      // onChange={handleChange("paymentMode")}
                      // defaultValue="DD"
                      defaultValue={values.paymentMode}
                      style={{
                        border: this.state.payModeError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      {paymentMode.map((payMode) => (
                        <option key={payMode.id} value={payMode.value}>
                          {payMode.label}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.payModeError}
                    </div>
                  </FloatingLabel>
                  {this.state.showBank && (
                    <BankDetails
                      bankError={this.state.bankError}
                      bankBranchError={this.state.bankBranchError}
                      accountNumberError={this.state.accountNumberError}
                      valueChange={valueChange}
                      values={values}
                      handleBankChange={this.handleBankChange}
                      handleChange={handleChange}
                      deductionDateError={this.state.deductionDateError}
                      signatureError={this.state.signatureError}
                      clear={this.clear}
                      validateAccountNumber={this.validateAccountNumber}
                      accountName={this.state.accountName}
                      handleDateChange={this.handleDateChange}
                    />
                  )}
                  {this.state.showPreInstitution && (
                    <PreInstitutionDetails
                      valueChange={valueChange}
                      values={values}
                      handleChange={handleChange}
                      staffNumberError={this.state.staffNumberError}
                      deductionDateError={this.state.deductionDateError}
                      handleDateChange={this.handleDateChange}
                    />
                  )}
                  {this.state.showInstitution && (
                    <InstitutionDetails
                      values={values}
                      handleChange={handleChange}
                      staffNumberError={this.state.staffNumberError}
                      deductionDateError={this.state.deductionDateError}
                      institutionError={this.state.institutionError}
                      handleDateChange={this.handleDateChange}
                    />
                  )}
                  <Form.Group>
                    <InputGroup
                      className="mb-3"
                      onChange={this.handleRadioChange}
                    >
                      <InputGroup.Radio
                        style={{
                          border: this.state.signatureTypeError
                            ? "1px solid red"
                            : "",
                        }}
                        value="sign"
                        name="sign"
                        aria-label="Radio 1"
                        // defaultChecked={this.state.checked}
                      />{" "}
                      &nbsp; Sign Signature &nbsp; &nbsp; &nbsp; &nbsp;
                      <InputGroup.Radio
                        style={{
                          border: this.state.signatureTypeError
                            ? "1px solid red"
                            : "",
                        }}
                        value="upload"
                        name="sign"
                        aria-label="Radio 1"
                      />{" "}
                      &nbsp; Upload Signature
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.signatureTypeError}
                      </div>
                    </InputGroup>
                  </Form.Group>
                  {this.state.showSignatureCanvas && (
                    <Form.Group>
                      <div className="mb-" style={{ textAlign: "left" }}>
                        Signature
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                      >
                        <SignatureCanvas
                          // textAlign="left"
                          ref={this.sigRef}
                          // penColor="purple"
                          canvasProps={{
                            className: "signatureCanvas",
                            // width: 500,
                            // height: 200,
                            // className: "sigCanvas",
                          }}
                          style={{
                            border: this.state.signatureError
                              ? "1px solid red"
                              : "",
                          }}
                        />

                        <br></br>
                        <Button variant="danger" onClick={this.clear}>
                          Clear&nbsp;
                          <FontAwesomeIcon icon={faEraser} />
                        </Button>
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.signatureError}
                        </div>
                      </div>
                    </Form.Group>
                  )}
                  {this.state.showSignatureUpload && (
                    <Form.Group>
                      <div className="mb-" style={{ textAlign: "left" }}>
                        Upload Signature
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {this.state.file && (
                          <img src={this.state.file} width="100" height="50" />
                        )}
                      </div>

                      <Form.Control
                        accept="image/*"
                        type="file"
                        controlId="signatureUpload"
                        label="Upload Signature"
                        className="mb-2"
                        onChange={this.onFileChange}
                        style={{
                          border: this.state.uploadSignatureError
                            ? "1px solid red"
                            : "",
                        }}
                      ></Form.Control>

                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.uploadSignatureError}
                      </div>
                    </Form.Group>
                  )}
                  {this.state.showBank && (
                    <Form.Group>
                      <div className="mb-" style={{ textAlign: "left" }}>
                        Upload Ghana Card
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {this.state.newImage && (
                          <img
                            src={this.state.newImage}
                            width="200"
                            height="100"
                          />
                        )}
                      </div>
                      <Form.Control
                        accept="image/*"
                        type="file"
                        controlId="ghanacardUpload"
                        label="Upload Ghana Card"
                        className="mb-2"
                        onChange={this.fileChangedHandler}
                        style={{
                          border: this.state.ghanaCardError
                            ? "1px solid red"
                            : "",
                        }}
                      ></Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.ghanaCardError}
                      </div>
                    </Form.Group>
                  )}
                </Form.Group>{" "}
                <br></br>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#0f366",
                    outlineColor: "#A80404",
                    borderColor: "#A80404",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormPaymentDetails;
