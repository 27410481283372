const GCBProducts = [
  {
    id: 3,
    label: "Child Education Plan",
    value: "3-CLP-Child Education Plan",
  },
  { id: 20, label: "WeathMaster Plan", value: "20-WPP-WeathMaster Plan" },
  {
    id: 42,
    label: "Ultimate Protection Plan",
    value: "42-UPP-Ultimate Protection Plan",
  },
  { id: 23, label: "HomeCall Plus", value: "23-EHP-HomeCall Plus" },
];

export default GCBProducts;
