import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import mypadie from "../StarLife-Chatbot-paddie.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";

import ProductDetailsCLP from "./ProductDetailsCLP";
import ProductDetailsWPP from "./ProductDetailsWPP";

export class ProductDetails extends Component {
  state = {
    productError: "",
    termError: "",
    tpdError: "",
    retrechmentError: "",
    dreadDiseaseError: "",
    paError: "",
    productChangeCLP: false,
    productChangeWPP: false,
  };

  handleProductChange = (e) => {
    console.log(e.target.value);
    const product = e.target.value;
    if (product === "CLP") {
      this.setState({ productChangeCLP: true });
      this.setState({ productChangeWPP: false });
      this.props.valueChange(["product", product]);
    } else if (e.target.value === "WPP") {
      this.setState({ productChangeWPP: true });
      this.setState({ productChangeCLP: false });
      this.props.valueChange(["product", product]);
    }
  };

  continue = (e) => {
    e.preventDefault();
    console.log(e);
    console.log(e.target.form[0].value);
    console.log(e.target.form[1].value);
    let productError = "";
    let termError = "";
    let tpdError = "";
    let retrechmentError = "";
    let dreadDiseaseError = "";
    let paError = "";

    if (!e.target.form[0].value) {
      // Checks if field is empty
      productError = "Please select product";
    }

    if (e.target.form[0].value === "CLP") {
      if (e.target.form[1].value < 8) {
        // Checks if field is empty
        termError = "Please enter a valid policy term";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        tpdError = "Please select option";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        retrechmentError = "Please select option";
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        dreadDiseaseError = "Please select option";
      }

      if (!e.target.form[5].value) {
        // Checks if field is empty
        paError = "Please select option";
      }
    }

    // if (!e.target.form[1].value) {
    //   // Checks if field is empty
    //   termError = "Please enter policy term";
    // }

    // if (e.target.form[1].value) {
    //   if (e.target.form[1].value < 8) {
    //     // Checks if field is empty
    //     termError = "Please enter a valid policy term";
    //   }

    //   if (!e.target.form[2].value) {
    //     // Checks if field is empty
    //     tpdError = "Please select option";
    //   }
    // }

    // if (!e.target.form[2].value) {
    //   // Checks if field is empty
    //   tpdError = "Please select option";
    // }

    if (
      productError ||
      termError ||
      tpdError ||
      retrechmentError ||
      dreadDiseaseError ||
      paError
    ) {
      this.setState({
        productError,
        termError,
        tpdError,
        retrechmentError,
        dreadDiseaseError,
        paError,
      });
      return false;
    }
    this.props.nextStep();
    return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange, valueChange } = this.props;

    const products = [
      { id: "1", label: "CHILD LIFELINE PLUS", value: "CLP" },
      { id: "2", label: "WEALTH MASTER PLUS", value: "WPP" },
    ];

    return (
      <div>
        <h2>
          <img src={mypadie} alt="logo" style={{ width: "70px" }} />
          Which of our products are you interested in,{" "}
          {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
            values["firstName"].toLowerCase().slice(1)}
          ?
        </h2>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 500,
            padding: 10,
          }}
        >
          <Form
            style={{
              color: "purple",
            }}
          >
            <Form.Group>
              <Form.Group>
                <FloatingLabel
                  controlId="products"
                  label="Select Product"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={this.handleProductChange}
                    // defaultValue={values.product}
                    style={{
                      border: this.state.productError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {products.map((product) => (
                      <option key={product.id} value={product.value}>
                        {product.label}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.productError}
                  </div>
                </FloatingLabel>
                {this.state.productChangeCLP && (
                  <ProductDetailsCLP
                    termError={this.state.termError}
                    tpdError={this.state.tpdError}
                    retrechmentError={this.state.retrechmentError}
                    dreadDiseaseError={this.state.dreadDiseaseError}
                    paError={this.state.paError}
                    handleChange={handleChange}
                    valueChange={valueChange}
                    values={values}
                  />
                )}
                {this.state.productChangeWPP && <ProductDetailsWPP />}
              </Form.Group>
            </Form.Group>
            <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button>
            &nbsp;
            <Button
              variant="primary"
              // type="submit"
              style={{
                backgroundColor: "#763984",
                borderColor: "#763984",
                outlineColor: "#763984",
              }}
              onClick={this.continue}
            >
              Continue
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default ProductDetails;
