import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserLock,
  faCheckCircle,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import mypadie from "../StarLife-Chatbot-paddie.png";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "./Spinner.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { min } from "date-fns";
import AuthContext from "../store/auth-context";
import digitallogo from "../starlife-logo.png";

function AgentFormTC(props) {
  const [accept, setAccept] = useState(false);
  const [loading, setLoading] = useState(false);

  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();

  function handleCheckboxChange(e) {
    // this.setState({ isChecked: e.target.checked }); // Update isChecked state when checkbox is changed
    setAccept(e.target.checked);
  }

  function acceptTerms(e) {
    e.preventDefault();

    setLoading(true);

    const User = {
      Username: props.values.userName,
      Password: props.values.password,
      acceptTerms: accept,
    };

    axios
      .post("/api/dso/register", User)
      .then(async (response) => {
        if (response.data.message_code === 100) {
          setLoading(false);
          const expirationTime = new Date(new Date().getTime() + 3600 * 24000);

          authCtx.dsoLogin(
            await props.getDecryptedData("currDSO")["token"],
            await props.getDecryptedData("currDSO")["agnCode"],
            expirationTime.toISOString()
          );

          const partner = await props.getDecryptedData("currDSO")["partner"];

          if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "GCB BANCASSURANCE"
          ) {
            // this.history.replace("/bancassurance/gcb");
            navigate("/bancassurance/gcb", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "ACCESS BANCASSURANCE"
          ) {
            navigate("/bancassurance/access", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "AMENFIMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/amenfiman", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "AHANTAMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/ahantaman", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "ATWIMA KWANWOMA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/atwimakwanwoma", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "OKOMFO ANOKYE RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/okomfoanokye", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "GA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/garural", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "ABOKOBI RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/abokobi", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "YAA ASANTEWAA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/yaaasantewaa", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "AMANSIE WEST RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/amansie", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "ATWIMA MPONUA RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/atwimamponua", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "FIASEMAN RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/fiaseman", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "ODOTOBRI RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/odotobri", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "SOUTH AKIM RURAL BANCASSURANCE"
          ) {
            navigate("/bancassurance/southakim", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "GCB BANCASSURANCE TELESALES"
          ) {
            navigate("/bancassurance/gcb/telesales", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "BSIC BANCASSURANCE"
          ) {
            navigate("/bancassurance/omnibsic", {
              replace: true,
            });
          } else if (
            (await props.getDecryptedData("currDSO")["partner"]) ===
            "ACCESS BANCASSURANCE STAFF"
          ) {
            navigate("/bancassurance/access", {
              replace: true,
            });
          } else {
            navigate("/retail/starlife", {
              replace: true,
            });
          }
          return true;
        } else {
          setLoading(false);
          alert("Error Occurred, Please Try Again Later");
          return false;
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("Error Occurred, Please Try Again Later");
        return false;
      });
  }

  return (
    <div>
      {loading ? (
        <>
          <div className={"cover-spin-retail"}></div>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          {/* <br /> <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: "70%",
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faFileContract} /> &nbsp;{" "}
              <u>Terms and Conditions</u>
            </h4>
            <iframe
              title="terms"
              src="https://slac-api.starlifecentral.com/StarLife/Terms_and_Conditions#zoom=110"
              width="100%"
              height="500px"
            />
            <Form.Check
              style={{ textAlign: "left", color: "purple" }}
              type="checkbox"
              label="By signing up you agree to our Terms and Conditions"
              checked={accept} // Set checkbox status based on isChecked state
              onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
            />
            {/* Disable button if checkbox is not checked */}
            &nbsp;
            <Button
              variant="primary"
              className="mt-2"
              style={{
                backgroundColor: "#763984",
                borderColor: "#763984",
                outlineColor: "#763984",
              }}
              onClick={acceptTerms}
              disabled={!accept}
            >
              Create Account <FontAwesomeIcon icon={faCheckCircle} />
            </Button>{" "}
          </div>
        </>
      ) : (
        <>
          <img
            src={digitallogo}
            alt="logo"
            // style={{
            //   width: "100%",
            // }}
          />
          {/* <br /> <br />
          <h1>
            <b>Digital Onboarding</b>
          </h1> */}
          <hr />
          <div
            className="container center_div"
            style={{
              display: "block",
              width: "70%",
              padding: 10,
            }}
          >
            <h4>
              <FontAwesomeIcon icon={faFileContract} /> &nbsp;{" "}
              <u>Terms and Conditions</u>
            </h4>
            <iframe
              title="terms"
              src="https://slac-api.starlifecentral.com/StarLife/Terms_and_Conditions#zoom=110"
              width="100%"
              height="500px"
            />
            <Form.Check
              style={{ textAlign: "left", color: "purple" }}
              type="checkbox"
              label="By signing up you agree to our Terms and Conditions"
              checked={accept} // Set checkbox status based on isChecked state
              onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
            />
            {/* Disable button if checkbox is not checked */}
            &nbsp;
            <Button
              variant="primary"
              className="mt-2"
              style={{
                backgroundColor: "#763984",
                borderColor: "#763984",
                outlineColor: "#763984",
              }}
              onClick={acceptTerms}
              disabled={!accept}
            >
              Create Account <FontAwesomeIcon icon={faCheckCircle} />
            </Button>{" "}
          </div>
        </>
      )}
    </div>
  );
}

export default AgentFormTC;
