import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faEraser,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import gcb from "../../gcb-logo.png";
import { InputGroup } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import "../sigCanvas.css";
import BankBranchesData from "./data/BankBranchesData";
import NewHeader from "./header/NewHeaderAtwima";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import BottomButton from "./SaveandExit";
// import { CameraFeed } from "../camera-feed";
import moment from "moment";

export class FormPaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.fileChangedHandlerBack = this.fileChangedHandlerBack.bind(this);
    this.state = {
      payModeError: "",
      bankError: "",
      bankBranchError: "",
      accountNumberError: "",
      paymentFrequencyError: "",
      signatureTypeError: "",
      signatureError: "",
      ghanaCardError: "",
      ghanaCardBackError: "",
      uploadSignatureError: "",
      showBankTextBoxes: false,
      showMoMoTextBoxes: false,
      deductionDateError: "",
      showSignatureCanvas: false,
      showSignatureUpload: false,
      file: "",
      ghanacard: "",
      newImage: "",
      backImage: "",
      loading: false,
      accountName: "",
      selectedMonth: "",
      options: [],
    };
  }

  componentDidMount() {
    this.populateOptions();
  }

  // Function to get month name from month index
  getMonthName(monthIndex) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  }

  // populateOptions() {
  //   // Get the current date
  //   const currentDate = new Date();

  //   // Array to store options for dropdown
  //   const options = [];

  //   // Populate options array with current month and next three months
  //   for (let i = 0; i < 4; i++) {
  //     const monthIndex = (currentDate.getMonth() + i) % 12;
  //     const year =
  //       currentDate.getFullYear() +
  //       Math.floor((currentDate.getMonth() + i) / 12);
  //     const monthName = this.getMonthName(monthIndex);
  //     options.push(`${monthName} ${year}`);
  //   }

  //   this.setState({ options });

  //   console.log("options", this.state.options);
  // }

  populateOptions() {
    // Get the current date
    const currentDate = new Date();

    console.log("current date", currentDate);

    // Get the day of the current month
    const currentDayOfMonth = currentDate.getDate();

    // Array to store options for dropdown
    const options = [];

    // Check if the day of the current month is below 15
    if (currentDayOfMonth < 15) {
      // Populate options array with current month and next three months
      for (let i = 0; i < 4; i++) {
        const monthIndex = (currentDate.getMonth() + i) % 12;
        const year =
          currentDate.getFullYear() +
          Math.floor((currentDate.getMonth() + i) / 12);
        const monthName = this.getMonthName(monthIndex);
        options.push(`${monthName} ${year}`);
      }
    } else {
      // Populate options array with next four months
      for (let i = 1; i <= 4; i++) {
        const monthIndex = (currentDate.getMonth() + i) % 12;
        const year =
          currentDate.getFullYear() +
          Math.floor((currentDate.getMonth() + i) / 12);
        const monthName = this.getMonthName(monthIndex);
        options.push(`${monthName} ${year}`);
      }
    }

    this.setState({ options });

    console.log("options", this.state.options);
  }

  // state = {
  //   payModeError: "",
  //   bankError: "",
  //   bankBranchError: "",
  //   accountNumberError: "",
  //   paymentFrequencyError: "",
  //   signatureTypeError: "",
  //   signatureError: "",
  //   ghanaCardError: "",
  //   uploadSignatureError: "",
  //   showBankTextBoxes: false,
  //   showMoMoTextBoxes: false,
  //   deductionDateError: "",
  //   showSignatureCanvas: false,
  //   showSignatureUpload: false,
  //   file: "",
  //   ghanacard: "",
  //   newImage: "",
  // };

  sigRef = React.createRef({});

  clear = (e) => {
    e.preventDefault();
    // console.log(this.sigRef.current.getTrimmedCanvas().toDataURL("image/png"));
    this.sigRef.current.clear();
  };

  handlePayModeChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "DD") {
      this.setState({ showBankTextBoxes: true });
      this.setState({ showMoMoTextBoxes: false });
      // this.handleChange("paymentMode");
      const paymentMode = e.target.value;
      this.props.valueChange(["paymentMode", paymentMode]);
      //   this.props.nextStep(5);
      // alert("Client has existing policy.");
      // return true;
    } else {
      this.setState({ showBankTextBoxes: false });
      this.setState({ showMoMoTextBoxes: true });
      // this.handleChange("paymentMode");
      const paymentMode = e.target.value;
      this.props.valueChange(["paymentMode", paymentMode]);
    }
  };

  validateAccountNumber = (e) => {
    e.preventDefault();
    let accountNumberError = "";
    let accountName = "";

    this.setState({
      accountNumberError,
      accountName,
    });

    if (e.target.value) {
      if (e.target.value.length === 16) {
        const account = {
          Account_Number: e.target.value,
          Bank_Name: "GCB-BANCASSURANCE",
        };
        this.setState({ loading: true });
        console.log(account);
        axios
          .post("/api/customer/account", account)
          .then((response) => {
            console.log(response);
            if (response.data.message_code === 100) {
              this.setState({ loading: false });
              console.log(response.data);
              console.log(response.data.data.account_number);
              this.props.valueChange([
                "accountNumber",
                response.data.data.account_number,
              ]);
              this.props.valueChange([
                "accountName",
                response.data.data.account_name,
              ]);
              this.setState({
                accountName:
                  "Name on Account: " + response.data.data.account_name,
              });
              // agentError = "";
              // this.setState({ agentError });
              // alert("Kindly verify agent name before you submit");
              // // this.props.nextStep();
              return true;
            } else if (response.data.message_code === 102) {
              this.setState({
                loading: false,
                accountNumberError: response.data.message,
                accountName: "",
              });
              this.props.valueChange(["accountNumber", ""]);
              // this.props.valueChange(["agentCode", null]);
              // this.props.valueChange(["agnCode", null]);
              // this.props.valueChange(["agentName", null]);
              // console.log(agentError);

              if (accountNumberError) {
                this.setState({
                  accountNumberError,
                });
                return false;
              }
            } else {
              this.setState({
                loading: false,
                accountNumberError: "Account number verification failed",
                accountName: "",
              });
              this.props.valueChange(["accountNumber", ""]);
              if (accountNumberError) {
                this.setState({
                  accountNumberError,
                });
                return false;
              }
            }
          })
          .catch((error) => {
            // console.log(error);
            // this.setState({ loading: false });
            // agentError = "An Error occured.";
            // // console.log(error);
            // if (agentError) {
            //   this.setState({
            //     agentError,
            //   });
            //   return false;
            // }
          });
      }
    }
  };

  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          627,
          412,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
            this.props.valueChange(["ghanaCard", uri.split(",")[1]]);
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  fileChangedHandlerBack(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          627,
          412,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ backImage: uri });
            this.props.valueChange(["ghanaCardBack", uri.split(",")[1]]);
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    console.log(event);
    console.log(event.target.files[0]);
    var reader = new FileReader();
    // reader.readAsText(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.props.valueChange(["signature", reader.result.split(",")[1]]);
      console.log(reader.result.split(",")[1]); //base64encoded string
    };
    this.setState({ file: URL.createObjectURL(event.target.files[0]) });
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };

  onCardChange = (event) => {
    console.log(event);
    console.log(event.target.files[0]);
    var reader = new FileReader();
    // reader.readAsText(event.target.files[0]);
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.props.valueChange(["ghanaCard", reader.result.split(",")[1]]);
      console.log(reader.result.split(",")[1]); //base64encoded string
    };
    this.setState({ ghanacard: URL.createObjectURL(event.target.files[0]) });
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
  };

  handleRadioChange = (e) => {
    if (e.target.form[5].checked) {
      this.setState({
        showSignatureCanvas: true,
        showSignatureUpload: false,
        file: "",
      });
      // this.setState({ showSignatureUpload: false });
      // this.setState({ file: "" });
    } else {
      this.setState({ showSignatureCanvas: false, showSignatureUpload: true });
      // this.setState({ showSignatureUpload: true });
    }
  };

  handleDateChange = (e) => {
    console.log(e.target.value);
    const [month, year] = e.target.value.split(" ");
    // Create a Date object with the month and year
    const dateObj = new Date(`${month} 15, ${year}`);
    const newDate = moment(dateObj).format("YYYY-MM-DD");
    console.log("new date", newDate);
    this.props.valueChange(["deductionDate", newDate]);
  };

  continue = (e) => {
    e.preventDefault();
    console.log(e);
    // console.log(
    //   this.sigRef.current
    //     .getTrimmedCanvas()
    //     .toDataURL("image/png")
    //     .split(",")[1]
    // );
    // console.log(this.sigRef.current.isEmpty());
    let payModeError = "";
    let bankError = "";
    let bankBranchError = "";
    let accountNumberError = "";
    let paymentFrequencyError = "";
    let signatureError = "";
    let deductionDateError = "";
    let signatureTypeError = "";
    let ghanaCardError = "";
    let uploadSignatureError = "";
    let ghanaCardBackError = "";

    // if (!e.target.form[0].checked & !e.target.form[1].checked) {
    //   // Checks if field is empty
    //   paymentFrequencyError = "Please indicated your payment frequency";
    // }

    if (!e.target.form[0].value) {
      // Checks if field is empty
      payModeError = "Please select your payment method";
    }

    if (e.target.form[0].value === "DD") {
      if (!e.target.form[1].value) {
        // Checks if field is empty
        bankError = "Please select your bank";
      }

      if (!e.target.form[2].value) {
        // Checks if field is empty
        bankBranchError = "Please select your bank branch";
      }

      if (!e.target.form[3].value) {
        // Checks if field is empty
        accountNumberError = "Please enter your bank account number";
      }

      if (e.target.form[3].value) {
        if (e.target.form[3].value.length !== 16) {
          accountNumberError = "Please enter a valid bank account number";
        }
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        deductionDateError = "Please select month of first deduction";
      }

      if (!e.target.form[5].checked & !e.target.form[6].checked) {
        // Checks if field is empty
        signatureTypeError =
          "Please indicated whether you want to sign or upload signature";
      }

      if (e.target.form[5].checked) {
        if (this.sigRef.current.isEmpty() === true) {
          // Checks if field is empty
          signatureError = "Signature is required";
        }

        if (this.sigRef.current.isEmpty() === false) {
          // Checks if field is empty
          this.props.valueChange([
            "signature",
            this.sigRef.current
              .getTrimmedCanvas()
              .toDataURL("image/png")
              .split(",")[1],
          ]);
        }

        if (!e.target.form[8].value) {
          // Checks if field is empty
          ghanaCardError = "Kindly upload front image of your Ghana Card";
        }
        if (!e.target.form[9].value) {
          // Checks if field is empty
          ghanaCardBackError = "Kindly upload back image of your Ghana Card";
        }
      } else if (e.target.form[6].checked) {
        if (!e.target.form[7].value) {
          // Checks if field is empty
          uploadSignatureError = "Kindly upload your signature";
        }

        // if (e.target.form[7].value) {
        //   console.log(e.target.form[7].value);
        //   console.log(e.target.files);
        //   var reader = new FileReader();
        //   // reader.readAsText(e.target.form[7].value);
        //   reader.readAsDataURL(e.target.form[7].value);
        //   reader.onload = () => {
        //     console.log(reader.result); //base64encoded string
        //   };
        // }

        if (!e.target.form[8].value) {
          // Checks if field is empty
          ghanaCardError = "Kindly upload front image of your Ghana Card";
        }

        if (!e.target.form[9].value) {
          // Checks if field is empty
          ghanaCardBackError = "Kindly upload back image of your Ghana Card";
        }
      } else {
        if (!e.target.form[7].value) {
          // Checks if field is empty
          ghanaCardError = "Kindly upload front image of your Ghana Card";
          console.log("here");
        }

        if (!e.target.form[8].value) {
          // Checks if field is empty
          ghanaCardBackError = "Kindly upload back image of your Ghana Card";
          console.log("here");
        }
      }
    }

    // if (!e.target.form[3].value) {
    //   // Checks if field is empty
    //   bankError = "Please select your bank";
    // }

    // if (!e.target.form[4].value) {
    //   // Checks if field is empty
    //   bankBranchError = "Please select your bank branch";
    // }

    // if (!e.target.form[5].value) {
    //   // Checks if field is empty
    //   accountNumberError = "Please enter your bank account number";
    // }

    // if (!e.target.form[6].checked & !e.target.form[7].checked) {
    //   // Checks if field is empty
    //   paymentFrequencyError = "Please indicated your payment frequency";
    // }

    // if (this.sigRef.current.isEmpty() === true) {
    //   // Checks if field is empty
    //   signatureError = "Your signature is required";
    // }

    // if (this.sigRef.current.isEmpty() === false) {
    //   // Checks if field is empty
    //   this.props.valueChange([
    //     "signature",
    //     this.sigRef.current.getTrimmedCanvas().toDataURL("image/png"),
    //   ]);
    // }

    if (
      payModeError ||
      bankError ||
      bankBranchError ||
      accountNumberError ||
      paymentFrequencyError ||
      signatureError ||
      deductionDateError ||
      signatureTypeError ||
      ghanaCardError ||
      uploadSignatureError ||
      ghanaCardBackError
    ) {
      this.setState({
        payModeError,
        bankError,
        bankBranchError,
        accountNumberError,
        paymentFrequencyError,
        signatureError,
        deductionDateError,
        signatureTypeError,
        ghanaCardError,
        uploadSignatureError,
        ghanaCardBackError,
      });
      return false;
    }

    if (this.props.getDecryptedData("currDSO") !== null) {
      this.props.nextStep(10);
      return true;
    } else {
      this.props.nextStep();
      return true;
    }
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;
    const { selectedMonth, options } = this.state;

    console.log("Options under render", options);

    const paymentMode = [
      { id: "1", label: "MoMo", value: "C" },
      { id: "2", label: "Bank", value: "DD" },
    ];

    const bankBranches = BankBranchesData;

    const banks = [
      {
        id: "1029",
        label: "ATWIMA KWANWOMA RURAL BANCASSURANCE",
        value: "1029",
      },
      { id: "2", label: "Access", value: "Access" },
      { id: "3", label: "Fidelity", value: "Fidelity" },
    ];

    // Use Javascript
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var day = tomorrow.getDay();
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (day === 0) {
      dd = dd + 1;
    }

    if (day === 6) {
      dd = dd + 2;
    }

    var tm = yyyy + "-" + mm + "-" + dd;

    console.log("Today " + today.getDay());
    console.log("Tooorrow " + tomorrow.getDay());
    console.log(day);

    // Using state to keep track of what the selected occupation is selected
    // this.setState(occs);

    // Using this function to update the state of fruit
    // whenever a new option is selected from the dropdown

    return (
      <div>
        {this.state.loading ? (
          <>
            <div className={"cover-spin-atwima"}></div>
            {/* <br></br> */}
            <NewHeader />

            <br></br>
            <h3>
              Thanks{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              , Please add your payment details.
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon icon={faMoneyBill} /> &nbsp;{" "}
                <u>Payment Details</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  {/* <Form.Group>
                <div className="mb-3" style={{ textAlign: "left" }}>
                  Payment Frequency
                </div>
                <InputGroup
                  className="mb-3"
                  onChange={handleChange("paymentFrequency")}
                  defaultValue={values.paymentFrequency}
                >
                  <InputGroup.Radio
                    style={{
                      border: this.state.paymentFrequencyError
                        ? "1px solid red"
                        : "",
                    }}
                    value="M"
                    name="paymentFrequency"
                    aria-label="Radio 1"
                  />{" "}
                  &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <InputGroup.Radio
                    style={{
                      border: this.state.paymentFrequencyError
                        ? "1px solid red"
                        : "",
                    }}
                    value="A"
                    name="paymentFrequency"
                    aria-label="Radio 1"
                  />{" "}
                  &nbsp; Annually
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.paymentFrequencyError}
                  </div>
                </InputGroup>
              </Form.Group> */}
                  <FloatingLabel
                    controlId="paymentmode"
                    label="Payment Mode"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      // onChange={this.handlePayModeChange}
                      onChange={handleChange("paymentMode")}
                      defaultValue="DD"
                      disabled
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.payModeError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      {paymentMode.map((payMode) => (
                        <option key={payMode.id} value={payMode.value}>
                          {payMode.label}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.payModeError}
                    </div>
                  </FloatingLabel>

                  <Form.Group>
                    <FloatingLabel
                      controlId="bank"
                      label="Bank Name"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        onChange={handleChange("bank")}
                        defaultValue="1029-ATWIMA KWANWOMA RURAL BANCASSURANCE"
                        disabled
                        style={{
                          border: this.state.bankError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        {banks.map((bank) => (
                          <option
                            key={bank.id}
                            value={bank.value + "-" + bank.label}
                          >
                            {bank.label}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group>
                    <FloatingLabel
                      controlId="bankBranch"
                      label="Bank Branch"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        onChange={handleChange("bankBranch")}
                        defaultValue={values.bankBranch}
                        style={{
                          border: this.state.bankBranchError
                            ? "1px solid red"
                            : "",
                        }}
                      >
                        <option></option>
                        {bankBranches.map((branch) => (
                          <option
                            key={branch.bbrCode}
                            value={branch.bbrCode + "-" + branch.bbrBranchName}
                          >
                            {branch.bbrBranchName}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankBranchError}
                      </div>
                    </FloatingLabel>
                    <Form.Group
                      style={{
                        color: "purple",
                      }}
                    >
                      <FloatingLabel
                        controlId="accountno"
                        label="Account Number"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          placeholder="Enter Your Account Number"
                          // onChange={this.validateAccountNumber}
                          onChange={handleChange("accountNumber")}
                          defaultValue={values.accountNumber}
                          style={{
                            border: this.state.accountNumberError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.accountNumberError}
                        </div>
                      </FloatingLabel>
                    </Form.Group>
                    <FloatingLabel
                      controlId="deductionDate"
                      label="Date of First Deduction"
                      className="mb-2"
                    >
                      <Form.Control
                        type="date"
                        min={tm}
                        // placeholder="Enter Beneficiary Date of Birth"
                        // defaultValue={moment(newDOB).format("YYYY-MM-DD")}
                        onChange={handleChange("deductionDate")}
                        defaultValue={values.deductionDate}
                        style={{
                          border: this.state.deductionDateError
                            ? "1px solid red"
                            : "",
                        }}
                      />
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.deductionDateError}
                      </div>
                    </FloatingLabel>
                    {/* <Form.Group>
                      <div className="mb-3" style={{ textAlign: "left" }}>
                        Payment Frequency
                      </div>
                      <InputGroup
                        className="mb-3"
                        onChange={handleChange("paymentFrequency")}
                        defaultValue={values.paymentFrequency}
                      >
                        <InputGroup.Radio
                          style={{
                            border: this.state.paymentFrequencyError
                              ? "1px solid red"
                              : "",
                          }}
                          value="M"
                          name="paymentFrequency"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <InputGroup.Radio
                          style={{
                            border: this.state.paymentFrequencyError
                              ? "1px solid red"
                              : "",
                          }}
                          value="A"
                          name="paymentFrequency"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Annually
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.paymentFrequencyError}
                        </div>
                      </InputGroup>
                    </Form.Group> */}

                    <Form.Group>
                      <InputGroup
                        className="mb-3"
                        onChange={this.handleRadioChange}
                      >
                        <InputGroup.Radio
                          style={{
                            border: this.state.signatureTypeError
                              ? "1px solid red"
                              : "",
                          }}
                          value="Yes"
                          name="agentreferral"
                          aria-label="Radio 1"
                          // defaultChecked={this.state.checked}
                        />{" "}
                        &nbsp; Sign Signature &nbsp; &nbsp; &nbsp; &nbsp;
                        <InputGroup.Radio
                          style={{
                            border: this.state.signatureTypeError
                              ? "1px solid red"
                              : "",
                          }}
                          value="No"
                          name="agentreferral"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Upload Signature
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.signatureTypeError}
                        </div>
                      </InputGroup>
                    </Form.Group>
                    {this.state.showSignatureCanvas && (
                      <Form.Group>
                        {/* <FloatingLabel
                        controlId="signature"
                        label="Signature"
                        className="mb-2"
                      > */}
                        <div className="mb-" style={{ textAlign: "left" }}>
                          Signature
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <SignatureCanvas
                            // textAlign="left"
                            ref={this.sigRef}
                            // penColor="purple"
                            canvasProps={{
                              className: "signatureCanvas",
                              // width: 500,
                              // height: 200,
                              // className: "sigCanvas",
                            }}
                            style={{
                              border: this.state.signatureError
                                ? "1px solid red"
                                : "",
                            }}
                          />
                          {/* <CameraFeed /> */}
                          <br></br>
                          <Button variant="danger" onClick={this.clear}>
                            Clear&nbsp;
                            <FontAwesomeIcon icon={faEraser} />
                          </Button>
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                            }}
                          >
                            {this.state.signatureError}
                          </div>
                        </div>

                        {/* </FloatingLabel> */}
                      </Form.Group>
                    )}
                    {this.state.showSignatureUpload && (
                      <Form.Group>
                        <div className="mb-" style={{ textAlign: "left" }}>
                          Upload Signature
                        </div>
                        <div style={{ textAlign: "left" }}>
                          {this.state.file && (
                            <img
                              src={this.state.file}
                              width="100"
                              height="50"
                            />
                          )}
                        </div>

                        <Form.Control
                          accept="image/*"
                          type="file"
                          controlId="signatureUpload"
                          label="Upload Signature"
                          className="mb-2"
                          onChange={this.onFileChange}
                          style={{
                            border: this.state.uploadSignatureError
                              ? "1px solid red"
                              : "",
                          }}
                        ></Form.Control>

                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.uploadSignatureError}
                        </div>
                      </Form.Group>
                    )}

                    <Form.Group>
                      <div className="mb-" style={{ textAlign: "left" }}>
                        Upload Ghana Card
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {this.state.newImage && (
                          <img
                            src={this.state.newImage}
                            width="200"
                            height="100"
                          />
                        )}
                      </div>
                      <Form.Control
                        accept="image/*"
                        type="file"
                        controlId="ghanacardUpload"
                        label="Upload Ghana Card"
                        className="mb-2"
                        onChange={this.fileChangedHandler}
                        style={{
                          border: this.state.ghanaCardError
                            ? "1px solid red"
                            : "",
                        }}
                      ></Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.ghanaCardError}
                      </div>
                    </Form.Group>
                  </Form.Group>
                </Form.Group>{" "}
                {/* <button onClick={this.initializeMedia}>Take Photo</button> */}
                <br></br>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#296F24",
                    outlineColor: "#191c4a",
                    borderColor: "#191c4a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
            </div>
          </>
        ) : (
          <>
            {/* <br></br> */}
            <NewHeader />

            <br></br>
            <h3>
              Thanks{" "}
              {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
                values["firstName"].toLowerCase().slice(1)}
              , Please add your payment details.
            </h3>
            <div
              className="container center_div"
              style={{
                display: "block",
                width: 500,
                padding: 10,
              }}
            >
              {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
              {/* <br></br> */}
              <h4>
                <FontAwesomeIcon icon={faMoneyBill} /> &nbsp;{" "}
                <u>Payment Details</u>
              </h4>
              <Form
                style={{
                  color: "purple",
                }}
              >
                <Form.Group>
                  {/* <Form.Group>
                <div className="mb-3" style={{ textAlign: "left" }}>
                  Payment Frequency
                </div>
                <InputGroup
                  className="mb-3"
                  onChange={handleChange("paymentFrequency")}
                  defaultValue={values.paymentFrequency}
                >
                  <InputGroup.Radio
                    style={{
                      border: this.state.paymentFrequencyError
                        ? "1px solid red"
                        : "",
                    }}
                    value="M"
                    name="paymentFrequency"
                    aria-label="Radio 1"
                  />{" "}
                  &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <InputGroup.Radio
                    style={{
                      border: this.state.paymentFrequencyError
                        ? "1px solid red"
                        : "",
                    }}
                    value="A"
                    name="paymentFrequency"
                    aria-label="Radio 1"
                  />{" "}
                  &nbsp; Annually
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.paymentFrequencyError}
                  </div>
                </InputGroup>
              </Form.Group> */}
                  <FloatingLabel
                    controlId="paymentmode"
                    label="Payment Mode"
                    className="mb-2"
                  >
                    <Form.Control
                      as="select"
                      // onChange={this.handlePayModeChange}
                      onChange={handleChange("paymentMode")}
                      defaultValue="DD"
                      disabled
                      // defaultValue={values.paymentMode}
                      style={{
                        border: this.state.payModeError ? "1px solid red" : "",
                      }}
                    >
                      <option></option>
                      {paymentMode.map((payMode) => (
                        <option key={payMode.id} value={payMode.value}>
                          {payMode.label}
                        </option>
                      ))}
                    </Form.Control>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.payModeError}
                    </div>
                  </FloatingLabel>

                  <Form.Group>
                    <FloatingLabel
                      controlId="bank"
                      label="Bank Name"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        onChange={handleChange("bank")}
                        defaultValue="1029-ATWIMA KWANWOMA RURAL BANCASSURANCE"
                        disabled
                        style={{
                          border: this.state.bankError ? "1px solid red" : "",
                        }}
                      >
                        <option></option>
                        {banks.map((bank) => (
                          <option
                            key={bank.id}
                            value={bank.value + "-" + bank.label}
                          >
                            {bank.label}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankError}
                      </div>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group>
                    <FloatingLabel
                      controlId="bankBranch"
                      label="Transaction Branch"
                      className="mb-2"
                    >
                      <Form.Control
                        as="select"
                        onChange={handleChange("bankBranch")}
                        defaultValue={values.bankBranch}
                        style={{
                          border: this.state.bankBranchError
                            ? "1px solid red"
                            : "",
                        }}
                      >
                        <option></option>
                        {bankBranches.map((branch) => (
                          <option
                            key={branch.bbrCode}
                            value={branch.bbrCode + "-" + branch.bbrBranchName}
                          >
                            {branch.bbrBranchName}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.bankBranchError}
                      </div>
                    </FloatingLabel>
                    <Form.Group
                      style={{
                        color: "purple",
                      }}
                    >
                      <FloatingLabel
                        controlId="accountno"
                        label="Account Number"
                        className="mb-2"
                      >
                        <Form.Control
                          type="number"
                          autoComplete="off"
                          placeholder="Enter Your Account Number"
                          // onBlur={this.validateAccountNumber}
                          onChange={handleChange("accountNumber")}
                          defaultValue={values.accountNumber}
                          style={{
                            border: this.state.accountNumberError
                              ? "1px solid red"
                              : "",
                          }}
                        />
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.accountNumberError}
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                            color: "green",
                          }}
                        >
                          {this.state.accountName && (
                            <>
                              Account number verification successful{" "}
                              <FontAwesomeIcon icon={faCheck} />
                            </>
                          )}
                        </div>
                        {values.accountName && (
                          <div
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <b>{this.state.accountName}</b>
                          </div>
                        )}
                      </FloatingLabel>
                    </Form.Group>
                    <FloatingLabel
                      controlId="deductionDate"
                      label="Month of First Deduction"
                      className="mb-2"
                    >
                      {/* <Form.Control
                        type="month"
                        min={tm}
                        // placeholder="Enter Beneficiary Date of Birth"
                        // defaultValue={moment(newDOB).format("YYYY-MM-DD")}
                        onChange={this.handleDateChange}
                        defaultValue={values.deductionDate}
                        style={{
                          border: this.state.deductionDateError
                            ? "1px solid red"
                            : "",
                        }}
                      /> */}
                      <Form.Control
                        as="select"
                        onChange={this.handleDateChange}
                        defaultValue={values.deductionDate}
                        style={{
                          border: this.state.deductionDateError
                            ? "1px solid red"
                            : "",
                        }}
                      >
                        <option>
                          {values.deductionDate &&
                            moment(values.deductionDate, "YYYY-MM-DD").format(
                              "MMMM YYYY"
                            )}
                        </option>
                        {options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.deductionDateError}
                      </div>
                    </FloatingLabel>
                    {/* <Form.Group>
                      <div className="mb-3" style={{ textAlign: "left" }}>
                        Payment Frequency
                      </div>
                      <InputGroup
                        className="mb-3"
                        onChange={handleChange("paymentFrequency")}
                        defaultValue={values.paymentFrequency}
                      >
                        <InputGroup.Radio
                          style={{
                            border: this.state.paymentFrequencyError
                              ? "1px solid red"
                              : "",
                          }}
                          value="M"
                          name="paymentFrequency"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <InputGroup.Radio
                          style={{
                            border: this.state.paymentFrequencyError
                              ? "1px solid red"
                              : "",
                          }}
                          value="A"
                          name="paymentFrequency"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Annually
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.paymentFrequencyError}
                        </div>
                      </InputGroup>
                    </Form.Group> */}

                    <Form.Group>
                      <InputGroup
                        className="mb-3"
                        onChange={this.handleRadioChange}
                      >
                        <InputGroup.Radio
                          style={{
                            border: this.state.signatureTypeError
                              ? "1px solid red"
                              : "",
                          }}
                          value="Yes"
                          name="agentreferral"
                          aria-label="Radio 1"
                          // defaultChecked={this.state.checked}
                        />{" "}
                        &nbsp; Sign Signature &nbsp; &nbsp; &nbsp; &nbsp;
                        <InputGroup.Radio
                          style={{
                            border: this.state.signatureTypeError
                              ? "1px solid red"
                              : "",
                          }}
                          value="No"
                          name="agentreferral"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Upload Signature
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.signatureTypeError}
                        </div>
                      </InputGroup>
                    </Form.Group>
                    {this.state.showSignatureCanvas && (
                      <Form.Group>
                        {/* <FloatingLabel
                        controlId="signature"
                        label="Signature"
                        className="mb-2"
                      > */}
                        <div className="mb-" style={{ textAlign: "left" }}>
                          Signature
                        </div>
                        <div
                          style={{
                            textAlign: "left",
                          }}
                        >
                          <SignatureCanvas
                            // textAlign="left"
                            ref={this.sigRef}
                            // penColor="purple"
                            canvasProps={{
                              className: "signatureCanvas",
                              // width: 500,
                              // height: 200,
                              // className: "sigCanvas",
                            }}
                            style={{
                              border: this.state.signatureError
                                ? "1px solid red"
                                : "",
                            }}
                          />
                          {/* <CameraFeed /> */}
                          <br></br>
                          <Button variant="danger" onClick={this.clear}>
                            Clear&nbsp;
                            <FontAwesomeIcon icon={faEraser} />
                          </Button>
                          <div
                            style={{
                              textAlign: "left",
                              color: "red",
                            }}
                          >
                            {this.state.signatureError}
                          </div>
                        </div>

                        {/* </FloatingLabel> */}
                      </Form.Group>
                    )}
                    {this.state.showSignatureUpload && (
                      <Form.Group>
                        <div className="mb-" style={{ textAlign: "left" }}>
                          Upload Signature
                        </div>
                        <div style={{ textAlign: "left" }}>
                          {this.state.file && (
                            <img
                              src={this.state.file}
                              width="100"
                              height="50"
                            />
                          )}
                        </div>

                        <Form.Control
                          accept="image/*"
                          type="file"
                          controlId="signatureUpload"
                          label="Upload Signature"
                          className="mb-2"
                          onChange={this.onFileChange}
                          style={{
                            border: this.state.uploadSignatureError
                              ? "1px solid red"
                              : "",
                          }}
                        ></Form.Control>

                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.uploadSignatureError}
                        </div>
                      </Form.Group>
                    )}

                    <Form.Group>
                      <div className="mb-" style={{ textAlign: "left" }}>
                        Upload Ghana Card (Image of front)
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {this.state.newImage && (
                          <img
                            src={this.state.newImage}
                            width="200"
                            height="100"
                          />
                        )}
                      </div>
                      <Form.Control
                        accept="image/*"
                        type="file"
                        controlId="ghanacardUpload"
                        label="Upload Ghana Card"
                        className="mb-2"
                        onChange={this.fileChangedHandler}
                        style={{
                          border: this.state.ghanaCardError
                            ? "1px solid red"
                            : "",
                        }}
                      ></Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.ghanaCardError}
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="mb-" style={{ textAlign: "left" }}>
                        Upload Ghana Card (Image of back)
                      </div>
                      <div style={{ textAlign: "left" }}>
                        {this.state.backImage && (
                          <img
                            src={this.state.backImage}
                            width="200"
                            height="100"
                          />
                        )}
                      </div>
                      <Form.Control
                        accept="image/*"
                        type="file"
                        controlId="ghanacardUploadback"
                        label="Upload Ghana Card"
                        className="mb-2"
                        onChange={this.fileChangedHandlerBack}
                        style={{
                          border: this.state.ghanaCardBackError
                            ? "1px solid red"
                            : "",
                        }}
                      ></Form.Control>
                      <div
                        style={{
                          textAlign: "left",
                          color: "red",
                        }}
                      >
                        {this.state.ghanaCardBackError}
                      </div>
                    </Form.Group>
                  </Form.Group>
                </Form.Group>{" "}
                {/* <button onClick={this.initializeMedia}>Take Photo</button> */}
                <br></br>
                <Button
                  variant="secondary"
                  // type="submit"
                  // style={{ backgroundColor: "#763984" }}
                  onClick={this.back}
                >
                  Back
                </Button>
                &nbsp;
                <Button
                  variant="primary"
                  // type="submit"
                  style={{
                    backgroundColor: "#009900",
                    outlineColor: "#191c4a",
                    borderColor: "#191c4a",
                    color: "#fff",
                  }}
                  onClick={this.continue}
                >
                  Continue
                </Button>
              </Form>
              <br></br>
              <BottomButton
                step={this.props.step}
                values={this.props.values}
                getDecryptedData={this.props.getDecryptedData}
                saveEncryptedData={this.props.saveEncryptedData}
                resetStep={this.props.resetStep}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

export default FormPaymentDetails;
