import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faEraser } from "@fortawesome/free-solid-svg-icons";
import capital from "../../capital-logo.png";
import { InputGroup } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import "../sigCanvas.css";
import BankBranchesData from "./data/BankBranchesData";

export class FormPaymentDetails extends Component {
  state = {
    payModeError: "",
    bankError: "",
    bankBranchError: "",
    accountNumberError: "",
    paymentFrequencyError: "",
    signatureError: "",
    showBankTextBoxes: false,
    showMoMoTextBoxes: false,
    deductionDateError: "",
  };

  sigRef = React.createRef({});

  clear = (e) => {
    e.preventDefault();
    // console.log(this.sigRef.current.getTrimmedCanvas().toDataURL("image/png"));
    this.sigRef.current.clear();
  };

  handlePayModeChange = (e) => {
    console.log(e.target.value);
    if (e.target.value === "DD") {
      this.setState({ showBankTextBoxes: true });
      this.setState({ showMoMoTextBoxes: false });
      // this.handleChange("paymentMode");
      const paymentMode = e.target.value;
      this.props.valueChange(["paymentMode", paymentMode]);
      //   this.props.nextStep(5);
      // alert("Client has existing policy.");
      // return true;
    } else {
      this.setState({ showBankTextBoxes: false });
      this.setState({ showMoMoTextBoxes: true });
      // this.handleChange("paymentMode");
      const paymentMode = e.target.value;
      this.props.valueChange(["paymentMode", paymentMode]);
    }
  };

  continue = (e) => {
    e.preventDefault();
    console.log(e);
    console.log(
      this.sigRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
        .split(",")[1]
    );
    // console.log(this.sigRef.current.isEmpty());
    let payModeError = "";
    let bankError = "";
    let bankBranchError = "";
    let accountNumberError = "";
    let paymentFrequencyError = "";
    let signatureError = "";
    let deductionDateError = "";

    if (!e.target.form[0].checked & !e.target.form[1].checked) {
      // Checks if field is empty
      paymentFrequencyError = "Please indicated your payment frequency";
    }

    if (!e.target.form[2].value) {
      // Checks if field is empty
      payModeError = "Please select your payment method";
    }

    if (e.target.form[2].value === "DD") {
      if (!e.target.form[3].value) {
        // Checks if field is empty
        bankError = "Please select your bank";
      }

      if (!e.target.form[4].value) {
        // Checks if field is empty
        bankBranchError = "Please select your bank branch";
      }

      if (!e.target.form[5].value) {
        // Checks if field is empty
        accountNumberError = "Please enter your bank account number";
      }

      if (e.target.form[5].value) {
        if (
          !(
            e.target.form[5].value.length >= 16 &&
            e.target.form[5].value.length <= 16
          )
        ) {
          accountNumberError = "Please enter a valid bank account number";
        }
      }

      if (!e.target.form[6].value) {
        // Checks if field is empty
        deductionDateError = "Please enter first deduction date";
      }

      if (this.sigRef.current.isEmpty() === true) {
        // Checks if field is empty
        signatureError = "Your signature is required";
      }

      if (this.sigRef.current.isEmpty() === false) {
        // Checks if field is empty
        this.props.valueChange([
          "signature",
          this.sigRef.current
            .getTrimmedCanvas()
            .toDataURL("image/png")
            .split(",")[1],
        ]);
      }
    }

    // if (!e.target.form[3].value) {
    //   // Checks if field is empty
    //   bankError = "Please select your bank";
    // }

    // if (!e.target.form[4].value) {
    //   // Checks if field is empty
    //   bankBranchError = "Please select your bank branch";
    // }

    // if (!e.target.form[5].value) {
    //   // Checks if field is empty
    //   accountNumberError = "Please enter your bank account number";
    // }

    // if (!e.target.form[6].checked & !e.target.form[7].checked) {
    //   // Checks if field is empty
    //   paymentFrequencyError = "Please indicated your payment frequency";
    // }

    // if (this.sigRef.current.isEmpty() === true) {
    //   // Checks if field is empty
    //   signatureError = "Your signature is required";
    // }

    // if (this.sigRef.current.isEmpty() === false) {
    //   // Checks if field is empty
    //   this.props.valueChange([
    //     "signature",
    //     this.sigRef.current.getTrimmedCanvas().toDataURL("image/png"),
    //   ]);
    // }

    if (
      payModeError ||
      bankError ||
      bankBranchError ||
      accountNumberError ||
      paymentFrequencyError ||
      signatureError ||
      deductionDateError
    ) {
      this.setState({
        payModeError,
        bankError,
        bankBranchError,
        accountNumberError,
        paymentFrequencyError,
        signatureError,
        deductionDateError,
      });
      return false;
    }

    this.props.nextStep();
    return true;
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;

    const paymentMode = [
      { id: "1", label: "MoMo", value: "C" },
      { id: "2", label: "Bank", value: "DD" },
    ];

    const bankBranches = BankBranchesData;

    const banks = [
      {
        id: "1024",
        label: "CAPITAL RURAL BANCASSURANCE",
        value: "1024",
      },
      { id: "2", label: "Access", value: "Access" },
      { id: "3", label: "Fidelity", value: "Fidelity" },
    ];

    // Use Javascript
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var day = tomorrow.getDay();
    var dd = tomorrow.getDate();
    var mm = tomorrow.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (day === 0) {
      dd = dd + 1;
    }

    if (day === 6) {
      dd = dd + 2;
    }

    var tm = yyyy + "-" + mm + "-" + dd;

    console.log("Today " + today.getDay());
    console.log("Tooorrow " + tomorrow.getDay());
    console.log(day);

    // Using state to keep track of what the selected occupation is selected
    // this.setState(occs);

    // Using this function to update the state of fruit
    // whenever a new option is selected from the dropdown

    return (
      <div>
        <br></br>
        <img src={capital} alt="logo" />
        <br></br>
        <br></br>
        <h2>
          Thanks{" "}
          {values["firstName"].toLowerCase().charAt(0).toUpperCase() +
            values["firstName"].toLowerCase().slice(1)}
          , Please add your payment details.
        </h2>
        <div
          className="container center_div"
          style={{
            display: "block",
            width: 500,
            padding: 10,
          }}
        >
          {/* <h2>
            <img src={mypadie} alt="logo" style={{ width: "70px" }} />
            Nice to meet you, {values["firstName"]}! Please add your employment
            details.
          </h2> */}
          {/* <br></br> */}
          <h4>
            <FontAwesomeIcon icon={faMoneyBill} /> &nbsp; <u>Payment Details</u>
          </h4>
          <Form
            style={{
              color: "purple",
            }}
          >
            <Form.Group>
              <Form.Group>
                <div className="mb-3" style={{ textAlign: "left" }}>
                  Payment Frequency
                </div>
                <InputGroup
                  className="mb-3"
                  onChange={handleChange("paymentFrequency")}
                  defaultValue={values.paymentFrequency}
                >
                  <InputGroup.Radio
                    style={{
                      border: this.state.paymentFrequencyError
                        ? "1px solid red"
                        : "",
                    }}
                    value="M"
                    name="paymentFrequency"
                    aria-label="Radio 1"
                  />{" "}
                  &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <InputGroup.Radio
                    style={{
                      border: this.state.paymentFrequencyError
                        ? "1px solid red"
                        : "",
                    }}
                    value="A"
                    name="paymentFrequency"
                    aria-label="Radio 1"
                  />{" "}
                  &nbsp; Annually
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.paymentFrequencyError}
                  </div>
                </InputGroup>
              </Form.Group>
              <FloatingLabel
                controlId="paymentmode"
                label="Payment Mode"
                className="mb-2"
              >
                <Form.Control
                  as="select"
                  // onChange={this.handlePayModeChange}
                  onChange={handleChange("paymentMode")}
                  defaultValue="DD"
                  disabled
                  // defaultValue={values.paymentMode}
                  style={{
                    border: this.state.payModeError ? "1px solid red" : "",
                  }}
                >
                  <option></option>
                  {paymentMode.map((payMode) => (
                    <option key={payMode.id} value={payMode.value}>
                      {payMode.label}
                    </option>
                  ))}
                </Form.Control>
                <div
                  style={{
                    textAlign: "left",
                    color: "red",
                  }}
                >
                  {this.state.payModeError}
                </div>
              </FloatingLabel>

              <Form.Group>
                <FloatingLabel
                  controlId="bank"
                  label="Bank Name"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("bank")}
                    defaultValue="1024-CAPITAL RURAL BANCASSURANCE"
                    disabled
                    style={{
                      border: this.state.bankError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {banks.map((bank) => (
                      <option
                        key={bank.id}
                        value={bank.value + "-" + bank.label}
                      >
                        {bank.label}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.bankError}
                  </div>
                </FloatingLabel>
              </Form.Group>
              <Form.Group>
                <FloatingLabel
                  controlId="bankBranch"
                  label="Bank Branch"
                  className="mb-2"
                >
                  <Form.Control
                    as="select"
                    onChange={handleChange("bankBranch")}
                    defaultValue={values.bankBranch}
                    style={{
                      border: this.state.bankBranchError ? "1px solid red" : "",
                    }}
                  >
                    <option></option>
                    {bankBranches.map((branch) => (
                      <option
                        key={branch.bbrCode}
                        value={branch.bbrCode + "-" + branch.bbrBranchName}
                      >
                        {branch.bbrBranchName}
                      </option>
                    ))}
                  </Form.Control>
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.bankBranchError}
                  </div>
                </FloatingLabel>
                <Form.Group
                  style={{
                    color: "purple",
                  }}
                >
                  <FloatingLabel
                    controlId="accountno"
                    label="Account Number"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Your Account Number"
                      onChange={handleChange("accountNumber")}
                      defaultValue={values.accountNumber}
                      style={{
                        border: this.state.accountNumberError
                          ? "1px solid red"
                          : "",
                      }}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.accountNumberError}
                    </div>
                  </FloatingLabel>
                </Form.Group>
                <FloatingLabel
                  controlId="deductionDate"
                  label="Date of First Deduction"
                  className="mb-2"
                >
                  <Form.Control
                    type="date"
                    min={tm}
                    // placeholder="Enter Beneficiary Date of Birth"
                    // defaultValue={moment(newDOB).format("YYYY-MM-DD")}
                    onChange={handleChange("deductionDate")}
                    defaultValue={values.deductionDate}
                    style={{
                      border: this.state.deductionDateError
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                      color: "red",
                    }}
                  >
                    {this.state.deductionDateError}
                  </div>
                </FloatingLabel>
                {/* <Form.Group>
                      <div className="mb-3" style={{ textAlign: "left" }}>
                        Payment Frequency
                      </div>
                      <InputGroup
                        className="mb-3"
                        onChange={handleChange("paymentFrequency")}
                        defaultValue={values.paymentFrequency}
                      >
                        <InputGroup.Radio
                          style={{
                            border: this.state.paymentFrequencyError
                              ? "1px solid red"
                              : "",
                          }}
                          value="M"
                          name="paymentFrequency"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Monthly &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <InputGroup.Radio
                          style={{
                            border: this.state.paymentFrequencyError
                              ? "1px solid red"
                              : "",
                          }}
                          value="A"
                          name="paymentFrequency"
                          aria-label="Radio 1"
                        />{" "}
                        &nbsp; Annually
                        <div
                          style={{
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          {this.state.paymentFrequencyError}
                        </div>
                      </InputGroup>
                    </Form.Group> */}
                <Form.Group>
                  {/* <FloatingLabel
                        controlId="signature"
                        label="Signature"
                        className="mb-2"
                      > */}
                  <div className="mb-" style={{ textAlign: "left" }}>
                    Signature
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <SignatureCanvas
                      // textAlign="left"
                      ref={this.sigRef}
                      // penColor="purple"
                      canvasProps={{
                        className: "signatureCanvas",
                        // width: 500,
                        // height: 200,
                        // className: "sigCanvas",
                      }}
                      style={{
                        border: this.state.signatureError
                          ? "1px solid red"
                          : "",
                      }}
                    />

                    <br></br>
                    <Button variant="danger" onClick={this.clear}>
                      Clear&nbsp;
                      <FontAwesomeIcon icon={faEraser} />
                    </Button>
                    <div
                      style={{
                        textAlign: "left",
                        color: "red",
                      }}
                    >
                      {this.state.signatureError}
                    </div>
                  </div>

                  {/* </FloatingLabel> */}
                </Form.Group>
              </Form.Group>
            </Form.Group>{" "}
            <br></br>
            <Button
              variant="secondary"
              // type="submit"
              // style={{ backgroundColor: "#763984" }}
              onClick={this.back}
            >
              Back
            </Button>
            &nbsp;
            <Button
              variant="primary"
              // type="submit"
              style={{
                backgroundColor: "#2B5A9B",
                outlineColor: "#ffc000",
                borderColor: "#2B5A9B",
                color: "#fff",
              }}
              onClick={this.continue}
            >
              Continue
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default FormPaymentDetails;
